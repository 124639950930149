<template>
  <div class="row align-items-center mb-3">
    <div class="col">
      <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">Associated Specifications</div>
    </div>
    <div class="col-auto">
      <button
        type="button"
        class="button btn-small btn-success-solid text-center d-flex align-items-center"
        @click="OpenSpecifcationModal"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 10.5H15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 15.5V5.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Add specifications
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <!--table-->
    <div
      class="row label-02 fw-semibold text-neutral-07 row-border-bottom ms-0 me-0 mb-3 set-width"
      style="padding-bottom: 12px"
    >
      <div class="col-3 ps-0">Specifications</div>
      <div class="col pe-0">
        <div class="row ms-32px">
          <div class="col-2 ps-0">Value</div>
          <div class="col">
            <span class="ms-32px">Assigned product</span>
          </div>
        </div>
      </div>
    </div>
    <Form :validation-schema="specSchema" ref="productSpecificationsFormRef" v-slot="{ errors }">
      <FieldArray name="associatedSpecs">
        <div
          class="row row-border-bottom border-bottom-highlighted ms-0 pt-3 pb-3 set-width"
          v-for="(spec, specIndex) in associatedSpecifications"
          :key="specIndex"
        >
          <div class="col-4 col-xl-3 ps-0">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="heading-02 fw-semibold text-neutral-08">{{ spec.Name }}</div>
              <button type="button" class="button table-btn" @click="$emit('delete', spec.Id)">
                <img src="../../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
            <label for="description" class="label-03 text-neutral-07 fw-semibold mb-2"
              >Description</label
            >
            <Field
              v-model="spec.Description"
              as="textarea"
              rows="3"
              cols="50"
              name="description"
              id="description"
              class="form-control form-control-lg"
              placeholder="Description.."
              :class="{ 'border-danger': errors.description }"
            ></Field>
            <ErrorMessage class="error-message" name="description" />
          </div>
          <div class="col">
            <Form :validation-schema="schema" ref="productSpecificationsFormRef">
              <FieldArray :name="`assigned[${specIndex}].values`">
                <div
                  class="row align-items-center ms-32px mb-3 pb-3 me-0"
                  :class="{ 'row-border-bottom': index < spec.AssignedProducts.length - 1 }"
                  v-for="(assigned, index) in spec.AssignedProducts"
                  :key="index"
                >
                  <div class="col-2 ps-0">
                    <VueMultiselect
                      class="custom-multiselect lg"
                      v-model="assigned.Value"
                      :options="assigned.Values"
                      :close-on-select="true"
                      track-by="Id"
                      placeholder="Select one"
                      select-label=""
                      label="Value"
                    />
                    <Field name="value" v-model="assigned.Value" class="d-none"></Field>
                    <ErrorMessage class="error-message" name="value" />
                  </div>
                  <div class="col">
                    <div class="ms-32px">
                      <div class="label-02 fw-semibold text-neutral-08 mb-1">
                        {{ assigned.Title }}
                      </div>
                      <div class="paragraph-01 text-neutral-06" :title="assigned.Description">
                        {{ this.$utils.Ellipsify(assigned.Description, 70) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto pe-0">
                    <button
                      type="button"
                      class="button table-btn"
                      @click="OpenChangeProductModal(assigned, spec)"
                    >
                      <span
                        class="text-button-01 fw-semibold text-neutral-07"
                        style="line-height: 1.667"
                        >Change product</span
                      >
                    </button>
                  </div>
                  <div class="col-auto pe-0">
                    <button
                      type="button"
                      class="button table-btn"
                      @click="$emit('delete', assigned.Id)"
                    >
                      <img src="../../../assets/images/icons/table/trash-linear.svg" />
                    </button>
                  </div>
                </div>
              </FieldArray>
            </Form>
            <div class="ms-32px">
              <button type="button" @click="OpenValuesModal(spec)" class="button btn-add-values">
                <div class="text-center d-flex align-items-center">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5 10.5H15.5"
                      stroke="#53666D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 15.5V5.5"
                      stroke="#53666D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div class="text-button-02 fw-semibold text-neutral-06">Add values</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </FieldArray>
    </Form>
    <!-- <div>
        <div class="label-02 fw-semibold text-neutral-08 mb-1">
          {{ recomandation.Title }}
        </div>
        <div class="paragraph-01 text-neutral-06">
          {{ recomandation.Description }}
        </div>
      </div> -->
    <div
      v-if="!associatedSpecifications || associatedSpecifications.length == 0"
      class="mt-5 pt-5 text-center"
    >
      <img src="../../../assets/images/not-found-img.png" />
    </div>
  </div>

  <AddSpecifications
    ref="addSpecificationModalRef"
    @add-specifications="AddSpecification"
    v-if="selectedTab === 'associated-specifications'"
  />
  <change-product-modal ref="changeProductModalRef" :product="selectedProd" />
  <AddValuesModal ref="addValuesModalRef" @add-values="AddValuesToSpecification" />
</template>

<script>
/*eslint-disable*/
import { Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';
import AddSpecifications from './Modals/AddSpecificationsModal.vue';
import AddValuesModal from './Modals/AddValuesModal.vue';
import ChangeProductModal from './Modals/ChangeProductModal.vue';

export default {
  name: 'ProductAssociatedSpecs',
  emits: ['get', 'add', 'delete'],
  props: {
    associatedSpecifications: Array,
    product: Object,
    selectedTab: String,
  },
  data() {
    return {
      pagination: {},
      filter: {
        PageSize: this.$store.state.globalPageSize,
        PageNumber: 1,
        SearchQuery: '',
      },
      selectedSpecification: {},
      valuesList: [],
      selectedProd: {},
    };
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray,
    Form,
    AddSpecifications,
    AddValuesModal,
    ChangeProductModal,
  },
  methods: {
    //change product
    OpenChangeProductModal(product, specification) {
      this.selectedProd = product;
      this.selectedProd.SpecificationName = specification.Name;
      this.$refs.changeProductModalRef.ClearModal();
      this.$refs.changeProductModalRef.GetProducts();
      // eslint-disable-next-line no-undef
      $('#changeProductModal').modal('show');
    },

    //values
    OpenValuesModal(specification) {
      this.selectedSpecification = specification;
      // this.$refs.addValuesModalRef.ClearModal();
      this.$refs.addValuesModalRef.GetValues();
      // eslint-disable-next-line no-undef
      $('#addValuesModal').modal('show');
    },
    AddValuesToSpecification(values) {
      debugger;
      console.log(values);
      values.forEach((specification) => {
        this.selectedSpecification.Specifications.push({
          SpecificationId: specification.Id,
          Name: specification.Name,
          Values: specification.Values,
        });
      });
    },

    //specifications
    OpenSpecifcationModal() {
      this.$refs.addSpecificationModalRef.ClearSpecificationModal();
      this.$refs.addSpecificationModalRef.GetSpecifcationCategories();
      // eslint-disable-next-line no-undef
      $('#addSpecificationModal').modal('show');
    },
    AddSpecification(specifications) {
      specifications.forEach((specification) => {
        this.associatedSpecifications.push({
          SpecificationId: specification.Id,
          Name: specification.Name,
          Values: specification.Values,
          AssignedProducts: [{ 
            SpecificationId: specification.Id,
            Title: this.product.Title,
            Id: this.product.Id,
            IsDisabled: true,
            Values: specification.Values,
          }] 
           
        });
      });

    
    },
    Submit() {
      return this.$refs.productPackageFormRef.validate().then((result) => result.valid);
    },
  },
  computed: {
    schema() {
      return yup.object({
        assigned: yup.array().of(
          yup.object().shape({
            values: yup.array().of(yup.object().required()),
          }),
        ),
      });
    },
    specSchema() {
      return yup.object({
        time: yup.array().of(
          yup.object().shape({
            start: yup.string().required(' Start time cannot be empty'),
            end: yup
              .string()
              .required('end time cannot be empty ')
              // eslint-disable-next-line func-names
              .test('is-greater', 'Time span is invalid ', function (value) {
                const { start } = this.parent;
                return moment(value, 'HH:mm').isSameOrAfter(moment(start, 'HH:mm'));
              }),
          }),
        ),
      });
    },
  },
};
</script>

<style scoped>
.table-responsive {
  overflow-x: hidden;
}
@media only screen and (max-width: 1200px) {
  .table-responsive {
    overflow-x: auto;
  }
  .table-responsive .set-width {
    width: 1200px;
  }
}

.ms-32px {
  margin-left: 32px;
}

.border-bottom-highlighted {
  border-bottom: 1px solid var(--blacktint-3);
}

.btn-add-values {
  object-fit: cover;
  width: 100%;
  height: 53px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.btn-add-values:hover {
  background-color: var(--neutral-2);
}
</style>
