/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */

import { createApp } from 'vue';
import App from './App.vue';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import './assets/main.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import utils from './utils/utils';

import router from './router';
import store from './store';

import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import VueApexCharts from 'vue3-apexcharts';

import auth from './services/auth.service';
import axios from 'axios';
import authHeader from './services/auth-header';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

library.add(fas);
library.add(far);

const app = createApp(App);

app.component('VueDatePicker', VueDatePicker);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('VueMultiselect', VueMultiselect);

app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.use(VueApexCharts);
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$auth = auth;

const baseURL = process.env.VUE_APP_BASEURL;

const axiosConfig = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: authHeader(),
  },
});

axiosConfig.interceptors.request.use(
  (config) => {
    store.commit('toggle');
    return config;
  },
  (error) => {
    store.commit('toggle');
    return Promise.reject(error);
  },
);

axiosConfig.interceptors.response.use(
  (res) => {
    store.commit('toggle');
    return res;
  },
  (err) => {
    const originalConfig = err.config;
    // console.log(err);
    if (
      originalConfig.url !== `${baseURL}/api/Auth/login` &&
      originalConfig.url !== `${baseURL}/api/Account/register` &&
      err.response
    ) {
      if (err.response.status === 401) {
        return store
          .dispatch('auth/refresh')
          .then(() => {
            originalConfig.headers.Authorization = authHeader();
            axiosConfig.defaults.headers.Authorization = authHeader();
            store.commit('toggle');
            return axiosConfig(originalConfig);
          })
          .catch((error) => {
            if (error.response.status === 408) {
              router.push('/login');
              store.dispatch('auth/logout');
            }
            store.commit('toggle');
            return Promise.reject(err);
          });
      }
    }
    store.commit('toggle');
    return Promise.reject(err);
  },
);

app.config.globalProperties.$axios = {
  ...axiosConfig,
};
app.mount('#app');
