<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="modal fade"
    id="editUserLocationModal"
    tabindex="-1"
    aria-labelledby="editUserLocationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="editLocationFormRef"
          @submit="Submit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../assets/images/icons/edit-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addBrandModalLabel">Edit address</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ClearModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="address">Address</label>
              <Field
                v-model="location.Address"
                type="text"
                class="form-control"
                name="address"
                id="address"
                placeholder="Address"
                :class="{ 'border-danger': errors.address }"
              />
              <ErrorMessage name="address" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="city">City</label>
              <Field
                v-model="location.City"
                type="text"
                class="form-control"
                name="city"
                id="city"
                placeholder="City"
                :class="{ 'border-danger': errors.city }"
              />
              <ErrorMessage name="city" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="state">State</label>
              <Field
                v-model="location.State"
                type="text"
                class="form-control"
                name="state"
                id="state"
                placeholder="State"
                :class="{ 'border-danger': errors.state }"
              />
              <ErrorMessage name="state" class="error-message" />
            </div>
            <label for="country">Country</label>
            <Field
              v-model="location.Country"
              type="text"
              class="form-control"
              name="country"
              id="country"
              placeholder="Country"
              :class="{ 'border-danger': errors.country }"
            />
            <ErrorMessage name="country" class="error-message" />
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'EditUserLocationModal',
  emits: ['edit'],
  props: {
    location: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      this.$emit('edit', this.location);
    },
    ClearModal() {
      this.$refs.editLocationFormRef.resetForm();
    },
  },
  computed: {
    schema() {
      return yup.object({
        address: yup.string().required('Address is required')
          .max(200, 'Address must be under 100 characters')
          .trim(),
        city: yup.string().required('City is required')
          .max(50, 'City must be under 100 characters')
          .trim(),
        state: yup.string().required('State is required')
          .max(50, 'State must be under 100 characters')
          .trim(),
        country: yup.string().required('Country is required')
          .max(50, 'Country must be under 100 characters')
          .trim(),
      });
    },
  },
};
</script>
