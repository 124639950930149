<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">
    Fidelity points
    <img
      class="ms-2"
      src="../../assets/images/icons/question-circle-24x24.svg"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      title="Fusce porta gravida ullamcorper. Maecenas et felis finibus, vehicula est vel, egestas purus. Nulla tincidunt tincidunt lectus, at malesuada diam congue non."
    />
  </div>
  <Form :validation-schema="schema" ref="fidelityPointsFormRef" v-slot="{ errors }">
    <div class="row align-items-center">
      <div class="col-md-6 col-xl mb-3 mb-xl-0">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">Points number</label>
        <Field
          v-model="fidelityPoints.Points"
          name="points"
          type="number"
          class="form-control form-control-lg"
          placeholder="Enter points number"
          :class="{ 'border-danger': errors.points }"
        />
        <ErrorMessage name="points" class="error-message" />
      </div>
      <div class="col-md-6 col-xl mb-3 mb-xl-0">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">Minimum products</label>
        <Field
          v-model="fidelityPoints.MinimumProducts"
          name="minProduct"
          type="number"
          class="form-control form-control-lg"
          :class="{ 'border-danger': errors.minProduct }"
        />
        <ErrorMessage name="minProduct" class="error-message" />
      </div>
      <div class="col-auto d-flex mt-4 pt-1">
        <label
          class="label-02 fw-semibold text-neutral-07 pointer"
          for="fidelity-points-add-product-switch"
          >Fidelity <br class="d-none d-xl-block" />
          program</label
        >
        <div class="ms-3">
          <input
            v-model="fidelityPoints.IsAvailable"
            class="switch-small switch-small--shadow"
            id="fidelity-points-add-product-switch"
            type="checkbox"
            role="switch"
            placeholder="Enter minimum products"
          />
          <label for="fidelity-points-add-product-switch" class="mb-0"></label>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ProductFidelityPoints',
  props: {
    product: {},
    fidelityPoints: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      return this.$refs.fidelityPointsFormRef.validate().then((result) => result.valid);
    },
  },
  computed: {
    schema() {
      return yup.object({
        points: yup.number().min(0, 'This number must be positive'),
        minProduct: yup.number().min(0, 'This number must be positive'),
      });
    },
  },
};
</script>
