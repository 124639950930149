/* eslint-disable */
import Swal from 'sweetalert2';
import moment from 'moment';
import DOMPurify from 'dompurify';



const utils = {
  sanitizeInput(input) {
    return DOMPurify.sanitize(input, {
      ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'b', 'p', 'li', 'ul', 'ol', 'h1', 'h2', 'h3', 'h4', 'h5', 'u', 's', 'span'],
      ALLOWED_ATTR: [],  // Block href and any other attributes
    });
  },
  FormatDayMonthYear(value) {
    if (!value) return null;
    return moment(value).format('DD.MM.YYYY');
  },
  FormatDayMonth(value) {
    if (!value) return null;
    return moment(value).format('DD.MM');
  },
  toastNotify(Type, Title, Timer) {
    // eslint-disable-next-line no-param-reassign
    if (!Timer) Timer = 2500;
    Swal.fire({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: Timer,
      timerProgressBar: true,
      icon: Type,
      title: Title,
    });
  },
  FormatNumber: function (number) {
    if (number) {
      return number.toLocaleString('en-US');
    }
    return 0;
  },
  GetImageFormat(imageBase64) {
    if (!imageBase64) {
      // eslint-disable-next-line global-require
      return require('@/assets/images/no-content-found.png');
    }
    return imageBase64;
  },
  InputImage(item) {
    // eslint-disable-next-line no-undef, no-restricted-globals
    const fileInput = event.target;
    const file = fileInput.files[0];
    if (file && !file.type.includes('image')) {
      this.toastNotify('warning', 'Only images are allowed.');
      return;
    }
    // 2 544 856 == 2.42mb
    if (file && file.size > 1055000) {
      this.toastNotify('warning', 'Image size should be maximum 1MB.');
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      item.ImgBase64 = reader.result;
      fileInput.value = '';
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  },
  Ellipsify(str, length) {
    if (str) {
      if (str.length > length) {
        return `${str.substring(0, length)}...`;
      }
    } else {
      return null;
    }
    return str;
  },
  EllipsifyReverse(str, length) {
    console.log(str.length);
    if (str) {
      if (str.length > length) {
        return `...${str.substring(str.length - length, length)}`;
      }
    } else {
      return null;
    }
    return str;
  },
  DynamicPathAttachment(name) {
    if (!name) {
      // eslint-disable-next-line global-require
      return require('@/assets/images/no-content-found.png');
    }
    // eslint-disable-next-line global-require, import/no-dynamic-require
    return require(`@/attachments/categories/${name}.svg`);
  },
};

export default utils;
