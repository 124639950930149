<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.213 20.09C25.213 21.81 26.6263 23.21 28.3463 23.21C28.3463 28.21 27.093 29.4634 22.093 29.4634H9.58634C4.58634 29.4634 3.33301 28.21 3.33301 23.21V22.5967C5.05301 22.5967 6.46634 21.1834 6.46634 19.4634C6.46634 17.7434 5.05301 16.33 3.33301 16.33V15.7167C3.34634 10.7167 4.58634 9.46338 9.58634 9.46338H22.0797C27.0797 9.46338 28.333 10.7167 28.333 15.7167V16.97C26.613 16.97 25.213 18.3567 25.213 20.09Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.6151 9.46317H9.49512L13.4018 5.5565C16.5885 2.36984 18.1884 2.36984 21.3751 5.5565L22.1751 6.3565C21.3351 7.1965 21.1351 8.4365 21.6151 9.46317Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.1719 9.46338L13.1719 29.4634"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-dasharray="5 5"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="OpenAddCouponModal"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Add <span class="d-none d-sm-inline-block">coupon</span>
        </button>
      </div>
    </div>
  </div>

  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center filters-zone mb-3">
      <div class="mb-3 mb-lg-0 col-lg col-xxl-3">
        <label for="search">Search</label>

        <div class="input-group left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="search"
            v-model="filter.SearchQuery"
            @keyup.enter="GetCoupons"
            type="text"
            class="search form-control"
            placeholder="Search in..."
          />
        </div>
      </div>
      <div class="mb-3 mb-md-0 col-md col-xxl-3">
        <label for="price-type"> Price type</label>
        <div :class="{ 'multiselect-value-selected': filter.SearchType }">
          <VueMultiselect
            id="price-type"
            v-model="filter.SearchType"
            :options="['Amount', 'Percentage']"
            :close-on-select="true"
            @select="GetCoupons"
            @remove="GetCoupons"
            class="custom-multiselect"
            select-label=""
            placeholder="Select price type"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.SearchType = '';
              GetCoupons();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md col-xxl-3">
        <label for="availability"> Availability</label>
        <div :class="{ 'multiselect-value-selected': filter.SearchAvailability }">
          <VueMultiselect
            id="availability"
            v-model="filter.SearchAvailability"
            :options="['Available', 'Unavailable']"
            :close-on-select="true"
            @select="GetCoupons"
            @remove="GetCoupons"
            class="custom-multiselect"
            select-label=""
            placeholder="Select availability"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.SearchAvailability = '';
              GetCoupons();
            "
          >
          </span>
        </div>
      </div>
    </div>
    <div class="table-scroll">
      <!--table-->
      <table class="custom table">
        <thead>
          <tr valign="middle">
            <th :class="{ active: filter.OrderBy.includes('name') }" width="13%">
              <span class="pointer" @click="OrderBy('name')">
                Name
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="name"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('startDate') }" width="9%">
              <span class="pointer" @click="OrderBy('startDate')">
                Start date
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="startDate"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('endDate') }" width="9%">
              <span class="pointer" @click="OrderBy('endDate')">
                End date
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="endDate"
                  class="ms-1"
                />
              </span>
            </th>
            <th width="8%">Type</th>
            <th :class="{ active: filter.OrderBy.includes('uses') }" width="7%">
              <span class="pointer" @click="OrderBy('uses')">
                Uses
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="uses"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('minPrice') }">
              <span class="d-flex align-items-center pointer" @click="OrderBy('minPrice')">
                <span class="me-2">
                  Minimum <br />
                  order
                </span>
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="minPrice"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('timesUsed') }" width="11%">
              <span class="pointer" @click="OrderBy('timesUsed')">
                Time used
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="timesUsed"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('fidelityPoints') }" width="8%">
              <span class="d-flex align-items-center pointer" @click="OrderBy('fidelityPoints')">
                <span class="me-2">
                  Fidelity <br />
                  points
                </span>
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="fidelityPoints"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('value') }">
              <span class="pointer" @click="OrderBy('value')">
                Value
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="value"
                  class="ms-1"
                />
              </span>
            </th>
            <th>Available</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(coupon, index) in coupons" :key="index" valign="middle">
            <td>
              <div class="title">{{ coupon.Name }}</div>
              <div
                class="text-button-01 fw-normal"
                v-if="coupon.Description"
                :title="coupon.Description"
              >
                {{ this.$utils.Ellipsify(coupon.Description, 25) }}
              </div>
            </td>
            <td>
              {{ $utils.FormatDayMonthYear(coupon.StartDate) }}
            </td>
            <td>
              {{ $utils.FormatDayMonthYear(coupon.EndDate) }}
            </td>
            <td>
              {{ coupon.Type ? 'Percentage' : 'Amount' }}
            </td>
            <td>
              <div class="title">
                {{ coupon.MaxUsage ? coupon.MaxUsage : 'Unlimited' }}
              </div>
            </td>
            <td>
              <div class="title">
                {{ coupon.MinPrice ? coupon.MinPrice : 'No restriction' }}
              </div>
            </td>
            <td>
              <div class="title">
                {{ coupon.TimesUsed }}
              </div>
            </td>
            <td>
              <div class="title">
                {{ coupon.FidelityPoints > 0 ? coupon.FidelityPoints : '-' }}
              </div>
            </td>
            <td>
              <div class="title">
                {{ coupon.Type ? coupon.Value + '%' : coupon.Value + ' RON' }}
              </div>
            </td>
            <td>
              <input
                :id="'switch-shadow-' + index"
                class="switch-small switch-small--shadow"
                type="checkbox"
                :checked="coupon.Available"
                :disabled="coupon.DisableCoupon"
                v-on:click="ChangeAvailability(coupon.Id)"
                @click.prevent
              />
              <label :for="'switch-shadow-' + index"></label>
            </td>
            <td>
              <div class="d-flex align-items-center float-end">
                <button class="button table-btn me-2" @click="GetCoupon(coupon.Id)">
                  <img src="../../assets/images/icons/table/edit-linear.svg" />
                </button>

                <button type="button" class="button table-btn" v-on:click="DeleteCoupon(coupon.Id)">
                  <img src="../../assets/images/icons/table/trash-linear.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="coupons.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>

  <AddCouponModal ref="addCouponModalRef" @save="SaveCoupon" />
  <EditCouponModal :coupon="selectedCoupon" @edit="EditCoupon" />
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import AddCouponModal from '../../components/Coupon/Modals/AddCouponModal.vue';
import EditCouponModal from '../../components/Coupon/Modals/EditCouponModal.vue';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';

export default {
  name: 'CouponsView',
  data() {
    return {
      coupons: {},
      selectedCoupon: {},
      filter: {
        SearchAvailability: '',
        SearchQuery: '',
        SearchType: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
      },
      pagination: {},
    };
  },
  components: {
    VueMultiselect,
    AddCouponModal,
    EditCouponModal,
    PaginationComponent,
    OrderByIconComponent,
  },
  methods: {
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetCoupons();
    },
    OpenAddCouponModal() {
      this.$refs.addCouponModalRef.ClearModal();
      // eslint-disable-next-line no-undef
      $('#addCouponModal').modal('show');
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetCoupons();
    },
    GetCoupons() {
      const searchParam = new URLSearchParams(this.filter);
      this.$axios
        .get(`api/Coupon/getAll?${searchParam}`)
        .then((response) => {
          this.coupons = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetCoupon(id) {
      this.$axios
        .get(`api/Coupon/get/${id}`)
        .then((respone) => {
          this.selectedCoupon = respone.data;
          this.selectedCoupon.MinPriceSwitch = !this.selectedCoupon.MinPrice;
          this.selectedCoupon.MaxUsageSwitch = !this.selectedCoupon.MaxUsage;
          this.selectedCoupon.FidelityPointsSwitch = !!this.selectedCoupon.FidelityPoints;
          // eslint-disable-next-line no-undef
          $('#editCouponModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    SaveCoupon(coupon) {
      this.$axios
        .post('api/Coupon/create', coupon)
        .then(() => {
          this.$utils.toastNotify('success', 'Coupon saved successfuly');
          this.GetCoupons();
          // eslint-disable-next-line no-undef
          $('#addCouponModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    EditCoupon(coupon) {
      this.$axios
        .put(`api/Coupon/update/${coupon.Id}`, coupon)
        .then(() => {
          this.$utils.toastNotify('success', 'Coupon edited successfuly');
          this.GetCoupons();
          // eslint-disable-next-line no-undef
          $('#editCouponModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteCoupon(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Coupon/delete/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetCoupons();
                this.$utils.toastNotify('success', 'Coupon deleted successfuly');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    ChangeAvailability(id) {
      const self = this;
      this.$swal
        .fire({
          title: 'Warning',
          text: 'Are you sure you want to change this property?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.value) {
            self.$axios
              .put(`/api/Coupon/changeAvailability/${id}`)
              .then(() => {
                self.GetCoupons();
                self.$swal.fire('Success!', 'Coupon availability changed successfuly', 'success');
              })
              .catch(() => {
                self.$swal.fire('Error', 'Something went wrong.', 'error');
              });
          }
        });
    },
  },
  created() {
    this.GetCoupons();
    this.$store.commit('ChangePage', 'Coupons');
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1700px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .table {
    width: 1700px;
  }
}
</style>
