<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    class="modal fade"
    id="addValuesModal"
    tabindex="-1"
    aria-labelledby="addValuesModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img src="../../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
          <h5 class="modal-title" id="addValuesModalLabel">Add specification</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label for="search">Search by name</label>
          <div class="input-group left mb-4">
            <span class="input-group-text">
              <img src="../../../../assets/images/icons/search-normal-20x20.svg" />
            </span>
            <input
              id="search-specification"
              name="search-specification"
              v-model="filter.SearchQuery"
              @keyup.enter="GetSpecifcationCategories"
              type="text"
              class="search form-control"
              placeholder="Search in..."
            />
          </div>
          <div class="mt-3 mb-3 paragraph-03 text-neutral-08">Specifications</div>
          <div class="custom-scrollbar" style="height: 250px; overflow-y: auto">
            <div
              class="row align-items-center justify-content-center m-0 row-border-bottom pt-2 pb-2"
              v-for="(category, index) in categories"
              :key="index"
            >
              <div class="col label-02 fw-semibold text-neutral-07 ps-0">
                {{ category.Name }}
              </div>
              <div class="col-auto pointer">
                <div v-if="!category.Caret">
                  <font-awesome-icon
                    :icon="['fas', 'caret-down']"
                    @click="GetSpecifications(category)"
                  />
                </div>
                <div v-else>
                  <font-awesome-icon :icon="['fas', 'caret-up']" @click="CloseColapse(category)" />
                </div>
              </div>
              <div class="collapse mt-3" :id="category.Id">
                <div
                  class="row"
                  :class="{ 'mb-2': index < category.Specifications.length - 1 }"
                  v-for="(specification, index) in category.Specifications"
                  :key="index"
                >
                  <div class="col ps-4 paragraph-01 text-neutral-06">{{ specification.Name }}</div>
                  <div class="col-auto pe-1">
                    <div class="form-check">
                      <input
                        v-model="specification.Check"
                        class="form-check-input"
                        type="checkbox"
                        value="true"
                        :id="`specification-checbox-${index}`"
                      />
                      <label class="form-check-label" :for="`specification-checbox-${index}`">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="../../../../assets/images/not-found-img.png"
              v-if="categories.length == 0"
              class="m-auto d-block mt-4"
              style="max-width: 150px"
            />
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            class="button btn-success-solid btn-medium w-100"
            @click="Submit"
            data-bs-dismiss="modal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddValuesModal',
  data() {
    return {
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
      },
      specificationsFilter: {
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      categories: {},
    };
  },
  methods: {
    GetSpecifications(category) {
      const searchParams = {
        PageSize: this.specificationsFilter.PageSize ? this.specificationsFilter.PageSize : '',
        PageNumber: this.specificationsFilter.PageNumber
          ? this.specificationsFilter.PageNumber
          : '',
        SearchQuery: this.filter.SearchQuery ? this.filter.SearchQuery : '',
      };
      this.$axios
        .get(`/api/SpecificationCategory/get/${category.Id}?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          category.Specifications = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          category.Caret = true;
          // eslint-disable-next-line no-undef
          $(`#${category.Id}`).collapse('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    CloseColapse(category) {
      // eslint-disable-next-line no-undef
      $(`#${category.Id}`).collapse('hide');
      // eslint-disable-next-line no-param-reassign
      category.Caret = false;
    },
    GetSpecifcationCategories() {
      const searchParams = new URLSearchParams(this.filter);
      this.$axios
        .get(`/api/SpecificationCategory/getAll?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.categories = response.data.Items;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    Submit() {
      const specifications = [];
      this.categories.forEach((category) => {
        if (category.Specifications) {
          category.Specifications.forEach((specification) => {
            if (specification.Check) {
              specifications.push(specification);
            }
          });
        }
      });
      if (specifications.length > 0) {
        this.$emit('add-specifications', specifications);
      }
      // eslint-disable-next-line no-undef
      $('#addValuesModal').modal('hide');
    },
    ClearSpecificationModal() {
      this.categories = [];
      this.filter = {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
      };
    },
  },
};
</script>
