<template>
  <div class="row align-items-center mb-3">
    <div class="col">
      <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">Price configuration</div>
    </div>
    <div class="col-auto">
      <button
        type="button"
        class="button btn-small btn-success-solid text-center d-flex align-items-center"
        @click="OpenAddConfigurationModal(idProduct)"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 10.5H15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 15.5V5.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Add configuration
      </button>
    </div>
  </div>

  <div class="row row-border-bottom label-02 fw-semibold text-neutral-07 pb-3 set-width">
    <div class="col">Specification</div>
    <div class="col-3">Category</div>
    <div class="col-2 text-end">Price</div>
    <div class="col-auto pe-0" style="width: 20vw"></div>
  </div>
  <div v-for="(configuration, index) in configurations" :key="index" class="table-border-bottom">
    <ConfigurationComponent
      :configuration="configuration"
      :layer="1"
      @add="OpenAddSubconfigurationModal"
      @edit="GetConfiguration"
      @delete="DeleteConfiguration"
    />
  </div>
  <div v-if="!configurations || configurations.length == 0" class="mt-5 pt-5 text-center">
    <img src="../../assets/images/not-found-img.png" />
  </div>
  <AddConfigurationModal
    ref="addConfigurationModalRef"
    :selectedConfig="selectedConfiguration"
    :productId="productId"
    @save="SaveConfiguration"
  />
  <EditConfigurationModal
    ref="editConfigurationModalRef"
    :configuration="selectedConfiguration"
    @edit="EditConfiguration"
  />
</template>

<script>
import ConfigurationComponent from './ProductConfigurations/ConfigurationComponent.vue';
import AddConfigurationModal from './ProductConfigurations/Modals/AddConfigurationModal.vue';
import EditConfigurationModal from './ProductConfigurations/Modals/EditConfigurationModal.vue';

export default {
  name: 'ProductConfiguration',
  emits: ['save-config', 'save-specification', 'edit', 'delete-config', 'delete-specification'],
  props: {
    configurations: Array,
    idProduct: Number,
  },
  components: {
    ConfigurationComponent,
    AddConfigurationModal,
    EditConfigurationModal,
  },
  data() {
    return {
      configurationId: Number,
      productId: Number,
      selectedConfiguration: {},
      selectedConfigurationParent: {},
    };
  },
  methods: {
    OpenAddSubconfigurationModal(selectedConfig) {
      this.$refs.addConfigurationModalRef.ClearModal();
      this.configurationId = selectedConfig.Id;
      this.selectedConfiguration = selectedConfig;
      this.productId = 0;
      // eslint-disable-next-line no-undef
      $('#addConfigurationModal').modal('show');
    },
    OpenAddConfigurationModal(id) {
      this.$refs.addConfigurationModalRef.ClearModal();
      this.configurationId = 0;
      this.selectedConfiguration = {};
      this.productId = id;
      // eslint-disable-next-line no-undef
      $('#addConfigurationModal').modal('show');
    },
    SaveConfiguration(param) {
      if (!param.createdSpecification) this.$emit('save-config', param);
      else this.$emit('save-specification', param);
    },
    EditConfiguration(config) {
      if (config.ConfigurationId) {
        const item = this.selectedConfigurationParent.Subconfigurations.find(
          (obj) => obj.Id === config.Id,
        );
        item.Name = config.Name;
        item.Value = config.Value;
        item.Description = config.Description;
      } else {
        const item = this.configurations.find((obj) => obj.Id === config.Id);
        item.Name = config.Name;
        item.Value = config.Value;
        item.Description = config.Description;
      }
      this.$emit('edit', config);
    },
    DeleteConfiguration(param) {
      if (typeof param !== 'number') {
        this.$emit('delete-specification', param);
      } else this.$emit('delete-config', param);
    },
    GetConfiguration(prop) {
      this.selectedConfigurationParent = prop.config;
      this.$refs.editConfigurationModalRef.ClearModal();
      this.$axios
        .get(
          `/api/ProductConfiguration/get/${
            prop.configurationToEdit ? prop.configurationToEdit.Id : prop.Id
          }`,
        )
        .then((respone) => {
          this.selectedConfiguration = respone.data;
          // eslint-disable-next-line no-undef
          $('#editConfigurationModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
};
</script>

<style scoped>
/* @media only screen and (max-width: 1100px) {
  .table-responsive {
    overflow-x: auto;
  }
  .table-responsive .set-width {
    width: 800px;
  }
} */

/* .display-custom-border:not(:nth-last-child(3)) .border-bottom-custom {
  border-bottom: 1px solid var(--blacktint-1);
  margin-left: 1px;
} */

.table-border-bottom {
  border-bottom: 1px solid var(--blacktint-1);
}
</style>
