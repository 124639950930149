<template>
  <div class="row m-0">
    <div class="col-auto p-0 m-1" v-for="item in 5" :key="item">
      <div v-if="item <= stars">
        <font-awesome-icon :icon="['fas', 'star']" style="color: #ffc107" />
      </div>
      <div v-else>
        <font-awesome-icon :icon="['far', 'star']" style="color: #ffc107" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    stars: {},
  },
};
</script>
