import { createRouter, createWebHistory } from 'vue-router';
import CategoryView from '../views/Categories/CategoriesView.vue';
import BrandView from '../views/Brands/BrandsView.vue';
import EditProductView from '../views/Products/EditProductView.vue';
import ProductView from '../views/Products/ProductsView.vue';
import ProductPresentationView from '../views/Products/ProductPresentationView.vue';
import AddProductView from '../views/Products/AddProductView.vue';
import LocationsView from '../views/Locations/LocationsView.vue';
import SpecificationsView from '../views/Specifications/SpecificationsView.vue';
import StatusesView from '../views/Statuses/StatusesView.vue';
import CouponsView from '../views/Coupons/CouponsView.vue';
import Login from '../views/Auth/Login.vue';
import Register from '../views/Auth/Register.vue';
import VerifyEmail from '../views/Auth/VerifyEmail.vue';
import Users from '../views/Users/Users.vue';
import ClientProfile from '../views/Clients/ClientProfileView.vue';
import ChangePassword from '../views/Auth/ChangeUserPassword.vue';
import ForgotPassword from '../views/Auth/ForgotPassword.vue';
import ClientsView from '../views/Clients/ClientsView.vue';
import BlogsView from '../views/Blog/BlogsView.vue';
import TrashView from '../views/TrashView.vue';
import AddBlogArticleView from '../views/Blog/AddBlogArticleView.vue';
import EditBlogArticleView from '../views/Blog/EditBlogArticleView.vue';
import DashboardView from '../views/Dashboard/DashboardView.vue';
import OrdersView from '../views/Orders/OrdersView.vue';
import OrderStripeErrorsView from '../views/Orders/OrderStripeErrorsView.vue';
import BlogCategoriesView from '../views/Blog/BlogCategoriesView.vue';
import ReviewsView from '../views/Reviews/ReviewsView.vue';
import ProductsOrderdView from '../views/Orders/ProductsOrderdView.vue';
import OrderDetailsView from '../views/Orders/OrderDetailsView.vue';
import PageNotFoundView from '../views/Layout/PageNotFoundView.vue';
import SettingsView from '../views/Settings/SettingsView.vue';
import LoggedInLayout from '../views/Layout/LoggedInLayout.vue';
import NotLoggedLayout from '../views/Layout/NotLoggedLayout.vue';

const router = createRouter({
  history: createWebHistory(),

  routes: [
    {
      path: '/Landing-page',
      name: 'LandingPage',
      component: NotLoggedLayout,
      children: [
        {
          path: '/login',
          component: Login,
        },
        {
          path: '/register',
          component: Register,
        },
        {
          path: '/account/verify-email',
          name: 'VerifyEmail',
          component: VerifyEmail,
        },
        {
          path: '/account/reset-password',
          name: 'ChangePassword',
          component: ChangePassword,
        },
        {
          path: '/account/forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword,
        },
      ],
    },
    {
      path: '',
      component: LoggedInLayout,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: DashboardView,
        },
        {
          path: '/trash',
          name: 'TrashView',
          component: TrashView,
        },
        {
          path: '/Categories',
          name: 'Categories',
          component: CategoryView,
        },
        {
          path: '/Brands',
          name: 'Brands',
          component: BrandView,
        },
        {
          path: '/Product/Edit/:productIdParam',
          name: 'EditProduct',
          component: EditProductView,
          props: true,
        },
        {
          path: '/Product/Add',
          name: 'AddProduct',
          component: AddProductView,
        },
        {
          path: '/Products',
          name: 'Products',
          component: ProductView,
        },
        {
          path: '/Product/:productIdParam',
          name: 'ProductPresentation',
          component: ProductPresentationView,
          props: true,
        },
        {
          path: '/Locations',
          name: 'Locations',
          component: LocationsView,
        },
        {
          path: '/Specifications',
          name: 'Specifications',
          component: SpecificationsView,
        },
        {
          path: '/Statuses',
          name: 'Product Status',
          component: StatusesView,
        },
        {
          path: '/Coupons',
          name: 'Coupons',
          component: CouponsView,
        },
        {
          path: '/ClientProfile/:clientIdParam',
          name: 'Client profile',
          component: ClientProfile,
          props: true,
        },
        {
          path: '/Clients',
          name: 'Clients',
          component: ClientsView,
        },
        {
          path: '/users',
          name: 'Users',
          component: Users,
        },
        {
          path: '/Blogs',
          name: 'Blog',
          component: BlogsView,
        },
        {
          path: '/AddBlogArticle',
          name: 'AddBlogArticle',
          component: AddBlogArticleView,
        },
        {
          path: '/EditBlogArticle/:articleIdParam',
          name: 'EditBlogArticle',
          component: EditBlogArticleView,
          props: true,
        },
        {
          path: '/Orders',
          name: 'Orders',
          component: OrdersView,
        },
        {
          path: '/OrderStripeErrors',
          name: 'OrderStripeErrors',
          component: OrderStripeErrorsView,
        },
        {
          path: '/BlogCategories',
          name: 'BlogCategories',
          component: BlogCategoriesView,
        },
        {
          path: '/Reviews',
          name: 'Reviews',
          component: ReviewsView,
        },
        {
          path: '/Orders/Summary',
          name: 'Summary',
          component: ProductsOrderdView,
        },
        {
          path: '/OrderDetails/:orderIdParam',
          name: 'OrderDetails',
          component: OrderDetailsView,
          props: true,
        },
        {
          path: '/PageNotFound',
          name: 'PageNotFound',
          component: PageNotFoundView,
        },
        {
          path: '/Settings',
          name: 'Settings',
          component: SettingsView,
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/login',
    '/register',
    '/account/verify-email',
    '/account/forgot-password',
    '/account/reset-password',
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
