<template>
  <div
    class="modal fade"
    id="addCouponModal"
    tabindex="-1"
    aria-labelledby="addCouponModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addCouponFormRef"
          @submit="Submit"
          :validation-schema="Schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addCouponModalLabel">Add coupon</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ClearModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="name">Name </label>
              <div class="row">
                <div class="col">
                  <Field
                    v-model="coupon.Name"
                    name="name"
                    type="text"
                    class="form-control"
                    id="name"
                    :class="{ 'border-danger': errors.name }"
                    placeholder="Name"
                  />
                </div>
                <div class="col-auto">
                  <button
                    @click="GenerateName"
                    type="button"
                    class="button h-100 btn-success-solid ps-4 pe-4"
                  >
                    Generate
                  </button>
                </div>
              </div>
              <ErrorMessage name="name" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="description">Coupon description</label>
              <Field
                v-model="coupon.Description"
                name="description"
                class="form-control"
                id="description"
                rows="2"
                as="textarea"
                :class="{ 'border-danger': errors.description }"
                placeholder="Description"
              />
              <ErrorMessage name="description" class="error-message" />
            </div>
            <div class="mb-3">
              <div class="row align-items-center">
                <div class="col">
                  <label for="startDate">Start date </label>
                  <Field v-slot="{ field }" name="startDate" id="startDate">
                    <VueDatePicker
                      v-bind="field"
                      v-model="coupon.StartDate"
                      placeholder="Select date"
                      :enableTimePicker="false"
                      autoApply
                      class="custom-datepicker"
                      :class="{ 'border-danger-datepicker': errors.startDate }"
                      format="dd-MM-yyyy"
                      id="orders-date"
                    ></VueDatePicker>
                  </Field>
                  <ErrorMessage name="startDate" class="error-message" />
                </div>
                <div class="col">
                  <label for="endDate">End date </label>
                  <Field v-slot="{ field }" name="endDate" id="endDate">
                    <VueDatePicker
                      v-bind="field"
                      v-model="coupon.EndDate"
                      placeholder="Select date"
                      :enableTimePicker="false"
                      autoApply
                      class="custom-datepicker"
                      :class="{ 'border-danger-datepicker': errors.endDate }"
                      format="dd-MM-yyyy"
                      id="orders-date"
                    ></VueDatePicker>
                  </Field>
                  <ErrorMessage name="endDate" class="error-message" />
                </div>
              </div>
            </div>
            <label for="maxUsage">Uses</label>
            <div class="mb-3 row align-items-center">
              <div class="col">
                <Field
                  v-model="coupon.MaxUsage"
                  type="number"
                  name="maxUsage"
                  id="maxUsage"
                  :disabled="coupon.MaxUsageSwitch"
                  class="form-control"
                  step="1"
                  min="1"
                  pattern="[0-9]"
                  :class="{ 'border-danger': errors.maxUsage }"
                  placeholder="Uses"
                />
                <ErrorMessage name="maxUsage" class="error-message" />
              </div>
              <div class="col d-flex">
                <div>
                  <input
                    v-model="coupon.MaxUsageSwitch"
                    name="maxUsageSwitch"
                    class="switch-small switch-small--shadow"
                    id="usesSwitch"
                    @click="SwitchMaxUses(coupon.MaxUsageSwitch)"
                    type="checkbox"
                    role="switch"
                  />
                  <label for="usesSwitch"></label>
                </div>
                <label
                  class="ms-2 label-01 text-neutral-07 fw-semibold pt-1 pointer"
                  for="usesSwitch"
                >
                  Unlimited
                </label>
              </div>
            </div>
            <label for="minPrice">Minimum order price</label>
            <div class="mb-3 row align-items-center">
              <div class="col">
                <Field
                  v-model="coupon.MinPrice"
                  type="number"
                  name="minPrice"
                  id="minPrice"
                  class="form-control"
                  :disabled="coupon.MinPriceSwitch"
                  :class="{ 'border-danger': errors.minPrice }"
                  placeholder="Order price"
                />
                <ErrorMessage name="minPrice" class="error-message" />
              </div>
              <div class="col d-flex">
                <div>
                  <input
                    v-model="coupon.MinPriceSwitch"
                    @click="SwitchMinPrice(coupon.MinPriceSwitch)"
                    name="maxUsageSwitch"
                    class="switch-small switch-small--shadow"
                    id="minPriceSwitch"
                    type="checkbox"
                    role="switch"
                  />
                  <label for="minPriceSwitch"></label>
                </div>
                <label
                  class="ms-2 label-01 text-neutral-07 fw-semibold pt-1 pointer"
                  for="minPriceSwitch"
                >
                  Unlimited
                </label>
              </div>
            </div>
            <label for="fidelityPoints">Fidelity points</label>
            <div class="mb-3 row align-items-center">
              <div class="col">
                <Field
                  v-model="coupon.FidelityPoints"
                  type="number"
                  name="fidelityPoints"
                  id="fidelityPoints"
                  class="form-control"
                  :class="{ 'border-danger': errors.fidelityPoints }"
                  :disabled="!coupon.FidelityPointsSwitch"
                  placeholder="Fidelity points"
                />
                <ErrorMessage name="fidelityPoints" class="error-message" />
              </div>
              <div class="col d-flex">
                <div>
                  <input
                    v-model="coupon.FidelityPointsSwitch"
                    name="maxUsageSwitch"
                    class="switch-small switch-small--shadow"
                    id="fidelityPointsSwitch"
                    type="checkbox"
                    role="switch"
                    @change="SwitchFidelityPoints(coupon.FidelityPointsSwitch)"
                  />
                  <label for="fidelityPointsSwitch"></label>
                </div>
                <label
                  class="ms-2 label-01 text-neutral-07 fw-semibold pt-1 pointer"
                  for="fidelityPointsSwitch"
                >
                  On fidelity program
                </label>
              </div>
            </div>
            <label for="value">Value </label>
            <div class="row">
              <div class="col-6">
                <Field
                  v-model="coupon.Value"
                  type="number"
                  name="value"
                  id="value"
                  class="form-control"
                  :class="{ 'border-danger': errors.value }"
                  placeholder="Value"
                />
                <ErrorMessage name="value" class="error-message" />
              </div>
              <div class="col">
                <div class="mb-0">
                  <input
                    v-model="coupon.Type"
                    :value="false"
                    @click="coupon.Value = ''"
                    class="form-check-input pointer"
                    :class="{ 'bg-success-05': coupon.Type === false }"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label
                    class="ms-2 ps-1 label-01 text-neutral-07 fw-semibold pt-1 pointer"
                    for="flexRadioDefault1"
                    >Amount</label
                  >
                </div>
                <div class="mb-0 pointer">
                  <input
                    v-model="coupon.Type"
                    :value="true"
                    @click="coupon.Value = ''"
                    class="form-check-input pointer"
                    :class="{ 'bg-success-05': coupon.Type === true }"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label
                    class="ms-2 ps-1 label-01 text-neutral-07 fw-semibold pt-1 pointer"
                    for="flexRadioDefault2"
                    >Percentage</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import moment from 'moment';

export default {
  name: 'AddCouponModal',
  emits: ['save'],
  data() {
    return {
      coupon: {
        FidelityPointsSwitch: true,
        MinPriceSwitch: false,
        MaxUsageSwitch: false,
        Type: false,
        TimesUsed: 0,
      },
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      this.coupon.MaxUsage = this.coupon.MaxUsageSwitch === true ? null : this.coupon.MaxUsage;
      this.coupon.MinPrice = this.coupon.MinPriceSwitch === true ? null : this.coupon.MinPrice;
      this.coupon.FidelityPoints =
        this.coupon.FidelityPointsSwitch === false ? null : this.coupon.FidelityPoints;
      const couponProps = {
        ...this.coupon,
        StartDate: this.$utils.FormatDayMonthYear(this.coupon.StartDate),
        EndDate: this.$utils.FormatDayMonthYear(this.coupon.EndDate),
      };
      this.$emit('save', couponProps);
    },
    ClearModal() {
      this.$refs.addCouponFormRef.resetForm();
      this.coupon.StartDate = '';
      this.coupon.EndDate = '';
    },
    GenerateName() {
      this.$axios
        .get('/api/Coupon/generateName')
        .then((respone) => {
          this.coupon.Name = respone.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    SwitchMaxUses(maxUsageSwitch) {
      if (maxUsageSwitch === false) {
        this.coupon.MaxUsage = ' ';
      } else {
        this.coupon.MaxUsage = 1;
      }
    },
    SwitchMinPrice(minPriceSwitch) {
      if (minPriceSwitch === false) {
        this.coupon.MinPrice = ' ';
      } else {
        this.coupon.MinPrice = 1;
      }
    },
    SwitchFidelityPoints(fidelityPoints) {
      if (fidelityPoints) {
        this.coupon.FidelityPoints = 1;
      } else {
        this.coupon.FidelityPoints = ' ';
      }
    },
  },

  computed: {
    Schema() {
      return yup.object().shape({
        name: yup
          .string()
          .required('Name is a required field')
          .min(3, 'Name must have at least 3 characters')
          .trim(),
        description: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,200})$/, 'Description must be between 5-200 characters')
          .trim(),
        startDate: yup.date().required('Start date is required'),
        endDate: yup
          .date()
          .required('End date is required')
          // eslint-disable-next-line func-names
          .test('is-greater', 'Time span is invalid ', function (value) {
            const { startDate } = this.parent;
            return moment(value, 'mm/dd/yyyy').isSameOrAfter(moment(startDate, 'mm/dd/yyyy'));
          }),
        maxUsageSwitch: yup.bool(),
        minPriceSwitch: yup.bool(),
        fidelityPointsSwitch: yup.bool(),
        fidelityPoints: yup
          .number()
          .nullable()
          .min(0, 'Fidelity points cannot be negative')
          .test('is-deciaml', 'Fidelity points is required', (number) => {
            if (!this.coupon.FidelityPointsSwitch) {
              return true;
            }
            if (!number && this.coupon.FidelityPointsSwitch) {
              return false;
            }
            return true;
          })
          .test('is-required-usage', 'Fidelity points should not be a decimal', (number) => {
            if (!this.coupon.FidelityPointsSwitch) {
              return true;
            }
            return /^\d+(\.\d{0})?$/.test(number);
          })
          .transform((_, val) => (val === Number(val) ? val : null)),
        maxUsage: yup
          .number()
          .nullable()
          .min(0, 'Number of uses cannot be negative')
          .test('is-deciaml', 'Number of uses is required', (number) => {
            if (this.coupon.MaxUsageSwitch) {
              return true;
            }
            if (!number && !this.coupon.MaxUsageSwitch) {
              return false;
            }
            return true;
          })
          .test('is-required-usage', 'Number of uses should not be a decimal', (number) => {
            if (this.coupon.MaxUsageSwitch) {
              return true;
            }
            return /^\d+(\.\d{0})?$/.test(number);
          })
          .transform((_, val) => (val === Number(val) ? val : null)),
        minPrice: yup
          .number()
          .nullable()
          .min(0, 'Minimum order cannot be negative')
          .test('min-price-switch', 'Minimum order is required', (number) => {
            if (this.coupon.MinPriceSwitch) {
              return true;
            }
            if (!number && !this.coupon.MinPriceSwitch) {
              return false;
            }
            return true;
          })
          .test('is-required-price', 'Min order can have maximum 2 decimals', (number) => {
            if (this.coupon.MinPriceSwitch) {
              return true;
            }
            return /^\d+(\.\d{1,2})?$/.test(number);
          })
          .transform((_, val) => (val === Number(val) ? val : null)),
        value: yup
          .number()
          .required('Value is required.')
          .positive('Coupon value must be positive')
          .test('value-percentage', 'Percentage cannot be higher than 100%', (number) => {
            if (this.coupon.Type && number > 100) {
              return false;
            }
            return true;
          })
          .transform((_, val) => (val === Number(val) ? val : null)),
      });
    },
  },
};
</script>
