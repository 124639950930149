<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.9998 18.4067C18.2973 18.4067 20.1598 16.5442 20.1598 14.2467C20.1598 11.9492 18.2973 10.0867 15.9998 10.0867C13.7023 10.0867 11.8398 11.9492 11.8398 14.2467C11.8398 16.5442 13.7023 18.4067 15.9998 18.4067Z"
              stroke="#3AAB14"
              stroke-width="1.5"
            />
            <path
              d="M4.8266 11.82C7.45327 0.273357 24.5599 0.28669 27.1733 11.8334C28.7066 18.6067 24.4933 24.34 20.7999 27.8867C18.1199 30.4734 13.8799 30.4734 11.1866 27.8867C7.5066 24.34 3.29327 18.5934 4.8266 11.82Z"
              stroke="#1F2629"
              stroke-width="1.5"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="OpenAddLocationModal()"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Add <span class="d-none d-sm-inline-block">location</span>
        </button>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="row mb-3 align-items-center">
      <div class="col-xxl-5 mb-3 mb-xxl-0 filters-zone">
        <label for="locations-search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            v-model="filter.SearchQuery"
            @keyup.enter="GetLocations()"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            id="locations-search"
          />
        </div>
      </div>
      <div class="col d-none d-xxl-block"></div>
      <div class="col-xxl-6">
        <div class="row">
          <div class="d-flex align-items-center col">
            <span>
              <input
                id="switch-shadow-displayed-all"
                class="switch-small switch-small--shadow"
                type="checkbox"
                :checked="filter.DisplayedAll === true"
                v-on:click="
                  filter.DisplayedOnWebsite = '';
                  filter.DisplayedOnProductLists = '';
                  filter.DisplayedAll = !filter.DisplayedAll;
                  GetLocations();
                "
              />
              <label for="switch-shadow-displayed-all"></label>
            </span>
            <label
              class="label-02 fw-semibold text-neutral-07 ms-2 mb-0 pointer"
              for="switch-shadow-displayed-all"
            >
              All locations
            </label>
          </div>
          <div class="d-flex align-items-center col">
            <span>
              <input
                id="switch-shadow-displayed-on-website"
                class="switch-small switch-small--shadow"
                type="checkbox"
                :checked="filter.DisplayedOnWebsite === true"
                v-on:click="
                  filter.DisplayedOnWebsite = !filter.DisplayedOnWebsite;
                  filter.DisplayedAll = '';
                  GetLocations();
                "
              />
              <label for="switch-shadow-displayed-on-website"></label>
            </span>
            <label
              class="label-02 fw-semibold text-neutral-07 ms-2 pointer"
              for="switch-shadow-displayed-on-website"
            >
              Displayed on <br />
              website
            </label>
          </div>
          <div class="d-flex align-items-center col-sm mt-3 mt-sm-0">
            <span>
              <input
                id="switch-shadow-displayed-on-product-list"
                class="switch-small switch-small--shadow"
                type="checkbox"
                :checked="filter.DisplayedOnProductLists === true"
                v-on:click="
                  filter.DisplayedOnProductLists = !filter.DisplayedOnProductLists;
                  filter.DisplayedAll = '';
                  GetLocations();
                "
              />
              <label for="switch-shadow-displayed-on-product-list"></label>
            </span>
            <label
              class="label-02 fw-semibold text-neutral-07 ms-2 pointer"
              for="switch-shadow-displayed-on-product-list"
            >
              Displayed on <br class="d-none d-sm-block" />
              product lists
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr class="pb-2" />
    <div class="row">
      <div
        class="col-sm-6 col-lg-4 col-xxl-3 mb-4"
        v-for="location in locationsList"
        :key="location.Id"
      >
        <div
          class="location-card"
          @mouseenter="location.ShowSchedule = true"
          @mouseleave="location.ShowSchedule = false"
        >
          <img :src="DisplayLocationImg(location.ImgBase64)" class="bg-img" />
          <transition name="fade">
            <div v-if="location.ShowSchedule" class="info full">
              <div class="heading-01 mb-1">{{ location.Name }}</div>
              <div class="label-01" style="height: 28px" :title="location.Description">
                {{ this.$utils.Ellipsify(location.Description, 100) }}
              </div>
              <div class="row" style="margin-top: 32px">
                <div class="col pe-3">
                  <div
                    v-for="(schedule, index) in location.TimeSchedules"
                    :key="index"
                    :style="{
                      'margin-bottom': index < location.TimeSchedules.length - 1 ? '12px' : '0px',
                    }"
                  >
                    <div class="label-01 d-flex justify-content-between">
                      <span>{{ schedule.Day }}:</span>
                      <span v-if="schedule.IsOpen"
                        >{{ schedule.StartSchedule }} - {{ schedule.EndSchedule }}</span
                      >
                      <span v-else>Closed</span>
                    </div>
                  </div>
                </div>

                <div class="col-4 border-start ps-3 d-flex flex-column justify-content-end">
                  <div class="border rounded text-center mb-2 p-2">
                    <div class="label-01 mb-1">Website</div>
                    <div class="label-01 fw-normal">
                      {{ location.DisplayWebsite ? 'Visible' : 'Hidden' }}
                    </div>
                  </div>
                  <div class="border rounded text-center mb-auto p-2">
                    <div class="label-01 mb-1">Products:</div>
                    <div class="label-01 fw-normal">
                      {{ location.DisplayProducts ? 'Visible' : 'Hidden' }}
                    </div>
                  </div>
                  <div>
                    <div class="d-flex justify-content-between">
                      <button
                        type="button"
                        class="btn btn-outline-light me-1"
                        style="padding: 5px 7px 5px 7px"
                        @click="GetLocationDetails(location.Id)"
                      >
                        <img src="../../assets/images/icons/edit-linear-white-20x20.svg" />
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-light"
                        style="padding: 5px 7px 5px 7px"
                        @click="DeleteLocation(location.Id)"
                      >
                        <img src="../../assets/images/icons/trash-white-20x20.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="info justify-content-end">
              <div class="heading-01 mb-1">{{ location.Name }}</div>
              <div class="label-01" style="height: 28px" :title="location.Description">
                {{ this.$utils.Ellipsify(location.Description, 90) }}
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div v-if="locationsList.length == 0" class="mt-5 pt-5 text-center">
      <img src="../../assets/images/not-found-img.png" />
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>

    <EditLocationModal
      ref="editLocationModalRef"
      :location="locationDetails"
      @update="UpdateLocation"
    />
    <AddLocationModal
      ref="addLocationModalRef"
      :timeSchedules="timeSchedules"
      @save="SaveLocation"
    />
  </div>
</template>

<script>
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import EditLocationModal from '../../components/Location/Modals/EditLocationModal.vue';
import AddLocationModal from '../../components/Location/Modals/AddLocationModal.vue';

export default {
  name: 'LocationsView',
  components: {
    EditLocationModal,
    AddLocationModal,
    PaginationComponent,
  },
  data() {
    return {
      selectedDisplayOn: 'All',
      timeSchedules: [],
      locationsList: [],
      locationDetails: {},
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
        DisplayedAll: true,
        DisplayedOnWebsite: '',
        DisplayedOnProductLists: '',
      },
      pagination: {},
    };
  },
  methods: {
    DisplayLocationImg(imageBase64) {
      if (!imageBase64) {
        // eslint-disable-next-line global-require, import/no-unresolved
        return require('@/assets/images/big-no-location-img.png');
      }
      return imageBase64;
    },
    PopulateScheduleList() {
      const daysList = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
      this.timeSchedules = [];
      for (let i = 0; i < daysList.length; i += 1) {
        this.timeSchedules.push({
          Day: daysList[i],
          StartSchedule: '09:00',
          EndSchedule: '23:00',
          IsClosed: false,
        });
      }
    },
    OpenAddLocationModal() {
      this.PopulateScheduleList();
      this.$refs.addLocationModalRef.ClearModalValues();
      // eslint-disable-next-line no-undef
      $('#addLocationModal').modal('show');
    },
    GetLocations() {
      const searchParam = new URLSearchParams(this.filter);
      this.$axios
        .get(`/api/Location/getAll?${searchParam}`)
        .then((response) => {
          this.locationsList = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetLocationDetails(id) {
      this.$axios
        .get(`/api/Location/get/${id}`)
        .then((response) => {
          this.locationDetails = response.data;
          this.$refs.editLocationModalRef.ClearLocationContactForm();
          // eslint-disable-next-line no-undef
          $('#editLocationModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    SaveLocation(location) {
      this.$axios
        .post('/api/Location/createLocationComplete', location)
        .then(() => {
          this.GetLocations();
          // eslint-disable-next-line no-undef
          $('#addLocationModal').modal('hide');
          this.$utils.toastNotify('success', 'Location added successfully');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    UpdateLocation(id, updateLocation) {
      this.$axios
        .put(`/api/Location/updateLocationComplete/${id}`, updateLocation)
        .then(() => {
          // eslint-disable-next-line no-undef
          $('#editLocationModal').modal('hide');
          this.GetLocations();
          this.$utils.toastNotify('success', 'Location updated successfully');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteLocation(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Location/deleteLocationComplete/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetLocations();
                this.$utils.toastNotify('success', 'Location deleted successfully');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetLocations();
    },
  },

  created() {
    this.GetLocations();
    this.$store.commit('ChangePage', 'Locations');
  },
};
</script>

<style scoped>
.btn-outline-light:hover img {
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(100%);
}
.btn-outline-light img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(99deg) brightness(101%) contrast(101%);
}
.btn-outline-light {
  border-color: var(--whitetint-7);
  border-radius: 4px;
}

.location-card .info {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 48.96%,
    rgba(0, 0, 0, 0.6) 73.44%,
    rgba(0, 0, 0, 0.9) 100%
  );
  position: absolute;
  bottom: 0;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  color: white;
}
.location-card .info.full {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.95) 100%);
}
.location-card .bg-img {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  display: block;
  object-position: center;
  object-fit: cover;
}

.location-card {
  position: relative;
}
</style>
