<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4932 12.34H23.4932"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.50684 12.34L9.50684 13.34L12.5068 10.34"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.4932 21.6733H23.4932"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.50684 21.6733L9.50684 22.6733L12.5068 19.6733"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.9998 29.8334H19.9998C26.6665 29.8334 29.3332 27.1667 29.3332 20.5V12.5C29.3332 5.83335 26.6665 3.16669 19.9998 3.16669H11.9998C5.33317 3.16669 2.6665 5.83335 2.6665 12.5V20.5C2.6665 27.1667 5.33317 29.8334 11.9998 29.8334Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold  d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="OpenAddCategoryModal()"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Add <span class="d-none d-sm-inline-block">category</span>
        </button>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="row mb-3 filters-zone">
      <div class="col-lg-8 mb-3 mb-md-0 col-xxl-4">
        <label for="specifications-search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            v-model="filter.SearchQuery"
            @keyup.enter="GetCategories()"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            id="specifications-search"
          />

          <button
            type="button"
            class="button btn-neutral-outlined dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ filter.SearchBy }}
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              @click="ChangeSearchBy"
              :class="{ active: filter.SearchBy == 'Categories' }"
            >
              Categories
            </li>
            <li>
              <hr class="dropdown-divider me-3 ms-3 border-color-blacktint-1" />
            </li>
            <li
              class="dropdown-item"
              @click="ChangeSearchBy"
              :class="{ active: filter.SearchBy == 'Subcategories' }"
            >
              Subcategories
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table-scroll">
      <div class="row-table m-0">
        <div class="row header m-0">
          <div class="col-3 ps-0" :class="{ active: filter.OrderBy.includes('name') }">
            <span @click="OrderBy('name')" class="pointer d-inline-flex">
              <span class="text">Name</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="name" />
            </span>
          </div>
          <div class="col-4" :class="{ active: filter.OrderBy.includes('specification') }">
            <span @click="OrderBy('specification')" class="pointer d-inline-flex">
              <span class="text">Specifications</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="specification" />
            </span>
          </div>
          <div class="col"></div>
          <div class="col-auto"></div>
        </div>
        <div
          :class="{ expandable: category.NumberOfSpecifications > 0 }"
          v-on:click="
            category.NumberOfSpecifications > 0 ? GetcSpecificationsDetails(category) : ''
          "
          class="row content align-items-center m-0"
          v-for="(category, index) in categories"
          :key="index"
        >
          <div class="col-3 d-flex align-items-end" style="padding-left: 37px">
            <div class="title">{{ category.Name }}</div>
          </div>
          <div class="col-4">
            <div class="title">{{ category.NumberOfSpecifications }} specifications</div>
          </div>
          <div class="col"></div>
          <div class="col-auto pe-0" v-on:click.stop>
            <div class="d-flex align-items-center">
              <button
                type="button"
                class="button table-btn me-2"
                style="padding: 11px"
                v-on:click="OpenAddSpecificationModal(category)"
              >
                <img src="../../assets/images/icons/table/add-linear.svg" />
              </button>
              <button
                type="button"
                class="button table-btn me-2"
                v-on:click="GetEditCategoryDetails(category.Id)"
              >
                <img src="../../assets/images/icons/table/edit-linear.svg" />
              </button>
              <button
                type="button"
                class="button table-btn"
                v-on:click="DeleteCategory(category.Id)"
              >
                <img src="../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
          </div>
          <div class="row collapse expanded-subcategory p-0 ms-0" v-on:click.stop :id="category.Id">
            <div class="col-auto" style="width: 50px"></div>
            <div class="col">
              <div
                class="row subcategories align-items-center text-neutral-05 fw-semibold label-01"
              >
                <div class="col-auto ps-0" style="width: 24%">Specification Name</div>
                <div class="col-3 ps-0">Values</div>
              </div>
              <div
                v-for="(specification, index) in category.Specifications"
                :key="index"
                class="row subcategories align-items-center"
              >
                <div class="col-auto sub-title text-neutral-07 ps-0" style="width: 24%">
                  {{ specification.Name }}
                </div>
                <div class="col-3 sub-description text-neutral-07 ps-0">
                  <div v-if="specification.Values.length > 0">
                    <span v-for="(item, index) in specification.Values" :key="index">
                      {{ item.Value }}<span v-if="index < specification.Values.length - 1">, </span>
                    </span>
                  </div>
                  <span v-else>This specification have no values associated!</span>
                </div>
                <div class="col"></div>
                <div class="col-auto d-flex align-items-center pe-2">
                  <img
                    src="../../assets/images/icons/table/expanded/edit.svg"
                    class="me-3 pointer"
                    @click="GetEditSpecificationDetails(specification, category)"
                  />
                  <img
                    class="pointer"
                    src="../../assets/images/icons/table/expanded/trash.svg"
                    @click="DeleteSpecification(specification.Id, category)"
                  />
                </div>
              </div>
              <div
                v-if="
                  category.SpecificationPagination &&
                  category.SpecificationPagination.TotalPageCount > 1
                "
              >
                <ColapsePagination
                  :element="category"
                  :pageSize="category.SpecificationPagination.PageSize"
                  :totalItemCount="category.SpecificationPagination.TotalItemCount"
                  :totalPageCount="category.SpecificationPagination.TotalPageCount"
                  :pageNumber="specificationFilter.PageNumber"
                  @select-page="SelectPageColapse"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="categories.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <button
      v-if="categories.length > 7"
      type="button"
      @click="OpenAddCategoryModal()"
      class="button btn-add-category mt-4"
    >
      <div class="text-center d-flex align-items-center">
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.5 10.5H15.5"
            stroke="#53666D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 15.5V5.5"
            stroke="#53666D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div class="text-button-02 fw-semibold text-neutral-06">Add category</div>
      </div>
    </button>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>
  <AddSpecificationModal
    ref="addSpecificationModalRef"
    :category="selectedCategory"
    @save="CreateSpecification"
  />
  <EditSpecificationModal
    ref="editSpecificationModalRef"
    :specification="selectedSpecification"
    @edit="EditSpecification"
  />

  <AddCategoryModal ref="addSpecificationCategoryModalRef" @save="CreateCategory" />
  <EditCategoryModal
    ref="editSpecificationCategoryModalRef"
    :category="selectedCategory"
    @edit="EditCategory"
  />
</template>

<script>
import AddSpecificationModal from '../../components/Specification/Modals/SpecificationModal/AddSpecificationModal.vue';
import EditSpecificationModal from '../../components/Specification/Modals/SpecificationModal/EditSpecificationModal.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import AddCategoryModal from '../../components/Specification/Modals/CategoryModal/AddCategoryModal.vue';
import EditCategoryModal from '../../components/Specification/Modals/CategoryModal/EditCategoryModal.vue';
import ColapsePagination from '../../components/General/ColapsePagination.vue';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';

export default {
  name: 'SpecificationsView',
  data() {
    return {
      categories: [],
      selectedCategory: {},
      selectedSpecification: {},
      pagination: {},
      specificationPagination: {},
      specificationFilter: {
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        SearchQuery: '',
      },
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
        SearchBy: 'Categories',
        SpecificationPage: true,
      },
      componentKey: 0,
    };
  },
  components: {
    OrderByIconComponent,
    AddSpecificationModal,
    EditSpecificationModal,
    EditCategoryModal,
    AddCategoryModal,
    PaginationComponent,
    ColapsePagination,
  },
  methods: {
    ChangeSearchBy() {
      if (this.filter.SearchBy === 'Categories') {
        this.filter.SearchBy = 'Subcategories';
      } else {
        this.filter.SearchBy = 'Categories';
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    SelectPageColapse(nr, category) {
      this.specificationFilter.PageNumber = nr;
      this.GetCategorySpecifications(category);
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetCategories();
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetCategories();
    },
    GetCategories() {
      const searchParams = new URLSearchParams(this.filter);
      if (this.categories.length > 0) {
        // eslint-disable-next-line no-undef
        $('div.expanded-subcategory').removeClass('show');
      }
      this.$axios
        .get(`/api/SpecificationCategory/getAll?${searchParams}`)
        .then((response) => {
          this.categories = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetCategorySpecifications(category) {
      const searchParams = {
        PageNumber: this.specificationFilter.PageNumber,
        PageSize: this.specificationFilter.PageSize,
        ...(this.filter.SearchBy === 'Subcategories' ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/SpecificationCategory/get/${category.Id}?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          category.Specifications = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          category.SpecificationPagination = response.data.PageDetails;
          this.forceRerender();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    CreateSpecification(specification, category) {
      this.$axios
        .post('/api/Specification/create', specification)
        .then(() => {
          this.GetCategorySpecifications(category);
          // eslint-disable-next-line no-param-reassign
          category.NumberOfSpecifications += 1;
          this.$utils.toastNotify('success', 'Specification added succesfuly.');
          // eslint-disable-next-line no-undef
          $('#addSpecificationModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    CreateCategory(specificationCategory) {
      this.$axios
        .post('/api/SpecificationCategory/create', specificationCategory)
        .then(() => {
          this.GetCategories();
          this.$utils.toastNotify('success', 'Specification category added succesfuly.');
          // eslint-disable-next-line no-undef
          $('#addSpecificationcategoryModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    EditSpecification(specification) {
      this.$axios
        .put(`/api/Specification/edit/${specification.Id}`, specification)
        .then(() => {
          this.GetCategorySpecifications(this.selectedCategory);
          this.$utils.toastNotify('success', 'Specification updated succesfuly!');
          // eslint-disable-next-line no-undef
          $('#editSpecificationModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    EditCategory(category) {
      this.$axios
        .put(`/api/SpecificationCategory/edit/${category.Id}`, category)
        .then(() => {
          this.GetCategories();
          this.$utils.toastNotify('success', 'Specification category updated succesfuly');
          // eslint-disable-next-line no-undef
          $('#editSpecificationcategoryModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteSpecification(id, category) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Specification/delete/${id}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Specification deleted succesfuly');
                // eslint-disable-next-line no-param-reassign
                category.NumberOfSpecifications -= 1;
                if (category.NumberOfSpecifications === 0) {
                  // eslint-disable-next-line no-undef
                  $(`#${category.Id}`).collapse('toggle');
                }
                this.GetCategorySpecifications(category);
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    DeleteCategory(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/SpecificationCategory/delete/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetCategories();
                this.$utils.toastNotify('success', 'Category deleted succesfuly');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },

    GetEditSpecificationDetails(specification, category) {
      this.selectedCategory = category;
      this.$refs.editSpecificationModalRef.ClearModal();
      this.$axios
        .get(`/api/Specification/get/${specification.Id}`)
        .then((response) => {
          this.selectedSpecification = response.data;
          // eslint-disable-next-line no-undef
          $('#editSpecificationModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetcSpecificationsDetails(category) {
      const searchParams = {
        PageNumber: this.specificationFilter.PageNumber,
        PageSize: this.specificationFilter.PageSize,
        ...(this.filter.SearchBy === 'Subcategories' ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/SpecificationCategory/get/${category.Id}?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          category.Specifications = response.data.Items;
          this.specificationPagination = response.data.PageDetails;
          // eslint-disable-next-line no-undef
          $(`#${category.Id}`).collapse('toggle');
          this.forceRerender();
        });
    },
    GetEditCategoryDetails(Id) {
      this.$refs.editSpecificationCategoryModalRef.ClearModal();
      this.$axios
        .get(`/api/SpecificationCategory/getCategorySpecifications/${Id}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          this.selectedCategory = response.data;
          // eslint-disable-next-line no-undef
          $('#editSpecificationcategoryModal').modal('show');
        });
    },

    OpenAddCategoryModal() {
      this.$refs.addSpecificationCategoryModalRef.ClearModal();
      // eslint-disable-next-line no-undef
      $('#addSpecificationcategoryModal').modal('show');
    },
    OpenAddSpecificationModal(category) {
      this.selectedCategory = category;
      this.$refs.addSpecificationModalRef.ClearModalValues();
      // eslint-disable-next-line no-undef
      $('#addSpecificationModal').modal('show');
    },
  },
  created() {
    this.$store.commit('ChangePage', 'Specifications');

    this.GetCategories();
  },
};
</script>
<style scoped>
@media (max-width: 1100px) {
  .table-scroll {
      overflow-x: auto;
      position: relative;
  }
 .row-table {
    width: 900px;
  }
}

.btn-add-category {
  object-fit: cover;
  width: 100%;
  height: 53px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.btn-add-category:hover {
  background-color: var(--neutral-2);
}

.row-table .content.expandable:before{
  top: 25px
}
</style>
