<!-- eslint-disable vue/no-mutating-props -->
<template>
  <FieldArray name="contact">
    <div class="row" :class="{ 'mb-3': index < contactsLength - 1 }">
      <div class="col">
        <Field
          :name="`contact[${index}].employee`"
          type="text"
          class="form-control"
          v-model="contact.Employee"
          :class="{ 'border-danger': errors[`contact[${index}].employee`] }"
          placeholder="Name"
        ></Field>
        <ErrorMessage :name="`contact[${index}].employee`" class="error-message" />
      </div>
      <div class="col">
        <Field
          :name="`contact[${index}].email`"
          type="email"
          class="form-control"
          v-model="contact.Email"
          :class="{ 'border-danger': errors[`contact[${index}].email`] }"
          placeholder="Enter email"
        ></Field>
        <ErrorMessage :name="`contact[${index}].email`" class="error-message" />
      </div>
      <div class="col pe-0">
        <Field
          :name="`contact[${index}].phoneNumber`"
          type="text"
          class="form-control"
          v-model="contact.PhoneNumber"
          :class="{ 'border-danger': errors[`contact[${index}].phoneNumber`] }"
          placeholder="Enter phone"
        ></Field>
        <ErrorMessage :name="`contact[${index}].phoneNumber`" class="error-message" />
      </div>
      <div class="col-1 ps-0">
        <button
          type="button"
          class="button table-btn float-end"
          @click="$emit('delete-contact', contact)"
        >
          <img src="../../assets/images/icons/table/trash-linear.svg" />
        </button>
      </div>
    </div>
  </FieldArray>
</template>

<script>
import { Field, ErrorMessage, FieldArray } from 'vee-validate';

export default {
  name: 'LocationContactComponent',
  emits: ['delete-contact', 'row-inputs-checking', 'add-location-contact'],
  props: {
    contactsLength: Number,
    errors: {},
    contact: {},
    index: {},
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray,
  },
  methods: {
    Submit() {
      const contact = {
        Employee: '',
        Email: '',
        PhoneNumber: '',
      };
      this.$emit('add-location-contact', contact);
    },
  },
};
</script>
