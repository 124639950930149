<template>
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg class="page-header-img" width="32" height="33" viewBox="0 0 32 33" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7466 3.1665L6.91992 8.0065" stroke="#1F2629" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.2529 3.1665L25.0796 8.0065" stroke="#3AAB14" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M2.66699 10.9665C2.66699 8.4998 3.98699 8.2998 5.62699 8.2998H26.3737C28.0137 8.2998 29.3337 8.4998 29.3337 10.9665C29.3337 13.8331 28.0137 13.6331 26.3737 13.6331H5.62699C3.98699 13.6331 2.66699 13.8331 2.66699 10.9665Z"
              stroke="#1F2629" stroke-width="1.5" />
            <path d="M13.0137 19.1665V23.8998" stroke="#3AAB14" stroke-width="1.5" stroke-linecap="round" />
            <path d="M19.1465 19.1665V23.8998" stroke="#3AAB14" stroke-width="1.5" stroke-linecap="round" />
            <path
              d="M4.66699 13.8335L6.54699 25.3535C6.97366 27.9402 8.00033 29.8335 11.8137 29.8335H19.8537C24.0003 29.8335 24.6137 28.0202 25.0937 25.5135L27.3337 13.8335"
              stroke="#292D32" stroke-width="1.5" stroke-linecap="round" />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">Order details</div>
          <div class="heading-02 fw-semibold d-sm-none">Order details</div>
        </div>
        <div class="label-01 fw-semibold mt-2 d-flex align-items-center">
          <router-link class="text-neutral-05" :to="{ name: 'Orders' }"> Orders </router-link>
          <img src="../../assets/images/icons/arrow-right-12x12.svg" class="ms-2 ps-1 me-2 pe-1" />
          <span class="text-success-05">Order {{ orderIdParam }}</span>
        </div>
      </div>
      <div class="col-auto" v-if="order.Payment != 'Card'">
        <button @click="OpenAddProductModal" type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 10.5H15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 15.5V5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Add products
        </button>
      </div>
    </div>
  </div>
  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center" style="margin-top: 35px">
      <div class="col-xxl mb-3 mb-xxl-0">
        <div class="row align-items-end justify-content-xxl-start justify-content-between">
          <div class="col-auto">
            <div class="text-button-01 text-neutral-07 mb-1">Order ID</div>
            <div class="heading-01 text-neutral-08 fw-semibold">Order #{{ orderIdParam }}</div>
          </div>
          <div class="col-1 text-center d-none d-xl-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle cx="3" cy="3" r="3" fill="#688088" />
            </svg>
          </div>
          <div class="col-auto">
            <div class="text-button-01 text-neutral-07 mb-1">Ordering date</div>
            <div class="row align-items-baseline label-03">
              <div class="col-auto pe-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M8 2V5" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M16 2V5" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M3.5 9.09009H20.5" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                    stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M15.6947 13.7H15.7037" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M15.6947 16.7H15.7037" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M11.9955 13.7H12.0045" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M11.9955 16.7H12.0045" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M8.29431 13.7H8.30329" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M8.29431 16.7H8.30329" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
              <div class="col-auto ps-0 fw-semibold text-neutral-05">
                <span>
                  {{ order.OrderDate }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-1 text-center d-none d-xl-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle cx="3" cy="3" r="3" fill="#688088" />
            </svg>
          </div>
          <div class="col-auto mt-3 mt-lg-0">
            <div class="text-button-01 text-neutral-07 mb-1">Desired shipping date</div>
            <div class="row align-items-baseline">
              <div class="col-auto pe-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M8 2V5" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M16 2V5" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M3.5 9.09009H20.5" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                    stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M15.6947 13.7H15.7037" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M15.6947 16.7H15.7037" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M11.9955 13.7H12.0045" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M11.9955 16.7H12.0045" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M8.29431 13.7H8.30329" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M8.29431 16.7H8.30329" stroke="#688088" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

              </div>
              <div class="col-auto ps-0 fw-semibold text-neutral-05 desired-datepicker">
                <VueDatePicker ref="datePicker" v-model="order.ShippingDate" placeholder="Select date"
                  :enableTimePicker="false" autoApply class="custom-datepicker" @update:model-value="UpdatePickUpDay"
                  format="dd.MM.yyy" id="pickUpDate" :clearable="false">
                </VueDatePicker>
                <!-- {{ order.ShippingDate }} -->
              </div>
            </div>
          </div>

          <div class="col-1 text-center d-none d-xl-block" v-if="order.Coupon">
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle cx="3" cy="3" r="3" fill="#688088" />
            </svg>
          </div>

          <div class="col-auto mt-3 mt-lg-0" v-if="order.Coupon">
            <div class="text-button-01 text-neutral-07 mb-1">Coupon used</div>
            <div class="row align-items-baseline">
              <div class="col-auto pe-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18.91 14.6924C18.91 15.9824 19.97 17.0324 21.26 17.0324C21.26 20.7824 20.32 21.7224 16.57 21.7224H7.19C3.44 21.7224 2.5 20.7824 2.5 17.0324V16.5724C3.79 16.5724 4.85 15.5124 4.85 14.2224C4.85 12.9324 3.79 11.8724 2.5 11.8724V11.4124C2.51 7.66241 3.44 6.72241 7.19 6.72241H16.56C20.31 6.72241 21.25 7.66241 21.25 11.4124V12.3524C19.96 12.3524 18.91 13.3924 18.91 14.6924Z"
                    stroke="#688088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M16.2111 6.7225H7.12109L10.0511 3.7925C12.4411 1.4025 13.6411 1.4025 16.0311 3.7925L16.6311 4.3925C16.0011 5.0225 15.8511 5.9525 16.2111 6.7225Z"
                    stroke="#688088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.87891 6.72266L9.87891 21.7227" stroke="#688088" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" stroke-dasharray="5 5" />
                </svg>
              </div>
              <div class="col-auto ps-0 fw-semibold text-neutral-05">{{ order.Coupon.Name }}</div>
            </div>
          </div>
          <div class="col-1 text-center d-none d-xl-block" v-if="order.Coupon">
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle cx="3" cy="3" r="3" fill="#688088" />
            </svg>
          </div>

          <div class="col-auto mt-3 mt-lg-0" v-if="order.Coupon">
            <div v-if="order.Coupon.Type" class="text-button-01 text-neutral-07 mb-1">
              Discount percentege
            </div>
            <div v-else class="text-button-01 text-neutral-07 mb-1">Discount value</div>
            <div class="d-flex align-items-baseline">
              <div v-if="order.Coupon.Type" class="col-auto ps-0 fw-semibold text-neutral-05">
                -{{ order.Coupon.Value }} %
              </div>
              <div v-else class="col-auto ps-0 fw-semibold text-neutral-05">
                -{{ order.Coupon.Value }} RON
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-md-6 col-xxl-2">
        <VueMultiselect id="status" v-model="order.Status" :options="statuses" :close-on-select="true"
          @select="ChangeOrderStatus" :allow-empty="false" class="custom-multiselect" select-label=""
          placeholder="Select status" />
      </div>
      <div class="col-auto">
        <button type="button" @click="DeleteOrder()" class="button table-btn" style="padding: 11.5px">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
              stroke="#DD2531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M7.08301 4.14175L7.26634 3.05008C7.39967 2.25841 7.49967 1.66675 8.90801 1.66675H11.0913C12.4997 1.66675 12.608 2.29175 12.733 3.05841L12.9163 4.14175"
              stroke="#DD2531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M15.7087 7.6167L15.167 16.0084C15.0753 17.3167 15.0003 18.3334 12.6753 18.3334H7.32533C5.00033 18.3334 4.92533 17.3167 4.83366 16.0084L4.29199 7.6167"
              stroke="#DD2531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.6084 13.75H11.3834" stroke="#DD2531" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M7.91699 10.4167H12.0837" stroke="#DD2531" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
    <div class="row align-items-center mt-5">
      <div class="col-lg mb-3 mb-lg-0">
        <div class="card-border">
          <div class="row align-items-center">
            <div class="col-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                  stroke="#688088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22"
                  stroke="#688088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="col p-0 label-03 fw-semibold text-success-05">Name</div>
            <div class="col-auto me-2 paragraph-01 fw-semibold text-neutral-07">
              {{ order.UserName }}
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-border">
          <div class="row align-items-center">
            <div class="col-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                  stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" />
              </svg>
            </div>
            <div class="col p-0 label-03 fw-semibold text-success-05">Phone</div>
            <div class="col-auto me-2 paragraph-01 fw-semibold text-neutral-07">
              {{ order.PhoneNumber }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-6 mt-3 mt-xxl-0">
        <div class="card-border">
          <div class="row align-items-center">
            <div class="col-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z"
                  stroke="#3AAB14" stroke-width="1.5" />
                <path
                  d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z"
                  stroke="#688088" stroke-width="1.5" />
              </svg>
            </div>
            <div class="col p-0 label-03 fw-semibold text-success-05">Address</div>
            <div class="col-auto paragraph-01 fw-semibold text-neutral-07">
              {{ order.Address }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-3">
      <div class="col-xxl col-lg-6 mb-3 mb-xxl-0">
        <div class="card-border">
          <div class="row align-items-center">
            <div class="col-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                  stroke="#688088" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#3AAB14" stroke-width="1.5"
                  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="col p-0 label-03 fw-semibold text-success-05">Email</div>
            <div class="col-auto paragraph-01 d-none d-sm-block" :title="order.Email">
              {{ $utils.Ellipsify(order.Email, 25) }}
            </div>
            <div class="col-auto paragraph-01 d-sm-none">
              {{ order.Email }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl col-lg-6 mb-3 mb-xxl-0">
        <div class="card-border">
          <div class="row align-items-center">
            <div class="col-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M3.92969 15.8792L15.8797 3.9292" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.1016 18.2791L12.3016 17.0791" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.793 15.5887L16.183 13.1987" stroke="#3AAB14" stroke-width="1.5" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z"
                  stroke="#688088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 21.9985H22" stroke="#688088" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div class="col p-0 label-03 fw-semibold text-success-05">Payment</div>
            <div class="col-auto paragraph-01 fw-semibold text-neutral-07">
              {{ order.Payment }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg mb-3 mb-lg-0">
        <div class="card-border">
          <div class="row align-items-center">
            <div class="col-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18.91 14.6924C18.91 15.9824 19.97 17.0324 21.26 17.0324C21.26 20.7824 20.32 21.7224 16.57 21.7224H7.19C3.44 21.7224 2.5 20.7824 2.5 17.0324V16.5724C3.79 16.5724 4.85 15.5124 4.85 14.2224C4.85 12.9324 3.79 11.8724 2.5 11.8724V11.4124C2.51 7.66241 3.44 6.72241 7.19 6.72241H16.56C20.31 6.72241 21.25 7.66241 21.25 11.4124V12.3524C19.96 12.3524 18.91 13.3924 18.91 14.6924Z"
                  stroke="#688088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M16.2111 6.7225H7.12109L10.0511 3.7925C12.4411 1.4025 13.6411 1.4025 16.0311 3.7925L16.6311 4.3925C16.0011 5.0225 15.8511 5.9525 16.2111 6.7225Z"
                  stroke="#688088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.87891 6.72266L9.87891 21.7227" stroke="#3AAB14" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" stroke-dasharray="5 5" />
              </svg>
            </div>
            <div class="col p-0 label-03 fw-semibold text-success-05">Total price</div>
            <div class="col-auto paragraph-01 fw-semibold text-neutral-07">
              {{ order.TotalPrice }} RON
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-border">
          <div class="row align-items-center">
            <div class="col-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="10" stroke="#688088" stroke-width="1.5" />
                <path
                  d="M11.0866 6.04906C11.439 5.25857 12.561 5.25857 12.9134 6.04906L13.9752 8.43129C14.1206 8.75732 14.4287 8.98117 14.7837 9.01863L17.3774 9.29239C18.2381 9.38323 18.5848 10.4503 17.9419 11.0297L16.0044 12.7758C15.7393 13.0147 15.6216 13.3769 15.6956 13.7261L16.2368 16.2775C16.4164 17.1242 15.5086 17.7837 14.7589 17.3513L12.4996 16.0482C12.1904 15.8698 11.8096 15.8698 11.5004 16.0482L9.24106 17.3513C8.49135 17.7837 7.58362 17.1242 7.76319 16.2775L8.30436 13.7261C8.37842 13.3769 8.26074 13.0147 7.99557 12.7758L6.05807 11.0297C5.41516 10.4503 5.76188 9.38323 6.62256 9.29239L9.21634 9.01863C9.57133 8.98117 9.87942 8.75732 10.0248 8.43129L11.0866 6.04906Z"
                  stroke="#3AAB14" stroke-width="1.5" />
              </svg>
            </div>
            <div class="col p-0 label-03 fw-semibold text-success-05">Fidelity points</div>
            <div class="col-auto paragraph-01 fw-semibold text-neutral-07">
              {{ order.FidelityPoints }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 heading-01 text-neutral-08 fw-semibold">Mentions</div>
    <div class="paragraph-01 mt-2">
      {{ order.Mentions || '-' }}
    </div>
    <div class="mt-5 mb-3 heading-01 text-neutral-08 fw-semibold">Products</div>
    <div class="table-responsive">
      <div class="row-table m-0">
        <div class="row header m-0">
          <div class="col-3 p-0">
            <span class="text">Name & description</span>
          </div>
          <div class="col-2 ps-0" :class="{ active: filter.OrderBy.includes('quantity') }">
            <span @click="OrderBy('quantity')" class="pointer d-inline-flex">
              <span class="text">Quantity</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="quantity" />
            </span>
          </div>
          <div class="col-2 ps-0" :class="{ active: filter.OrderBy.includes('price') }">
            <span @click="OrderBy('price')" class="pointer d-inline-flex">
              <span class="text">Price</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="price" />
            </span>
          </div>
          <div class="col-2 ps-0" :class="{ active: filter.OrderBy.includes('fidelity_points') }">
            <span @click="OrderBy('fidelity_points')" class="pointer d-inline-flex">
              <span class="text">Fidelity points</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="fidelity_points" />
            </span>
          </div>
        </div>
        <div class="row content align-items-center m-0" v-for="(product, index) in products" :key="index">
          <div class="col-3 p-0">
            <div class="d-flex align-items-center">
              <img class="content-img me-3" :src="$utils.GetImageFormat(product.ImgBase64)" />
              <div>
                <div class="title mb-1">{{ product.Name }}</div>
                <div class="row">
                  <div class="col-auto pe-0" v-for="(config, configIndex) in product.OrderConfigurations"
                    :key="configIndex">
                    <div class="badge-config text-button-01 text-neutral-07">
                      {{ config }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 ps-0">
            {{ product.Quantity }}
          </div>
          <div class="col-2 ps-0">{{ product.Price }} RON</div>
          <div class="col-2 ps-0">
            {{ product.FidelityPoints }}
          </div>
          <div class="col"></div>
          <div class="col-auto">
            <button type="button" class="button table-btn" @click="DeleteProduct(product.Id)">
              <img src="../../assets/images/icons/table/trash-linear.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PaginationComponent :numberOfPages="pagination.TotalPageCount" :pageNumber="filter.PageNumber"
    :functionName="SelectPage"></PaginationComponent>
  <SelectProductModal ref="selectProductRef" @add-product="AddProductToOrder" />
</template>

<script>
/*eslint-disable*/
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import SelectProductModal from '../../components/Orders/Modals/SelectProductModal.vue';
import moment from 'moment';

export default {
  name: 'OrderDetails',
  props: {
    orderIdParam: {
      type: String,
      required: true,
    },
  },
  components: {
    OrderByIconComponent,
    PaginationComponent,
    SelectProductModal,
  },
  data() {
    return {
      statuses: ['New', 'In progress', 'Ready', 'Finished', 'Not Picket UP', 'Canceled'],
      order: {},
      filter: {
        OrderBy: 'quantity',
        PageNumber: 1,
        PageSize: 10,
      },
      products: [],
      pagination: {},
    };
  },
  methods: {
    OpenDatePicker() {
      console.log(this.$refs.datePicker);
      this.$refs.datePicker.openMenu();
    },
    UpdatePickUpDay(date) {
      console.log(date);
      const pickUpday = new Date(date).toDateString('en-US');
      console.log(pickUpday);

      this.$axios.post(`/api/Orders/updatePickUpDay/${this.orderIdParam}?pickUpday=${pickUpday}`).then(() => {
        this.$utils.toastNotify('success', 'Date modified succesfully');
      }).catch(() => {
        this.$utils.toastNotify('error', 'Something went wrong!');
      })
    },
    OpenAddProductModal() {
      this.$refs.selectProductRef.OpenModal(true);
    },

    AddProductToOrder(product) {
      const payload = {
        ProductId: product.Id,
        Quantity: product.Quantity || 1,
        OrderId: parseInt(this.orderIdParam),
      };
      this.$axios
        .post('/api/Orders/addProductToOrder', payload)
        .then(() => {
          this.GetOrder();
          this.$utils.toastNotify('success', 'Product added successfully!');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong!');
        });
    },
    FormatOrderingDate(date) {
      return {
        Hour: moment(date).format('HH:mm'),
        Date: moment(date).format('MM.DD.YYYY'),
      };
    },

    DeleteProduct(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Orders/deleteOrderProduct/${id}`)
              .then(() => {
                this.filter.PageNumber = 1;
                this.GetOrder();
                this.$utils.toastNotify('success', 'Order deleted successfully');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    DeleteOrder() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Orders/deleteOrder/${this.orderIdParam}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Your work has been saved.');
                setTimeout(() => {
                  this.$router.push('/Orders');
                }, '2500');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetOrder();
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetOrder();
    },
    GetOrder() {
      const searchParams = {
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
        ...(this.filter.OrderBy ? { OrderBy: this.filter.OrderBy } : ''),
      };
      this.$axios
        .get(
          `/api/Orders/getOrderDetails/${this.orderIdParam}?${new URLSearchParams(searchParams)}`,
        )
        .then((response) => {
          this.order = response.data;
          this.products = response.data.Products.Items;
          this.pagination = response.data.Products.PageDetails;
          console.log(this.order);
        })
        .catch(() => {
          this.$router.push('/Orders');
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    ChangeOrderStatus() {
      // eslint-disable-next-line no-param-reassign
      this.$axios
        .put(`/api/Orders/updateOrderStatus/${this.orderIdParam}?status=${this.order.Status}`)
        .then(() => {
          // eslint-disable-next-line no-undef
          $('.dropdown-menu').removeClass('show');
          this.$utils.toastNotify('success', 'Order status updated succesfully');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    ShowDropdown(index, order) {
      // eslint-disable-next-line no-undef
      $('.dropdown-menu').removeClass('show');
      // eslint-disable-next-line no-undef
      $(`#status-dropdown-${index}`).dropdown('toggle');
      // eslint-disable-next-line no-param-reassign
      order.OpenStatusDropdown = true;
    },
    CloseDropdown(index, order) {
      // eslint-disable-next-line no-undef
      $('.dropdown-menu').removeClass('show');
      // eslint-disable-next-line no-param-reassign
      order.OpenStatusDropdown = false;
    },
  },
  created() {
    if (this.orderIdParam) {
      this.GetOrder();
    } else {
      this.$router.push('Orders');
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1200px) {
  .table-responsive .row-table {
    width: 1200px;
  }
}

.card-border {
  border-radius: 8px;
  border: 1px solid var(--tints-blacktint-2, rgba(0, 0, 0, 0.2));
  background: var(--main-colors-neutral-1-white, #fff);
  padding: 13px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.badge-config {
  border: 1px solid var(--blacktint-1);
  border-radius: 4px;
  padding: 4px 6px;
  text-align: center;
}
</style>
