<template>
  <span v-if="percentage" class="d-flex align-items-center float-end">
    <svg
      v-if="percentage > 0"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6134 5.21338C14.5457 5.05048 14.4163 4.92103 14.2534 4.85338C14.1732 4.81922 14.0871 4.8011 14 4.80005H10.6667C10.4899 4.80005 10.3203 4.87029 10.1953 4.99531C10.0703 5.12034 10 5.2899 10 5.46672C10 5.64353 10.0703 5.8131 10.1953 5.93812C10.3203 6.06314 10.4899 6.13338 10.6667 6.13338H12.3934L8.6667 9.86005L6.47336 7.66005C6.41139 7.59756 6.33765 7.54797 6.25642 7.51412C6.17518 7.48028 6.08804 7.46285 6.00003 7.46285C5.91202 7.46285 5.82489 7.48028 5.74365 7.51412C5.66241 7.54797 5.58867 7.59756 5.5267 7.66005L1.5267 11.6601C1.46421 11.722 1.41462 11.7958 1.38077 11.877C1.34692 11.9582 1.3295 12.0454 1.3295 12.1334C1.3295 12.2214 1.34692 12.3085 1.38077 12.3898C1.41462 12.471 1.46421 12.5447 1.5267 12.6067C1.58867 12.6692 1.66241 12.7188 1.74365 12.7526C1.82489 12.7865 1.91202 12.8039 2.00003 12.8039C2.08804 12.8039 2.17518 12.7865 2.25641 12.7526C2.33765 12.7188 2.41139 12.6692 2.47336 12.6067L6.00003 9.07338L8.19336 11.2734C8.25534 11.3359 8.32907 11.3855 8.41031 11.4193C8.49155 11.4532 8.57869 11.4706 8.6667 11.4706C8.75471 11.4706 8.84184 11.4532 8.92308 11.4193C9.00432 11.3855 9.07806 11.3359 9.14003 11.2734L13.3334 7.07338V8.80005C13.3334 8.97686 13.4036 9.14643 13.5286 9.27145C13.6537 9.39648 13.8232 9.46672 14 9.46672C14.1768 9.46672 14.3464 9.39648 14.4714 9.27145C14.5965 9.14643 14.6667 8.97686 14.6667 8.80005V5.46672C14.6656 5.3796 14.6475 5.29353 14.6134 5.21338Z"
        fill="#2E8910"
      />
    </svg>

    <svg
      v-else
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6134 7.78662C13.5457 7.94952 13.4163 8.07897 13.2534 8.14662C13.1732 8.18078 13.0871 8.1989 13 8.19995H9.6667C9.48989 8.19995 9.32032 8.12971 9.19529 8.00469C9.07027 7.87966 9.00003 7.7101 9.00003 7.53328C9.00003 7.35647 9.07027 7.1869 9.19529 7.06188C9.32032 6.93686 9.48989 6.86662 9.6667 6.86662H11.3934L7.6667 3.13995L5.47336 5.33995C5.41139 5.40244 5.33765 5.45203 5.25642 5.48588C5.17518 5.51972 5.08804 5.53715 5.00003 5.53715C4.91202 5.53715 4.82489 5.51972 4.74365 5.48588C4.66241 5.45203 4.58867 5.40244 4.5267 5.33995L0.526697 1.33995C0.464212 1.27797 0.414615 1.20424 0.38077 1.123C0.346924 1.04176 0.329498 0.954625 0.329498 0.866617C0.329498 0.778609 0.346924 0.691472 0.38077 0.610232C0.414615 0.528993 0.464212 0.455259 0.526697 0.393284C0.588672 0.330799 0.662407 0.281201 0.743646 0.247355C0.824886 0.21351 0.912023 0.196085 1.00003 0.196085C1.08804 0.196085 1.17518 0.21351 1.25641 0.247355C1.33765 0.281201 1.41139 0.330799 1.47336 0.393284L5.00003 3.92662L7.19336 1.72662C7.25534 1.66413 7.32907 1.61454 7.41031 1.58069C7.49155 1.54684 7.57869 1.52942 7.6667 1.52942C7.75471 1.52942 7.84184 1.54684 7.92308 1.58069C8.00432 1.61454 8.07806 1.66413 8.14003 1.72662L12.3334 5.92662V4.19995C12.3334 4.02314 12.4036 3.85357 12.5286 3.72855C12.6537 3.60352 12.8232 3.53328 13 3.53328C13.1768 3.53328 13.3464 3.60352 13.4714 3.72855C13.5965 3.85357 13.6667 4.02314 13.6667 4.19995V7.53328C13.6656 7.6204 13.6475 7.70647 13.6134 7.78662Z"
        fill="#DD2531"
      />
    </svg>

    <span
      class="label-01 fw-semibold ms-1"
      :class="[percentage > 0 ? 'text-success-06' : 'text-danger-05']"
    >
      {{ percentage > 0 ? '+' : '' }}{{ percentage }}%
    </span>
  </span>
  <span v-else class="label-01 float-end fw-semibold">No data</span>
</template>
<script>
export default {
  name: 'arrow-up-down-component',
  props: {
    percentage: Number,
  },
  methods: {},
  data() {
    return {};
  },
};
</script>
<style scoped></style>
