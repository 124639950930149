<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    class="modal fade"
    id="addSpecificationModal"
    tabindex="-1"
    aria-labelledby="addSpecificationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addSpecificationFormRef"
          @submit="Submit"
          :validation-schema="Schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addSpecificationModalLabel">Add specification</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ResetModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="name">Specification name:</label>
              <Field
                type="text"
                id="name"
                name="name"
                class="form-control"
                v-model="specification.Name"
                placeholder="Specification"
                :class="{ 'border-danger': errors.name }"
              >
              </Field>
              <ErrorMessage name="name" class="error-message" />
            </div>
            <label>Values</label>
            <div class="row mb-2" v-for="(item, index) in values" :key="index">
              <div class="col pe-0">
                <Field
                  :name="`values[${index}].val`"
                  v-model="item.Value"
                  type="text"
                  class="form-control rounded-1"
                  :placeholder="`Value ${index + 1}`"
                  :class="{ 'border-danger': errors[`values[${index}].val`] }"
                >
                </Field>
              </div>
              <div class="col-auto">
                <button type="button" class="button table-btn h-100" @click="DeleteValue(index)">
                  <img src="../../../../assets/images/icons/trash-linear-red.svg" />
                </button>
              </div>
              <div class="col-12">
                <ErrorMessage :name="`values[${index}].val`" class="error-message" />
              </div>
            </div>

            <button
              type="button"
              class="button add-spec-button paragraph-02 text-neutral-04 w-100"
              @click="AddValue"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10H15"
                  stroke="#95A6AC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 15V5"
                  stroke="#95A6AC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Add value
            </button>
          </div>

          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddSpecificationModal',
  data() {
    return {
      specification: {},
      values: [],
    };
  },
  props: {
    category: Object,
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    DeleteValue(index) {
      this.values.splice(index, 1);
    },
    AddValue() {
      this.values.push({ Value: '' });
    },
    Submit() {
      this.specification.SpecificationCategoryId = this.category.Id;
      this.specification.Values = this.values;
      this.$emit('save', this.specification, this.category);
    },
    ClearModalValues() {
      this.$refs.addSpecificationFormRef.resetForm();
      this.values = [{ Value: '' }];
    },
  },
  computed: {
    Schema() {
      return yup.object({
        name: yup.string().required('Name field is required.')
          // .min(3, 'Specification name must have at least 3 characters')
          .max(200, 'Specification name be under 200 characters')
          .trim(),
        values: yup.array().of(
          yup.object().shape({
            val: yup.string().required('Please provide a value').trim(),
          }),
        ),
      });
    },
    ValueSchema() {
      return yup.object({
        value: yup.string().required().trim(),
      });
    },
  },
};
</script>
<style scoped>
.add-spec-button {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px dashed var(--neutral-3);
  transition: 0.2s;
}

.add-spec-button:hover {
  background-color: var(--neutral-2);
}
</style>
