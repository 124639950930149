<!-- eslint-disable import/no-extraneous-dependencies -->
<template>
  <div
    class="modal fade"
    id="addCategoryModal"
    tabindex="-1"
    aria-labelledby="addCategoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addCategoryFormRef"
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addCategoryModalLabel">Add category</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="name">Category name</label>
              <Field
                id="name"
                name="name"
                type="text"
                class="form-control"
                v-model="category.Name"
                placeholder="Name"
                :class="{ 'border-danger': errors.name }"
              ></Field>
              <ErrorMessage name="name" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="description">Category description</label>
              <Field
                rows="3"
                id="description"
                name="description"
                type="text"
                as="textarea"
                class="form-control"
                v-model="category.Description"
                placeholder="Description"
                :class="{ 'border-danger': errors.description }"
              ></Field>
              <ErrorMessage name="description" class="error-message" />
            </div>
            <!-- <div class="text-center">
              <label for="upload-file" class="btn btn-outline-success"> Add image</label>
              <input
                id="upload-file"
                accept="image/*"
                type="file"
                class="d-none"
                @input="$utils.InputImage(category)"
              />
            </div>
            <img
              class="rounded mx-auto d-block"
              v-if="category.ImgBase64"
              :src="$utils.GetImageFormat(category.ImgBase64)"
            /> -->
            <div class="icons-search">
              <input
                type="text"
                v-model="searchIconText"
                class="form-control"
                placeholder="Search icons"
              />
              <div class="row list align-items-center">
                <div class="col-auto" v-for="(item, index) in filterImages" :key="index">
                  <div
                    class="box-img p-2"
                    :class="{ active: category.ImgBase64 == item }"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :title="item"
                    @click="category.ImgBase64 = item"
                  >
                    <img class="category-img" :src="DynamicPathAttachment(item)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddCategoryModal',
  data() {
    return {
      searchIconText: '',
      icons: [
        'apple-pie-1',
        'apple-pie-2',
        'birthday-cake-1',
        'birthday-cake-2',
        'cake-1',
        'cake-10',
        'cake-11',
        'cake-12',
        'cake-13',
        'cake-14',
        'cake-15',
        'cake-16',
        'cake-17',
        'cake-18',
        'cake-19',
        'cake-2',
        'cake-20',
        'cake-21',
        'cake-22',
        'cake-23',
        'cake-3',
        'cake-4',
        'cake-5',
        'cake-6',
        'cake-7',
        'cake-8',
        'cake-9',
        'chocolate-cake',
        'cupcake-1',
        'cupcake-2',
        'cupcake-3',
        'cupcake-4',
        'cupcake-5',
        'dessert',
        'king-cake',
        'muffin-1',
        'muffin-2',
        'muffin-3',
        'pie',
        'piece-of-cake',
        'roscon-de-reyes',
        'sweet-bread',
      ],
      category: {
        Name: '',
        Description: '',
      },
    };
  },
  methods: {
    DynamicPathAttachment(name) {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@/attachments/categories/${name}.svg`);
    },
    ClearModalValues() {
      this.$refs.addCategoryFormRef.resetForm();
      this.category.ImgBase64 = '';
    },
    onSubmit() {
      this.SaveCategory();
    },
    SaveCategory() {
      this.$axios
        .post('/api/categories/create', this.category)
        .then(() => {
          this.$utils.toastNotify('success', 'Category added successfully.', 2500);
          this.$emit('save');
          // eslint-disable-next-line no-undef
          $('#addCategoryModal').modal('hide');
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$utils.toastNotify('error', 'This category already exist', 2500);
          } else {
            this.$utils.toastNotify('error', 'Something went wrong.', 2500);
          }
        });
    },
    ChangeImage() {
      this.$utils.InputImage(this.category);
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  computed: {
    filterImages() {
      return this.icons.filter((imgName) => imgName.toLowerCase().includes(this.searchIconText));
    },
    schema() {
      return yup.object({
        name: yup
          .string()
          .required('Name field is required.')
          .min(3, 'Category title must have at least 3 characters')
          .max(50, 'Category title must be under 50 characters')
          .trim(),
        description: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,200})$/, 'Between 5-200 characters')
          .trim(),
      });
    },
  },
  created() {},
};
</script>
<style scoped>
.icons-search .list .box-img {
  cursor: pointer;
  padding: 2px;
  border-radius: 4px;
}

.icons-search .list .box-img.active {
  background-color: #ebf7e8;
  cursor: default !important;
}

.icons-search .form-control {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.icons-search .list {
  margin: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  height: 150px;
  overflow-y: auto;
  border: 1px solid var(--neutral-3);
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px;
}

.category-img {
  width: 38px;
}
</style>
