<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="modal fade"
    id="addRecomandation"
    tabindex="-1"
    aria-labelledby="addRecomandationLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img src="../../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
          <h5 class="modal-title" id="addRecomandationLabel">Add product</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label for="search">Search product</label>
          <div class="input-group left mb-4">
            <span class="input-group-text">
              <img src="../../../../assets/images/icons/search-normal-20x20.svg" />
            </span>
            <input
              id="search"
              v-model="filter.SearchQuery"
              @keyup.enter="$emit('search')"
              type="text"
              class="search form-control"
              placeholder="Search in..."
            />
          </div>
          <div class="paragraph-03 fw-normal text-neutral-08">Products</div>
          <div class="custom-scrollbar" style="height: 405px; overflow-y: auto">
            <div v-for="(product, index) in products" :key="index">
              <div class="row border-bottom pb-3 pt-3 align-items-center m-0">
                <div class="col d-flex align-items-center">
                  <img :src="$utils.GetImageFormat(product.ImgBase64)" class="cover-img-48px" />
                  <div class="ms-3">
                    <div class="label-02 fw-semibold text-neutral-08 mb-3px">
                      {{ product.Title }}
                    </div>
                    <div
                      v-if="product.ShortDescription"
                      class="paragraph-01 text-neutral-06"
                      :title="product.ShortDescription"
                    >
                      {{ this.$utils.Ellipsify(product.ShortDescription, 25) }}
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="form-check">
                    <input
                      v-model="product.Selected"
                      class="form-check-input"
                      type="checkbox"
                      value="true"
                      :id="`product-checbox-${index}`"
                    />
                    <label class="form-check-label" :for="`product-checbox-${index}`"> </label>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="../../../../assets/images/not-found-img.png"
              v-if="products.length == 0"
              class="m-auto d-block mt-5"
              style="max-width: 150px"
            />
          </div>

          <div class="text-center ps-2 pe-2 mt-3">
            <button
              v-if="filter.PageNumber < pagination.TotalPageCount"
              @click="$emit('get')"
              class="button btn-small btn-success-outlined"
            >
              Show more
            </button>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            class="button btn-success-solid btn-medium w-100"
            @click="AddProducts"
            data-bs-dismiss="modal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddRecomandationModal',
  props: {
    products: Array,
    pagination: Object,
    productId: Number,
    filter: Object,
    recomandations: Array,
  },
  methods: {
    AddProducts() {
      const selectedProducts = [];
      this.products.forEach((element) => {
        if (element.Selected === true) {
          selectedProducts.push({
            ProductId: element.Id,
            Title: element.Title,
            ShortDescription: element.ShortDescription,
            ImgBase64: element.ImgBase64,
          });
        }
      });
      this.$emit('add', selectedProducts);
    },
    ClearModal() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.SearchQuery = '';
    },
  },
};
</script>

<style>
.border-bottom {
  border-bottom: 1px solid black;
}
</style>
