<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">Inventory</div>
  <Form ref="productInventoryFormRef" :validation-schema="schema" v-slot="{ errors }">
    <div class="row">
      <div class="col-lg mb-3 mb-lg-0">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">SKU</label>
        <Field
          name="sku"
          type="text"
          placeholder="Sku"
          class="form-control form-control-lg"
          v-model="product.Sku"
          :class="{ 'border-danger': errors.sku }"
        ></Field>
        <ErrorMessage class="error-message" name="sku" />
      </div>
      <div class="col-lg mb-3 mb-lg-0">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">Total stock</label>
        <Field
          name="stock"
          type="number"
          step="0.01"
          placeholder="0.00"
          class="form-control form-control-lg"
          v-model="product.Stock"
          :class="{ 'border-danger': errors.stock }"
        ></Field>
        <ErrorMessage class="error-message" name="stock" />
      </div>
      <div class="col">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">Supplier</label>
        <Field
          name="supplier"
          type="text"
          placeholder="Supplier"
          class="form-control form-control-lg"
          v-model="product.Supplier"
          :class="{ 'border-danger': errors.supplier }"
        ></Field>
        <ErrorMessage class="error-message" name="supplier" />
      </div>
    </div>
    <div v-if="product.Locations && product.Locations.length > 0">

    <hr
      style="
        margin-left: -67px;
        width: 87.6vw;
        margin-top: 38px;
        margin-bottom: 38px;
        color: var(--blacktint-4);
      "
    />
    <div class="heading-01 fw-semibold text-neutral-08 mb-4 pb-2">Locations stock</div>
    <div class="table-responsive">
      <!--table-->
      <div
        class="row label-02 fw-semibold text-neutral-07 row-border-bottom ms-0 me-0"
        style="padding-bottom: 12px"
      >
        <div class="col-5 col-xl-7 ps-0">Location name</div>
        <div class="col">Stock</div>
        <div class="col col-xl-1">On stock</div>
        <div class="col col-xl-1">Available</div>
        <!-- <div class="col col-xl-1 pe-0"></div> -->
      </div>
      <FieldArray name="location">
        <div
          class="row row-border-bottom pt-3 pb-3 ms-0 me-0 align-items-center"
          v-for="(location, index) in product.Locations"
          :key="index"
        >
          <div class="col-5 col-xl-7 ps-0">
            <div class="label-02 fw-semibold text-neutral-08 mb-1">
              {{ location.LocationName }}
            </div>
            <div class="paragraph-01 text-neutral-06">
              {{ location.LocationName }}
            </div>
          </div>
          <div class="col">
            <Field
              :name="`location[${index}].stock`"
              type="number"
              v-model="location.Stock"
              placeholder="0"
              class="form-control form-control-lg"
              :class="{ 'border-danger': errors[`location[${index}].stock`] }"
              :disabled="!location.InStock"
            ></Field>
            <ErrorMessage :name="`location[${index}].stock`" class="error-message" />
          </div>
          <div class="col col-xl-1">
            <input
              v-model="location.InStock"
              @click="location.InStock ? (location.Stock = 0) : ''"
              class="switch-small switch-small--shadow"
              :id="`inventory-location-[${index}]-on-stock-switch`"
              type="checkbox"
              role="switch"
            />
            <label :for="`inventory-location-[${index}]-on-stock-switch`" class="mb-0"></label>
          </div>
          <div class="col col-xl-1">
            <input
              v-model="location.IsAvailable"
              class="switch-small switch-small--shadow"
              :id="`inventory-location-[${index}]-available-switch`"
              type="checkbox"
              role="switch"
            />
            <label :for="`inventory-location-[${index}]-available-switch`" class="mb-0"></label>
          </div>
          <!-- <div class="col col-xl-1 pe-0">
            <button
              type="button"
              class="button table-btn float-end"
              @click="$emit('remove-location', location)"
            >
              <img src="../../assets/images/icons/table/trash-linear.svg" />
            </button>
          </div> -->
        </div>
      </FieldArray>
      <ErrorMessage name="location" class="error-message" />

      <div v-if="!product.Locations || product.Locations.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/locations-should-be-added.svg" />
      </div>
    </div>
  </div>
  </Form>
</template>

<script>
/* eslint-disable */
import { Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ProductInventory',
  emits: ['remove-location'],
  props: {
    product: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    FieldArray,
  },
  methods: {
    Submit() {
      return this.$refs.productInventoryFormRef.validate().then((result) => result.valid);
    },
  },
  computed: {
    schema() {
      return yup.object({
        stock: yup
          .number()
          .min(0, 'Stock cannot be negative')
          .test('is-deciaml', 'The number should not be a deciaml', (number) => {
            if (!number) {
              return true;
            }
            return /^\d+(\.\d{0})?$/.test(number);
          })
          .notRequired()
          .transform((_, val) => (val === Number(val) ? val : null)),
        supplier: yup.string().notRequired().trim(),
        sku: yup.string().notRequired().trim(),
        location: yup.array().of(
          yup.object().shape({
            stock: yup
              .number()
              .min(0, 'Stock cannot be negative')
              .test('is-deciaml', 'The number should not be a deciaml', (number) => {
                if (!number) {
                  return true;
                }
                return /^\d+(\.\d{0})?$/.test(number);
              })
              .notRequired()
              .transform((_, val) => (val === Number(val) ? val : null)),
          }),
        ),
      });
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1170px) {
  .table-responsive .row {
    width: 992px;
  }
}
</style>
