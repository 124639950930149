<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.2133 14.9932C12.08 14.9798 11.92 14.9798 11.7733 14.9932C8.59996 14.8865 6.07996 12.2865 6.07996 9.0865C6.07996 5.81984 8.71996 3.1665 12 3.1665C15.2666 3.1665 17.92 5.81984 17.92 9.0865C17.9066 12.2865 15.3866 14.8865 12.2133 14.9932Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.88 5.8335C24.4667 5.8335 26.5467 7.92683 26.5467 10.5002C26.5467 13.0202 24.5467 15.0735 22.0533 15.1668C21.9467 15.1535 21.8267 15.1535 21.7067 15.1668"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.54671 19.9135C2.32004 22.0735 2.32004 25.5935 5.54671 27.7402C9.21338 30.1935 15.2267 30.1935 18.8934 27.7402C22.12 25.5802 22.12 22.0602 18.8934 19.9135C15.24 17.4735 9.22671 17.4735 5.54671 19.9135Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.4534 27.1665C25.4134 26.9665 26.32 26.5798 27.0667 26.0065C29.1467 24.4465 29.1467 21.8732 27.0667 20.3132C26.3334 19.7532 25.44 19.3798 24.4934 19.1665"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          :disabled="!isAdmin"
          @click="OpenAddUserModal"
          type="button"
          :class="{
            'button btn-small btn-success-solid d-flex align-items-center pe-3': isAdmin,
            'button btn-small btn-disabled-solid d-flex align-items-center pe-3': !isAdmin,
          }"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Add user
        </button>
      </div>
    </div>
  </div>

  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center filters-zone mb-3">
      <div class="mb-3 mb-lg-0 col-lg col-xxl-3">
        <label for="search">Search</label>
        <div class="input-group left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="search"
            v-model="filter.SearchText"
            @keyup.enter="SearchByQuery"
            type="text"
            class="search form-control"
            placeholder="Search in..."
          />
        </div>
      </div>
      <div class="mb-3 mb-md-0 col-md col-xxl-3">
        <label for="status">Status</label>
        <div :class="{ 'multiselect-value-selected': filter.Status }">
          <VueMultiselect
            id="status"
            v-model="filter.Status"
            :options="['Active', 'Inactive']"
            :close-on-select="true"
            @select="GetUsers"
            @remove="GetUsers"
            class="custom-multiselect"
            select-label=""
            placeholder="Select status"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Status = '';
              GetUsers();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md col-xxl-3">
        <label for="roles">Roles</label>
        <div :class="{ 'multiselect-value-selected': filter.Role }">
          <VueMultiselect
            id="roles"
            v-model="filter.Role"
            :options="['Admin', 'User']"
            :close-on-select="true"
            @select="GetUsers"
            @remove="GetUsers"
            class="custom-multiselect"
            select-label=""
            placeholder="Select role"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Role = '';
              GetUsers();
            "
          >
          </span>
        </div>
      </div>
    </div>
    <!--table-->
    <div class="table-scroll">
      <table class="custom table">
        <thead>
          <tr valign="middle">
            <th :class="{ active: filter.OrderBy.includes('name') }">
              <span @click="OrderBy('name')" class="pointer">
                Name
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="name"
                  class="ms-1"
                />
              </span>
            </th>
            <th>Email</th>
            <th>Phone number</th>
            <th>Active</th>
            <th>Role</th>
            <th>Verified email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="index" valign="middle">
            <td>{{ user.FirstName }} {{ user.LastName }}</td>
            <td>
              {{ user.Email }}
            </td>
            <td>
              {{ user.PhoneNumber }}
            </td>
            <td>
              <input
                :disabled="!isAdmin"
                :id="'switch-shadow-' + index"
                :class="{'switch-small switch-small--shadow ': isAdmin,
              'switch-small switch-disabled':!isAdmin}"
                type="checkbox"
                :checked="user.IsBlocked"
                v-on:click="UpdateUserAccess(user.Id)"
                @click.prevent
              />
              <label :for="'switch-shadow-' + index"></label>
            </td>
            <td>{{ user.Role }}</td>
            <td>
              <span class="text-button-02 text-success-06" v-if="user.EmailConfirmed">
                Verified
              </span>
              <span class="paragraph-01" v-else>Not Verified</span>
            </td>
            <td>
              <div class="d-flex align-items-center float-end">
                <button
                  type="button"
                  :disabled="!isAdmin"
                  :class="{
                    'button table-btn me-2': isAdmin,
                    'button table-btn me-2 btn-disabled-solid': !isAdmin,
                  }"
                  @click="GetUserDetails(user.Id)"
                >
                  <img src="../../assets/images/icons/table/edit-linear.svg" />
                </button>

                <button
                  type="button"
                  :disabled="!isAdmin"
                  :class="{
                    'button table-btn me-2': isAdmin,
                    'button table-btn me-2 btn-disabled-solid': !isAdmin,
                  }"
                  v-on:click="DeleteUser(user.Id)"
                >
                  <img src="../../assets/images/icons/table/trash-linear.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="users.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>

  <AddUserModal ref="addUserModalRef" @save="CreateUser" />
  <EditUserModal ref="editUserModalRef" :user="selectedUser" @edit="UpdateUser" />
</template>

<script>
import AddUserModal from '../../components/Users/Modals/AddUserModal.vue';
import EditUserModal from '../../components/Users/Modals/EditUserModal.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';

export default {
  name: 'User',
  data() {
    return {
      users: [],
      selectedUser: {},
      filter: {
        SearchText: '',
        Status: '',
        Role: '',
        OrderBy: 'name',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      pagination: {},
      isAdmin: false,
    };
  },
  components: {
    AddUserModal,
    EditUserModal,
    PaginationComponent,
    OrderByIconComponent,
  },
  methods: {
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetUsers();
    },
    SearchByQuery() {
      this.filter.PageNumber = 1;
      this.GetUsers();
    },
    GetUsers() {
      this.filter.Role = !this.filter.Role ? '' : this.filter.Role;
      this.filter.Status = !this.filter.Status ? '' : this.filter.Status;
      const searchParams = new URLSearchParams(this.filter);
      this.$axios
        .get(`/api/User/getAllUsers?${searchParams}`)
        .then((response) => {
          this.users = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    GetUserDetails(id) {
      this.$axios
        .get(`/api/User/getUser/${id}`)
        .then((response) => {
          this.selectedUser = response.data;
          // eslint-disable-next-line no-undef
          $('#editUserModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    CreateUser(user) {
      this.$axios
        .post('/api/User/createUser', user)
        .then(() => {
          this.$utils.toastNotify('success', 'User added successfully.');
          this.GetUsers();
          // eslint-disable-next-line no-undef
          $('#addUserModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    UpdateUser(user) {
      this.$axios
        .put('/api/User/updateUser', user)
        .then(() => {
          this.$utils.toastNotify('success', 'User updated successfully.');
          this.GetUsers();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
      // eslint-disable-next-line no-undef
      $('#editUserModal').modal('hide');
    },
    UpdateUserAccess(id) {
      const self = this;
      this.$swal
        .fire({
          title: 'Warning',
          text: 'Are you sure you want to change this property?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.value) {
            self.$axios
              .put(`/api/User/changeUserAccess/${id}`, this.$store.state.auth.user)
              .then((response) => {
                self.GetUsers();
                self.$swal.fire('Success', `${response.data}`, 'success');
              })
              .catch((error) => {
                self.$swal.fire('Error', `${error}`, 'error');
              });
          }
        });
    },

    DeleteUser(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/User/deleteUser/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetUsers();
                this.$utils.toastNotify('success', 'User deleted successfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },

    GetUserRole() {
      this.$axios
        .get('/api/Role/isUserAuthorized')
        .then((response) => {
          this.isAdmin = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    OpenAddUserModal() {
      this.$refs.addUserModalRef.ClearModal();
      // eslint-disable-next-line no-undef
      $('#addUserModal').modal('show');
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetUsers();
    },
  },
  created() {
    if (!this.$store.state.auth.user) {
      this.$router.push('/login');
    }
    this.GetUserRole();
    this.GetUsers();
  },
};
</script>

<style scoped>
@media (max-width: 1400px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .table {
    width: 1400px;
  }
}
</style>
