<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.187 8.33984H6.81364C4.53364 8.33984 2.66699 10.2065 2.66699 12.4865V27.6332C2.66699 29.5665 4.05366 30.3932 5.74699 29.4465L10.987 26.5265C11.547 26.2198 12.4537 26.2198 13.0003 26.5265L18.2403 29.4465C19.9336 30.3932 21.3203 29.5665 21.3203 27.6332V12.4865C21.3336 10.2065 19.467 8.33984 17.187 8.33984Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3337 12.4865V27.6332C21.3337 29.5665 19.947 30.3798 18.2537 29.4465L13.0137 26.5265C12.4537 26.2198 11.547 26.2198 10.987 26.5265L5.74699 29.4465C4.05366 30.3798 2.66699 29.5665 2.66699 27.6332V12.4865C2.66699 10.2065 4.53364 8.33984 6.81364 8.33984H17.187C19.467 8.33984 21.3337 10.2065 21.3337 12.4865Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.3337 7.31315V22.4598C29.3337 24.3932 27.947 25.2065 26.2537 24.2732L21.3337 21.5265V12.4865C21.3337 10.2065 19.467 8.33984 17.187 8.33984H10.667V7.31315C10.667 5.03315 12.5336 3.1665 14.8136 3.1665H25.187C27.467 3.1665 29.3337 5.03315 29.3337 7.31315Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">Blog Categories</div>
          <div class="heading-02 fw-semibold  d-sm-none">Blog Categories</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="OpenAddCategoryModal"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Add <span class="d-none d-sm-inline-block">category</span>
        </button>
      </div>
    </div>
  </div>

  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center filters-zone mb-3">
      <div class="col col-lg-7 col-xxl-3">
        <label for="blog-categories-search">Search</label>
        <div class="input-group left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="blog-categories-search"
            v-model="filter.SearchQuery"
            @keyup.enter="GetBlogCategories"
            type="text"
            class="search form-control"
            placeholder="Search in..."
          />
        </div>
      </div>
    </div>
    <!--table-->
    <div class="table-scroll">
      <table class="custom table">
        <thead>
          <tr valign="middle">
            <th
              @click="OrderBy('name')"
              :class="{ active: filter.OrderBy.includes('name') }"
              width="70%"
            >
              Name
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="name" class="ms-1" />
            </th>
            <th class="ms-5 ps-5">Written articles</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(category, index) in categories" :key="index" valign="middle">
            <td>
              <div class="d-flex align-items-center">
                <img class="content-img" :src="$utils.GetImageFormat(category.ImgBase64)" />
                <div>
                  <div class="title">{{ category.Name }}</div>
                  <div
                    style="max-width: 80%"
                    class="description"
                    v-if="category.Description"
                    :title="category.Description"
                  >
                    {{ category.Description }}
                  </div>
                </div>
              </div>
            </td>
            <td class="ms-5 ps-5">
              <div class="title">
                {{ category.NumberOfBlogs }}
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center float-end">
                <button class="button table-btn me-2" @click="GetBlogCategory(category.Id)">
                  <img src="../../assets/images/icons/table/edit-linear.svg" />
                </button>
                <button
                  type="button"
                  class="button table-btn"
                  v-on:click="DeleteBlogCategory(category.Id)"
                >
                  <img src="../../assets/images/icons/table/trash-linear.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="categories.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>
  <AddBlogCategoryModal ref="addBlogCategoryRef" @save="CreateBlogCategory" />
  <EditBlogCategory @edit="EditBlogCategory" :category="selectedCategory" />
</template>

<script>
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import AddBlogCategoryModal from '../../components/Blog/Modals/AddBlogCategory.vue';
import EditBlogCategory from '../../components/Blog/Modals/EditBlogCategory.vue';

export default {
  name: 'BlogCategoriesView',
  data() {
    return {
      categories: {},
      selectedCategory: {},
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
      },
      pagination: {},
    };
  },
  components: {
    PaginationComponent,
    OrderByIconComponent,
    AddBlogCategoryModal,
    EditBlogCategory,
  },
  methods: {
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetBlogCategories();
    },
    OpenAddCategoryModal() {
      this.$refs.addBlogCategoryRef.ClearModal();
      // eslint-disable-next-line no-undef
      $('#addBlogCategoryModal').modal('show');
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetBlogCategories();
    },
    GetBlogCategories() {
      const searchParam = new URLSearchParams(this.filter);
      this.$axios
        .get(`api/BlogCategory/getAll?${searchParam}`)
        .then((response) => {
          this.categories = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetBlogCategory(id) {
      this.$axios
        .get(`api/BlogCategory/get/${id}`)
        .then((respone) => {
          this.selectedCategory = respone.data;
          // eslint-disable-next-line no-undef
          $('#editBlogCategoryModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    CreateBlogCategory(category) {
      this.$axios
        .post('api/BlogCategory/create', category)
        .then(() => {
          this.$utils.toastNotify('success', 'Category saved successfully.');
          this.GetBlogCategories();
          // eslint-disable-next-line no-undef
          $('#addBlogCategoryModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    EditBlogCategory(category) {
      this.$axios
        .put(`api/BlogCategory/update/${category.Id}`, category)
        .then(() => {
          this.$utils.toastNotify('success', 'Category edited successfully.');
          this.GetBlogCategories();
          // eslint-disable-next-line no-undef
          $('#editBlogCategoryModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteBlogCategory(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/BlogCategory/delete/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetBlogCategories();
                this.$utils.toastNotify('success', 'Category deleted successfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
  },
  mounted() {
    this.GetBlogCategories();
  },
};
</script>
<style scoped>
.custom.table tbody tr td .title {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  max-width: max-content !important;
}

@media (max-width: 1100px) {
  .table-scroll {
      overflow-x: auto;
      position: relative;
  }
 .table {
    width: 900px;
  }
}
</style>
