<template>
  <div
    class="modal fade"
    id="addStatusModal"
    tabindex="-1"
    aria-labelledby="addStatusModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addStatusFormRef"
          @submit="Submit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addBrandModalLabel">Add status</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="name">Status name</label>
              <Field
                v-model="status.Name"
                name="name"
                type="text"
                class="form-control"
                placeholder="Name"
                :class="{ 'border-danger': errors.name }"
              />
              <ErrorMessage name="name" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="description">Status description</label>
              <Field
                id="description"
                v-model="status.Description"
                name="description"
                rows="3"
                as="textarea"
                class="form-control"
                placeholder="Description"
                :class="{ 'border-danger': errors.description }"
              />
              <ErrorMessage name="description" class="error-message" />
            </div>
            <div class="mb-3">
              <label>Set color</label>
              <label for="color" class="form-control mb-0 d-flex align-items-center pointer">
                <span class="colored-box" :style="{ 'background-color': status.Color }"></span>
                <span class="ms-3 paragraph-02 text-neutral-08 text-uppercase">
                  {{ status.Color }}
                </span>
              </label>
              <Field
                id="color"
                name="color"
                v-model="status.Color"
                type="color"
                class="d-none"
                placeholder="Color"
              />
            </div>
            <div class="d-flex mt-4">
              <div>
                <input
                  v-model="status.IsAvailable"
                  @click="status.IsAvailable = !status.IsAvailable"
                  class="switch-small switch-small--shadow"
                  id="add-status-available"
                  type="checkbox"
                  role="switch"
                />
                <label for="add-status-available"></label>
              </div>
              <label
                class="ms-2 label-01 text-neutral-07 fw-semibold pt-1 mb-0 pointer"
                for="add-status-available"
              >
                The product can be bought
              </label>
            </div>
          </div>

          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddStatusModal',
  data() {
    return {
      status: {
        Color: '#000000',
      },
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      this.$emit('save', this.status);
    },
    ClearModal() {
      this.$refs.addStatusFormRef.resetForm();
      this.status.Color = '#000000';
      this.status.IsAvailable = false;
    },
  },
  computed: {
    schema() {
      return yup.object({
        name: yup.string().required('Status name is required')
          .min(3, 'Status title must have minimum 3 characters')
          .max(50, 'Status title must be under 50 characters')
          .trim(),
        description: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,200})$/, 'Between 5-200 characters'),
        color: yup.string().required('Color is required').max(200, 'Color id cannot be longer than 200 characters'),
      });
    },
  },
};
</script>
<style scoped>
.colored-box {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--neutral-04);
  display: block;
}
</style>
