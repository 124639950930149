<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="row">
    <div class="col-md mb-3 mb-md-0 col-xxl-3">
      <div class="input-group left">
        <span class="input-group-text">
          <img src="../../assets/images/icons/search-normal-20x20.svg" />
        </span>
        <input
          id="search"
          v-model="filter.SearchQuery"
          @keyup.enter="$emit('get', this.filter)"
          type="text"
          class="search form-control"
          placeholder="Search in..."
        />
      </div>
    </div>
    <div class="col col-xxl-3">
      <VueDatePicker
        v-model="filter.Date"
        placeholder="Select date"
        :enableTimePicker="false"
        autoApply
        class="custom-datepicker"
        name="Date"
        format="dd-MM-yyyy"
        id="reviews-date"
        @update:modelValue="$emit('get', filter)"
      ></VueDatePicker>
      <!-- <input
        v-model="filter.Date"
        @change="$emit('get', this.filter)"
        type="date"
        class="form-control"
        placeholder="Select date..."
        id="reviews-date"
      /> -->
    </div>
  </div>

  <div class="table-scroll">
    <table class="custom table mt-3">
      <thead>
        <tr valign="middle">
          <th width="29%">Review body</th>
          <th @click="OrderBy('rating')" :class="{ active: filter.OrderBy.includes('rating') }">
            Rating
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="rating" class="ms-1" />
          </th>
          <th @click="OrderBy('date')" :class="{ active: filter.OrderBy.includes('date') }">
            Date
            <order-by-icon-component :orderBy="filter.OrderBy" orderByName="date" class="ms-1" />
          </th>
          <th>Approve</th>
          <th>Testimonial</th>
          <th>Product</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(review, index) in reviews" :key="index" valign="middle">
          <td>{{ review.Comment }}</td>
          <td>
            <RatingComponent :stars="review.Rating" />
          </td>
          <td>
            {{ review.Date }}
          </td>
          <td>
            <input
              :id="'switch-approve-' + index"
              class="switch-small switch-small--shadow"
              type="checkbox"
              :checked="review.IsAproved"
              v-on:click="
                review.IsAproved = !review.IsAproved;
                $emit('update', review);
              "
              @click.prevent
            />
            <label :for="'switch-approve-' + index"></label>
          </td>
          <td>
            <input
              :id="'switch-testimonial-' + index"
              class="switch-small switch-small--shadow"
              type="checkbox"
              :checked="review.Testimonial"
              v-on:click="
                review.Testimonial = !review.Testimonial;
                $emit('update', review);
              "
              @click.prevent
            />
            <label :for="'switch-testimonial-' + index"></label>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <img class="content-img" :src="$utils.GetImageFormat(review.ImgBase64)" />
              <div class="title">{{ review.ProductName }}</div>
            </div>
          </td>
          <td>
            <div class="float-end">
              <button
                type="button"
                class="button table-btn"
                v-on:click="$emit('delete', review.Id)"
              >
                <img src="../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="reviews.length == 0" class="mt-5 pt-5 text-center">
      <img src="../../assets/images/not-found-img.png" />
    </div>
  </div>

  <pagination-component
    :numberOfPages="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    :functionName="SelectPage"
  ></pagination-component>
</template>
<script>
import RatingComponent from '../General/RatingComponent.vue';
import PaginationComponent from '../General/PaginationComponent.vue';
import OrderByIconComponent from '../General/OrderByIconComponent.vue';

export default {
  name: 'UserReviewsComponent',
  emits: ['get', 'delete', 'update'],
  props: {
    reviews: {},
    pagination: {},
    key: {},
    filter: {},
  },

  components: {
    RatingComponent,
    PaginationComponent,
    OrderByIconComponent,
  },
  methods: {
    SelectPage(nr) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = nr || 1;
      this.$emit('get');
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = orderBy;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.Date = this.filter.Date
        ? new Date(this.filter.Date).toLocaleDateString('en-US')
        : '';
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = 1;
      this.$emit('get');
    },
  },
};
</script>
<style scoped>
@media (max-width: 1400px) {
  .table-scroll {
      overflow-x: auto;
      position: relative;
  }
 .table {
    width: 1400px;
  }
}
</style>
