<template>
  <div v-if="$store.state.loader" class="page-loader-background"></div>
  <div v-if="$store.state.loader" class="page-loader-middle"></div>

  <router-view />
</template>

<script>
import { Tooltip } from 'bootstrap';

export default {
  name: 'App',

  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    // eslint-disable-next-line no-new
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
};
</script>

<style scoped>
.plus-btn {
  border: 1px solid var(--blacktint-2);
  padding: 11px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 41px;
  transition: 0.2s;
}

.plus-btn:hover {
  background-color: var(--neutral-2);
}

.border-logged-user {
  width: 1px;
  height: 40px;
  background: var(--blacktint-1);
  margin-right: 40px;
}

.text-button-02 {
  color: var(--success-5);
}

.logged-user .user-img {
  margin-left: 14px;
}
.logged-user {
  height: 96px;
  margin-right: 64px;
}

.ml-sidebar {
  margin-left: var(--sidebar-width);
}
</style>
