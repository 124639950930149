<!-- eslint-disable vue/no-mutating-props -->

<template>
  <!-- eslint-disable -->
  <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">Details</div>
  <Form ref="productDetailsForm" :validation-schema="schema" v-slot="errors">
    <div class="card-body">
      <div class="row">
        <div class="col-lg">
          <div class="mb-3">
            <label class="label-02 fw-semibold text-neutral-07 mb-2">Brand</label>
            <VueMultiselect
              class="custom-multiselect lg"
              v-model="product.Brand"
              :options="brandsList"
              label="Name"
              :close-on-select="true"
              track-by="Id"
              placeholder="Select one"
              select-label=""
              @open="$emit('getBrands')"
            />
            <Field name="brand" v-model="product.Brand" class="d-none"></Field>
            <ErrorMessage class="error-message" name="brand" />
          </div>
          <div class="mb-3">
            <div class="row align-items-center">
              <div class="col">
                <label class="label-02 fw-semibold text-neutral-07 mb-2">Priority number</label>
                <Field
                  v-model="product.PriorityNumber"
                  name="priorityNumber"
                  type="number"
                  class="form-control form-control-lg"
                  placeholder="Product priority number"
                  :class="{ 'border-danger': errors.priorityNumber }"
                  min="1"
                >
                </Field>
                <ErrorMessage name="priorityNumber" class="error-message" />
              </div>
              <div class="col">
                <div class="row align-items-center mt-4">
                  <div class="col-auto">
                    <input
                      v-model="product.InStock"
                      class="switch-small switch-small--shadow"
                      id="productInStock"
                      type="checkbox"
                      role="switch"
                    />
                    <label for="productInStock" class="mb-0"></label>
                  </div>
                  <div class="col">
                    <label class="label-02 fw-semibold text-neutral-07 pointer" for="productInStock"
                      >Is in stock</label
                    >
                  </div>
                  <div class="col-auto">
                    <input
                      v-model="product.IsAvailable"
                      class="switch-small switch-small--shadow"
                      id="productIsAvailable"
                      type="checkbox"
                      role="switch"
                    />
                    <label for="productIsAvailable" class="mb-0"></label>
                  </div>
                  <div class="col">
                    <label
                      class="label-02 fw-semibold text-neutral-07 pointer"
                      for="productIsAvailable"
                      >Is available</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row align-items-center">
              <div class="col">
                <label class="label-02 fw-semibold text-neutral-07 mb-2">Category</label>
                <VueMultiselect
                  class="custom-multiselect lg"
                  v-model="product.Category"
                  :options="categoriesList"
                  label="Name"
                  track-by="Id"
                  :close-on-select="true"
                  @select="$emit('onChangeCategory', product.Category.Id)"
                  placeholder="Select one"
                  select-label=""
                  @open="$emit('getCategories')"
                />
                <Field name="category" v-model="product.Category" class="d-none"></Field>
                <ErrorMessage class="error-message" name="category" />
              </div>
              <div class="col">
                <label class="label-02 fw-semibold text-neutral-07 mb-2">Subcategory</label>
                <VueMultiselect
                  class="custom-multiselect lg"
                  v-model="product.Subcategory"
                  :options="subcategoriesList"
                  :close-on-select="true"
                  :disabled="subcategoriesList.length === 0"
                  placeholder="Select one"
                  label="Name"
                  track-by="Id"
                  select-label=""
                />
                <Field name="subcategory" v-model="product.Subcategory" class="d-none"></Field>
                <ErrorMessage class="error-message" name="subcategory" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto d-none d-xxl-block"></div>
        <div class="col-lg">
          <div class="mb-3">
            <label class="label-02 fw-semibold text-neutral-07 mb-2">Statuses</label>
            <VueMultiselect
              class="custom-multiselect lg"
              v-model="product.Status"
              :options="statusesList"
              label="Name"
              :close-on-select="true"
              track-by="Id"
              select-label=""
              placeholder="Choose status"
              @open="$emit('getStatus')"
            />
          </div>
          <div class="mb-3">
            <label class="label-02 fw-semibold text-neutral-07 mb-2">Tags</label>
            <Field
              v-model="product.Tag"
              name="tags"
              type="text"
              class="form-control form-control-lg"
              placeholder="List of tags"
              :class="{ 'border-danger': errors.tags }"
            >
            </Field>
            <ErrorMessage name="tags" class="error-message" />
          </div>
          <div class="mb-3">
            <label class="label-02 fw-semibold text-neutral-07 mb-2">Locations</label>
            <VueMultiselect
              class="custom-multiselect lg"
              v-model="product.Locations"
              :options="locationsList"
              :multiple="true"
              label="LocationName"
              :close-on-select="true"
              track-by="LocationId"
              select-label=""
              placeholder="Choose location"
              @open="$emit('getLocations')"
            />
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
/* eslint-disable */
import { Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';
import VueMultiselect from 'vue-multiselect';

export default {
  name: 'ProductDetails',
  emits: ['save', 'onChangeCategory', 'getCategories', 'getBrands', 'getLocations', 'getStatus'],
  props: {
    product: {},
    locationsList: Array,
    categoriesList: Array,
    subcategoriesList: Array,
    brandsList: Array,
    statusesList: Array,
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    VueMultiselect,
    FieldArray,
  },
  methods: {
    Submit() {
      return this.$refs.productDetailsForm.validate().then((result) => result.valid);
    },
  },
  computed: {
    schema() {
      return yup.object({
        brand: yup.object().notRequired(),
        category: yup.object().notRequired(),
        subcategory: yup.object().notRequired(),
        tags: yup
          .string()
          .notRequired()
          .max(200, 'Product list of tags cannot be longer than 200 characters')
          .trim(),
        priorityNumber: yup
          .number()
          .notRequired()
          .nullable()
          .min(0, 'Priority number cannot be negative')
          .test('is-decimal', 'Priority number cannot be a decimal', (number) => {
            if (!number) {
              return true;
            }
            return /^(0|[1-9]\d*)$/.test(number);
          })
          .transform((_, val) => (val === Number(val) ? val : null)),
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css" scoped>

</style>
