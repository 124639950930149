<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg class="page-header-img" width="32" height="33" viewBox="0 0 32 33" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M29.3333 15.0332V5.9665C29.3333 3.9665 28.48 3.1665 26.36 3.1665H20.9733C18.8533 3.1665 18 3.9665 18 5.9665V15.0332C18 17.0332 18.8533 17.8332 20.9733 17.8332H26.36C28.48 17.8332 29.3333 17.0332 29.3333 15.0332Z"
              stroke="#1F2629" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M29.3333 27.0335V24.6335C29.3333 22.6335 28.48 21.8335 26.36 21.8335H20.9733C18.8533 21.8335 18 22.6335 18 24.6335V27.0335C18 29.0335 18.8533 29.8335 20.9733 29.8335H26.36C28.48 29.8335 29.3333 29.0335 29.3333 27.0335Z"
              stroke="#3AAB14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M14 17.9665V27.0332C14 29.0332 13.1467 29.8332 11.0267 29.8332H5.64002C3.52002 29.8332 2.66669 29.0332 2.66669 27.0332V17.9665C2.66669 15.9665 3.52002 15.1665 5.64002 15.1665H11.0267C13.1467 15.1665 14 15.9665 14 17.9665Z"
              stroke="#1F2629" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M14 5.9665V8.3665C14 10.3665 13.1467 11.1665 11.0267 11.1665H5.64002C3.52002 11.1665 2.66669 10.3665 2.66669 8.3665V5.9665C2.66669 3.9665 3.52002 3.1665 5.64002 3.1665H11.0267C13.1467 3.1665 14 3.9665 14 5.9665Z"
              stroke="#3AAB14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content" style="margin-top: 40px">
    <div class="row">
      <!--Last orders-->
      <div class="col-xxl-8 mb-3">
        <div class="table-card">
          <div class="row pt-3 pb-3 ps-4 pe-4 align-items-center">
            <div class="col">
              <div class="heading-02 fw-semibold text-neutral-08">Last orders</div>
            </div>
            <div class="col-auto">
              <router-link type="button" class="btn-medium btn-neutral-solid button" :to="{ name: 'Orders' }"
                target="_blank">
                All orders
              </router-link>
            </div>
          </div>
          <div class="dashboard table-responsive">
            <table class="table custom-scrollbar">
              <thead>
                <tr>
                  <th>ID</th>
                  <th width="30%">Products</th>
                  <th width="1%">Quantity</th>
                  <th>Date</th>
                  <th width="12%">Status</th>
                  <th>Payment</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody v-if="orders && orders.length > 0">
                <tr v-for="(order, index) in orders" :key="index" valign="middle" @click="GoToOrderDetails(order.Id)"
                  class="pointer">
                  <td>
                    <div>#{{ order.Id }}</div>
                  </td>
                  <td>
                    <div v-if="order.NumberOfProducts === 1" class="d-flex align-items-center">
                      <img class="table-content-img" :src="$utils.GetImageFormat(order.ImgBase64)" />
                      <div class="title-small">{{ order.ProductName }}</div>
                    </div>

                    <div v-else class="d-flex align-items-center">
                      <img class="table-content-img-small"
                        src="../../assets/images/icons/multiple-products-order.svg" />
                      <div class="title">Multiple products order</div>
                    </div>
                  </td>
                  <td>
                    {{ order.Quantity }}
                  </td>
                  <td>
                    {{ order.PurchaseDate }}
                  </td>
                  <td>
                    {{ order.Status }}
                  </td>
                  <td>
                    {{ order.Payment }}
                  </td>
                  <td>
                    {{ $utils.FormatNumber(order.TotalPrice) }} RON
                    <img src="../../assets/images/icons/last-orders-arrow-down.svg" class="float-end" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="d-block m-auto text-center">
                <tr class="d-block text-center">
                  <div class="mt-4 ">
                    <img src="../../assets/images/not-found-img.png" style="max-width: 120px" />
                  </div>
                </tr>
              </tbody>
              <div v-if="
                orders &&
                orders.length > 0 &&
                ordersPagination.TotalPageCount > ordersFilter.PageNumber
              " class="d-flex align-items-center justify-content-center pt-2">
                <button class="button btn-small btn-success-solid" type="button" @click="GetOrderNextPage()">
                  Show more
                </button>
              </div>
            </table>
          </div>
        </div>
      </div>
      <!--Top Products-->
      <div class="col-lg-6 col-xxl-4 mb-3">
        <div class="table-card h-100">
          <div style="padding-top: 15.5px; padding-bottom: 15.5px"
            class="row align-items-center border-bottom border-color-blacktint-1 ps-2 pe-2 m-0">
            <div class="col-md col-lg-12 col-xl mb-2 mb-xl-0">
              <div class="heading-02 fw-semibold text-neutral-08">Top Products</div>
            </div>
            <div class="col">
              <DropdownFilterComponent filterName="topProducts" :optionsList="chartListOfMonths"
                :filter="productsFilter" @apply-filter="GetProducts()" />
            </div>
          </div>
          <div class="top-products list custom-scrollbar">
            <div class="row item align-items-center ms-4 me-4" v-for="(product, index) in products" :key="index">
              <div class="col-6 d-flex align-items-center ps-0 pe-0">
                <img :src="$utils.GetImageFormat(product.ImgBase64)" class="table-content-img" />
                <span class="label-02 fw-semibold text-neutral-08" style="max-width: 168px" :title="product.Title">
                  {{ product.Title }}
                </span>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <img :src="$utils.DynamicPathAttachment(product.CategoryImgBase64)" class="table-content-img-small" />
                  <div class="ms-1">
                    <div class="label-02 fw-semibold text-neutral-08">
                      {{ product.CategoryName || 'No category' }}
                    </div>
                    <div class="label-01 fw-normal text-neutral-07 mt-1" v-if="product.Subcategory">
                      {{ product.Subcategory }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="products && products.length == 0" class="mt-5 text-center">
              <img src="../../assets/images/not-found-img.png" style="max-width: 120px" />
            </div>
            <div v-if="
              products &&
              products.length > 0 &&
              productsPagination.TotalPageCount > productsFilter.PageNumber
            " class="d-flex align-items-center justify-content-center pt-2">
              <button class="button btn-small btn-success-solid" type="button" @click="GetProductsNextPage()">
                Show more
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Top Category-->
      <div class="col-lg-6 col-xxl-4 mb-3 mb-xxl-0">
        <div class="table-card h-100">
          <div class="row pt-3 pb-3 align-items-center border-bottom border-color-blacktint-1 ps-2 pe-2 m-0">
            <div class="col-auto mb-2 mb-xl-0 pe-0">
              <div class="dropdown">
                <div class="heading-02 fw-semibold text-neutral-08 pointer dropdown-toggle ps-2"
                  id="topCategoryDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                  Top {{ selectedTopCategory }}
                </div>
                <ul class="dropdown-menu" aria-labelledby="topCategoryDropdownMenu">
                  <li @click="
                    selectedTopCategory = 'Category';
                  GetCategories();
                  ">
                    <span class="dropdown-item d-flex align-items-center"
                      :class="{ active: selectedTopCategory == 'Category' }">
                      Categories
                    </span>
                    <hr class="dropdown-divider me-3 ms-3 border-color-blacktint-1" />
                  </li>
                  <li @click="
                    selectedTopCategory = 'Subcategory';
                  GetSubcategories();
                  ">
                    <span class="dropdown-item d-flex align-items-center"
                      :class="{ active: selectedTopCategory == 'Subcategory' }">
                      Subcategories
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col"></div>
            <div class="col-md col-lg-12 col-xl-5" v-if="selectedTopCategory == 'Category'">
              <DropdownFilterComponent filterName="topCategories" :optionsList="chartListOfMonths"
                :filter="categoriesFilter" @apply-filter="GetCategories()" />
            </div>
            <div class="col-md col-lg-12 col-xl-5" v-if="selectedTopCategory == 'Subcategory'">
              <DropdownFilterComponent filterName="subCategories" :optionsList="chartListOfMonths"
                :filter="subcategoriesFilter" @apply-filter="GetSubcategories()" />
            </div>
          </div>
          <div class="top-products list custom-scrollbar" v-if="
            (selectedTopCategory == 'Category' && categories && categories.length > 0) ||
            (selectedTopCategory == 'Subcategory' && subcategories && subcategories.length > 0)
          ">
            <div class="row item align-items-center ms-4 me-4" v-for="(category, index) in selectedTopCategory == 'Category'
              ? categories
              : subcategories" :key="index">
              <div class="col-6 d-flex align-items-center ps-0">
                <img v-if="category.ImgBase64" :src="$utils.DynamicPathAttachment(category.ImgBase64)"
                  class="table-content-img-small me-2" />
                <div>
                  <span class="label-02 fw-semibold text-neutral-08 d-block" style="max-width: 168px"
                    :title="category.Name">
                    {{ this.$utils.Ellipsify(category.Name, 39) }}
                  </span>
                  <span class="label-01 text-neutral-07 d-block" v-if="category.Subcategories"
                    :title="category.Subcategories">
                    {{ this.$utils.Ellipsify(category.Subcategories, 39) }}
                  </span>
                </div>
              </div>
              <div class="col-3 label-02 fw-semibold text-neutral-08">
                {{ category.NumberOfProducts }} products
              </div>
              <div class="col-3 pe-0">
                <arrow-up-down-component :percentage="category.Percentage" />
              </div>
            </div>
            <div v-if="
              (selectedTopCategory === 'Category' &&
                categoriesPagination.TotalPageCount > categoriesFilter.PageNumber) ||
              (selectedTopCategory === 'Subcategory' &&
                subcategoriesPagination.TotalPageCount > subcategoriesFilter.PageNumber)
            " class="d-flex align-items-center justify-content-center pt-2">
              <button class="button btn-small btn-success-solid" type="button" @click="
                selectedTopCategory == 'Category'
                  ? GetCategoriesNextPage()
                  : GetSubcategoriesNextPage()
                ">
                Show more
              </button>
            </div>
          </div>
          <div v-if="
            (selectedTopCategory == 'Category' && categories.length == 0) ||
            (selectedTopCategory == 'Subcategory' && subcategories.length == 0)
          " class="mt-5 text-center">
            <img src="../../assets/images/not-found-img.png" style="max-width: 180px" />
          </div>
        </div>
      </div>
      <!--Locations-->
      <div class="col-lg-6 col-xxl-4 mb-3 mb-xxl-0">
        <div class="table-card h-100">
          <div class="row pt-3 pb-3 align-items-center border-bottom border-color-blacktint-1 ps-2 pe-2 m-0">
            <div class="col-md col-lg-12 col-xl mb-2 mb-xl-0">
              <div class="heading-02 fw-semibold text-neutral-08">Locations</div>
            </div>
            <div class="col">
              <DropdownFilterComponent filterName="locations" :optionsList="chartListOfMonths" :filter="locationsFilter"
                @apply-filter="GetLocations()" />
            </div>
          </div>
          <div class="top-products list custom-scrollbar" v-if="locations && locations.length > 0">
            <div class="row item align-items-center ms-4 me-4" v-for="(location, index) in locations" :key="index">
              <div class="col-6 ps-0">
                <span class="label-02 fw-semibold text-neutral-08 d-block" style="max-width: 168px"
                  :title="location.Name">
                  {{ this.$utils.Ellipsify(location.Name, 39) }}
                </span>
                <span class="label-01 text-neutral-07 d-block" v-if="location.Description"
                  :title="location.Description">
                  {{ this.$utils.Ellipsify(location.Description, 30) }}
                </span>
              </div>
              <div class="col-3 label-02 fw-semibold text-neutral-08">
                {{ location.NumberOfOrders }} orders
              </div>
              <div class="col-3 pe-0 text-end">
                <arrow-up-down-component :percentage="location.Percentage" />
              </div>
            </div>
            <div v-if="locationsPagination.TotalPageCount > locationsFilter.PageNumber"
              class="d-flex align-items-center justify-content-center pt-2">
              <button class="button btn-small btn-success-solid" type="button" @click="GetLocationNextPage()">
                Show more
              </button>
            </div>
          </div>
          <div v-if="locations.length == 0" class="mt-5 text-center">
            <img src="../../assets/images/not-found-img.png" style="max-width: 180px" class="pb-5" />
          </div>
        </div>
      </div>
      <!--Summary-->
      <div class="col-lg-6 col-xxl-4">
        <div class="table-card">
          <div class="row pt-3 pb-3 align-items-center border-bottom border-color-blacktint-1 ps-2 pe-2 m-0">
            <div class="col-md col-lg-12 col-xl mb-2 mb-xl-0">
              <div class="heading-02 fw-semibold text-neutral-08">Summary</div>
            </div>
            <div class="col">
              <DropdownFilterComponent filterName="summary" :optionsList="chartListOfMonths" :filter="chartFilter"
                @apply-filter="GetOrdersChart()" />
            </div>
          </div>
          <VueApexCharts height="180" :key="componentKey" type="area" :options="options" :series="series">
          </VueApexCharts>
          <div class="justify-content-xxl-around row mt-2 ms-2 ms-xxl-0" style="padding-bottom: 10.25px">
            <div class="col-auto">
              <div class="text-neutral-07 label-02 fw-semibold">Total orders number</div>
              <div class="summary-item-title text-success-06">{{ chartData.TotalOrders }}</div>
            </div>
            <div class="col-auto">
              <div class="text-neutral-07 label-02 fw-semibold">Total amount</div>
              <div class="summary-item-title text-secondary-06">{{ chartData.TotalAmount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import ArrowUpDownComponent from '../../components/Dashboard/ArrowUpDownComponent.vue';
import DropdownFilterComponent from '../../components/Dashboard/DropdownFilterComponent.vue';

export default {
  name: 'DashboardView',
  data() {
    return {
      selectedTopCategory: 'Category',
      showTop: '',
      componentKey: 0,
      chartListOfMonths: [
        { Month: 0, Name: 'This month' },
        { Month: 1, Name: 'Last month' },
        { Month: 6, Name: 'Last 6 months' },
        { Month: 12, Name: 'Last year' },
        { Month: 24, Name: 'Last 2 years' },
      ],
      ordersFilter: {
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      ordersPagination: {},
      orders: [],
      productsFilter: {
        Month: { Month: 0, Name: 'This month' },
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      productsPagination: {},
      products: [],
      categoriesFilter: {
        Month: { Month: 0, Name: 'This month' },
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      categoriesPagination: {},
      categories: [],
      subcategoriesFilter: {
        Month: { Month: 0, Name: 'This month' },
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      subcategoriesPagination: {},
      subcategories: [],
      locationsFilter: {
        Month: { Month: 0, Name: 'This month' },
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      locationsPagination: {},
      locations: [],
      chartFilter: {
        Month: { Month: 0, Name: 'This month' },
      },
      chartData: {},
      series: [
        {
          name: 'Orders',
          data: [],
        },
        {
          name: 'Amount',
          data: [],
        },
      ],
      options: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          display: false,
          show: false,
        },
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    };
  },
  components: {
    VueApexCharts,
    ArrowUpDownComponent,
    DropdownFilterComponent,
  },
  methods: {
    FilterByDate() {
      if (this.categoriesFilter.DateInterval) {
        this.categoriesFilter.StartDate = this.categoriesFilter.DateInterval
          ? new Date(this.categoriesFilter.DateInterval[0]).toISOString()
          : '';
        this.categoriesFilter.EndDate = this.categoriesFilter.DateInterval
          ? new Date(this.categoriesFilter.DateInterval[1]).toISOString()
          : '';
      } else {
        this.categoriesFilter.StartDate = '';
        this.categoriesFilter.EndDate = '';
      }
      this.GetCategories();
    },
    GoToOrderDetails(orderId) {
      this.$router.push({ name: 'OrderDetails', params: { orderIdParam: orderId } });
    },
    GetOrders() {
      const searchParams = new URLSearchParams(this.ordersFilter);
      this.$axios
        .get(`/api/Orders/getRecentOrders?${searchParams}`)
        .then((response) => {
          this.orders = response.data.Items;
          this.ordersPagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetOrderNextPage() {
      this.ordersFilter.PageNumber += 1;
      const searchParams = new URLSearchParams(this.ordersFilter);
      this.$axios
        .get(`/api/Orders/getRecentOrders?${searchParams}`)
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.orders.push(element);
          });
          this.ordersPagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProducts() {
      const searchParams = {
        ...(this.productsFilter.Month ? { Month: this.productsFilter.Month.Month } : ''),
        PageNumber: this.productsFilter.PageNumber,
        PageSize: this.productsFilter.PageSize,
        ...(this.productsFilter.DateInterval
          ? {
            StartDate: new Date(this.productsFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.productsFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      this.$axios
        .get(`/api/Product/getTopProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.productsPagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProductsNextPage() {
      this.productsFilter.PageNumber += 1;
      const searchParams = {
        ...(this.productsFilter.Month ? { Month: this.productsFilter.Month.Month } : ''),
        PageNumber: this.productsFilter.PageNumber,
        PageSize: this.productsFilter.PageSize,
        ...(this.productsFilter.DateInterval
          ? {
            StartDate: new Date(this.productsFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.productsFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      this.$axios
        .get(`/api/Product/getTopProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.products.push(element);
          });
          this.productsPagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    async GetCategories() {
      const searchParams = {
        ...(this.categoriesFilter.Month ? { Month: this.categoriesFilter.Month.Month } : ''),
        PageNumber: this.categoriesFilter.PageNumber,
        PageSize: this.categoriesFilter.PageSize,
        // ...(this.categoriesFilter.StartDate ? { StartDate: this.categoriesFilter.StartDate } : ''),
        // ...(this.categoriesFilter.EndDate ? { EndDate: this.categoriesFilter.EndDate } : ''),
        ...(this.categoriesFilter.DateInterval
          ? {
            StartDate: new Date(this.categoriesFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.categoriesFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      await this.$axios
        .get(`/api/categories/getTopCategories?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.categories = response.data.Items;
          this.categoriesPagination = response.data.PageDetails;
          this.showTop = 'Category';
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetCategoriesNextPage() {
      this.categoriesFilter.PageNumber += 1;
      const searchParams = {
        ...(this.categoriesFilter.Month ? { Month: this.categoriesFilter.Month.Month } : ''),
        PageNumber: this.categoriesFilter.PageNumber,
        PageSize: this.categoriesFilter.PageSize,
        ...(this.categoriesFilter.DateInterval
          ? {
            StartDate: new Date(this.categoriesFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.categoriesFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      this.$axios
        .get(`/api/categories/getTopCategories?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.categories.push(element);
          });
          this.categoriesPagination = response.data.PageDetails;
          this.showTop = 'Category';
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetSubcategories() {
      const searchParams = {
        ...(this.subcategoriesFilter.Month ? { Month: this.subcategoriesFilter.Month.Month } : ''),
        PageNumber: this.subcategoriesFilter.PageNumber,
        PageSize: this.subcategoriesFilter.PageSize,
        ...(this.subcategoriesFilter.DateInterval
          ? {
            StartDate: new Date(this.subcategoriesFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.subcategoriesFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      this.$axios
        .get(`/api/subcategories/getTopSubcategories?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.subcategories = response.data.Items;
          this.subcategoriesPagination = response.data.PageDetails;
          this.showTop = 'Subcategory';
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetSubcategoriesNextPage() {
      this.subcategoriesFilter.PageNumber += 1;
      const searchParams = {
        ...(this.subcategoriesFilter.Month ? { Month: this.subcategoriesFilter.Month.Month } : ''),
        PageNumber: this.subcategoriesFilter.PageNumber,
        PageSize: this.subcategoriesFilter.PageSize,
        ...(this.subcategoriesFilter.DateInterval
          ? {
            StartDate: new Date(this.subcategoriesFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.subcategoriesFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      this.$axios
        .get(`/api/subcategories/getTopSubcategories?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.subcategories.push(element);
          });
          this.subcategoriesPagination = response.data.PageDetails;
          this.showTop = 'Subcategory';
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetLocations() {
      const searchParams = {
        ...(this.locationsFilter.Month ? { Month: this.locationsFilter.Month.Month } : ''),
        PageNumber: this.locationsFilter.PageNumber,
        PageSize: this.locationsFilter.PageSize,
        ...(this.locationsFilter.DateInterval
          ? {
            StartDate: new Date(this.locationsFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.locationsFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      this.$axios
        .get(`/api/Location/getTopLocations?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.locations = response.data.Items;
          this.locationsPagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetLocationNextPage() {
      this.locationsFilter.PageNumber += 1;
      const searchParams = {
        ...(this.locationsFilter.Month ? { Month: this.locationsFilter.Month.Month } : ''),
        PageNumber: this.locationsFilter.PageNumber,
        PageSize: this.locationsFilter.PageSize,
        ...(this.locationsFilter.DateInterval
          ? {
            StartDate: new Date(this.locationsFilter.DateInterval[0]).toISOString(),
            EndDate: new Date(this.locationsFilter.DateInterval[1]).toISOString(),
          }
          : ''),
      };
      this.$axios
        .get(`/api/Location/getTopLocations?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.locations.push(element);
          });
          this.locationsPagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetOrdersChart() {
      if (this.chartFilter.Month && this.chartFilter.Month.Month < 3) {
        const searchParams = {
          ...(this.chartFilter.Month ? { MonthNumber: this.chartFilter.Month.Month } : ''),
        };
        this.$axios
          .get(`/api/Orders/getOrderGraphicDays?${new URLSearchParams(searchParams)}`)
          .then((response) => {
            this.series[0].data = response.data.AxisOrders;
            this.series[1].data = response.data.AxisAmount;
            this.options.xaxis.categories = response.data.Months;
            this.chartData.TotalOrders = response.data.TotalOrders;
            this.chartData.TotalAmount = response.data.TotalAmount;
            this.forceRender();
          })
          .catch(() => {
            this.$utils.toastNotify('error', 'Something went wrong.');
          });
      } else {
        const searchParams = {
          ...(this.chartFilter.Month ? { Month: this.chartFilter.Month.Month } : ''),
          ...(this.chartFilter.DateInterval
            ? {
              StartDate: new Date(this.chartFilter.DateInterval[0]).toISOString(),
              EndDate: new Date(this.chartFilter.DateInterval[1]).toISOString(),
            }
            : ''),
        };
        this.$axios
          .get(`/api/Orders/getOrderGraphic?${new URLSearchParams(searchParams)}`)
          .then((response) => {
            this.series[0].data = response.data.AxisOrders;
            this.series[1].data = response.data.AxisAmount;
            this.options.xaxis.categories = response.data.Months;
            this.chartData.TotalOrders = response.data.TotalOrders;
            this.chartData.TotalAmount = response.data.TotalAmount;
            this.forceRender();
          })
          .catch(() => {
            this.$utils.toastNotify('error', 'Something went wrong.');
          });
      }
    },

    forceRender() {
      this.componentKey += 1;
    },
  },
  async created() {
    await this.GetCategories();
    this.GetOrders();
    this.GetProducts();
    this.GetLocations();
    this.GetOrdersChart();
  },
};
</script>
<style scoped>
.summary-item-title {
  font-size: 56px;
  font-weight: 300;
  line-height: 63px;
  margin-top: 8px;
}

.top-products.list {
  height: 300px;
  overflow-y: auto;
}

.top-products .item {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

.top-products .item:not(:last-child) {
  border-bottom: 1px solid var(--blacktint-1) !important;
}

@media only screen and (max-width: 1100px) {
  .table-responsive {
    overflow-x: auto !important;
  }

  .table-responsive .table {
    width: 1450px;
  }
}

@media only screen and (max-width: 1760px) {
  .dashboard.table-responsive {
    overflow-x: auto !important;
  }

  .dashboard.table-responsive .table {
    width: 1000px;
  }
}

.dashboard .table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}

.dashboard .table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

.top-products .row:hover {
  background-color: rgba(128, 128, 128, 0.055);
}

.dashboard .table thead {
  border-top: 1px solid var(--blacktint-1);
  border-bottom: 1px solid var(--blacktint-1);
}

.dashboard .table thead tr th {
  padding: 13px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--neutral-5);
  background-color: #f5f6f9;
}

.dashboard .table thead tr th.active {
  color: var(--neutral-7);
  font-weight: 600;
}

.table-content-img {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  object-fit: contain;
}

.table-content-img-small {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  object-fit: contain;
}

.dashboard .table tbody tr td {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: var(--neutral-7);
  height: 64px;
  border-color: var(--blacktint-1);
  padding-left: 20px;
}

.dashboard .table tbody tr td:first-child {
  font-size: 14px;
}

.dashboard .table tbody tr:last-child td {
  border-bottom: 1px solid transparent;
}

.dashboard .table tbody tr td .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 240px;
  color: var(--neutral-7);
}

.dashboard .table tbody tr td .title-small {
  font-size: 14px;
  font-weight: 600;
  line-height: 15.75px;
  max-width: 240px;
  color: var(--neutral-8);
}

.dashboard .table tbody tr td .description {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--success-6);
  margin-top: 4px;
}

.dashboard .table {
  height: 300px;
  display: block;
  overflow-y: scroll;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-collapse: separate;
  /* Don't collapse */
  border-spacing: 0;
}

.dashboard .table thead {
  position: sticky;
  top: 0px;
  /* box-shadow: 0px 1px 0px var(--blacktint-1), 0px -1px 0px var(--blacktint-1); */
}

.table-card {
  box-shadow: 0px 5px 16px -5px #00000033;
  border-radius: 12px;
}
</style>
