<template>
  <div class="d-none d-md-block">
    <Sidebar />
  </div>

  <mobile-navbar-component />

  <div class="ml-sidebar">
    <div class="logged-user justify-content-end align-items-center d-none d-md-flex">
      <div class="dropdown">
        <button
          class="button plus-btn"
          type="button"
          id="shortcutDropdownMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="../../assets/images/icons/add-green-24x24.svg" />
        </button>
        <ul class="dropdown-menu" aria-labelledby="shortcutDropdownMenu">
          <li>
            <router-link
              :to="{ name: 'Categories' }"
              class="dropdown-item d-flex align-items-center"
            >
              <img src="../../assets/images/icons/add-linear-16x16.svg" class="me-1" /> Add category
            </router-link>
            <hr class="dropdown-divider me-3 ms-3 border-color-blacktint-1" />
          </li>
          <li>
            <router-link
              :to="{ name: 'AddProduct' }"
              class="dropdown-item d-flex align-items-center"
            >
              <img src="../../assets/images/icons/add-linear-16x16.svg" class="me-1" /> Add product
            </router-link>
            <hr class="dropdown-divider me-3 ms-3 border-color-blacktint-1" />
          </li>
          <li>
            <router-link :to="{ name: 'Users' }" class="dropdown-item d-flex align-items-center">
              <img src="../../assets/images/icons/add-linear-16x16.svg" class="me-1" /> Add user
            </router-link>
          </li>
        </ul>
      </div>

      <div class="border-logged-user"></div>
      <div v-if="currentUser">
        <div class="sub-heading-02">{{ currentUser.FirstName }} {{ currentUser.LastName }}</div>
        <div class="text-button-02 text-end">{{ currentUser.Role }}</div>
      </div>

      <img
        :src="require('@/assets/user-img.png')"
        class="user-img pointer"
        id="logoutDropdownMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      />
      <ul class="dropdown-menu" aria-labelledby="logoutDropdownMenu">
        <li @click="logOut">
          <span class="dropdown-item d-flex align-items-center"> Logout </span>
        </li>
      </ul>

      <svg
        style="margin-left: 14px"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.79335 5.95312H11.9467C12.1398 5.95312 12.2406 6.18851 12.1048 6.32791C12.1045 6.32823 12.1042 6.32855 12.1039 6.32887L9.96646 8.46624L8.65313 9.77957C8.29506 10.1376 7.71164 10.1376 7.35357 9.77957L3.90024 6.32624C3.76096 6.18696 3.86011 5.95312 4.05335 5.95312H7.79335Z"
          fill="#3AAB14"
          stroke="#3AAB14"
        />
      </svg>
    </div>
    <div class="mt-5 mt-md-0">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from '../../components/General/Sidebar.vue';
import MobileNavbarComponent from '../../components/General/MobileNavbarComponent.vue';

export default {
  name: 'logged-in-layout',
  components: {
    Sidebar,
    MobileNavbarComponent,
  },
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to log out?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Log out!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('auth/logout').then(() => {
              this.$router.push('/login');
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.plus-btn {
  border: 1px solid var(--blacktint-2);
  padding: 11px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 41px;
  transition: 0.2s;
}

.plus-btn:hover {
  background-color: var(--neutral-2);
}

.border-logged-user {
  width: 1px;
  height: 40px;
  background: var(--blacktint-1);
  margin-right: 40px;
}

.text-button-02 {
  color: var(--success-5);
}

.logged-user .user-img {
  margin-left: 14px;
}
.logged-user {
  height: 96px;
  margin-right: 64px;
}

@media only screen and (min-width: 768px) {
  .ml-sidebar {
    margin-left: var(--sidebar-width);
  }
}
</style>
