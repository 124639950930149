<template>
  <router-link class="isDisabled" :to="{ name: 'ProductView' }"><font-awesome-icon :icon="['fas', 'left-long']" />Back</router-link>
  <h1>{{ product.Title }}</h1>
  <div class="card">
    <div class="row mt-5">
      <div class="col-5">
        <img class="img-thumbnail" :src="$utils.GetImageFormat(product.ImgBase64)" />
        <hr />
        <div class="row">
          <div class="col-4" v-for="gallery in productGallery" :key="gallery.Id">
            <img class="img-gallery" :src="$utils.GetImageFormat(gallery.ImgBase64)" alt="" />
          </div>
        </div>
      </div>
      <div class="col m-2">
        <h1>{{ product.Title }}</h1>
        <h5 class="mt-2">{{ product.Category.Name }}</h5>
        <h5>Description</h5>
        <p>{{ product.Description }}</p>
        <div class="row mt-5">
          <div class="col">
            <h2>{{  $utils.FormatNumber(product.Price) }}RON</h2>
          </div>
          <div class="col">
            <div class="row">
              <div class="col border rounded center-items">
                <div class="row">
                  <img class="col brand-img" :src="$utils.GetImageFormat(product.BrandImg)" alt="" />
                  <p class="col-auto">{{ product.Brand.Name }}</p>
                </div>
              </div>
              <div class="col">
                <div v-if="product.Stock > 0">
                  <div class="center-items border-style-avalaible">
                    <p>{{ product.Stock }} in stock</p>
                  </div>
                </div>
                <div v-else>
                  <div class="center-items border-style-out">
                    <p>Out of stock</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductPresentationView',
  props: {
    productIdParam: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      product: {},
      productGallery: {},
    };
  },
  created() {
    this.GetProduct(this.productIdParam).then(() => {
      this.GetProductGallery(this.productIdParam);
    });
  },
  methods: {
    async GetProduct(id) {
      await this.$axios
        .get(`/api/Product/get/${id}`)
        .then((response) => {
          this.product = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProductGallery(id) {
      this.$axios
        .get(`/api/Product/getProductWithProductsGallery/${id}`)
        .then((response) => {
          this.productGallery = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
};
</script>

<style scoped>
hr {
  display: block;
  border-right: 0;
  border-left: 0;
}

.file-upload {
  left: -9999px;
  position: absolute;
}

.img-gallery {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 1px;
  border-radius: 8px;
}

.img-thumbnail {
  max-width: 418px;
  max-height: 266px;
  border: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-gallery-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background-color: #eeeeed;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-gallery-delete img {
  width: 20px;
}

.brand-img {
  max-width: 75px;
  max-height: 75px;
  border: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isDisabled {
  text-decoration: none;
  color: black;
}

.border-style-avalaible {
  border: var(--bs-border-width) var(--bs-border-style) green;
  border-radius: var(--bs-border-radius);
}

.border-style-out {
  border: var(--bs-border-width) var(--bs-border-style) red;
  border-radius: var(--bs-border-radius);
}

.center-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
