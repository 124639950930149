/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from './auth-header';

const baseURL = process.env.VUE_APP_BASEURL;
class AuthService {
  login(user) {
    return axios.post(`${baseURL}/api/Auth/login`, user).then((response) => {
      if (response.data.Token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  logout(user) {
    localStorage.removeItem('user');
    return axios
      .post(`${baseURL}/api/Auth/logout`, user, { headers: authHeader() })
      .then((response) => response)
      .catch((error) => error);
  }

  register(user) {
    return axios.post(`${baseURL}/api/Auth/register`, user);
  }

  refreshToken(user) {
    const refreshToken = {
      AccessToken: user.Token,
      RefreshToken: user.RefreshToken,
    };
    return axios
      .post(`${baseURL}/api/Auth/refreshToken`, refreshToken, { headers: authHeader() })
      .then((response) => {
        if (response.data.RefreshToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch(() => {
        localStorage.removeItem('user');
        window.location.reload();
      });
  }
}

export default new AuthService();
