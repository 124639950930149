<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="modal fade"
    id="editClientModal"
    tabindex="-1"
    aria-labelledby="editClientModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="editClientFormRef"
          @submit="Submit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../assets/images/icons/edit-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="editClientModalLabel">Edit client</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ResetModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="heading-03 text-neutral-07 fw-normal mb-3">User details</div>
            <div class="row mb-3">
              <div class="col-lg mb-3 mb-lg-0">
                <label for="firstName">First Name </label>
                <Field
                  v-model="client.FirstName"
                  type="text"
                  name="firstName"
                  class="form-control"
                  placeholder="Name"
                  :class="{ 'border-danger': errors.firstName }"
                />
                <ErrorMessage name="firstName" class="error-message" />
              </div>
              <div class="col">
                <label for="lastName">Last Name </label>
                <Field
                  v-model="client.LastName"
                  type="text"
                  name="lastName"
                  class="form-control"
                  placeholder="Name"
                  :class="{ 'border-danger': errors.lastName }"
                />
                <ErrorMessage name="lastName" class="error-message" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg mb-3 mb-lg-0">
                <label for="email">Email </label>
                <Field
                  v-model="client.Email"
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="Email"
                  :class="{ 'border-danger': errors.email }"
                />
                <ErrorMessage name="email" class="error-message" />
              </div>
              <div class="col">
                <label for="phoneNumber">Phone Number</label>
                <Field
                  v-model="client.PhoneNumber"
                  type="text"
                  name="phoneNumber"
                  class="form-control"
                  placeholder="Phone number"
                  :class="{ 'border-danger': errors.phoneNumber }"
                />
                <ErrorMessage name="phoneNumber" class="error-message" />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg mb-3 mb-lg-0">
                <div class="mb-3">
                  <div class="heading-03 text-neutral-07 fw-normal mb-3">Shipping address *</div>
                  <label for="shippingAddress">Address</label>
                  <Field
                    v-model="shipping.Address"
                    type="text"
                    name="shippingAddress"
                    class="form-control"
                    placeholder="Enter address"
                    :class="{ 'border-danger': errors.shippingAddress }"
                    @focusout="AddressSwitch"
                  />
                  <ErrorMessage name="shippingAddress" class="error-message" />
                </div>
                <div class="mb-3">
                  <label for="shippingCity">City</label>
                  <Field
                    v-model="shipping.City"
                    type="text"
                    name="shippingCity"
                    class="form-control"
                    placeholder="City"
                    :class="{ 'border-danger': errors.shippingCity }"
                    @focusout="AddressSwitch"
                  />
                  <ErrorMessage name="shippingCity" class="error-message" />
                </div>
                <div class="mb-3">
                  <label for="shippingState">State</label>
                  <Field
                    v-model="shipping.State"
                    type="text"
                    name="shippingState"
                    class="form-control"
                    placeholder="State"
                    :class="{ 'border-danger': errors.shippingState }"
                    @focusout="AddressSwitch"
                  />
                  <ErrorMessage name="shippingState" class="error-message" />
                </div>
                <div class="mb-3">
                  <label for="shippingCountry">Country</label>
                  <Field
                    v-model="shipping.Country"
                    type="text"
                    name="shippingCountry"
                    class="form-control"
                    placeholder="Country"
                    :class="{ 'border-danger': errors.shippingCountry }"
                    @focusout="AddressSwitch"
                  />
                  <ErrorMessage name="shippingCountry" class="error-message" />
                </div>
              </div>
              <div class="col">
                <div class="heading-03 text-neutral-07 fw-normal mb-3">Billing address *</div>
                <div class="mb-3">
                  <label for="billingAddress">Address</label>
                  <Field
                    v-model="billing.Address"
                    type="text"
                    name="billingAddress"
                    class="form-control"
                    :disabled="client.Switch"
                    placeholder="Enter address"
                    :class="{ 'border-danger': errors.billingAddress }"
                  />
                  <ErrorMessage name="billingAddress" class="error-message" />
                </div>

                <div class="mb-3">
                  <label for="billingCity">City</label>
                  <Field
                    v-model="billing.City"
                    type="text"
                    name="billingCity"
                    class="form-control"
                    :disabled="client.Switch"
                    placeholder="City"
                    :class="{ 'border-danger': errors.billingCity }"
                  />
                  <ErrorMessage name="billingCity" class="error-message" />
                </div>

                <div class="mb-3">
                  <label for="billingState">State</label>
                  <Field
                    v-model="billing.State"
                    type="text"
                    name="billingState"
                    class="form-control"
                    :disabled="client.Switch"
                    placeholder="State"
                    :class="{ 'border-danger': errors.billingState }"
                  />
                  <ErrorMessage name="billingState" class="error-message" />
                </div>

                <div class="mb-3">
                  <label for="billingCountry">Country</label>
                  <Field
                    v-model="billing.Country"
                    type="text"
                    name="billingCountry"
                    class="form-control"
                    :disabled="client.Switch"
                    placeholder="Country"
                    :class="{ 'border-danger': errors.billingCountry }"
                  />
                  <ErrorMessage name="billingCountry" class="error-message" />
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span>
                <input
                  id="switch-shadow-displayed-on-client-edit"
                  class="switch-small switch-small--shadow"
                  type="checkbox"
                  v-model="client.Switch"
                  @change="AddressSwitch"
                />
                <label for="switch-shadow-displayed-on-client-edit"></label>
              </span>

              <label
                class="ms-2 label-01 text-neutral-07 fw-semibold pt-1 pointer"
                for="switch-shadow-displayed-on-client-edit"
              >
                The billing address is the same as the shipping one
              </label>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
/* eslint-disable */
export default {
  name: 'EditClientModal',
  emits: ['edit'],
  props: {
    client: {},
    billing: {},
    shipping: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    AddressSwitch() {
      if (this.client.Switch) {
        this.billing.Address = this.shipping.Address;
        this.billing.City = this.shipping.City;
        this.billing.State = this.shipping.State;
        this.billing.Country = this.shipping.Country;
      } else {
        this.billing.Address = '';
        this.billing.City = '';
        this.billing.State = '';
        this.billing.Country = '';
      }
    },
    Submit() {
      this.client.Shipping = this.shipping;
      this.client.Billing = this.billing;
      this.$emit('edit', this.client);
    },
    ClearModal() {
      this.$refs.editClientFormRef.resetForm();
    },
  },
  computed: {
    schema() {
      return yup.object({
        firstName: yup
          .string()
          .required('First name is required')
          .min(2, 'First name must have at least 2 characters')
          .max(100, 'First name must be under 100 characters')
          .trim(),
        lastName: yup
          .string()
          .required('Last name is required')
          .min(2, 'Last name must have at least 2 characters')
          .max(100, 'Last name must be under 100 characters')
          .trim(),
        email: yup
          .string()
          .required('Email is required')
          .email('This email address is not valid')
          .max(250, 'Last name must be under 250 characters')
          .trim(),
        phoneNumber: yup
          .string()
          .required('Phone number is required')
          .trim()
          .test('validate-phone', 'Phone number is not valid', (phoneNumber) => {
            if (!phoneNumber) return true;
            if (phoneNumber.length < 10) return false;
            return /^([\\+]?40[-]?|[0])?[1-9][0-9]{8}$/.test(phoneNumber);
          }),
        shippingAddress: yup
          .string()
          .notRequired()
          .max(200, 'Shipping address must be under 100 characters')
          .trim(),
        shippingCity: yup
          .string()
          .notRequired()
          .max(50, 'Shipping city must be under 100 characters')
          .trim(),
        shippingState: yup
          .string()
          .notRequired()
          .max(50, 'Shipping  state must be under 100 characters')
          .trim(),
        shippingCountry: yup
          .string()
          .notRequired()
          .max(50, 'Shipping country must be under 100 characters')
          .trim(),
        billingAddress: yup
          .string()
          .notRequired()
          .max(200, 'Billing address must be under 100 characters')
          .trim(),
        billingCity: yup
          .string()
          .notRequired()
          .max(50, 'Billing city must be under 100 characters')
          .trim(),
        billingState: yup
          .string()
          .notRequired()
          .max(50, 'Billing state must be under 100 characters')
          .trim(),
        billingCountry: yup
          .string()
          .notRequired()
          .max(50, 'Billing country must be under 100 characters')
          .trim(),
      });
    },
  },
};
</script>

<style scoped>
label {
  margin-top: 10px;
  font-weight: bold;
}
</style>
