<template>
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4">
      <div class="card-details p-5">
        <h5>Sign Up</h5>
        <Form @submit="Register" :validation-schema="schema">
          <div v-if="!successful">
            <div class="form-group mt-2">
              <label for="FirstName">First name</label>
              <Field v-model="user.FirstName" name="FirstName" type="text" class="form-control" />
              <ErrorMessage name="FirstName" class="error-feedback error-message" />
            </div>
            <div class="form-group mt-2">
              <label for="LastName">Last Name</label>
              <Field v-model="user.LastName" name="LastName" type="text" class="form-control" />
              <ErrorMessage name="LastName" class="error-feedback error-message" />
            </div>
            <div class="form-group mt-2">
              <label for="Email">Email</label>
              <Field v-model="user.Email" name="Email" type="email" class="form-control" />
              <ErrorMessage name="Email" class="error-feedback error-message" />
            </div>
            <div class="form-group mt-2">
              <label for="PhoneNumber">Phone Number</label>
              <Field v-model="user.PhoneNumber" name="PhoneNumber" type="text" class="form-control" />
              <ErrorMessage name="PhoneNumber" class="error-feedback error-message" />
            </div>
            <div class="form-group mt-2">
              <label for="Password">Password</label>
              <Field v-model="user.Password" name="Password" type="password" class="form-control" />
              <ErrorMessage name="Password" class="error-feedback error-message" />
            </div>
            <div class="form-group mt-2">
              <label for="ConfirmPassword">Confirm Password</label>
              <Field v-model="user.ConfirmPassword" name="ConfirmPassword" type="password" class="form-control" />
              <ErrorMessage name="ConfirmPassword" class="error-feedback error-message" />
            </div>
            <div class="form-check mt-2">
              <label class="form-check-label" for="AcceptTerms">I accept <strong>Terms and conditions</strong></label>
              <Field v-model="user.AcceptTerms" id="AcceptTerms" name="AcceptTerms" type="checkbox"
                class="form-check-input" value="true" checked :class="{ 'bg-success': user.AcceptTerms }" />
            </div>
            <ErrorMessage name="AcceptTerms" class="error-feedback error-message" />
            <div class="form-group mt-2">
              <button class=" w-100 btn btn-success btn-block" :disabled="loading">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span>Sign Up</span>
              </button>
              <div class="text-center">
                <p class="mt-3" style="color:green">Already have a account? <strong><router-link to="/login"
                      class="mt-2 forgotPassword">Sign in Here</router-link></strong></p>
              </div>
            </div>
          </div>
        </Form>
        <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'Register',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      user: {
      },
      successful: false,
      loading: false,
      message: '',
    };
  },
  computed: {
    // loggedIn() {
    //   return this.$store.state.auth.status.loggedIn;
    // },
    schema() {
      return yup.object().shape({
        FirstName: yup
          .string()
          .required('Firrst name is required!')
          .min(3, 'Must be at least 3 characters!'),
        LastName: yup
          .string()
          .required('Firrst name is required!')
          .min(3, 'Must be at least 3 characters!'),
        Email: yup
          .string()
          .required('Email is required!')
          .email('Email is invalid!')
          .max(50, 'Must be maximum 50 characters!'),
        PhoneNumber: yup.string().notRequired().trim().test('validate-phone', 'Phone number is not valid',
          (phoneNumber) => {
            if (!phoneNumber) return true;
            if (phoneNumber.length < 10) return false;
            return /^([\\+]?40[-]?|[0])?[1-9][0-9]{8}$/.test(phoneNumber);
          }),
        Password: yup
          .string()
          .required('Password is required!')
          .min(6, 'Must be at least 6 characters!')
          .max(40, 'Must be maximum 40 characters!')
          .test('validate-password',
            'Password must contain: numbers, special characthers and uppercase letters',
            (password) => {
              const specialCharRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~]/;
              const numberRegex = /[0-9]/;
              const uppercaseRegex = /[A-Z]/;
              return (
                specialCharRegex.test(password) &&
                numberRegex.test(password) &&
                uppercaseRegex.test(password)
              );
            }),
        ConfirmPassword: yup
          .string()
          .required('Password is required!')
          .min(6, 'Must be at least 6 characters!')
          .max(40, 'Must be maximum 40 characters!')
          .test('match-passwords', 'Confirmed password is incorect', (password) => password === this.user.Password),
        AcceptTerms: yup
          .string()
          .required('Accept terms are required!'),
      });
    },
  },
  mounted() {
    // if (this.loggedIn) {
    //   this.$router.push('/profile');
    // }
  },
  methods: {
    Register(user) {
      this.user.UserName = `${this.user.FirstName} ${this.user.LastName}`;
      this.loading = true;
      this.message = '';
      this.successful = false;

      this.$store.dispatch('auth/register', user).then(() => {
        this.message = 'Registration succesfulul, please check the confirmation email';
        this.successful = true;
        this.loading = false;
      }).catch((error) => {
        this.message = error.response.data;
        this.successful = false;
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.card-details {
  border: 1px solid green;
  border-radius: 20px;
  box-shadow: 10px 5px 5px green;
}
</style>
