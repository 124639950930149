<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.3333 11.86V5.80669C29.3333 3.92669 28.48 3.16669 26.36 3.16669H20.9733C18.8533 3.16669 18 3.92669 18 5.80669V11.8467C18 13.74 18.8533 14.4867 20.9733 14.4867H26.36C28.48 14.5 29.3333 13.74 29.3333 11.86Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.3333 26.86V21.4733C29.3333 19.3533 28.48 18.5 26.36 18.5H20.9733C18.8533 18.5 18 19.3533 18 21.4733V26.86C18 28.98 18.8533 29.8333 20.9733 29.8333H26.36C28.48 29.8333 29.3333 28.98 29.3333 26.86Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 11.86V5.80669C14 3.92669 13.1467 3.16669 11.0267 3.16669H5.64002C3.52002 3.16669 2.66669 3.92669 2.66669 5.80669V11.8467C2.66669 13.74 3.52002 14.4867 5.64002 14.4867H11.0267C13.1467 14.5 14 13.74 14 11.86Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 26.86V21.4733C14 19.3533 13.1467 18.5 11.0267 18.5H5.64002C3.52002 18.5 2.66669 19.3533 2.66669 21.4733V26.86C2.66669 28.98 3.52002 29.8333 5.64002 29.8333H11.0267C13.1467 29.8333 14 28.98 14 26.86Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold  d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="OpenAddBrandModal()"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Add <span class="d-none d-sm-inline-block">brand</span>
        </button>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="row mb-3 filters-zone">
      <div class="col-lg-6 mb-3 mb-lg-0 col-xxl-3">
        <label for="brands-search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            v-model="filter.SearchQuery"
            @keyup.enter="GetBrands"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            id="brands-search"
          />
        </div>
      </div>
      <div class="col-lg-6 col-xxl-3">
         <label>Category</label>
        <div :class="{ 'multiselect-value-selected': filter.Category }">
          <VueMultiselect
            id="status"
            v-model="filter.Category"
            :options="categoriesList"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="GetBrands"
            @remove="GetBrands"
            class="custom-multiselect"
            select-label=""
            @search-change="GetCategoriesList"
            @open="GetCategoriesList()"
            placeholder="Select category"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Category = '';
              GetBrands();
            "
          >
          </span>
        </div>
      </div>
    </div>

    <div class="table-scroll">
      <div class="row-table m-0">
        <div class="row header m-0">
          <div class="col-6 col-xxl-4 ps-0" :class="{ active: filter.OrderBy.includes('name') }">
            <span @click="OrderBy('name')" class="pointer d-flex">
              <span class="text">Name</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="name" />
            </span>
          </div>
          <div class="col col-xxl-4" :class="{ active: filter.OrderBy.includes('products') }">
            <span @click="OrderBy('products')" class="pointer d-flex">
              <span class="text">Products number</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="products" />
            </span>
          </div>
          <div class="col"></div>
          <div class="col-auto"></div>
        </div>
        <div
          class="row content align-items-center m-0"
          :class="{ expandable: brand.NumberOfProducts > 0 }"
          v-on:click="brand.NumberOfProducts > 0 ? ColapseProdutcs(brand) : ''"
          v-for="(brand, index) in brandsList"
          :key="index"
        >
          <!--review this @click Colapse-->
          <div class="col-6 col-xxl-4 d-flex align-items-end" style="padding-left: 37px">
            <img class="content-img" :src="$utils.GetImageFormat(brand.ImgBase64)" />
            <div class="ms-3">
              <div class="title">{{ brand.Name }}</div>
              <div class="description" :title="brand.Description">
                {{ this.$utils.Ellipsify(brand.Description, 41) }}
              </div>
            </div>
          </div>
          <div class="col col-xxl-4">
            <div class="title">{{ brand.NumberOfProducts }} products</div>
          </div>
          <div class="col"></div>
          <div class="col-auto pe-0" v-on:click.stop>
            <div class="d-flex align-items-center">
              <button
                type="button"
                class="button table-btn me-2"
                v-on:click="GetBrandDetails(brand.Id)"
              >
                <img src="../../assets/images/icons/table/edit-linear.svg" />
              </button>
              <button type="button" class="button table-btn" v-on:click="DeleteBrand(brand.Id)">
                <img src="../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
          </div>
          <div class="row collapse expanded-subcategory m-0 p-0" :id="brand.Id" v-on:click.stop>
            <div class="col-auto cursor-default" style="width: 25px"></div>
            <div class="col">
              <div class="subcategory">Products</div>
              <div
                v-for="product in brand.productsList"
                :key="product.Id"
                class="row subcategories align-items-center"
              >
                <div class="col-5 d-flex align-items-center">
                  <img class="content-img" :src="$utils.GetImageFormat(product.ImgBase64)" />
                  <div class="ms-3">
                    <div class="title">{{ product.Title }}</div>
                    <div class="description" :title="product.Description">
                      {{ this.$utils.Ellipsify(product.Description, 41) }}
                    </div>
                  </div>
                </div>
                <div class="col"></div>
                <div class="col-auto">
                  <img
                    src="../../assets/images/icons/table/expanded/trash.svg"
                    class="pointer"
                    @click="DeleteProduct(product.Id, brand)"
                  />
                </div>
              </div>
              <div v-if="brand.Pagination && brand.Pagination.TotalPageCount > 1">
                <ColapsePagination
                  :key="componentKey"
                  :pageSize="brand.Pagination.PageSize"
                  :totalItemCount="brand.Pagination.TotalItemCount"
                  :totalPageCount="brand.Pagination.TotalPageCount"
                  :pageNumber="brand.Filter.PageNumber"
                  :element="brand"
                  @select-page="SelectPageProduct"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="brandsList.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>

  <AddBrandModal ref="addBrandModalRef" @save="GetBrands" />
  <EditBrandModal :brand="editBrandData" @update="UpdateBrand(editBrandData)" />
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import AddBrandModal from '../../components/Brand/Modals/AddBrandModal.vue';
import EditBrandModal from '../../components/Brand/Modals/EditBrandModal.vue';
import ColapsePagination from '../../components/General/ColapsePagination.vue';

export default {
  name: 'BrandView',
  components: {
    PaginationComponent,
    AddBrandModal,
    EditBrandModal,
    VueMultiselect,
    OrderByIconComponent,
    ColapsePagination,
  },
  data() {
    return {
      brandsList: [],
      editBrandData: {},
      editProductData: {},
      categoriesList: [],
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
        Category: '',
      },
      pagination: {},
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    SelectPageProduct(nr, brand) {
      // eslint-disable-next-line no-param-reassign
      brand.Filter.PageNumber = nr;

      this.GetBrandProducts(brand);
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetBrands();
    },
    OpenAddBrandModal() {
      this.$refs.addBrandModalRef.ClearModalValues();
      // eslint-disable-next-line no-undef
      $('#addBrandModal').modal('show');
    },
    GetBrands() {
      const searchParam = new URLSearchParams({
        OrderBy: this.filter.OrderBy,
        ...(this.filter.Category ? { CategoryId: this.filter.Category.Id } : ''),
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      });
      if (this.brandsList.length > 0) {
        // eslint-disable-next-line no-undef
        $('div.expanded-subcategory').removeClass('show');
      }
      this.$axios
        .get(`/api/Brand/getAll?${searchParam}`)
        .then((response) => {
          this.brandsList = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetBrandProducts(brand) {
      const searchParams = new URLSearchParams(brand.Filter);
      this.$axios
        .get(`/api/Brand/products/${brand.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          brand.productsList = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          brand.Pagination = response.data.PageDetails;
          this.forceRerender();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetCategoriesList() {
      this.$axios
        .get('/api/categories/getListOfCategories')
        .then((response) => {
          this.categoriesList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteBrand(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Brand/delete/virtual/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetBrands();
                this.$utils.toastNotify('success', 'Brand has been deleted.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    DeleteProduct(id, brand) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Product/delete/${id}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Product has been deleted.');

                // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
                brand.NumberOfProducts -= 1;
                if (brand.NumberOfProducts === 0) {
                  // eslint-disable-next-line no-undef
                  $(`#${brand.Id}`).collapse('toggle');
                }
                this.GetBrandProducts(brand);
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    GetBrandDetails(id) {
      this.$axios
        .get(`/api/Brand/get/${id}`)
        .then((response) => {
          this.editBrandData = response.data;
          // eslint-disable-next-line no-undef
          $('#editBrandModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetBrands();
    },
    ColapseProdutcs(brand) {
      if (!brand.Filter) {
        // eslint-disable-next-line no-param-reassign
        brand.Filter = {
          PageNumber: 1,
          PageSize: this.$store.state.globalPageSize,
        };
      }
      const searchParams = new URLSearchParams(brand.Filter);
      this.$axios
        .get(`/api/Brand/products/${brand.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          brand.productsList = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          brand.Pagination = response.data.PageDetails;
          // eslint-disable-next-line no-undef
          $(`#${brand.Id}`).collapse('toggle');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    UpdateBrand(brand) {
      this.$axios
        .put(`/api/Brand/update/${brand.Id}`, brand)
        .then(() => {
          this.$utils.toastNotify('success', 'Brand edited successfully!');
          this.GetBrands();
          // eslint-disable-next-line no-undef
          $('#editBrandModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },

  created() {
    this.$store.commit('ChangePage', 'Brands');
    this.GetBrands();
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1200px) {
  .table-scroll {
      overflow-x: auto;
      position: relative;
  }
 .row-table {
    width: 900px;
  }
}
</style>
