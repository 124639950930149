<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28 9.83317V23.1665C28 27.1665 26 29.8332 21.3333 29.8332H10.6667C6 29.8332 4 27.1665 4 23.1665V9.83317C4 5.83317 6 3.1665 10.6667 3.1665H21.3333C26 3.1665 28 5.83317 28 9.83317Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.3335 6.5V9.16667C19.3335 10.6333 20.5335 11.8333 22.0002 11.8333H24.6668"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.6665 17.8335H15.9998"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.6665 23.1665H21.3332"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold  d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <router-link
          to="/AddBlogArticle"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Add <span class="d-none d-sm-inline-block">article</span>
        </router-link>
      </div>
    </div>
  </div>
  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center filters-zone mb-3">
      <div class="col-lg-6 col-xxl mb-2 mb-xxl-0">
        <label for="search">Search</label>

        <div class="input-group left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="search"
            v-model="filter.Search"
            @keyup.enter="GetBlogs"
            type="text"
            class="search form-control"
            placeholder="Search in..."
          />
        </div>
      </div>
      <div class="col-md-6 col-xxl mb-2 mb-xxl-0">
        <label for="author">Author</label>
        <div :class="{ 'multiselect-value-selected': filter.Author }">
          <VueMultiselect
            id="author"
            v-model="filter.Author"
            :options="authors"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="GetBlogs"
            @remove="GetBlogs"
            class="custom-multiselect"
            select-label=""
            placeholder="Select author"
            @search-change="GetAuthors"
            @open="GetAuthors()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Author = '';
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md-6 col-lg">
        <label for="status"> Status</label>
        <div :class="{ 'multiselect-value-selected': filter.Status }">
          <VueMultiselect
            id="status"
            v-model="filter.Status"
            :options="['Published', 'Draft']"
            :close-on-select="true"
            @select="GetBlogs"
            @remove="GetBlogs"
            class="custom-multiselect"
            select-label=""
            placeholder="Select status"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Status = '';
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md mt-2 mt-lg-0">
        <label for="category"> Categories</label>
        <div :class="{ 'multiselect-value-selected': filter.Category }">
          <VueMultiselect
            id="category"
            v-model="filter.Category"
            :options="categories"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="GetBlogs"
            @remove="GetBlogs"
            class="custom-multiselect"
            select-label=""
            placeholder="Select category"
            @search-change="GetCategories"
            @open="GetCategories()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Category = '';
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md mt-2 mt-lg-0">
        <label for="tags"> Tags</label>
        <div :class="{ 'multiselect-value-selected': filter.Tags }">
          <VueMultiselect
            id="tags"
            v-model="filter.Tags"
            :options="tags"
            :close-on-select="true"
            @select="GetBlogs"
            @remove="GetBlogs"
            class="custom-multiselect"
            placeholder="Select tags"
            select-label=""
            @search-change="GetTags"
            @open="GetTags()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Tags = '';
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
    </div>
    <hr class="pb-2" />

    <div class="row" v-if="blogs">
      <div class="col-sm-6 col-lg-4 col-xxl-3  mb-4" v-for="blog in blogs" :key="blog.Id">
        <div
          class="blog-card"
          @mouseenter="blog.ShowDetails = true"
          @mouseleave="blog.ShowDetails = false"
        >
          <img :src="$utils.GetImageFormat(blog.ImgBase64)" class="bg-img" />

          <transition name="fade">
            <div class="info" :class="{ full: blog.ShowDetails }">
              <div class="row">
                <div class="col">
                  <div class="badge" :class="[blog.Draft ? 'badge-secondary' : 'badge-success']">
                    {{ blog.Draft ? 'Draft' : 'Published' }}
                  </div>
                  <div v-if="blog.ShowDetails" class="label-01 fw-normal mt-2 pt-1">
                    {{ $store.getters.dateFormat('2023-09-18 11:57:16.7943578') }}
                  </div>
                </div>
                <div v-if="blog.ShowDetails" class="col-auto">
                  <div class="d-flex">
                    <router-link
                      type="button"
                      class="btn btn-outline-light me-2"
                      style="padding: 5px 7px 5px 7px"
                      :to="{ name: 'EditBlogArticle', params: { articleIdParam: blog.Id } }"
                    >
                      <img src="../../assets/images/icons/edit-linear-white-20x20.svg" />
                    </router-link>
                    <button
                      @click="DeleteBlog(blog.Id)"
                      type="button"
                      class="btn btn-outline-light"
                      style="padding: 5px 7px 5px 7px"
                    >
                      <img src="../../assets/images/icons/trash-white-20x20.svg" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-auto">
                <div class="heading-01 mb-1">{{ blog.Title }}</div>
                <span class="label-01 fw-light"> {{ blog.LastName }} {{ blog.FirstName }} </span>
                <img
                  v-if="blog.CategoryName"
                  src="../../assets/images/icons/small-dot.svg"
                  style="padding-left: 10px; padding-right: 10px"
                />
                <span class="label-01 fw-normal">{{ blog.CategoryName }}</span>
              </div>
            </div>
            <!-- <div v-if="blog.ShowDetails" class="info full">
              <div class="heading-01 mb-1">{{ blog.Title }}</div>
              <div class="label-01" style="max-width: 240px; height: 28px">
                {{ blog.Description }}
              </div>
            </div>
            <div v-else class="info justify-content-end">
              <div class="heading-01 mb-1">{{ blog.Title }}</div>
              <div class="label-01" style="max-width: 240px; height: 28px">
                {{ blog.Description }}
              </div>
            </div> -->
          </transition>
        </div>
      </div>
    </div>
    <div v-if="blogs.length == 0" class="mt-5 pt-5 text-center">
      <img src="../../assets/images/not-found-img.png" />
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>
</template>

<script>
import PaginationComponent from '../../components/General/PaginationComponent.vue';

export default {
  components: { PaginationComponent },
  name: 'BlogsView',
  data() {
    return {
      pagination: {},
      filter: {
        Search: '',
        Author: '',
        Status: '',
        Category: '',
        Tags: '',
        PageSize: this.$store.state.globalPageSize,
        PageNumber: 1,
      },
      blogs: [],
      authors: [],
      status: [],
      categories: [],
      tags: [],
    };
  },
  methods: {
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetBlogs();
    },
    GetBlogs() {
      const searchParams = new URLSearchParams({
        Search: this.filter.Search || '',
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
        Author: this.filter.Author ? this.filter.Author.Id : '',
        Status: this.filter.Status || '',
        CategoryId: this.filter.Category ? this.filter.Category.Id : '',
        Tags: this.filter.Tags || '',
      });
      this.$axios
        .get(`/api/Blog/getAllBlogs?${searchParams}`)
        .then((response) => {
          this.blogs = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetCategories() {
      this.$axios
        .get('/api/Blog/getCategories')
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetAuthors() {
      this.$axios
        .get('/api/Blog/getAuthors')
        .then((response) => {
          this.authors = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetTags() {
      this.$axios
        .get('/api/Blog/getTags')
        .then((response) => {
          this.tags = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteBlog(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Blog/delete/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetBlogs();
                this.$utils.toastNotify('success', 'Blog has been deleted.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.GetBlogs();
  },
};
</script>
<style scoped>
.btn-outline-light:hover img {
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(100%);
}
.btn-outline-light img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(99deg) brightness(101%) contrast(101%);
}
.btn-outline-light {
  border-color: var(--whitetint-7);
  border-radius: 4px;
}
.blog-card .info {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40.63%,
    rgba(0, 0, 0, 0.214286) 48.96%,
    rgba(0, 0, 0, 0.6) 62.5%,
    rgba(0, 0, 0, 0.9) 100%
  );

  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 12px;
  padding: 14px 17px 10px 17px;
  display: flex;
  flex-direction: column;
  color: white;
}
.blog-card .info.full {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
}
.blog-card .bg-img {
  width: 100%;
  /* height: 280px; */
  height: 276px;
  border-radius: 8px;
  display: block;
  object-position: center;
  object-fit: cover;
}

.blog-card {
  position: relative;
}
</style>
