<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="modal fade"
    id="editUserModal"
    tabindex="-1"
    aria-labelledby="editUserModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="editUserFormRef"
          @submit="Submit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../assets/images/icons/edit-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="editBrandModalLabel">Edit user</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ClearModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="firstName">First name</label>
              <Field
                v-model="user.FirstName"
                class="form-control"
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First name"
                :class="{ 'border-danger': errors.firstName }"
              />
              <ErrorMessage name="firstName" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="lastName">Last name</label>
              <Field
                v-model="user.LastName"
                class="form-control"
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last name"
                :class="{ 'border-danger': errors.lastName }"
              />
              <ErrorMessage name="lastName" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="email">Email</label>
              <Field
                v-model="user.Email"
                class="form-control"
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                :class="{ 'border-danger': errors.email }"
              />
              <ErrorMessage name="email" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="email">Phone number</label>
              <Field
                v-model="user.PhoneNumber"
                class="form-control"
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Phone number"
                :class="{ 'border-danger': errors.phoneNumber }"
              />
              <ErrorMessage name="phoneNumber" class="error-message" />
            </div>

          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'EditUserModal',
  emits: ['edit'],
  props: {
    user: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      // eslint-disable-next-line vue/no-mutating-props
      this.user.Role = 'Client';
      this.$emit('edit', this.user);
    },
    ClearModal() {
      this.$refs.editUserFormRef.resetForm();
    },
    HandleCloseModal() {
      const modal = document.getElementById('editUserModal');
      if (modal) {
        const app = this;
        modal.addEventListener('hidden.bs.modal', () => {
          app.$refs.editUserFormRef.resetForm();
        });
      }
    },
  },
  mounted() {
    this.HandleCloseModal();
  },
  computed: {
    schema() {
      return yup.object({
        firstName: yup.string().required('First name is required.')
          .min(2, 'First name must have at least 2 characters')
          .max(100, 'First name must be under 100 characters')
          .trim(),
        lastName: yup.string().required('Last name is required.')
          .min(2, 'Last name must have at least 2 characters')
          .max(100, 'Last name must be under 100 characters')
          .trim(),
        email: yup.string().required('Email is required')
          .email('This email address is not valid')
          .max(250, 'Last name must be under 250 characters')
          .trim(),
        role: yup.string().notRequired('Role is required'),
        phoneNumber: yup
          .string()
          .notRequired()
          .min(10, 'Phone number is not valid')
          .trim()
          .test('validate-phone', 'Phone number is not valid', (phoneNumber) => {
            if (!phoneNumber) return true;
            return /^([\\+]?40[-]?|[0])?[1-9][0-9]{8}$/.test(phoneNumber);
          }),
      });
    },
  },
};
</script>
