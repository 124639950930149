<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8267 21.3333L14.8267 23.3333L19.16 19.3333"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.7467 2.66667L6.92001 7.50667"
              stroke="#3E4D52"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.2533 2.66667L25.08 7.50667"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66666 10.4667C2.66666 8 3.98666 7.8 5.62666 7.8H26.3733C28.0133 7.8 29.3333 8 29.3333 10.4667C29.3333 13.3333 28.0133 13.1333 26.3733 13.1333H5.62666C3.98666 13.1333 2.66666 13.3333 2.66666 10.4667Z"
              stroke="#3E4D52"
              stroke-width="1.5"
            />
            <path
              d="M4.66666 13.3333L6.54666 24.8533C6.97332 27.44 7.99999 29.3333 11.8133 29.3333H19.8533C24 29.3333 24.6133 27.52 25.0933 25.0133L27.3333 13.3333"
              stroke="#3E4D52"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">Products summary</div>
          <div class="heading-02 fw-semibold d-sm-none">Products summary</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="button btn-small btn-success-solid d-flex align-items-center"
          v-on:click="Export()"
        >
          Export
        </button>
      </div>
    </div>
  </div>
  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center filters-zone mb-3">
      <div class="col-xl-6 mb-3 mb-xl-0">
        <label for="search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="search"
            v-model="filter.SearchQuery"
            @keyup.enter="SearchByQuery"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            aria-label="Text input with dropdown button"
          />
        </div>
      </div>
      <div class="col-sm mb-3 mb-sm-0 col-xxl-3">
        <label for="orders-date">Group by order date</label>
        <VueDatePicker
          v-model="orderInterval"
          placeholder="Select interval"
          :enableTimePicker="false"
          autoApply
          multi-calendars
          range
          class="custom-datepicker"
          name="Start date"
          format="dd-MM-yyyy"
          id="orders-date"
          @update:modelValue="FilterByDate()"
        ></VueDatePicker>
      </div>
      <div class="col col-xxl-3">
        <label for="orders-date">Group by pick-up date</label>
        <VueDatePicker
          v-model="pickUpInterval"
          placeholder="Select interval"
          :enableTimePicker="false"
          autoApply
          multi-calendars
          range
          class="custom-datepicker"
          name="Start date"
          format="dd-MM-yyyy"
          id="orders-date"
          @update:modelValue="FilterByDate()"
        ></VueDatePicker>
      </div>
    </div>
    <div class="table-scroll">
      <div class="row-table m-0">
        <div class="row header m-0 align-items-center">
          <div class="col" :class="{ active: filter.OrderBy.includes('title') }">
            <span @click="OrderBy('title')" class="pointer d-inline-flex">
              <span class="text">Title</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="title" />
            </span>
          </div>
          <div class="col" :class="{ active: filter.OrderBy.includes('quantity') }">
            <span @click="OrderBy('quantity')" class="pointer d-inline-flex">
              <span class="text">Quantity</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="quantity" />
            </span>
          </div>
          <div class="col" :class="{ active: filter.OrderBy.includes('category') }">
            <div @click="OrderBy('category')" class="pointer d-inline-flex align-items-center">
              <div class="text">Category</div>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="category" />
            </div>
          </div>
        </div>
        <div
          class="row content align-items-center m-0"
          v-for="(product, index) in products"
          :key="index"
        >
          <div class="col d-flex align-items-center">
            <img class="content-img" :src="$utils.GetImageFormat(product.ImgBase64)" />
            <div class="label-02 ms-3" style="color: #1f2629">
              <div class="fw-semibold">{{ product.Title }}</div>
              <div v-if="product.OrderConfigurations" class="text mt-1 pt-1 mb-0 pb-0">
                {{ product.OrderConfigurations }}
              </div>
            </div>
          </div>
          <div class="col">
            {{ product.Quantity }}
          </div>
          <div class="col">
            <div class="row align-items-center">
              <div class="col-auto">
                <img
                  :src="$utils.DynamicPathAttachment(product.CategoryImg)"
                  class="table-content-img-small content-img me-2"
                />
              </div>
              <div class="col">
                {{ product.Category }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="products.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>
    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';

export default {
  name: 'ProductOrderdView',
  data() {
    return {
      filter: {
        StartDate: '',
        EndDate: '',
        SearchQuery: '',
        PageSize: 10,
        PageNumber: 1,
        OrderBy: 'title',
      },
      orderInterval: '',
      pickUpInterval: '',
      products: [],
      pagination: {},
    };
  },
  components: {
    OrderByIconComponent,
    PaginationComponent,
  },
  methods: {
    SearchByQuery() {
      this.filter.PageNumber = 1;
      this.GetProductsOrderd();
    },
    FilterByDate() {
      if (this.orderInterval) {
        this.filter.OrderStartDate = this.orderInterval
          ? new Date(this.orderInterval[0]).toISOString()
          : '';
        this.filter.OrderEndDate = this.orderInterval
          ? new Date(this.orderInterval[1]).toISOString()
          : '';
      } else {
        this.filter.OrderStartDate = '';
        this.filter.OrderEndDate = '';
      }

      if (this.pickUpInterval) {
        this.filter.PickUpStartDate = this.pickUpInterval
          ? new Date(this.pickUpInterval[0]).toISOString()
          : '';
        this.filter.PickUpEndDate = this.pickUpInterval
          ? new Date(this.pickUpInterval[1]).toISOString()
          : '';
      } else {
        this.filter.PickUpEndDate = '';
        this.filter.PickUpStartDate = '';
      }

      this.GetProductsOrderd();
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetProductsOrderd();
    },
    GetProductsOrderd() {
      const searchParams = {
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
        ...(this.filter.SearchQuery ? { searchQuery: this.filter.SearchQuery } : ''),
        ...(this.filter.OrderStartDate ? { StartDate: this.filter.OrderStartDate } : ''),
        ...(this.filter.OrderEndDate ? { EndDate: this.filter.OrderEndDate } : ''),
        ...(this.filter.PickUpStartDate ? { PickUpStartDate: this.filter.PickUpStartDate } : ''),
        ...(this.filter.PickUpEndDate ? { PickUpEndDate: this.filter.PickUpEndDate } : ''),
        ...(this.filter.OrderBy ? { OrderBy: this.filter.OrderBy } : ''),
      };
      this.$axios
        .get(`/api/Orders/getProductsOrders?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetProductsOrderd();
    },

    Export: function () {
      const searchParams = {
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
        ...(this.filter.SearchQuery ? { searchQuery: this.filter.SearchQuery } : ''),
        ...(this.filter.OrderStartDate ? { StartDate: this.filter.OrderStartDate } : ''),
        ...(this.filter.OrderEndDate ? { EndDate: this.filter.OrderEndDate } : ''),
        ...(this.filter.PickUpStartDate ? { PickUpStartDate: this.filter.PickUpStartDate } : ''),
        ...(this.filter.PickUpEndDate ? { PickUpEndDate: this.filter.PickUpEndDate } : ''),
        ...(this.filter.OrderBy ? { OrderBy: this.filter.OrderBy } : ''),
      };
      this.$axios
        .get(`/api/Orders/OrdersExport?${new URLSearchParams(searchParams)}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Summary-${moment().format('DD/MM/yyyy HH.mm')}.xlss`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  created() {
    this.GetProductsOrderd();
  },
};
</script>

<style scoped>
@media (max-width: 1000px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .row-table {
    width: 1000px;
  }
}
</style>
