<template>
  <div class="not-found-container">
  <img :src="require('@/assets/images/not_found.png')" />
  <div>Page not found</div>
</div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>
.not-found-container {
  text-align: center;
  padding-top: 25vh;
}

.not-found-container div {
  font-weight: 600;
}
</style>
