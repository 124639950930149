<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.93359 12.8467L16.0002 16.94L23.0136 12.8733"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 24.1934V16.9267"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.347 8.88668L10.0803 11.26C9.12032 11.7933 8.32031 13.14 8.32031 14.2467V18.7667C8.32031 19.8733 9.10699 21.22 10.0803 21.7533L14.347 24.1267C15.2537 24.6333 16.747 24.6333 17.667 24.1267L21.9337 21.7533C22.8937 21.22 23.6936 19.8733 23.6936 18.7667V14.2333C23.6936 13.1267 22.907 11.78 21.9337 11.2467L17.667 8.87334C16.747 8.36668 15.2537 8.36668 14.347 8.88668Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.3333 20.5C29.3333 25.66 25.16 29.8333 20 29.8333L21.4 27.5"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66699 12.5C2.66699 7.34002 6.84033 3.16669 12.0003 3.16669L10.6003 5.50002"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="row filters-zone align-items-center">
      <div class="col-md-6 mb-3 mb-xl-0 col-xl-3">
        <label for="reviews-search">Search</label>
        <div class="input-group left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="reviews-search"
            v-model="filter.SearchQuery"
            @keyup.enter="SearchByQuery"
            type="text"
            class="search form-control"
            placeholder="Search in..."
          />
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-xl-0 col-xl-3">
        <label>Date</label>
        <VueDatePicker
          v-model="filter.Date"
          placeholder="Select date"
          :enableTimePicker="false"
          autoApply
          class="custom-datepicker"
          name="Date"
          format="dd-MM-yyyy"
          id="reviews-date"
          @update:modelValue="GetReviews"
        ></VueDatePicker>
      </div>
      <div class="col-md-6 mb-3 mb-xl-0 col-xl-3">
        <label for="orders-date">Testimonial</label>
        <div :class="{ 'multiselect-value-selected': filter.Testimonial.Value }">
          <VueMultiselect
            id="status"
            v-model="filter.Testimonial"
            :options="[
              { Name: 'Only testimonals', Value: true },
              { Name: 'Not testimonials', Value: false },
              { Name: 'All', Value: '' },
            ]"
            :close-on-select="true"
            @select="GetReviews"
            @remove="GetReviews"
            class="custom-multiselect"
            select-label=""
            placeholder="Select testimonial value"
            label="Name"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Testimonial = { Name: 'All', Value: '' };
              GetReviews();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md-6 mb-3 mb-xl-0 col-xl-3">
        <label for="orders-date">Approve</label>
        <div :class="{ 'multiselect-value-selected': filter.Approved.Value }">
          <VueMultiselect
            id="status"
            v-model="filter.Approved"
            :options="[
              { Name: 'Approved', Value: true },
              { Name: 'Not approved', Value: false },
              { Name: 'All', Value: '' },
            ]"
            :close-on-select="true"
            @select="GetReviews"
            @remove="GetReviews"
            class="custom-multiselect"
            select-label=""
            placeholder="Select approved value"
            label="Name"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Approved = { Name: 'All', Value: '' };
              GetReviews();
            "
          >
          </span>
        </div>
      </div>
    </div>

    <div class="table-scroll">
      <table class="custom table mt-3">
        <thead>
          <tr valign="middle">
            <th width="29%">Review body</th>
            <th :class="{ active: filter.OrderBy.includes('rating') }">
              <span class="pointer" @click="OrderBy('rating')">
                Rating
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="rating"
                  class="ms-1"
                />
              </span>
            </th>

            <th :class="{ active: filter.OrderBy.includes('date') }">
              <span class="pointer" @click="OrderBy('date')">
                Date
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="date"
                  class="ms-1"
                />
              </span>
            </th>

            <th>Approve</th>
            <th>Testimonial</th>
            <th>Product</th>
            <th>Order Id</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(review, index) in reviews" :key="index" valign="middle">
            <td>
              <div class="row align-items-center">
                <div class="col-auto">
                  <img class="content-img" :src="$utils.GetImageFormat(review.UserImgBase64)" />
                </div>
                <div class="col ms-0 ps-0">
                  <p class="m-0 p-0 bold">{{ review.UserName }}</p>
                  <p v-if="review.Comment" class="m-0 p-0" :title="review.Comment">
                    {{ $utils.Ellipsify(review.Comment, 85) }}
                  </p>
                  <p class="m-0 p-0" v-else></p>
                </div>
              </div>
            </td>
            <td>
              <RatingComponent :stars="review.Rating" />
            </td>
            <td>
              {{ review.Date }}
            </td>
            <td>
              <input
                :id="'switch-approve-' + index"
                class="switch-small switch-small--shadow"
                type="checkbox"
                :checked="review.IsAproved"
                v-on:click="UpdateReviewAproved(review)"
                @click.prevent
              />
              <label :for="'switch-approve-' + index"></label>
            </td>
            <td>
              <input
                :id="'switch-testimonial-' + index"
                class="switch-small switch-small--shadow"
                type="checkbox"
                :checked="review.Testimonial"
                v-on:click="UpdateReviewTestimonial(review)"
                @click.prevent
              />
              <label :for="'switch-testimonial-' + index"></label>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <img class="content-img" :src="$utils.GetImageFormat(review.ImgBase64)" />
                <div class="title">{{ review.ProductName }}</div>
              </div>
            </td>
            <td>
              <div v-if="review.OrderId">
                <strong>
                  <router-link
                    class="no-decoration text-neutral-06"
                    :to="{ name: 'OrderDetails', params: { orderIdParam: review.OrderId } }"
                  >
                    #{{ review.OrderId }}
                  </router-link>
                </strong>
              </div>
              <div v-else>No order</div>
            </td>
            <td>
              <div class="float-end">
                <button type="button" class="button table-btn" v-on:click="DeleteReview(review.Id)">
                  <img src="../../assets/images/icons/table/trash-linear.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="reviews.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>
</template>

<script>
/*eslint-disable*/

import RatingComponent from '../../components/General/RatingComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';

export default {
  name: 'ReviewsView',
  data() {
    return {
      reviews: [],
      pagination: {},
      filter: {
        SearchQuery: '',
        Date: '',
        OrderBy: '',
        Testimonial: { Name: 'All', Value: '' },
        Approved: { Name: 'All', Value: '' },
        isApproved: false,
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
    };
  },
  components: {
    RatingComponent,
    PaginationComponent,
    OrderByIconComponent,
  },
  methods: {
    SelectPage(nr) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = nr || 1;
      this.GetReviews();
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.OrderBy = orderBy;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.Date = this.filter.Date
        ? new Date(this.filter.Date).toLocaleDateString('en-US')
        : '';
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = 1;
      this.GetReviews();
    },
    SearchByQuery() {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber =  1;
      this.GetReviews();
    },
    GetReviews() {
      this.filter.Date = this.filter.Date
        ? new Date(this.filter.Date).toLocaleDateString('en-US')
        : '';
      const searchParams = {
        ...this.filter,
        isTestimonial: this.filter.Testimonial.Value,
        isApproved: this.filter.Approved.Value,
      };
      this.$axios
        .get(`/api/Reviews/getAll?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.reviews = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    DeleteReview(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Reviews/delete/${id}`)
              .then(() => {
                this.GetReviews();
                this.$utils.toastNotify('success', 'Review deleted succesesfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    UpdateReviewTestimonial(review) {
      this.$swal
        .fire({
          title: 'Warning',
          text: 'Are you sure you want to change this property?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.value) {
            // eslint-disable-next-line no-param-reassign
            review.Testimonial = !review.Testimonial;
            this.$axios
              .put(`/api/Reviews/updateTestimonial/${review.Id}?status=${review.Testimonial}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Review updated succesesfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    UpdateReviewAproved(review) {
      this.$swal
        .fire({
          title: 'Warning',
          text: 'Are you sure you want to change this property?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.value) {
            // eslint-disable-next-line no-param-reassign
            review.IsAproved = !review.IsAproved;
            this.$axios
              .put(`/api/Reviews/updateAproved/${review.Id}?status=${review.IsAproved}`)
              .then(() => {
                // this.GetReviews();
                this.$utils.toastNotify('success', 'Review updated succesesfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
  },
  mounted() {
    this.GetReviews();
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

@media (max-width: 1400px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .table {
    width: 1400px;
  }
}
</style>
