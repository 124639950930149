<template>
  <div class="d-flex justify-content-center" style="margin-top: 41px">
    <ul v-if="numberOfPages >= 8" class="pagination justify-content-center">
      <li class="page-item" v-if="pageNumber > 1">
        <a v-on:click="functionName(1)" class="page-link" tabindex="-1">
          <img src="../../assets/images/icons/pagination/arrows-left.svg" />
        </a>
      </li>
      <li class="page-item" v-if="pageNumber > 1">
        <a v-on:click="functionName(pageNumber - 1)" class="page-link" tabindex="-1">
          <img src="../../assets/images/icons/pagination/chevron-left.svg" />
        </a>
      </li>

      <template v-for="page in numberOfPages">
        <li
          class="page-item"
          v-if="page < 3"
          v-bind:class="{ active: page == pageNumber }"
          v-bind:key="page"
        >
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item" v-if="pageNumber > 4">
        <a class="page-link disabled">...</a>
      </li>

      <template v-for="page in numberOfPages">
        <li
          class="page-item"
          v-if="
            page < pageNumber + 2 && page > pageNumber - 2 && numberOfPages - page >= 2 && page >= 3
          "
          v-bind:class="{ active: page == pageNumber }"
          v-bind:key="page"
        >
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item" v-if="numberOfPages - pageNumber > 3">
        <a class="page-link disabled">...</a>
      </li>

      <template v-for="page in numberOfPages">
        <li
          class="page-item"
          v-if="numberOfPages - page < 2"
          v-bind:class="{ active: page == pageNumber }"
          v-bind:key="page"
        >
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(pageNumber + 1)" class="page-link">
          <img src="../../assets/images/icons/pagination/chevron-right.svg" />
        </a>
      </li>

      <li class="page-item" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(numberOfPages)" class="page-link" tabindex="-1">
          <img src="../../assets/images/icons/pagination/arrows-right.svg" />
        </a>
      </li>
    </ul>
    <ul v-if="numberOfPages > 1 && numberOfPages < 8" class="pagination">
      <li class="page-item" v-if="pageNumber > 1">
        <a v-on:click="functionName(1)" class="page-link" tabindex="-1">
          <img src="../../assets/images/icons/pagination/arrows-left.svg" />
        </a>
      </li>
      <li class="page-item" v-if="pageNumber > 1">
        <a v-on:click="functionName(pageNumber - 1)" class="page-link" tabindex="-1">
          <img src="../../assets/images/icons/pagination/chevron-left.svg" />
        </a>
      </li>
      <template v-for="page in numberOfPages" v-bind:key="page">
        <li class="page-item" v-bind:class="{ active: page == pageNumber }">
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(pageNumber + 1)" class="page-link">
          <img src="../../assets/images/icons/pagination/chevron-right.svg" />
        </a>
      </li>

      <li class="page-item" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(numberOfPages)" class="page-link" tabindex="-1">
          <img src="../../assets/images/icons/pagination/arrows-right.svg" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    numberOfPages: Number,
    pageNumber: Number,
    functionName: Function,
  },
};
</script>
<style scoped>
.page-item {
  margin-left: 6px;
  margin-right: 6px;
}
.page-link {
  background-color: transparent !important;
  border: 0px;
  color: var(--neutral-5);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
  cursor: pointer;

  font-size: 14px !important;
  font-weight: 400 !important;
}

.active > .page-link,
.page-link.active {
  background-color: var(--neutral-7) !important;
  color: white;
}

.page-link:hover {
  background-color: var(--neutral-2) !important;
  color: var(--neutral-5);
}
</style>
