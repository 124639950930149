<template>
  <div
    class="modal fade"
    id="selectProductModal"
    tabindex="-1"
    aria-labelledby="selectProductModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <img src="../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
          <h5 class="modal-title" id="selectProductModalLabel">Add products</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="name">Search Products</label>
            <input
              type="text"
              class="form-control"
              v-model="filter.SearchQuery"
              placeholder="Search"
              @keyup.enter="FilterBySearchQuery"
            />
          </div>
          <div class="table-responsive">
            <div class="row-table">
              <div
                class="row text-label-02 fw-semibold border-underneath h-100 mt-3 m-auto border-underneath pt-2 pb-2"
              >
                <div class="col-6">Product</div>
                <div class="col-2">Price</div>
                <div class="col">Quantity</div>
                <div class="col-auto"></div>
              </div>

              <div
                v-for="(product, index) in products"
                :key="index"
                class="row align-items-center h-100 mt-3 m-auto border-underneath pt-2 pb-2"
              >
                <div class="col-6 d-flex align-items-center">
                  <img
                    :src="$utils.GetImageFormat(product.ImgBase64)"
                    alt=""
                    class="me-3 product-img"
                  />
                  <div>
                    <div class="text-neutral-08 label-02 font-weight-bold">{{ product.Title }}</div>
                    <div
                      v-if="product.Description"
                      class="text-success-06 label-01"
                      :title="product.Description"
                    >
                      {{ $utils.Ellipsify(product.Description, 90) }}
                    </div>
                  </div>
                </div>
                <div class="col-2 paragraph-02">{{ product.RegularPrice }} RON</div>
                <div class="col">
                  <div class="row align-items-center">
                    <div class="col-auto pe-0">
                      <button
                        type="button"
                        class="button quantity-button"
                        :class="{ 'pointer-event-none': product.Quantity === 1 }"
                        @click="product.Quantity > 1 && product.Quantity--"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 10H15"
                            stroke="#3E4D52"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="col-3 text-center pe-0 ps-0">
                      {{ product.Quantity }}
                    </div>
                    <div class="col-auto ps-0">
                      <button
                        type="button"
                        class="button quantity-button"
                        @click="product.Quantity++"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 10H15"
                            stroke="#3E4D52"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 15V5"
                            stroke="#3E4D52"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="button quantity-button green"
                    @click="AddToOrder(product)"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 10H15"
                        stroke="#F5F6F9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 15V5"
                        stroke="#F5F6F9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="products.length == 0" class="mt-5 pt-5 text-center">
              <img
                src="../../../assets/images/not-found-img.png"
                style="max-width: 300px"
                class="w-100"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center set-mt">
          <!-- <button class="button btn-small btn-success-solid d-flex align-items-center pe-3"
            v-if="filter.PageNumber < pagination.TotalPageCount" @click="GetNextPage">Show more</button> -->
          <PaginationComponent
            :numberOfPages="pagination.TotalPageCount"
            :pageNumber="filter.PageNumber"
            :functionName="SelectPage"
          ></PaginationComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from '../../General/PaginationComponent.vue';

export default {
  name: 'SelectProductModal',
  emits: ['add-product'],
  components: {
    PaginationComponent,
  },
  data() {
    return {
      products: [],
      pagination: {},
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: 8,
      },
    };
  },
  methods: {
    AddToOrder(product) {
      this.$emit('add-product', product);
    },

    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetListedProducts();
    },
    OpenModal() {
      this.filter = {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: 8,
      };
      const searchQuery = {
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
        ...(this.filter.SearchByQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/Product/getListedProducts?${new URLSearchParams(searchQuery)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.products = this.products.map((item) => ({ ...item, Quantity: 1 }));
          this.pagination = response.data.PageDetails;
          // eslint-disable-next-line no-undef
          $('#selectProductModal').modal('show');
        });
    },
    GetListedProducts() {
      console.log(this.filter);
      const searchQuery = {
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/Product/getListedProducts?${new URLSearchParams(searchQuery)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.products = this.products.map((item) => ({ ...item, Quantity: 1 }));
          this.pagination = response.data.PageDetails;
        });
    },
    FilterBySearchQuery() {
      this.filter.PageNumber = 1;
      this.GetListedProducts();
    },
  },
};
</script>
<style scoped>
.set-mt div {
  margin-top: 0px !important;
  padding-bottom: 20px;
}

.table-responsive {
  overflow-x: hidden;
  height: 680px;
}
@media only screen and (max-width: 992px) {
  .table-responsive {
    overflow-x: auto;
  }
  .table-responsive .row-table {
    width: 835px;
  }
  .modal-dialog {
    max-width: 90%;
  }
}

.product-img {
  width: 44px;
  height: 41px;
}

.cursor-hover {
  cursor: pointer;
}

.label-02 {
  font-weight: 600;
}

.border-underneath {
  border-bottom: 1px solid #c3cccf;
}

.change-image-box {
  height: 136px;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
}

.change-image-box .delete-icon {
  cursor: pointer;
  background-color: white;
  border-radius: 2px;
  padding: 5px;
  position: absolute;
  top: 2px;
  right: 2px;
  transition: 0.2s;
}

.change-image-box .delete-icon:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.change-image-box img:first-child {
  border: 1px solid var(--neutral-3);
  height: 88px;
  width: 88px;
  object-fit: contain;
  border-radius: 8px;
}

.quantity-button {
  border: 1px solid var(--blacktint-2);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.quantity-button:hover {
  background-color: var(--neutral-2);
}

.green.quantity-button {
  background-color: var(--success-5);
  transition: 0.2s;
}

.green.quantity-button:hover {
  background-color: var(--success-6);
}
</style>
