<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="modal fade"
    id="editConfigurationModal"
    tabindex="-1"
    aria-labelledby="editConfigurationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          :validation-schema="schema"
          @submit="Submit"
          v-slot="{ errors }"
          ref="editConfigurationFormRef"
        >
          <div class="modal-header">
            <img src="../../../../assets/images/icons/edit-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="editConfigurationModalLabel">Edit configuration</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ClearModal()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="name">Name</label>
              <Field
                v-model="configuration.Name"
                type="text"
                class="form-control"
                id="name"
                name="name"
                :class="{ 'border-danger': errors.name }"
                placeholder="Name"
              />
              <ErrorMessage name="name" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="value">Value</label>
              <div class="input-group right">
                <Field
                v-model="configuration.Value"
                class="form-control"
                id="value"
                name="value"
                type="number"
                :class="{ 'border-danger': errors.value }"
                placeholder="Value"
              />
              <span class="input-group-text">RON</span>
            </div>
            <ErrorMessage name="value" class="error-message" />
            </div>
            <div class="mb-3">
              <label for="descriptionEdit">Category name:</label>
              <Field
                v-model="configuration.Description"
                class="form-control"
                id="descriptionEdit"
                name="descriptionEdit"
                type="text"
                placeholder="Category.."
                :class="{ 'border-danger': errors.descriptionEdit }"
              />
              <ErrorMessage name="descriptionEdit" class="error-message" />
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddConfigurationModal',
  emits: ['edit'],
  props: {
    configuration: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      if (!this.configuration.Value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.configuration.Value = 0;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.$emit('edit', this.configuration);
    },
    ClearModal() {
      this.$refs.editConfigurationFormRef.resetForm();
    },
  },
  computed: {
    schema() {
      return yup.object({
        name: yup
          .string()
          .required('Configuration name is required')
          .min(3, 'Configuration name must have at least 3 characters')
          .trim(),
        descriptionEdit: yup
          .string()
          .required('Configuration description is required')
          .min(3, 'Configuration description must have at least 3 characters')
          .trim(),
        value: yup
          .number()
          .min(0, 'Configuration value cannot be negative')
          .test(
            'is-decimal',
            'The value should be a decimal with maximum two digits after comma',
            (number) => {
              if (!number) {
                return true;
              }
              return /^\d+(\.\d{1,2})?$/.test(number);
            },
          )
          .transform((_, val) => (val === Number(val) ? val : 0)),
      });
    },
  },
};
</script>
