<template>
  <div class="position-relative">
    <img class="img-image" :src="$utils.GetImageFormat(image.ImgBase64)" alt="" />
    <img
      src="../../assets/images/icons/red-trash-16x16.svg"
      class="img-image-delete"
      @click="$emit('delete')"
    />
  </div>
</template>

<script>
export default {
  name: 'ProductGallery',
  props: {
    image: {},
  },
};
</script>

<style scoped>
.img-image-delete {
  position: absolute;
  right: 1px;
  top: 1px;
  cursor: pointer;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.img-image {
  object-fit: contain;
  width: 100%;
  height: 182px;
  border: 1px solid var(--blacktint-2);
  border-radius: 8px;
}
</style>
