<template>
  <span style="margin-left: 8px">
    <span v-if="!orderBy.includes(orderByName)">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.96665 4.47998L4.48663 2L2.00665 4.47998"
          stroke="#95A6AC"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.48663 14V2"
          stroke="#95A6AC"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.03333 11.52L11.5133 14L13.9933 11.52"
          stroke="#95A6AC"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5133 2V14"
          stroke="#95A6AC"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>

    <span v-if="orderBy === orderByName">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.96665 4.47998L4.48663 2L2.00665 4.47998"
          stroke="#3AAB14"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.48663 14V2"
          stroke="#3AAB14"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.03333 11.52L11.5133 14L13.9933 11.52"
          stroke="#3E4D52"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5133 2V14"
          stroke="#3E4D52"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>

    <span v-if="orderBy === orderByName + '_desc'">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.96665 4.47998L4.48663 2L2.00665 4.47998"
          stroke="#3E4D52"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.48663 14V2"
          stroke="#3E4D52"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.03333 11.52L11.5133 14L13.9933 11.52"
          stroke="#3AAB14"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5133 2V14"
          stroke="#3AAB14"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </span>
</template>

<script>
export default {
  name: 'not-found-img',
  props: {
    orderBy: String,
    orderByName: String,
  },
};
</script>
