import { createStore } from 'vuex';
import { auth } from './auth.module';

export default createStore({
  modules: {
    auth,
  },
  state: {
    globalPageSize: 20,
    loader: false,
    sidebar: '',
  },
  mutations: {
    toggle(state) {
      state.loader = !state.loader;
    },
    ChangePage(state, page) {
      state.sidebar = page;
    },
  },
  getters: {
    GetState(state) {
      return state.auth;
    },
    dateFormat: () => (dateString) => {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleString('ro', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
      });
      return formattedDate;
    },
  },
});
