<!-- eslint-disable no-undef -->
<template>
  <Form :validation-schema="schema" ref="productThumbnailRef" v-slot="{ errors }">
    <div class="page-header page-header-height">
      <div class="row align-items-center">
        <div class="col-lg mb-3 mb-lg-0">
          <div class="d-flex align-items-center">
            <svg
              class="page-header-img"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.66675 12V9.33329C2.66675 5.33329 5.33341 2.66663 9.33341 2.66663H22.6667C26.6667 2.66663 29.3334 5.33329 29.3334 9.33329V12"
                stroke="#1F2629"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.66675 20V22.6667C2.66675 26.6667 5.33341 29.3333 9.33341 29.3333H22.6667C26.6667 29.3333 29.3334 26.6667 29.3334 22.6667V20"
                stroke="#1F2629"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.93335 12.3467L16 16.44L23.0133 12.3734"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 23.6933V16.4266"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.3467 8.3867L10.0801 10.7601C9.12006 11.2934 8.32007 12.6401 8.32007 13.7467V18.2668C8.32007 19.3734 9.10672 20.7201 10.0801 21.2534L14.3467 23.6267C15.2534 24.1334 16.7467 24.1334 17.6667 23.6267L21.9334 21.2534C22.8934 20.7201 23.6934 19.3734 23.6934 18.2668V13.7467C23.6934 12.6401 22.9067 11.2934 21.9334 10.7601L17.6667 8.3867C16.7467 7.8667 15.2534 7.8667 14.3467 8.3867Z"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="heading-04 fw-semibold d-none d-sm-block">Products</div>
            <div class="heading-02 fw-semibold d-sm-none">Products</div>
          </div>
          <div class="label-01 fw-semibold mt-2 d-flex align-items-center">
            <router-link class="text-neutral-05" target="_blank" :to="{ name: 'Products' }">
              Products
            </router-link>
            <img
              src="../../assets/images/icons/arrow-right-12x12.svg"
              class="ms-2 ps-1 me-2 pe-1"
            />
            <span class="text-success-05"> Add product</span>
          </div>
        </div>
        <div class="col-sm-auto pe-0 mb-3 mb-sm-0">
          <button
            type="button"
            class="button btn-small btn-neutral-solid"
            style="width: 125px"
            @click="Submit(true)"
          >
            Save as draft
          </button>
        </div>
        <div class="col-sm-auto pe-0 mb-3 mb-sm-0">
          <!-- <button type="button" class="button btn-small btn-secondary-solid" style="width: 125px">
            Preview
          </button> -->
        </div>
        <div class="col-auto">
          <button
            type="button"
            class="button btn-small btn-success-solid"
            style="width: 125px"
            @click="Submit(false)"
          >
            Publish
          </button>
        </div>
      </div>
    </div>
    <div class="page-content">
      <h1
        class="heading-01 text-neutral-08 fw-semibold"
        style="margin-top: 40px; margin-bottom: 36px"
      >
        General information
      </h1>
      <div class="row">
        <div class="col col-lg-7 col-xl me-xxl-5 mb-3 mb-lg-0">
          <div class="pb-3 mb-1">
            <label for="title" class="label-02 text-neutral-07 fw-semibold mb-2">Title</label>
            <Field
              v-model="product.Title"
              type="text"
              name="title"
              id="title"
              class="form-control form-control-lg"
              placeholder="Name"
              :class="{ 'border-danger': errors.title }"
            />
            <ErrorMessage class="error-message" name="title" />
          </div>
          <div class="pb-3 mb-1">
            <label for="short-description" class="label-02 text-neutral-07 fw-semibold mb-2"
              >Short description</label
            >
            <Field
              v-model="product.ShortDescription"
              as="textarea"
              rows="3"
              cols="50"
              name="shortDescription"
              id="short-description"
              class="form-control form-control-lg"
              placeholder="Short Description.."
              :class="{ 'border-danger': errors.shortDescription }"
            ></Field>
            <ErrorMessage class="error-message" name="shortDescription" />
          </div>
          <label for="description" class="label-02 text-neutral-07 fw-semibold mb-2"
            >Description</label
          >
          <!-- <Field
            v-model="product.Description"
            as="textarea"
            rows="5"
            cols="50"
            name="description"
            id="description"
            class="form-control form-control-lg"
            placeholder="Description.."
            :class="{ 'border-danger': errors.description }"
          ></Field>
          <ErrorMessage class="error-message" name="description" /> -->

          <!--
          <Field v-slot="{ field }" name="startDate" id="startDate">
            <VueDatePicker
              v-bind="field"
              v-model="coupon.StartDate"
              placeholder="Select date"
              :enableTimePicker="false"
              autoApply
              class="custom-datepicker"
              :class="{ 'border-danger-datepicker': errors.startDate }"
              format="dd-MM-yyyy"
              id="orders-date"
            ></VueDatePicker>
          </Field>
          <ErrorMessage name="startDate" class="error-message" /> -->

          <div class="form-control form-control-lg p-0 product-content">
            <Field v-model="product.Description" name="description" id="description" class="d-none">
            </Field>
            <!-- <textarea name="bolg-area" id="bolg-area" class="form-control form-control-lg"  rows="5" v-model="product.Description"></textarea> -->
            <Editor :showTitle="false" v-model="product.Description" />
            <ErrorMessage name="description" class="error-message" />
          </div>
        </div>
        <div class="col-lg-5 col-xl">
          <label class="label-02 text-neutral-07 fw-semibold mb-2">Thumbnail</label>
          <div class="thumbnail card">
            <div class="card-body">
              <img :src="$utils.GetImageFormat(product.ImgBase64)" />
            </div>
            <div class="card-footer">
              <div class="row align-items-center">
                <div class="col paragraph-02 text-neutral-08">
                  Select thumbnail image <br />
                  for this product
                </div>
                <div class="col-auto d-flex justify-content-between">
                  <label
                    for="change-thumbnail"
                    style="width: 126px"
                    class="button btn-small btn-success-solid me-3 text-center"
                    >Upload image</label
                  >
                  <input
                    accept="image/*"
                    type="file"
                    id="change-thumbnail"
                    class="d-none"
                    @input="$utils.InputImage(product)"
                  />
                  <button
                    type="button"
                    class="button btn-small btn-neutral-outlined fw-semibold"
                    style="width: 126px"
                    @click="product.ImgBase64 = ''"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
  <!-- Navigation throw the product details -->
  <ul class="nav nav-tabs mt-5" id="addProduct" role="tablist" v-if="windowWidth > 1500">
    <li class="nav-item">
      <button
        class="nav-link active"
        id="details-tab"
        data-bs-toggle="tab"
        data-bs-target="#details"
        type="button"
      >
        Details
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="pricing-tab"
        data-bs-toggle="tab"
        data-bs-target="#pricing"
        type="button"
      >
        Pricing
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="inventory-tab"
        data-bs-toggle="tab"
        data-bs-target="#inventory"
        type="button"
      >
        Inventory
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="gallery-tab"
        data-bs-toggle="tab"
        data-bs-target="#gallery"
        type="button"
      >
        Gallery
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="package-tab"
        data-bs-toggle="tab"
        data-bs-target="#package"
        type="button"
        @click="selectedTab = 'package'"
      >
        Package
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="specifications-tab"
        data-bs-toggle="tab"
        data-bs-target="#specifications"
        type="button"
        @click="selectedTab = 'specifications'"
      >
        Specifications
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="fidelity-tab"
        data-bs-toggle="tab"
        data-bs-target="#fidelity"
        type="button"
      >
        Fidelity points
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="recomandation-tab"
        data-bs-toggle="tab"
        data-bs-target="#recomandation"
        type="button"
        @click="selectedTab = 'recomandation'"
      >
        Recomandations
      </button>
    </li>
    <li class="nav-item">
      <button class="nav-link" @click="OpenTab('configuration')" type="button">
        Configuration
      </button>
    </li>
  </ul>
  <div class="dropdown ms-5 ps-3" v-else>
    <button
      class="btn btn-outline-secondary btn-md dropdown-toggle text-start"
      type="button"
      id="productTabDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="width: 200px"
    >
      {{ selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1) }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="productTabDropdown" id="addProduct" role="tablist">
      <li class="dropdown-item" :class="{ active: selectedTab == 'details' }">
        <button
          class="nav-link"
          id="details-tab"
          data-bs-toggle="tab"
          data-bs-target="#details"
          type="button"
          @click="selectedTab = 'details'"
        >
          Details
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'pricing' }">
        <button
          class="nav-link"
          id="pricing-tab"
          data-bs-toggle="tab"
          data-bs-target="#pricing"
          type="button"
          @click="selectedTab = 'pricing'"
        >
          Pricing
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'inventory' }">
        <button
          class="nav-link"
          id="inventory-tab"
          data-bs-toggle="tab"
          data-bs-target="#inventory"
          type="button"
          @click="selectedTab = 'inventory'"
        >
          Inventory
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'gallery' }">
        <button
          class="nav-link"
          id="gallery-tab"
          data-bs-toggle="tab"
          data-bs-target="#gallery"
          type="button"
          @click="selectedTab = 'gallery'"
        >
          Gallery
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'package' }">
        <button
          class="nav-link"
          id="package-tab"
          data-bs-toggle="tab"
          data-bs-target="#package"
          type="button"
          @click="selectedTab = 'package'"
        >
          Package
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'specifications' }">
        <button
          class="nav-link"
          id="specifications-tab"
          data-bs-toggle="tab"
          data-bs-target="#specifications"
          type="button"
          @click="selectedTab = 'specifications'"
        >
          Specifications
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'fidelity points' }">
        <button
          class="nav-link"
          id="fidelity-tab"
          data-bs-toggle="tab"
          data-bs-target="#fidelity"
          type="button"
          @click="selectedTab = 'fidelity points'"
        >
          Fidelity points
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'recomandation' }">
        <button
          class="nav-link"
          id="recomandation-tab"
          data-bs-toggle="tab"
          data-bs-target="#recomandation"
          type="button"
          @click="selectedTab = 'recomandation'"
        >
          Recomandations
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'configuration' }">
        <button
          class="nav-link"
          @click="
            OpenTab('configuration');
            selectedTab = 'configuration';
          "
          type="button"
        >
          Configuration
        </button>
      </li>
    </ul>
  </div>
  <div class="page-content mt-4 pt-2 mb-5 pb-5">
    <div class="tab-content" id="addProductTabContent">
      <div id="details" class="tab-pane fade show active">
        <ProductDetailsComponent
          ref="productDetailsRef"
          @onChangeCategory="OnChangeCategory"
          @getCategories="GetListOfCategories"
          @getBrands="GetListOfBrands"
          @getLocations="GetListOfLocations"
          @getStatus="GetListOfStatuses"
          :product="product"
          :categoriesList="categoriesList"
          :brandsList="brandsList"
          :subcategoriesList="subcategoriesList"
          :locationsList="locationsList"
          :statusesList="statusesList"
        />
      </div>
      <div id="pricing" class="tab-pane fade">
        <ProductPricingComponent
          ref="productPricingRef"
          :product="product"
          @remove-location="RemoveLocation"
        />
      </div>
      <div id="inventory" class="tab-pane fade">
        <ProductInventoryComponent
          ref="productInventoryRef"
          :product="product"
          @remove-location="RemoveLocation"
        />
      </div>
      <div id="gallery" class="tab-pane fade">
        <div>
          <div class="row align-items-center">
            <div class="col">
              <div class="heading-01 fw-semibold text-neutral-08">Gallery</div>
              <small class="label-01 fw-normal text-neutral-04">
                {{ `${galleryList.length} / 8 images` }}
              </small>
            </div>
            <div class="col-auto d-flex align-items-center">
              <div class="label-01 fw-normal text-neutral-05">Max. 8 images</div>
              <div class="ms-4">
                <label
                  :class="[galleryList.length >= 8 ? 'btn-disabled-solid' : 'btn-success-solid']"
                  :title="[
                    galleryList.length >= 8
                      ? 'Maximum 8 images are allowed.'
                      : 'Choose your images',
                  ]"
                  for="upload-gallery"
                  style="width: 126px"
                  class="button btn-small text-center text-button-01"
                  >Upload image</label
                >
                <input
                  :disabled="galleryList.length >= 8"
                  accept="image/*"
                  type="file"
                  id="upload-gallery"
                  class="d-none"
                  multiple
                  @input="AddImageToGallery"
                />
              </div>
            </div>
          </div>
          <div class="row mt-4 pt-2">
            <div
              class="col-md-6 col-lg-4 col-xl-3 col-xxl-2 mb-4"
              v-for="gallery in galleryList"
              :key="gallery.Id"
            >
              <ProductGalleryComponent :image="gallery" @delete="DeleteImageGallery(gallery)" />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 col-xxl-2" v-if="galleryList.length < 8">
              <label for="upload-gallery" class="button btn-add-img-to-gallery">
                <div class="text-button-01 fw-semibold text-neutral-04 text-center">
                  <img src="../../assets/images/icons/add-linear-32x32.svg" class="mb-2" />
                  <div>Upload images</div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="package" class="tab-pane fade">
        <product-package-component
          :selectedTab="selectedTab"
          ref="productPackageRef"
          :packages="packages"
          :productId="0"
        />
      </div>
      <div id="specifications" class="tab-pane fade">
        <ProductSpecificationsComponent
          ref="productSpecificationsRef"
          :sections="specificationSections"
          :selectedTab="selectedTab"
        />
      </div>
      <div id="fidelity" class="tab-pane fade">
        <ProductFidelityPointsComponent ref="fidelityPointsRef" :fidelityPoints="fidelityPoints" />
      </div>
      <div id="recomandation" class="tab-pane fade">
        <ProductRecomandationComponent
          :selectedTab="selectedTab"
          :recomandations="recomandations"
          :productId="0"
        />
      </div>
      <!-- <div id="associated-specifications" class="tab-pane fade">
        <ProductAssociatedSpecs
          ref="associatedSpecRef"
          :associatedSpecifications="associatedSpecifications"
          @get="GetAssociatedSpecs(this.productIdParam)"
          @delete="DeleteAssociatedSpec"
          @add="AddAssociatedSpec"
        />
      </div> -->
      <div id="configuration" class="tab-pane fade"></div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import ProductDetailsComponent from '../../components/Product/ProductDetailsComponent.vue';
import ProductPricingComponent from '../../components/Product/ProductPricingComponent.vue';
import ProductInventoryComponent from '../../components/Product/ProductInventoryComponent.vue';
import ProductGalleryComponent from '../../components/Product/ProductGalleryComponent.vue';
import ProductSpecificationsComponent from '../../components/Product/ProductSpecifications/ProductSpecificationsComponent.vue';
import ProductFidelityPointsComponent from '../../components/Product/ProductFidelityPointsComponent.vue';
// import ProductAssociatedSpecs from '../../components/Product/ProductAssociatedSpecs/ProductAssociatedSpecsComponent.vue';
import ProductRecomandationComponent from '../../components/Product/ProductRecomandations/ProductRecomandationComponent.vue';
import ProductPackageComponent from '../../components/Product/ProductPackage/ProductPackageComponent.vue';
import Editor from '../../components/Blog/Editor.vue';

export default {
  name: 'AddProductView',
  components: {
    ProductDetailsComponent,
    ProductGalleryComponent,
    ProductSpecificationsComponent,
    ProductPricingComponent,
    ProductInventoryComponent,
    ProductFidelityPointsComponent,
    Form,
    Field,
    ErrorMessage,
    Editor,
    // ProductAssociatedSpecs,
    ProductRecomandationComponent,
    ProductPackageComponent,
  },
  data() {
    return {
      selectedTab: 'details',
      product: {
        InStock: true,
        IsAvailable: true,
      },
      packages: [],
      categoriesList: [],
      brandsList: [],
      subcategoriesList: [],
      galleryList: [],
      statusesList: [],
      saveImages: [],
      locationsList: [],
      specificationSections: [],
      recomandations: [],
      fidelityPoints: {
        Points: 0,
        MinimumProducts: 0,
        IsAvailable: false,
      },
      associatedSpecifications: [],
      windowWidth: 0,
    };
  },
  methods: {
    async OpenTab(name) {
      this.$swal
        .fire({
          title: `If you want to add ${name} to this product you must save it first?`,
          text: 'Do u want to go to the configuration page?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, take me there!',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.Submit(); // draft
          }
        });
    },
    async Submit(draft) {
      const validateThumbail = await this.$refs.productThumbnailRef
        .validate()
        .then((result) => result.valid);
      const validateDetails = await this.$refs.productDetailsRef.Submit();
      const validatePricing = await this.$refs.productPricingRef.Submit();
      const validateInventory = await this.$refs.productInventoryRef.Submit();
      const validateFidelityPoints = await this.$refs.fidelityPointsRef.Submit();
      const validateSpecifications = await this.$refs.productSpecificationsRef.Submit();
      const valid = [];
      // eslint-disable-next-line no-undef
      if (validateDetails === false) {
        valid.push('Details');
      }
      if (validatePricing === false) {
        valid.push('Pricing');
      }
      if (validateFidelityPoints === false) {
        valid.push('Fidelity');
      }
      if (validateInventory === false) {
        valid.push('Inventory');
      }
      if (validateSpecifications === false) {
        valid.push('Specifications');
      }
      if (validateThumbail === false) {
        valid.push('General information');
      }
      if (valid.length === 0) {
        this.SaveProduct(draft);
      } else {
        // eslint-disable-next-line no-undef
        $(`#${valid[0].toLocaleLowerCase()}-tab`).tab('show');
        this.$utils.toastNotify('warning', `Please check again ${valid}`);
      }
    },
    // Chnage the specification format to the format accepted by api
    SaveSpecificationSections() {
      const sectionsReturn = [];
      this.specificationSections.forEach((section) => {
        const specs = [];
        section.Specifications.forEach((specification) => {
          if (specification.ValueDetails) {
            specs.push({
              Name: specification.Name,
              SpecificationId: specification.SpecificationId,
              ValueId: specification.ValueDetails.Id,
            });
          } else {
            specs.push({
              Name: specification.Name,
              SpecificationId: specification.SpecificationId,
              Value: specification.Value,
            });
          }
          return specs;
        });
        sectionsReturn.push({
          Name: section.Name,
          Specifications: specs,
        });
      });
      return sectionsReturn;
    },
    AddImageToGallery(event) {
      const { files } = event.target;
      if (this.galleryList.length + files.length > 8) {
        this.$utils.toastNotify('warning', 'Maximum 8 images.');
        return;
      }
      for (let i = 0; i < files.length; i += 1) {
        if (!files[i].type.includes('image')) {
          this.$utils.toastNotify('warning', 'Only images are allowed.');
          return;
        }
        // 2 544 856 == 2.42mb
        if (files[i].size > 1055000) {
          this.$utils.toastNotify('warning', 'Image size should be maximum 1MB.');
          return;
        }
        const reader = new FileReader();
        reader.onload = () => {
          // eslint-disable-next-line vue/no-mutating-props
          const ImgBase64 = reader.result;
          this.galleryList.push({
            Name: 'Image',
            ImgBase64,
          });
        };
        if (files[i]) {
          reader.readAsDataURL(files[i]);
        }
      }
    },
    OnChangeCategory(id) {
      this.GetListOfSubcategories(id);
      this.product.Subcategory = null;
    },
    SaveProduct(draft) {
      //
      const Product = {
        Title: this.product.Title,
        ShortDescription: this.product.ShortDescription,
        // Description: this.product.Description,
        Description: this.$utils.sanitizeInput(this.product.Description),
        Tag: this.product.Tag,
        RegularPrice: this.product.RegularPrice || 0,
        SalePrice: this.product.SalePrice || 0,
        PurchasePrice: this.product.PurchasePrice || 0,
        Supplier: this.product.Supplier,
        Sku: this.product.Sku,
        Stock: this.product.Stock,
        Tva: this.product.Tva,
        Draft: draft,
        CategoryId: !this.product.Category ? null : this.product.Category.Id,
        SubcategoryId: !this.product.Subcategory ? null : this.product.Subcategory.Id,
        BrandId: !this.product.Brand ? null : this.product.Brand.Id,
        UpdatedBy: this.currentUser.UserId,
        ImgBase64: this.product.ImgBase64,
        StatusId: this.product.Status ? this.product.Status.Id : null, // tabel status
        OnSale: this.product.OnSale,
        InStock: this.product.InStock,
        IsAvailable: this.product.IsAvailable,
        ...(this.product.PriorityNumber ? { PriorityNumber: this.product.PriorityNumber } : ''),
      };
      const ProductLocations = this.product.Locations;

      const addPackage = [];

      this.packages.forEach((element) => {
        addPackage.push({
          RecommendedProductId: element.AssociatedProductId,
          DiscountProduct: element.DiscountProduct,
          DiscountAssociatedProduct: element.DiscountAssociatedProduct,
        });
      });
      const saveProduct = {
        Product,
        ProductGallery: this.galleryList,
        ProductLocations,
        Sections: this.SaveSpecificationSections(),
        FidelityPoints: this.fidelityPoints,
        Recomandations:
          this.recomandations.length > 0
            ? this.recomandations.map((product) => product.ProductId)
            : null,
        Packages: addPackage.length > 0 ? addPackage : null,
      };
      this.$axios
        .post('/api/Product/create', saveProduct)
        .then(() => {
          this.$utils.toastNotify('success', 'Your product has been added.');
          setTimeout(() => {
            this.$router.push({ name: 'Products' });
          }, '2500');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
          //
        });
      this.saveImages = [];
    },
    async GetListOfCategories() {
      await this.$axios
        .get('/api/categories/getListOfCategories')
        .then((response) => {
          this.categoriesList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfBrands() {
      this.$axios
        .get('/api/Brand/getListOfBrands')
        .then((response) => {
          this.brandsList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfSubcategories(id) {
      this.$axios
        .get(`/api/categories/subcategories/${id}`)
        .then((response) => {
          this.subcategoriesList = response.data;
          this.CheckSubcategory();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfStatuses() {
      this.$axios
        .get('/api/ProductStatus/getList')
        .then((response) => {
          this.statusesList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfLocations() {
      this.$axios
        .get('/api/Location/getListOfLocations')
        .then((response) => {
          this.locationsList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteImageGallery(prodGallery) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            const index = this.galleryList.findIndex(
              (element) => element.ImgBase64 === prodGallery.ImgBase64,
            );
            if (index !== -1) {
              this.galleryList.splice(index, 1); // Eliminăm elementul din array
            }
            this.$utils.toastNotify('success', 'Your file has been deleted.');
          }
        });
    },
    RemoveLocation(location) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            const index = this.product.Locations.indexOf(location);
            this.product.Locations[index] = {};
            this.product.Locations.splice(index, 1);
            this.$utils.toastNotify('success', 'Your location has been deleted.');
          }
        });
    },
    CheckSubcategory() {
      if (this.subcategoriesList === 'undefined') {
        this.subcategoriesList.Disabled = true;
      } else if (this.subcategoriesList.length === 0) {
        this.subcategoriesList.Disabled = true;
      } else {
        this.subcategoriesList.Disabled = false;
      }
    },
    PopulateLists() {
      this.GetListOfCategories();
      this.GetListOfBrands();
      this.GetListOfLocations();
      this.GetListOfStatuses();
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    schema() {
      return yup.object().shape(
        {
          title: yup
            .string()
            .required('Title is required.')
            .min(3, 'Product title must have at least 3 characters')
            .max(50, 'Product title must be under 50 characters')
            .trim(),
          description: yup
            .string()
            .nullable()
            .notRequired()
            .when('description', {
              is: (value) => value?.length,
              then: (rule) =>
                // eslint-disable-next-line implicit-arrow-linebreak
                rule.max(2000, 'Description must have maximum 2000 characters'),
            }),
          shortDescription: yup
            .string()
            .notRequired()
            .matches(/^(|.{5,500})$/, 'Between 5-500 characters')
            .trim(),
        },
        [
          // Add Cyclic deps here because when require itself
          ['description', 'description'],
        ],
      );
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    // this.PopulateLists();
    this.$store.commit('ChangePage', 'Products');
  },
};
</script>
<style scoped>
.page-header-height {
  height: 100%;
}
@media only screen and (min-width: 992px) {
  .page-header-height {
    height: 102px;
  }
}

.btn-add-img-to-gallery {
  object-fit: cover;
  width: 100%;
  height: 182px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.btn-add-img-to-gallery:hover {
  background-color: var(--neutral-2);
}

.thumbnail.card {
  border-radius: 16px;
  border-color: var(--blacktint-2);
}
.thumbnail.card .card-body img {
  max-width: 415px;
  width: 100%;
  height: 264px;
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid var(--blacktint-2);
  background-color: white;
}
.thumbnail.card .card-body {
  background-color: #f5f6f9;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 309px;
}
.thumbnail.card .card-footer {
  padding-top: 13px;
  padding-bottom: 13px;
  border-color: var(--blacktint-2);
  background-color: transparent;
}
</style>
