<!-- eslint-disable-->
<template>
  <div class="table-scroll">
    <table class="table">
      <!-- header -->
      <thead>
        <tr :class="{ active: orderByProp.includes('id') }">
          <th>
            <span @click="OrderBy('id')" class="pointer d-inline-flex">
              <span class="text">ID</span>
              <order-by-icon-component :orderBy="orderByProp" orderByName="id" />
            </span>
          </th>
          <th>
            <span class="text">Products</span>
          </th>
          <th>
            <span @click="OrderBy('quantity')" class="pointer d-inline-flex">
              <span class="text">Quantity</span>
              <order-by-icon-component :orderBy="orderByProp" orderByName="quantity" />
            </span>
          </th>
          <th>
            <span @click="OrderBy('date')" class="pointer d-inline-flex">
              <span class="text">Date</span>
              <order-by-icon-component :orderBy="orderByProp" orderByName="date" />
            </span>
          </th>
          <th>
            <span class="text">Status</span>
          </th>
          <th>
            <span class="text">Payment</span>
          </th>
          <th>
            <span @click="OrderBy('price')" class="pointer d-inline-flex">
              <span class="text">Total price</span>
              <order-by-icon-component :orderBy="orderByProp" orderByName="price" />
            </span>
          </th>
          <th>
            <div @click="OrderBy('pick_up_date')" class="pointer d-inline-flex align-items-center">
              <div class="text">Pick up date</div>
              <order-by-icon-component :orderBy="orderByProp" orderByName="pick_up_date" />
            </div>
          </th>
          <th>
            <span class="text">Fidelity points</span>
          </th>
          <th></th>
        </tr>
      </thead>
      <!-- body -->
      <tbody v-for="(order, index) in ordersProp" :key="index">
        <tr
          v-on:click="order.NumberOfProducts > 1 ? ColapseProducts(order) : ''"
          :class="[order.NumberOfProducts > 1 ? 'pointer' : '']"
        >
          <td class="no-border">
            <div>
              <!-- <span class="paragraph-01" :style="[order.NumberOfProducts > 1 ?  : '']"> -->
              <span class="paragraph-01"> #{{ order.Id }} </span>
            </div>
          </td>
          <td ref="parent-1">
            <div v-if="order.NumberOfProducts === 1" class="d-flex align-items-center">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img class="content-img" :src="$utils.GetImageFormat(order.ImgBase64)" />
                </div>
                <div class="col">
                  <div class="label-02 fw-semibold ms-3">{{ order.ProductName }}</div>
                  <div v-if="order.OrderConfigurations" class="label-02 ms-3 mt-2">
                    {{ order.OrderConfigurations }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="d-flex align-items-center">
              <img
                class="content-icon"
                src="../../assets/images/icons/multiple-products-order-24x24.svg"
              />
              <div class="title ms-3">Multiple product orders</div>
            </div>
          </td>
          <td ref="parent-2">
            {{ order.Quantity }}
          </td>
          <td ref="parent-3">
            <div class="float-start">
              <div class="purchase-date">
                <!-- {{ new Date(order.PurchaseDate).toLocaleString('ro-RO') }} -->
                <span>{{ FormatPurchaseDate(order.PurchaseDate).Date }}</span>
                <br />
                <small> {{ FormatPurchaseDate(order.PurchaseDate).Hour }}</small>
              </div>
            </div>
          </td>
          <td ref="parent-4">
            <div class="table-dropdown dropdown" v-on:click.stop>
              <button
                class="btn-dropdown-outlined dropdown-toggle"
                type="button"
                :id="`status-dropdown-${index}`"
                @click="ShowDropdown(index, order)"
                aria-expanded="false"
                data-bs-toggle="dropdown"
              >
                {{ order.Status }}
              </button>
              <ul class="dropdown-menu" :aria-labelledby="`status-dropdown-${index}`">
                <li
                  v-for="(status, index) in statuses"
                  :key="index"
                  @click="ChangeOrderStatus(order, status)"
                >
                  <div class="dropdown-item" :class="{ active: status === order.Status }">
                    {{ status }}
                  </div>
                  <hr
                    v-if="index < statuses.length - 1"
                    class="dropdown-divider me-3 ms-3 border-color-blacktint-1"
                  />
                </li>
              </ul>
            </div>
          </td>
          <td ref="parent-5">
            {{ order.Payment }}
          </td>

          <td ref="parent-6">
            <img
              src="../../assets/images/icons/table/cart-linear-20x20.svg"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="order.CouponCode"
            />
            <span class="ms-1">{{ $utils.FormatNumber(order.TotalPrice) }} RON</span>
          </td>
          <td ref="parent-7">{{ new Date(order.PickUpDate).toLocaleDateString('ro-RO') }}</td>
          <td ref="parent-8">
            {{ order.TotalFidelityPoints }}
          </td>
          <td class="no-border me-0 pe-0">
            <div class="float-end" v-on:click.stop>
              <router-link
                class="button table-btn btn-backgound"
                :to="{ name: 'OrderDetails', params: { orderIdParam: order.Id } }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M11.7466 2.66675L6.91992 7.50675"
                    stroke="#1F2629"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.2529 2.66675L25.0796 7.50675"
                    stroke="#3AAB14"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.66699 10.4667C2.66699 8.00005 3.98699 7.80005 5.62699 7.80005H26.3737C28.0137 7.80005 29.3337 8.00005 29.3337 10.4667C29.3337 13.3334 28.0137 13.1334 26.3737 13.1334H5.62699C3.98699 13.1334 2.66699 13.3334 2.66699 10.4667Z"
                    stroke="#1F2629"
                    stroke-width="1.5"
                  />
                  <path
                    d="M13.0137 18.6667V23.4001"
                    stroke="#3AAB14"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M19.1465 18.6667V23.4001"
                    stroke="#3AAB14"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.66699 13.3333L6.54699 24.8533C6.97366 27.4399 8.00033 29.3333 11.8137 29.3333H19.8537C24.0003 29.3333 24.6137 27.5199 25.0937 25.0133L27.3337 13.3333"
                    stroke="#3E4D52"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </router-link>
            </div>
          </td>
        </tr>
        <!-- collapse -->
        <tr v-if="order.IsColapsed">
          <td colspan="1" class="no-border"></td>
          <td colspan="8" class="collapse m-0 p-0 no-border" :id="order.Id">
            <table class="table m-0 p-0 products-table">
              <tbody class="m-0" v-for="product in order.Products" :key="product.Id">
                <tr>
                  <td :style="{ width: SetColumnChildWidth('parent-1') }">
                    <div class="d-flex align-items-center">
                      <div class="col-auto">
                        <img class="content-img" :src="$utils.GetImageFormat(product.ImgBase64)" />
                      </div>
                      <div class="col-auto">
                        <div class="label-02 fw-semibold ms-3">{{ product.Name }}</div>
                        <div v-if="product.OrderConfigurations" class="label-02 ms-3 mt-2">
                          {{ product.OrderConfigurations }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td :style="{ width: SetColumnChildWidth('parent-2') }">
                    {{ product.Quantity }}
                  </td>
                  <td :style="{ width: SetColumnChildWidth('parent-3') }"></td>
                  <td :style="{ width: SetColumnChildWidth('parent-4') }"></td>
                  <td :style="{ width: SetColumnChildWidth('parent-5') }"></td>
                  <td :style="{ width: SetColumnChildWidth('parent-6') }">
                    <span class="ps-4">{{ $utils.FormatNumber(product.Price) }} RON</span>
                  </td>
                  <td :style="{ width: SetColumnChildWidth('parent-7') }"></td>
                  <td :style="{ width: SetColumnChildWidth('parent-8') }">
                    {{ product.FidelityPoints }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td colspan="1" class="no-border"></td>
        </tr>
        <!-- colapse pagination -->
        <tr
          v-if="
            order.ProductsPagination &&
            order.ProductsPagination.TotalPageCount > 1 &&
            order.IsColapsed
          "
          class="m-0 p-0"
        >
          <td colspan="1" class="no-border"></td>
          <td colspan="8" class="m-0 p-0 no-border">
            <table class="table align-middle m-0 p-0">
              <tbody class="m-0">
                <tr class="m-0 p-0">
                  <td class="d-flex justify-content-center" style="border: 0">
                    <!-- <ColapsePagination
                      :pageSize="order.ProductsPagination.PageSize"
                      :totalItemCount="order.ProductsPagination.TotalItemCount"
                      :totalPageCount="order.ProductsPagination.TotalPageCount"
                      :pageNumber="order.Filter.PageNumber"
                      :element="order"
                      @select-page="SelectPageOrderProducts"
                    /> -->
                    <router-link
                      :to="{ name: 'OrderDetails', params: { orderIdParam: order.Id } }"
                      type="button"
                      class="button btn-small btn-success-solid d-flex align-items-center pe-3"
                      >See more</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td colspan="1" class="no-border"></td>
        </tr>
        <!-- bottom details -->
        <tr>
          <td colspan="1"></td>
          <td colspan="10" class="m-0 p-0">
            <table class="table m-0 p-0">
              <tbody>
                <tr>
                  <td colspan="3" style="width: 30%">
                    <div class="d-flex align-items-center">
                      <div class="col text-neutral-07 ps-0 pt-2">
                        <div class="paragraph-02 fw-semibold">{{ order.UserName }}</div>
                        <div class="row align-items-center">
                          <div class="col-auto text-button-01 fw-normal">
                            {{ order.UserPhoneNumber }}
                          </div>
                          <div class="col-auto">
                            <svg
                              width="6"
                              height="6"
                              viewBox="0 0 6 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="3" cy="3" r="3" fill="#3E4D52" />
                            </svg>
                          </div>
                          <div class="col-auto text-button-01 fw-normal">{{ order.UserEmail }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td colspan="2" style="width: 30%">
                    <div v-if="order.IsJuridicalPerson" class="text-neutral-07 ps-0 pt-2">
                      <div class="paragraph-02 fw-bold">{{ order.CompanyName }}</div>
                      <div class="row align-items-center">
                        <div class="col-auto text-button-01 fw-normal">{{ order.CompanyCIF }}</div>
                        <div class="col-auto">
                          <svg
                            width="6"
                            height="6"
                            viewBox="0 0 6 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3" cy="3" r="3" fill="#3E4D52" />
                          </svg>
                        </div>
                        <div class="col-auto text-button-01 fw-normal">
                          {{ order.CompanyTradeNumber }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td colspan="4">
                    <div v-if="order.Clarifications" class="text-neutral-07 ps-0 pt-2">
                      <div class="paragraph-02 fw-bold">Mentions</div>
                      <div :title="order.Clarifications" class="text-button-01 fw-normal mt-1">
                        {{ order.Clarifications }}
                      </div>
                    </div>
                    <div v-else class="text-neutral-07 ps-0 pt-2">
                      <div class="paragraph-02 fw-bold">Mentions</div>
                      <div class="text-button-01 fw-normal mt-1">No details</div>
                    </div>
                  </td>
                  <td colspan="1" class="me-0 pe-0">
                    <div class="float-end" v-on:click.stop>
                      <button
                        type="button"
                        class="button table-btn btn-backgound"
                        v-on:click="DeleteOrder(order.Id)"
                      >
                        <img src="../../assets/images/icons/table/trash-linear.svg" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="ordersProp.length == 0" class="mt-5 pt-5 text-center">
    <img src="../../assets/images/not-found-img.png" />
  </div>

  <pagination-component
    :numberOfPages="totalPageCount"
    :pageNumber="pageNumber"
    :functionName="SelectPage"
  ></pagination-component>
</template>

<script>
/* eslint-disable */
import moment from 'moment';

import ColapsePagination from '../General/ColapsePagination.vue';
import OrderByIconComponent from '../General/OrderByIconComponent.vue';
import PaginationComponent from '../General/PaginationComponent.vue';

export default {
  name: 'orders-list-component',
  props: {
    pageNumber: Number,
    totalPageCount: Number,
    orderByProp: String,
    ordersProp: Array,
    isClientProfile: {
      default: false,
    },
  },
  emits: [
    'delete-order',
    'collapse-products',
    'order-by',
    'select-page',
    'select-page-order-products',
  ],
  components: { OrderByIconComponent, ColapsePagination, PaginationComponent },
  data() {
    return {
      statuses: ['New', 'In progress', 'Ready', 'Finished', 'Not Picket UP', 'Canceled'],
    };
  },
  methods: {
    SetColumnChildWidth(parentRefName) {
      const parentWidth = this.$refs[parentRefName][0].offsetWidth;
      return parentWidth + 'px';
    },
    FormatPurchaseDate(date) {
      return {
        Hour: moment(date).format('HH:mm'),
        Date: moment(date).format('DD.MM.YYYY'),
      };
    },
    ShowDropdown(index) {
      $('.dropdown-toggle.show').each(function () {
        if ($(this).attr('id') != `status-dropdown-${index}`) $(this).dropdown('toggle');
      });
    },
    DeleteOrder(id) {
      this.$emit('delete-order', id);
    },
    ColapseProducts(order) {
      if (order.IsColapsed) {
        order.IsColapsed = false;
      } else {
        order.IsColapsed = true;
        this.$emit('collapse-products', order);
      }
    },
    OrderBy(orderBy) {
      $('.dropdown-toggle.show').dropdown('toggle');
      this.$emit('order-by', { orderBy, filter: 'ordersFilter' });
    },
    SelectPage(value) {
      $('.dropdown-toggle.show').dropdown('toggle');
      this.$emit('select-page', value);
    },
    SelectPageOrderProducts(order, value) {
      this.$emit('select-page-order-products', order, value);
    },
    ChangeOrderStatus(order, newStatus) {
      if (newStatus === 'Canceled') {
        this.$swal
          .fire({
            title: 'Warning!',
            text: 'Are you sure you want to change this order status to Canceled?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$axios
                .put(`/api/Orders/updateOrderStatus/${order.Id}?status=${newStatus}`)
                .then(() => {
                  order.Status = newStatus;
                  $('.dropdown-toggle.show').dropdown('toggle');
                  this.SelectPage(this.pageNumber);
                  this.$utils.toastNotify('success', 'Order status updated succesfully');
                })
                .catch(() => {
                  this.$utils.toastNotify('error', 'Something went wrong.');
                });
            }
          });
      } else {
        this.$axios
          .put(`/api/Orders/updateOrderStatus/${order.Id}?status=${newStatus}`)
          .then(() => {
            order.Status = newStatus;
            $('.dropdown-toggle.show').dropdown('toggle');
            this.SelectPage(this.pageNumber);
            this.$utils.toastNotify('success', 'Order status updated succesfully');
          })
          .catch(() => {
            this.$utils.toastNotify('error', 'Something went wrong.');
          });
      }
    },
  },
};
</script>

<style scoped>
.purchase-date {
  line-height: 17px;
  text-align: end;
}

.content-img {
  width: 44px;
  height: 41px;
  flex-shrink: 0;
}

.content-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.products-table {
  overflow-y: hidden;
}

.row-table .content.expandable:before {
  top: 25px;
}

@media (max-width: 1800px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .table {
    width: 1700px;
  }
  .products-table.table {
    width: 100%;
  }
}

.border-top-light {
  border-top: 1px solid var(--tints-blacktint-1, rgba(0, 0, 0, 0.1));
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.no-border {
  border-bottom-width: 0;
}

tbody:hover tr td table td {
  background-color: rgba(128, 128, 128, 0);
}

.btn-backgound {
  background-color: var(--neutral-1);
}

tbody:hover tr td {
  background-color: rgba(128, 128, 128, 0.055);
}

.table-dropdown .btn-dropdown-outlined {
  width: 180px;
  text-align: left;
}

td {
  vertical-align: middle;
}
</style>
