<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="modal fade"
    id="addLocationModal"
    tabindex="-1"
    aria-labelledby="addLocationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-width">
      <div class="modal-content">
        <Form
          ref="addLocationFormRef"
          @submit="Submit"
          :validation-schema="Schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addLocationModalLabel">Add location</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ClearModalValues"
            ></button>
          </div>
          <!--General info & Schedule-->
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-lg mb-5 mb-lg-0">
                <div class="label-03 fw-semibold text-neutral-07 mb-2">General infos</div>
                <div class="mb-3">
                  <label for="name">Name</label>
                  <Field
                    name="name"
                    type="text"
                    class="form-control"
                    v-model="location.Name"
                    :class="{ 'border-danger': errors.name }"
                    placeholder="Name"
                  ></Field>
                  <ErrorMessage name="name" class="error-message" />
                </div>
                <div class="mb-4">
                  <label for="description">Description</label>
                  <Field
                    name="description"
                    type="text"
                    rows="3"
                    as="textarea"
                    class="form-control"
                    v-model="location.Description"
                    :class="{ 'border-danger': errors.description }"
                    placeholder="Description"
                  >
                  </Field>
                  <ErrorMessage name="description" class="error-message" />
                </div>

                <div class="row justify-content-around align-items-center">
                  <div class="col-auto">
                    <div class="position-relative">
                      <img
                        v-if="location.ImgBase64"
                        :src="$utils.GetImageFormat(location.ImgBase64)"
                        class="location-img"
                      />
                      <img
                        v-else
                        src="../../../assets/images/small-no-location-img.png"
                        class="location-img"
                      />
                      <img
                        v-if="location.ImgBase64"
                        src="../../../assets/images/icons/red-trash-16x16.svg"
                        class="delete-icon"
                        @click="location.ImgBase64 = ''"
                      />
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="label-02 fw-semibold text-neutral-07 mb-2 text-center">
                      Choose image
                    </div>
                    <label
                      for="upload-image-add-location-img"
                      class="button btn-small btn-success-outlined text-success-06 text-button-01 pe-4 ps-4 text-center"
                      >Upload image</label
                    >
                    <input
                      id="upload-image-add-location-img"
                      accept="image/*"
                      type="file"
                      class="d-none"
                      @input="$utils.InputImage(location)"
                    />
                  </div>
                </div>
              </div>
              <div class="col d-flex" v-if="timeSchedules">
                <div class="d-block m-auto">
                  <div class="label-03 fw-semibold text-neutral-07 mb-3">Schedule</div>
                  <Form ref="timeScheduleFormRef" :validation-schema="ScheduleSchema">
                    <FieldArray name="time">
                      <div v-for="(schedule, index) in timeSchedules" :key="index">
                        <div
                          class="row mb-3 mb-sm-0"
                          :class="{ 'mb-sm-2': index < timeSchedules.length - 1 }"
                        >
                          <div class="col-sm-4 col-lg d-flex align-items-center mb-2 mb-sm-0">
                            <div>
                              <input
                                v-model="schedule.IsOpen"
                                class="switch-small switch-small--shadow"
                                :id="`add-location-${schedule.Day}`"
                                type="checkbox"
                                role="switch"
                              />
                              <label :for="`add-location-${schedule.Day}`" class="mb-0"></label>
                            </div>
                            <label
                              :for="`add-location-${schedule.Day}`"
                              class="ms-2 label-02 fw-semibold mb-0 pointer"
                              :class="[schedule.IsOpen ? 'text-neutral-07' : 'text-neutral-04']"
                            >
                              {{ schedule.Day }}
                            </label>
                          </div>
                          <div
                            class="col d-flex align-items-center justify-content-start justify-content-sm-end"
                          >
                            <div>
                              <Field
                                :name="`time[${index}].start`"
                                :disabled="!schedule.IsOpen"
                                :class="{
                                  'bg-neutral-02  border-transparent text-neutral-04':
                                    !schedule.IsOpen,
                                }"
                                v-model="schedule.StartSchedule"
                                class="form-control schedule"
                                type="time"
                                min="00:00"
                                max="23:59"
                                placeholder="09:00"
                                pattern="[0-9]{2}:[0-9]{2}"
                                required
                              ></Field>
                              <ErrorMessage :name="`time[${index}].start`" class="error-message" />
                            </div>
                            <img
                              src="../../../assets/images/icons/schedule-middle-line.png"
                              class="ps-2 pe-2"
                            />
                            <div>
                              <Field
                                :name="`time[${index}].end`"
                                :disabled="!schedule.IsOpen"
                                :class="{
                                  'bg-neutral-02 border-transparent text-neutral-04':
                                    !schedule.IsOpen,
                                }"
                                v-model="schedule.EndSchedule"
                                type="time"
                                class="form-control schedule"
                                min="00:00"
                                max="23:59"
                                placeholder="22:00"
                                pattern="[0-9]{2}:[0-9]{2}"
                                required
                              ></Field>
                              <ErrorMessage :name="`time[${index}].end`" class="error-message" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </FieldArray>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <!--Display switches-->
          <hr class="mt-0 mb-3" />
          <div class="row pt-2 pb-2 ms-0 me-0 justify-content-md-around">
            <div class="col-sm-auto d-flex align-items-center mb-3 mb-lg-0">
              <div>
                <input
                  v-model="location.DisplayWebsite"
                  class="switch-small switch-small--shadow"
                  id="add-location-display-website"
                  type="checkbox"
                  role="switch"
                />
                <label for="add-location-display-website" class="mb-0"></label>
              </div>
              <label
                class="ms-3 label-02 fw-semibold text-neutral-07 mb-0 pointer"
                for="add-location-display-website"
                >Display on website</label
              >
            </div>
            <div class="col-sm-auto d-flex align-items-center mb-3 mb-lg-0">
              <div>
                <input
                  v-model="location.DisplayProducts"
                  class="switch-small switch-small--shadow"
                  id="add-location-display-products"
                  type="checkbox"
                  role="switch"
                />
                <label for="add-location-display-products" class="mb-0"></label>
              </div>
              <label
                class="ms-3 label-02 fw-semibold text-neutral-07 pointer mb-0"
                for="add-location-display-products"
                >Display on products list</label
              >
            </div>
            <div class="col-sm-auto d-flex align-items-center mb-3 mb-lg-0">
              <div>
                <input
                  v-model="location.DisplayLocation"
                  class="switch-small switch-small--shadow"
                  id="add-location-display-location"
                  type="checkbox"
                  role="switch"
                />
                <label for="add-location-display-location" class="mb-0"></label>
              </div>
              <label
                class="ms-3 label-02 fw-semibold text-neutral-07 pointer mb-0"
                for="add-location-display-location"
                >Display location</label
              >
            </div>
            <div
              class="col-sm-auto d-flex align-items-center mb-3 mb-lg-0"
              v-if="locationContacts && locationContacts.length > 0"
            >
              <div>
                <input
                  v-model="location.DisplayContacts"
                  class="switch-small switch-small--shadow"
                  id="add-location-display-contacts"
                  type="checkbox"
                  role="switch"
                />
                <label for="add-location-display-contacts" class="mb-0"></label>
              </div>
              <label
                class="ms-3 label-02 fw-semibold text-neutral-07 mb-0 pointer"
                for="add-location-display-contacts"
                >Display contacts</label
              >
            </div>
          </div>
          <hr class="mt-3 mb-1" />
          <!--Location-->
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="label-03 fw-semibold text-neutral-07 mb-2">Location</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm mb-3 mb-sm-0">
                <label for="latitude">Latitude</label>
                <Field
                  name="latitude"
                  type="number"
                  class="form-control"
                  v-model="location.Latitude"
                  :class="{ 'border-danger': errors.latitude }"
                  placeholder="Enter latitude"
                ></Field>
                <ErrorMessage name="latitude" class="error-message" />
              </div>
              <div class="col">
                <label for="latitude">Longitutde</label>
                <Field
                  name="longitude"
                  type="number"
                  class="form-control"
                  v-model="location.Longitude"
                  :class="{ 'border-danger': errors.longitude }"
                  placeholder="Enter longitude"
                ></Field>
                <ErrorMessage name="longitude" class="error-message" />
              </div>
            </div>
          </div>
          <hr class="mt-1 mb-0" />
          <!--Contacts-->
          <div class="modal-body">
            <div class="label-03 fw-semibold text-neutral-07 mb-2">Contacts</div>
            <div
              class="row label-02 fw-semibold text-neutral-07"
              style="margin-bottom: 8px"
              v-if="locationContacts.length > 0"
            >
              <div class="col">Name</div>
              <div class="col">Email</div>
              <div class="col">Phone</div>
              <div class="col-1"></div>
            </div>
            <Form ref="addContactFormRef" :validation-schema="ContactSchema" v-slot="{ errors }">
              <LocationContactComponent
                :contactsLength="locationContacts.length"
                :errors="errors"
                v-for="(contact, index) in locationContacts"
                :contact="contact"
                :index="index"
                :key="index"
                @delete-contact="DeleteContact"
                @row-inputs-checking="CheckIfInputRowIsCompleted"
                @add-location-contact="AddContact"
              />
            </Form>

            <button type="button" @click="AddContact" class="button btn-add-contact mt-3">
              <div class="text-center d-flex align-items-center">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 10.5H15.5"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 15.5V5.5"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div class="text-button-02 fw-semibold text-neutral-06">Add contact</div>
              </div>
            </button>
          </div>
          <div class="modal-footer justify-content-center">
            <div class="col pe-2">
              <button
                type="button"
                class="button btn-medium btn-neutral-solid w-100"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div class="col ps-2">
              <button class="button btn-success-solid btn-medium w-100">Save</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
// eslint-disable-next-line object-curly-newline
import { Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';
import LocationContactComponent from '../LocationContactComponent.vue';

export default {
  name: 'AddLocationModal',
  props: {
    timeSchedules: {},
  },
  data() {
    return {
      location: {
        Name: '',
        Description: '',

        ImgBase64: '',
        DisplayWebsite: false,
        DisplayProducts: false,
      },
      locationContacts: [],
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    FieldArray,
    LocationContactComponent,
  },
  methods: {
    CheckIfInputRowIsCompleted(index) {
      console.log(this.locationContacts[index]);
    },
    AddContact() {
      this.$refs.addContactFormRef.validate().then((result) => {
        if (result.valid) {
          this.locationContacts.push({
            PhoneNumber: '',
            Email: '',
            Employee: '',
          });
        }
      });
    },
    DeleteContact(contact) {
      const index = this.locationContacts.findIndex(
        (element) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          element.Employee === contact.Employee &&
          element.Email === contact.Email &&
          element.PhoneNumber === contact.PhoneNumber,
      );
      if (index !== -1) {
        this.locationContacts.splice(index, 1);
      }
      if (this.locationContacts && this.locationContacts.length === 0) {
        this.location.DisplayContacts = false;
      }
    },
    ClearModalValues() {
      this.$refs.addLocationFormRef.resetForm();
      this.location.ImgBase64 = '';
      this.location.DisplayProducts = false;
      this.location.DisplayWebsite = false;
      this.location.DisplayContacts = false;
      this.location.DisplayLocation = false;
      this.locationContacts = [];
    },
    async Submit() {
      const scheduleIsValid = await this.$refs.timeScheduleFormRef.validate();
      const contactsAreValid = await this.$refs.addContactFormRef.validate();
      if (scheduleIsValid.valid && contactsAreValid.valid) {
        const saveLocation = {
          Location: this.location,
          TimeSchedules: this.timeSchedules,
          LocationContacts: this.locationContacts,
        };
        this.$emit('save', saveLocation);
      }
    },
  },
  computed: {
    Schema() {
      return yup.object({
        name: yup
          .string()
          .required('Name is required.')
          .min(3, 'Name must have minimum 3 characters')
          .max(200, 'Name must be under 200 characters')
          .trim(),
        description: yup
          .string()
          .required('Description is required.')
          .matches(/^(|.{3,600})$/, 'Between 3-600 characters')
          .trim(),
        latitude: yup
          .number('Please provide valid latitude informations')
          .required('Latitude is required')
          .transform((_, val) => (val === Number(val) ? val : null)),
        longitude: yup
          .number('Please provide valid longitutde informations')
          .required('Longitutde is required')
          .transform((_, val) => (val === Number(val) ? val : null)),
      });
    },
    ContactSchema() {
      return yup.object({
        contact: yup.array().of(
          yup.object().shape({
            email: yup
              .string()
              .required('Email address is required')
              .email('Email format is not valid')
              .trim(),
            phoneNumber: yup
              .string()
              .required('Phone number is required')
              .matches(/^([\\+]?40[-]?|[0])?[1-9][0-9]{8}$/, 'Phone number is not valid')
              .trim(),
            employee: yup
              .string()
              .required('Employee name is required')
              .min(3, 'Employee name must have minimum 3 characters')
              .trim(),
          }),
        ),
      });
    },
    ScheduleSchema() {
      return yup.object({
        time: yup.array().of(
          yup.object().shape({
            start: yup.string().required(' Start time cannot be empty'),
            end: yup
              .string()
              .required('end time cannot be empty ')
              // eslint-disable-next-line func-names
              .test('is-greater', 'Time span is invalid ', function (value) {
                const { start } = this.parent;
                return moment(value, 'HH:mm').isSameOrAfter(moment(start, 'HH:mm'));
              }),
          }),
        ),
      });
    },
  },
};
</script>
<style scoped>
.btn-add-contact {
  object-fit: cover;
  width: 100%;
  height: 53px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.btn-add-contact:hover {
  background-color: var(--neutral-2);
}

@media only screen and (max-width: 992px) {
  .modal-dialog-width {
    width: 100% !important;
    max-width: 100%;
  }
}

.form-control.schedule {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--neutral-5);
  border-color: var(--neutral-4);
  padding: 12px 8px;
  letter-spacing: 2px;
  width: 105px !important;
}

.form-control.schedule:disabled {
  background-color: transparent;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-outer-spin-button {
  display: none;
}
/* Style the AM/PM text for input[type="time"] in webkit browsers */
input[type='time']::-webkit-datetime-edit-ampm-field {
  font-size: 5px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 977px !important;
  }
}

.location-img {
  border: 1px solid var(--neutral-3);
  height: 110px;
  width: 160px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0px 10px 10px -10px #00000026;
}

.delete-icon {
  cursor: pointer;
  background-color: white;
  border-radius: 2px;
  padding: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  transition: 0.2s;
}

.change-image-box .delete-icon:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
</style>
