<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">General pricing</div>
  <Form ref="productPricingFormRef" :validation-schema="schema" v-slot="{ errors }">
    <div class="row">
      <div class="col-6 col-lg mb-3 mb-lg-0">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">Regular price</label>
        <div class="input-group right" :class="{ 'border-danger-input-group': errors.regularPrice }">
          <Field
            name="regularPrice"
            type="number"
            step="0.01"
            placeholder="0.00"
            class="form-control form-control-lg"
            v-model="product.RegularPrice"
            @change="VerifyOnSaleButton(product)"
          ></Field>
          <span class="input-group-text"> RON </span>
        </div>

        <ErrorMessage class="error-message" name="regularPrice" />
      </div>
      <div class="col-6 col-lg mb-3 mb-lg-0">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">Sale price</label>
        <div class="input-group right" :class="{ 'border-danger-input-group': errors.salePrice }">
          <Field
            name="salePrice"
            type="number"
            step="0.01"
            placeholder="0.00"
            class="form-control form-control-lg"
            v-model="product.SalePrice"
            @change="VerifyOnSaleButton(product)"
          ></Field>
          <span class="input-group-text"> RON </span>
        </div>
        <ErrorMessage class="error-message" name="salePrice" />
      </div>
      <div class="col">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">Purchase price</label>
        <div class="input-group right" :class="{ 'border-danger-input-group': errors.purchasePrice }">
          <Field
            name="purchasePrice"
            type="number"
            step="0.01"
            placeholder="0.00"
            class="form-control form-control-lg"
            v-model="product.PurchasePrice"
          ></Field>
          <span class="input-group-text"> RON </span>
        </div>
        <ErrorMessage class="error-message" name="purchasePrice" />
      </div>
      <div class="col">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">TVA</label>
        <div class="input-group right" :class="{ 'border-danger-input-group': errors.tva }">
          <Field
          name="tva"
          type="number"
          step="0.01"
          placeholder="0.00"
          class="form-control form-control-lg"
          v-model="product.Tva"
        ></Field>
        <span class="input-group-text"> % </span>
      </div>
      <ErrorMessage class="error-message" name="tva" />
      </div>
      <div class="col-2">
        <label class="label-02 fw-semibold text-neutral-07 mb-2">On sale</label>
        <div class="d-flex align-items-center mt-2 pt-1">
          <div>
            <input
              v-model="product.OnSale"
              class="switch-small switch-small--shadow"
              id="add-product-on-sale"
              type="checkbox"
              role="switch"
              :disabled="!(product.SalePrice > 0 && product.RegularPrice > 0)"
            />
            <label
              for="add-product-on-sale"
              class="mb-0"
              :class="{ 'c-not-allowed': !product.SalePrice }"
            ></label>
          </div>
          <div class="label-01 text-neutral-07 ms-3" v-if="!product.SalePrice">
            The product must <br class="d-xl-none" />
            have a sale price.
          </div>
        </div>
      </div>
    </div>
    <div v-if="product.Locations && product.Locations.length > 0">
      <hr
        style="
          margin-left: -67px;
          width: 87.6vw;
          margin-top: 38px;
          margin-bottom: 38px;
          color: var(--blacktint-4);
        "
      />
      <div class="heading-01 fw-semibold text-neutral-08 mb-4 pb-2">Locations pricing</div>
      <!--table-->
      <div class="table-responsive">
        <div
          class="row label-02 fw-semibold text-neutral-07 row-border-bottom ms-0 me-0"
          style="padding-bottom: 12px"
        >
          <div class="col ps-0">Location name</div>
          <div class="col-2">Regular price</div>
          <div class="col-2">Sale price</div>
          <div class="col-2">Purchase price</div>
          <div class="col-1">On sale</div>
          <!-- <div class="col-1 pe-0"></div> -->
        </div>
        <FieldArray name="location">
          <div
            class="row row-border-bottom pt-3 pb-3 ms-0 me-0 align-items-center"
            v-for="(location, index) in product.Locations"
            :key="index"
          >
            <div class="col ps-0">
              <div class="label-02 fw-semibold text-neutral-08 mb-1">
                {{ location.LocationName }}
              </div>
              <div class="paragraph-01 text-neutral-06">
                {{ location.LocationName }}
              </div>
            </div>
            <div class="col-2 pe-5">
              <div
                class="input-group right"
                :class="{ 'border-danger-input-group': errors[`location[${index}].regularPrice`] }"
              >
                <Field
                  :name="`location[${index}].regularPrice`"
                  type="number"
                  v-model="location.RegularPrice"
                  placeholder="0"
                  class="form-control form-control-lg"
                  @change="VerifyOnSaleButton(location)"
                ></Field>
                <span class="input-group-text">RON</span>
              </div>
              <ErrorMessage :name="`location[${index}].regularPrice`" class="error-message" />
            </div>
            <div class="col-2 pe-5">
              <div
                class="input-group right"
                :class="{ 'border-danger-input-group': errors[`location[${index}].salePrice`] }"
              >
                <Field
                  :name="`location[${index}].salePrice`"
                  type="number"
                  v-model="location.SalePrice"
                  placeholder="0"
                  class="form-control form-control-lg"
                  @change="VerifyOnSaleButton(location)"
                ></Field>
                <span class="input-group-text">RON</span>
              </div>
              <ErrorMessage :name="`location[${index}].salePrice`" class="error-message" />
            </div>
            <div class="col-2 pe-5">
              <div
                class="input-group right"
                :class="{ 'border-danger-input-group': errors[`location[${index}].purchasePrice`] }"
              >
                <Field
                  :name="`location[${index}].purchasePrice`"
                  type="number"
                  v-model="location.PurchasePrice"
                  placeholder="0"
                  class="form-control form-control-lg"
                ></Field>
                <span class="input-group-text">RON</span>
              </div>
              <ErrorMessage :name="`location[${index}].purchasePrice`" class="error-message" />
            </div>
            <div class="col-1">
              <input
                v-model="location.OnSale"
                class="switch-small switch-small--shadow"
                :id="`location-[${index}]-on-sale-switch`"
                type="checkbox"
                role="switch"
                :disabled="!(location.SalePrice > 0)"
              />
              <label :for="`location-[${index}]-on-sale-switch`" class="mb-0"></label>
            </div>
            <!-- <div class="col-1 pe-0">
            <button
              type="button"
              class="button table-btn float-end"
              @click="$emit('remove-location', location)"
            >
              <img src="../../assets/images/icons/table/trash-linear.svg" />
            </button>
          </div> -->
          </div>
        </FieldArray>
        <ErrorMessage name="location" class="error-message" />

        <div
          v-if="!product.Locations || product.Locations.length == 0"
          class="mt-5 pt-5 text-center"
        >
          <img src="../../assets/images/locations-should-be-added.svg" />
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
/* eslint-disable */
import { Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ProductPricing',
  emits: ['remove-location'],
  props: {
    product: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    FieldArray,
  },
  methods: {
    VerifyOnSaleButton(product) {
      // eslint-disable-next-line no-param-reassign
      if (product.SalePrice <= 0 || product.RegularPrice <= 0) {
        // eslint-disable-next-line no-param-reassign
        product.OnSale = false;
      }
    },
    Submit() {
      if (this.product.Locations) {
        this.product.Locations.forEach((location) => {
          location.RegularPrice =
            typeof location.RegularPrice === 'number' ? location.RegularPrice : null;
          location.SalePrice = typeof location.SalePrice === 'number' ? location.SalePrice : null;
          location.PurchasePrice =
            typeof location.PurchasePrice === 'number' ? location.PurchasePrice : null;
          location.Stock = typeof location.Stock === 'number' ? location.Stock : null;
        });
      }
      return this.$refs.productPricingFormRef
        ? this.$refs.productPricingFormRef.validate().then((result) => result.valid)
        : true;
    },
  },
  computed: {
    schema() {
      return yup.object({
        tva: yup
          .number()
          .positive('TVA cannot be negative')
          .test(
            'is-decimal',
            'The amount should be a decimal with maximum two digits after comma',
            (number) => {
              if (!number) {
                return true;
              }
              return /^\d+(\.\d{1,2})?$/.test(number);
            },
          )
          .notRequired()
          .transform((_, val) => (val === Number(val) ? val : null)),
        regularPrice: yup
          .number()
          .positive('Regular price cannot be negative')
          .test(
            'is-decimal',
            'The amount should be a decimal with maximum two digits after comma',
            (number) => {
              if (!number) {
                return true;
              }
              return /^\d+(\.\d{1,2})?$/.test(number);
            },
          )
          .test(
            'verify-sale-price',
            'To add the reduced price you must also add the original price',
            (number) => {
              const salePrice = this.product.SalePrice;
              if (!number && salePrice) return false;
              return true;
            },
          )
          .notRequired()
          .transform((_, val) => (val === Number(val) ? val : null)),
        salePrice: yup
          .number()
          .nullable()
          .min(0, 'Sale price cannot be negative')
          .test(
            'is-decimal',
            'The amount should be a decimal with maximum two digits after comma',
            (number) => {
              if (!number) {
                return true;
              }
              return /^\d+(\.\d{1,2})?$/.test(number);
            },
          )

          .test(
            'is-bigger-than-sale',
            'Sale price should be smaller than Regular price',
            (saleValue) => {
              const regularPrice = this.product.RegularPrice;
              if (!saleValue) return true;
              if (regularPrice && saleValue) {
                if (regularPrice <= saleValue) {
                  return false;
                }
              }
              return /^\d+(\.\d{1,2})?$/.test(saleValue);
            },
          )
          .transform((_, val) => (val === Number(val) ? val : null)),
        purchasePrice: yup
          .number()
          .nullable()
          .min(0, 'Purchase price cannot be negative')
          .test(
            'is-decimal',
            'The amount should be a decimal with maximum two digits after comma',
            (number) => {
              if (!number) {
                return true;
              }
              return /^\d+(\.\d{1,2})?$/.test(number);
            },
          )
          .transform((_, val) => (val === Number(val) ? val : null)),
        location: yup.array().of(
          yup.object().shape({
            regularPrice: yup
              .number()
              .positive('Regular price cannot be negative')
              .test(
                'is-decimal',
                'The amount should be a decimal with maximum two digits after comma',
                (number) => {
                  if (!number) {
                    return true;
                  }
                  return /^\d+(\.\d{1,2})?$/.test(number);
                },
              )
              .test(
                'verify-sale-price',
                'To add the reduced price you must also add the original price',
                (number, { parent }) => {
                  const salePrice = parent.salePrice;
                  if (!number && salePrice) return false;
                  return true;
                },
              )
              .notRequired()
              .transform((_, val) => (val === Number(val) ? val : null)),
            salePrice: yup
              .number()
              .nullable()
              .min(0, 'Sale price cannot be negative')
              .test(
                'is-decimal',
                'The amount should be a decimal with maximum two digits after comma',
                (number) => {
                  if (!number) {
                    return true;
                  }
                  return /^\d+(\.\d{1,2})?$/.test(number);
                },
              )
              .test(
                'is-decimal',
                'Sale price should be smaller than Regular price',
                (saleValue, { parent }) => {
                  const regularPrice = parent.regularPrice;
                  if (!saleValue) return true;
                  if (regularPrice && saleValue) {
                    if (regularPrice <= saleValue) {
                      return false;
                    }
                  }
                  return /^\d+(\.\d{1,2})?$/.test(saleValue);
                },
              )
              .transform((_, val) => (val === Number(val) ? val : null)),
            purchasePrice: yup
              .number()
              .nullable()
              .min(0, 'Purchase price cannot be negative')
              .test(
                'is-decimal',
                'The amount should be a decimal with maximum two digits after comma',
                (number) => {
                  if (!number) {
                    return true;
                  }
                  return /^\d+(\.\d{1,2})?$/.test(number);
                },
              )
              .transform((_, val) => (val === Number(val) ? val : null)),
          }),
        ),
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1200px) {
  .table-responsive .row {
    width: 1200px;
  }
}

.input-group-text {
  border-color: var(--bs-border-color) !important;
}
</style>
