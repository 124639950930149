<template>
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 12.5V19.1667"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 29.0467H7.92001C3.29335 29.0467 1.36001 25.74 3.60001 21.7L7.76001 14.2067L11.68 7.16667C14.0533 2.88666 17.9467 2.88666 20.32 7.16667L24.24 14.22L28.4 21.7133C30.64 25.7533 28.6933 29.06 24.08 29.06H16V29.0467Z"
              stroke="#3E4D52"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.9927 23.1667H16.0047"
              stroke="#3AAB14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">Stripe errors</div>
          <div class="heading-02 fw-semibold d-sm-none">Stripe errors</div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content" style="margin-top: 12px">
    <div class="row mb-3 filters-zone">
      <div class="col-lg-6 mb-3 mb-lg-0 col-xxl-3">
        <label for="stripe-errors-search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            v-model="filter.SearchQuery"
            @keyup.enter="GetStripeErrors(1)"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            id="stripe-errors-search"
          />
        </div>
      </div>
      <div class="col-lg-6 col-xxl-3">
        <label>Occurred date</label>
        <VueDatePicker
          v-model="filter.OccuredDate"
          placeholder="Select date"
          :enableTimePicker="false"
          autoApply
          class="custom-datepicker"
          name="Date"
          format="dd-MM-yyyy"
          id="stripe-occured-date"
          @update:modelValue="GetStripeErrors(1)"
        ></VueDatePicker>
      </div>
    </div>

    <div class="table-scroll">
      <table class="custom table mt-3">
        <thead>
          <tr valign="middle">
            <th width="100">Order ID</th>
            <th>User</th>
            <th width="100">Status</th>
            <th>Name</th>
            <th width="150">Occured date</th>
            <th width="100">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(error, index) in stripeErrors.Items" :key="index" valign="middle">
            <td>#{{ error.Id }}</td>
            <td>
              <div class="contact-user">
                <span>
                  <a
                    :href="`mailto:${error.UserEmail}`"
                    :title="`Send an email to: ${error.UserEmail}`"
                    >{{ error.UserEmail }}</a
                  >
                </span>
                <br />
                <small>
                  <a
                    :href="`tel:${error.UserPhoneNumber}`"
                    :title="`Call: ${error.UserPhoneNumber}`"
                    >{{ error.UserPhoneNumber }}</a
                  >
                </small>
              </div>
            </td>
            <td>
              {{ error.Status }}
            </td>
            <td>
              <div v-html="error.Name" style="max-width: 51vw; overflow-wrap: break-word"></div>
            </td>
            <td>
              <div class="float-start">
                <div class="updated-date">
                  <span>{{ FormatUpdatedDate(error.OccuredDate).Date }}</span>
                  <br />
                  <small> {{ FormatUpdatedDate(error.OccuredDate).Hour }}</small>
                </div>
              </div>
            </td>
            <td>{{ error.TotalPrice }} RON</td>
          </tr>
        </tbody>
      </table>
      <div v-if="stripeErrors.PageDetails.TotalItemCount == 0" class="mt-5 pt-5 pb-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="stripeErrors.PageDetails.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="GetStripeErrors"
    ></pagination-component>
  </div>
</template>

<script>
/*eslint-disable*/
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import moment from 'moment';

export default {
  name: 'OrderStripeErrors',
  components: {
    OrderByIconComponent,
    PaginationComponent,
  },
  data() {
    return {
      stripeErrors: {
        Items: [],
        PageDetails: {
          TotalItemCount: 0,
          TotalPageCount: 0,
          PageSize: 0,
        },
      },
      filter: {
        PageNumber: 1,
        SearchQuery: '',
        OccuredDate: '',
      },
    };
  },
  methods: {
    FormatUpdatedDate(date) {
      return {
        Hour: moment(date).format('HH:mm'),
        Date: moment(date).format('DD.MM.YYYY'),
      };
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetOrder();
    },
    GetStripeErrors(pageNumber) {
      const searchParams = new URLSearchParams({
        PageNumber: (this.filter.PageNumber = pageNumber || 1),
        PageSize: 20,
        ...(this.filter.OccuredDate
          ? { OccuredDate: moment(this.filter.OccuredDate).format('YYYY-MM-DD') }
          : ''),
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      });
      this.$axios
        .get(`/api/Orders/getStripeErrors?${searchParams}`)
        .then((response) => {
          this.stripeErrors = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
  created() {
    this.GetStripeErrors();
  },
};
</script>

<style scoped>
.contact-user a {
  color: var(--bs-body-color) !important;
  transition: 0.2s;
}
.contact-user a:hover {
  color: var(--success-5) !important;
}

.updated-date {
  line-height: 17px;
  text-align: end;
}

@media (max-width: 1430px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .table {
    width: 1200px;
  }
}
</style>
