<!-- eslint-disable vue/no-mutating-props -->
<!-- :style="[layer < 2 ? '' : `margin-left: ${layer}em`]" -->
<template>
  <div
    class="row-table m-0 position-relative"
    :class="{
      'table-border-bottom': layer > 1 && !configuration.IsExpanded,
    }"
    :style="[layer < 2 ? '' : `margin-left: ${layer}em !important`]"
  >
    <div
      :class="{
        'horizontal-border': configuration.IsExpanded && confingurationsLength,
      }"
    ></div>
    <div
      class="row content align-items-center m-0"
      :class="{
        expandable: configuration.SubconfigurationsCount > 0,
      }"
      v-on:click="
        configuration.SubconfigurationsCount > 0 ? GetSubconfigurations(configuration) : ''
      "
    >
      <!-- <div class="col-auto p-0" :style="`width: calc(${layer}em)`" v-if="layer > 1"></div> -->
      <div class="col ps-0">
        <span class="d-flex align-items-center">
          <img
            v-if="configuration.SubconfigurationsCount > 0"
            :src="DynamicChevron(configuration.IsExpanded)"
            class="me-3 vertical-arrow"
          />
          {{ configuration.Name }}
        </span>
      </div>
      <div class="col-3">
        {{ configuration.Description }}
      </div>
      <div v-if="layer === 1" class="col-2 text-end">
        {{  configuration.Value }} RON
      </div>
      <div v-else class="col-2 text-end">
        +{{ configuration.Value }} RON
      </div>
      <div class="col-auto pe-0" style="width: 20vw" v-on:click.stop>
        <div class="d-flex align-items-center justify-content-end">
          <button
            type="button"
            class="button table-btn me-2"
            style="padding: 11px"
            @click="$emit('add', configuration)"
          >
            <img src="../../../assets/images/icons/table/add-linear.svg" />
          </button>
          <button type="button" class="button table-btn me-2" @click="$emit('edit', configuration)">
            <img src="../../../assets/images/icons/table/edit-linear.svg" />
          </button>
          <button type="button" class="button table-btn" @click="$emit('delete', configuration.Id)">
            <img src="../../../assets/images/icons/table/trash-linear.svg" />
          </button>
        </div>
      </div>
      <div
        class="col-12 collapse expanded-subcategory pe-0"
        :id="`${configuration.Id}`"
        :class="`layer-${layer}`"
        v-on:click.stop
      >
        <div v-for="(subconfiguration, index) in configuration.Subconfigurations" :key="index">
          <ConfigurationComponent
            :configurationIndex="index"
            :confingurationsLength="configuration.Subconfigurations.length"
            :configuration="subconfiguration"
            :layer="layer + 1"
            :price="price"
            @add="EmitAddConfiguration"
            @edit="EmitEditConfiguration"
            @delete="EmitDeleteConfiguration"
          />
        </div>
        <div style="padding-left: 32px" class="pt-3" v-if="configuration.IsExpanded">
          <button
            type="button"
            @click="$emit('add', configuration)"
            class="button btn-add-specification"
          >
            <div class="text-center d-flex align-items-center">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 10.5H15.5"
                  stroke="#53666D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 15.5V5.5"
                  stroke="#53666D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div class="text-button-02 fw-semibold text-neutral-06">Add specification</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationComponent',
  emits: ['add', 'edit', 'delete'],
  props: {
    configuration: Object,
    layer: {
      type: Number,
      default: () => 0,
    },
    configurationIndex: Number,
    confingurationsLength: Number,
  },
  components: {
    // eslint-disable-next-line import/no-self-import
    ConfigurationComponent: () => import('./ConfigurationComponent.vue'),
  },
  data() {
    return {
      colapse: false,
      isLastChild: false,
    };
  },
  methods: {
    ValueIsNumber(value) {
      return typeof value === 'number';
    },

    DynamicChevron(IsExpanded) {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`../../../assets/images/icons/chevron-${
        IsExpanded ? 'expanded' : 'collapsed'
      }-${this.layer < 2 ? '24x24' : '20x20'}.svg`);
    },
    EmitAddConfiguration(selectedConfig) {
      this.$emit('add', selectedConfig);
    },
    EmitEditConfiguration(configurationToEdit) {
      if (this.configuration.Id === configurationToEdit.ConfigurationId) {
        this.$emit('edit', { configurationToEdit, config: this.configuration });
      } else {
        this.$emit('edit', configurationToEdit);
      }
    },
    EmitDeleteConfiguration(prop) {
      if (typeof prop === 'number') {
        this.$emit('delete', { id: prop, configuration: this.configuration });
      } else {
        this.$emit('delete', prop);
      }
    },
    GetSubconfigurations(configuration) {
      if (!configuration.IsExpanded) {
        this.$axios
          .get(`/api/ProductConfiguration/getSubconfigurations/${configuration.Id}`)
          .then((respone) => {
            // eslint-disable-next-line vue/no-mutating-props
            this.configuration.Subconfigurations = respone.data;
            // eslint-disable-next-line vue/no-mutating-props
            this.configuration.IsExpanded = true;
          });
        // eslint-disable-next-line no-param-reassign
      } else configuration.IsExpanded = false;
      // eslint-disable-next-line no-undef
      $(`#${this.configuration.Id}`).collapse('toggle');
    },
  },
};
</script>

<style scoped>
.btn-add-specification {
  object-fit: cover;
  width: 100%;
  height: 53px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.btn-add-specification:hover {
  background-color: var(--neutral-2);
}

.row-table .content {
  border-bottom: 0;
}

.row-table .content.expandable:before {
  content: ' ' !important;
}

.row-table .expanded-subcategory {
  padding-left: 0px;
}

.row-table .expanded-subcategory .category {
  margin-left: 50px;
}

.row-table .content.expandable {
  overflow-y: hidden;
}

.table-border-bottom {
  border-bottom: 1px solid var(--blacktint-1);
}

.display-custom-border:not(:nth-child(2)) .border-bottom-custom {
  border-bottom: 1px solid var(--blacktint-1);
  margin-left: 1px;
}

.row-table .content.expandable:has(> .collapse.show):after {
  height: 100%;
  position: absolute;
  border-left: 2px solid var(--blacktint-1);
  top: 60px;
  left: 10px;
  content: ' ';
}

.horizontal-border {
  position: absolute;
  border-bottom: 1px solid var(--blacktint-1) !important;
  bottom: 0px;
  right: 0;
  width: 99.5%;
}

.border-top {
  border-top: 1px solid var(--blacktint-1);
}

.row-table .content:hover {
  background-color: transparent;
}
</style>
