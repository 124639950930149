<template>
  <div class="sidebar">
    <router-link :to="{ name: 'Dashboard' }">
      <img class="logo" :src="require('@/assets/SidebarAssets/logo.png')" />
    </router-link>
    <div class="content content-scroll">
      <div v-for="(page, index) in pages" :key="index">
        <div class="section">{{ page.Category }}</div>
        <ul class="nav flex-column">
          <li class="nav-item" v-for="(subPage, indexSubpage) in page.Pages" :key="indexSubpage">
            <router-link :to="{ name: subPage.Name }">
              <img :src="require(`@/assets/images/pages-icons/${subPage.Icon}`)" />
              <div>{{ subPage.Label || subPage.Name }}</div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="section">Informations</div>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a href="/documentation.pdf">
            <img src="@/assets/images/pages-icons/document-text.svg" />
            <div>Documentation</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import pagesList from '../../utils/pages-list';

export default {
  name: 'Sidebar',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    pages() {
      return pagesList;
    },
  },
  unmounted() {
    window.removeEventListener('resize', this.myEventHandler);
  },
  methods: {
    readFile() {
      window.open('./documentation.pdf', '_blank'); // to open in new tab
    },
    logOut() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to log out?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Log out!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('auth/logout').then(() => {
              this.$router.push('/login');
            });
          }
        });
    },
    myEventHandler() {
      // const pageHeight = window.innerHeight - 100;
      const contentScroll = document.getElementsByClassName('content-scroll')[0];
      contentScroll.style.height = '85vh';
    },
  },
  mounted() {
    window.addEventListener('resize', this.myEventHandler);
  },
};
</script>

<style scoped>
.nav-item .router-link-exact-active img {
  filter: invert(50%) sepia(92%) saturate(480%) hue-rotate(60deg) brightness(90%) contrast(90%);
}

.nav-item .router-link-exact-active:before {
  content: ' ';
  width: 5px;
  height: 32px;
  background-color: var(--success-5);
  display: block;
  position: absolute;
  right: 0;
  top: -4px;
  border-radius: 3px 0px 0px 3px;
}

.nav-item img {
  margin-right: 12px;
  filter: invert(57%) sepia(13%) saturate(557%) hue-rotate(149deg) brightness(82%) contrast(89%);
}

.nav-item {
  padding: 0px 0px 4px 0px;

  margin-bottom: 12px;
  position: relative;
}

.nav {
  margin-top: 12px;
  margin-left: 32px;
}

.section {
  font-size: 12px;
  font-weight: 600;
  line-height: 13.5px;
  padding: 4px 12px;
  color: var(--neutral-6);
}

.content {
  margin-top: 30px;
}

@media only screen and (max-height: 990px) {
  .documentation {
    padding-top: 0px;
  }

  .content-scroll {
    height: 87vh;
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .content-scroll::-webkit-scrollbar-thumb:hover {
    background: var(--blacktint-1);
  }

  .content-scroll::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }

  /* Track */
  .content-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .content-scroll::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 3px;
  }

  /* Handle on hover */
  .content-scroll ::-webkit-scrollbar-thumb:hover {
    background: var(--blacktint-1);
  }
}

.logo {
  margin-top: 29px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--sidebar-width) - 30px);
}

.sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  background-color: #f5f6f9;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid var(--blacktint-1);
}

a svg {
  color: var(--neutral-5);
}

a div {
  margin-top: 3px;
}

a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: var(--neutral-5);
}

.router-link-exact-active svg {
  color: var(--success-5);
}

.router-link-exact-active {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: var(--neutral-9);
}

@media only screen and (min-height: 880px) {
  .documentation {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
</style>
