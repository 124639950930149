<template>
  <div class="page-header" style="height: 102px">
    <div class="row align-items-center">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 25.1466H22.9867C21.92 25.1466 20.9067 25.5599 20.16 26.3066L17.88 28.5599C16.84 29.5866 15.1467 29.5866 14.1067 28.5599L11.8267 26.3066C11.08 25.5599 10.0533 25.1466 9 25.1466H8C5.78667 25.1466 4 23.3733 4 21.1866V6.63989C4 4.45323 5.78667 2.67993 8 2.67993H24C26.2133 2.67993 28 4.45323 28 6.63989V21.1866C28 23.3599 26.2133 25.1466 24 25.1466Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.9998 13.3334C17.7155 13.3334 19.1064 11.9425 19.1064 10.2267C19.1064 8.51096 17.7155 7.12012 15.9998 7.12012C14.284 7.12012 12.8931 8.51096 12.8931 10.2267C12.8931 11.9425 14.284 13.3334 15.9998 13.3334Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3332 20.88C21.3332 18.48 18.9465 16.5334 15.9998 16.5334C13.0532 16.5334 10.6665 18.48 10.6665 20.88"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold  d-sm-none">{{ $route.name }}</div>
        </div>
        <div class="label-01 fw-semibold mt-2 d-flex align-items-center">
          <router-link class="text-neutral-05" :to="{ name: 'Clients' }"> Clients </router-link>
          <img src="../../assets/images/icons/arrow-right-12x12.svg" class="ms-2 ps-1 me-2 pe-1" />
          <span class="text-success-05"> {{ user.FirstName }} {{ user.LastName }}</span>
        </div>
      </div>
      <div class="col-auto">
        <button
        :disabled="!isAdmin"
          @click="GetEditUserDetails()"
          type="button"
          :class="{'button btn-small btn-neutral-solid d-flex align-items-center ps-4 pe-4':isAdmin,
          'button btn-small btn-disabled-solid d-flex align-items-center ps-4 pe-4': !isAdmin}"
        >
          Edit client
        </button>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="row" style="margin-top: 35px">
      <div class="col-xl-6 mb-3 mb-xl-0">
        <span class="text-neutral-05 label-01 text-uppercase fw-semibold">General details</span>
        <div class="box mt-2">
          <div class="row align-items-center">
            <div class="col col-xxl d-flex">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 7C16.5 9.48528 14.4853 11.5 12 11.5C9.51472 11.5 7.5 9.48528 7.5 7C7.5 4.51472 9.51472 2.5 12 2.5C14.4853 2.5 16.5 4.51472 16.5 7Z"
                  fill="#3E4D52"
                  stroke="#3E4D52"
                />
                <path
                  d="M3.41016 22C3.41016 18.222 7.17176 15 12.0002 15C16.8286 15 20.5902 18.222 20.5902 22H3.41016Z"
                  fill="#3E4D52"
                  stroke="#3E4D52"
                />
              </svg>
              <div class="heading-03 fw-semibold text-neutral-06 ms-2 ps-1">
                {{ user.FirstName }} {{ user.LastName }}
              </div>
            </div>
            <div class="col-auto align-items-bottom d-flex justify-content-end">
              <input
              :disabled="!isAdmin"
                id="switch-shadow-user-active"
                :class="{'switch-small switch-small--shadow': isAdmin,
                'switch-small switch-disabled': !isAdmin}"
                type="checkbox"
                :checked="user.IsBlocked"
                v-on:click="UpdateClientAccess(user.Id)"
                @click.prevent
              />
              <label for="switch-shadow-user-active"></label>
              <label for="switch-shadow-user-active" class="paragraph-02 ms-3 mb-0 pointer"
                >Active</label
              >
            </div>
          </div>
          <div class="row mt-4 mb-3 align-items-center">
            <div class="col col-xxl-9">
              <div class="row align-items-center">
                <div class="col-3 col-xxl text-neutral-07 label-03 fw-semibold">Email:</div>
                <div class="col-6">{{ user.Email || '-' }}</div>
              </div>
              <div class="row align-items-center mt-2 pt-1">
                <div class="col-3 col-xxl text-neutral-07 label-03 fw-semibold">Phone:</div>
                <div class="col-6">{{ user.PhoneNumber || '-' }}</div>
              </div>
            </div>
            <!-- <div class="col align-items-bottom d-none d-xxl-flex">
              <input
                id="switch-shadow-user-active"
                class="switch-small switch-small--shadow"
                type="checkbox"
                :checked="user.IsBlocked"
                v-on:click="UpdateClientAccess(user.Id)"
                @click.prevent
              />
              <label for="switch-shadow-user-active"></label>
              <span class="paragraph-02 ms-3">Active</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <span class="text-neutral-05 label-01 text-uppercase fw-semibold">Address details</span>
        <div class="box mt-2 pe-4">
          <div
            class="d-flex justify-content-between align-items-center"
            v-for="(location, index) in user.Locations"
            :key="index"
            :style="[index < user.Locations.length - 1 ? 'padding-bottom: 27px' : '']"
          >
            <div>
              <div class="text-neutral-07 label-03 fw-semibold mb-2">{{ location.Title }}</div>
              <div v-if="location.Country || location.State || location.City || location.Address">
                {{ location.Country }}
                <span v-if="location.State && location.Country">,</span>
                {{ location.State }}
                <span v-if="location.City && location.State">,</span>
                {{ location.City }}
                <span v-if="location.Address && location.City">,</span>
                {{ location.Address }}
              </div>
              <div v-else>No address</div>
            </div>
            <img v-if="isAdmin"
              src="../../assets/images/icons/edit-20x20.svg"
              @click="GetLocation(location.Id)"
              class="pointer"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="text-neutral-05 label-01 text-uppercase fw-semibold mt-4">Activity details</div>
    <div class="row mt-1">
      <div class="col-md-6 col-xl-3 mb-2 mb-xl-0">
        <div class="box mt-1 d-flex align-items-center justify-content-between ps-4 pe-4 pt-3 pb-3">
          <div class="d-flex align-items-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.6499 14.2999L11.2599 15.5499C11.4699 15.7599 11.9399 15.8598 12.2499 15.8598H14.2299C14.8499 15.8598 15.5299 15.3899 15.6899 14.7699L16.9399 10.9798C17.1999 10.2498 16.7299 9.62984 15.9499 9.62984H13.8699C13.5599 9.62984 13.2999 9.36984 13.3499 9.00984L13.6099 7.34981C13.7099 6.87981 13.3999 6.35979 12.9299 6.20979C12.5099 6.04979 11.9899 6.25978 11.7899 6.56978L9.65991 9.73982"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-miterlimit="10"
              />
              <path
                d="M7 14.2998V9.20972C7 8.47972 7.30999 8.21973 8.03999 8.21973H8.56C9.29 8.21973 9.60001 8.47972 9.60001 9.20972V14.2998C9.60001 15.0298 9.29 15.2898 8.56 15.2898H8.03999C7.30999 15.2898 7 15.0298 7 14.2998Z"
                stroke="#3E4D52"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 19.3597H17.24C16.44 19.3597 15.68 19.6697 15.12 20.2297L13.41 21.9198C12.63 22.6898 11.36 22.6898 10.58 21.9198L8.87 20.2297C8.31 19.6697 7.54 19.3597 6.75 19.3597H6C4.34 19.3597 3 18.0298 3 16.3898V5.47974C3 3.83974 4.34 2.50977 6 2.50977H18C19.66 2.50977 21 3.83974 21 5.47974V16.3898C21 18.0198 19.66 19.3597 18 19.3597Z"
                stroke="#3E4D52"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="label-03 text-success-05 fw-semibold ms-3">Reviews</div>
          </div>
          <div class="heading-06 fw-normal text-neutral-09">{{ user.ReviewsCount }}</div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3 mb-2 mb-xl-0">
        <div class="box mt-1 d-flex align-items-center justify-content-between ps-4 pe-4 pt-3 pb-3">
          <div class="d-flex align-items-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2.5H3.74001C4.82001 2.5 5.67 3.43 5.58 4.5L4.75 14.46C4.61 16.09 5.89999 17.49 7.53999 17.49H18.19C19.63 17.49 20.89 16.31 21 14.88L21.54 7.38C21.66 5.72 20.4 4.37 18.73 4.37H5.82001"
                stroke="#3E4D52"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.25 22.5C16.9404 22.5 17.5 21.9404 17.5 21.25C17.5 20.5596 16.9404 20 16.25 20C15.5596 20 15 20.5596 15 21.25C15 21.9404 15.5596 22.5 16.25 22.5Z"
                stroke="#3E4D52"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.25 22.5C8.94036 22.5 9.5 21.9404 9.5 21.25C9.5 20.5596 8.94036 20 8.25 20C7.55964 20 7 20.5596 7 21.25C7 21.9404 7.55964 22.5 8.25 22.5Z"
                stroke="#3E4D52"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.6001 8.2998H18.0001"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="label-03 text-success-05 fw-semibold ms-3">Orders</div>
          </div>
          <div class="heading-06 fw-normal text-neutral-09">{{ user.OrdersCount }}</div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3 mb-2 mb-md-0">
        <div class="box mt-1 d-flex align-items-center justify-content-between ps-4 pe-4 pt-3 pb-3">
          <div class="d-flex align-items-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 19.7143C2 21.5714 2.92857 22.5 4.78571 22.5H12.2143C14.0714 22.5 15 21.5714 15 19.7143V12.2857C15 10.4286 14.0714 9.5 12.2143 9.5H4.78571C2.92857 9.5 2 10.4286 2 12.2857V19.7143Z"
                stroke="#3E4D52"
                stroke-width="1.5"
              />
              <path
                d="M18.2143 15.5C20.0714 15.5 21 14.5714 21 12.7143V5.28571C21 3.42857 20.0714 2.5 18.2143 2.5H10.7857C8.92857 2.5 8 3.42857 8 5.28571V6.5"
                stroke="#3E4D52"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M5 13.5H12"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 6.5H18"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="label-03 text-success-05 fw-semibold ms-3">Products bought</div>
          </div>
          <div class="heading-06 fw-normal text-neutral-09">{{ user.ProductsBought }}</div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="box mt-1 d-flex align-items-center justify-content-between ps-4 pe-4 pt-3 pb-3">
          <div class="d-flex align-items-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12.5" r="10" stroke="#3E4D52" stroke-width="1.5" />
              <path
                d="M11.0866 6.54906C11.439 5.75857 12.561 5.75857 12.9134 6.54906L13.9752 8.93129C14.1206 9.25732 14.4287 9.48117 14.7837 9.51863L17.3774 9.79239C18.2381 9.88323 18.5848 10.9503 17.9419 11.5297L16.0044 13.2758C15.7393 13.5147 15.6216 13.8769 15.6956 14.2261L16.2368 16.7775C16.4164 17.6242 15.5086 18.2837 14.7589 17.8513L12.4996 16.5482C12.1904 16.3698 11.8096 16.3698 11.5004 16.5482L9.24106 17.8513C8.49135 18.2837 7.58362 17.6242 7.76319 16.7775L8.30436 14.2261C8.37842 13.8769 8.26074 13.5147 7.99557 13.2758L6.05807 11.5297C5.41516 10.9503 5.76188 9.88323 6.62256 9.79239L9.21634 9.51863C9.57133 9.48117 9.87942 9.25732 10.0248 8.93129L11.0866 6.54906Z"
                stroke="#3AAB14"
                stroke-width="1.5"
              />
            </svg>

            <div class="label-03 text-success-05 fw-semibold ms-3">Fidelity Points</div>
          </div>
          <div class="heading-06 fw-normal text-neutral-09">{{ user.FidelityPoints }}</div>
        </div>
      </div>
    </div>
  </div>
  <ul class="nav nav-tabs" id="clientsProfileTab" role="tablist" style="margin-top: 37px">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="reviews-tab"
        data-bs-toggle="tab"
        data-bs-target="#reviews"
        type="button"
        role="tab"
        aria-controls="reviews"
        aria-selected="true"
        @click="OpenTabById('reviews-tab', 'GetUserReviews')"
      >
        Reviews
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="orders-tab"
        data-bs-toggle="tab"
        data-bs-target="#orders"
        type="button"
        role="tab"
        aria-controls="orders"
        aria-selected="false"
        @click="OpenTabById('orders-tab', 'GetUserOrders')"
      >
        Orders
      </button>
    </li>
  </ul>
  <div class="page-content">
    <div class="tab-content" id="clientsProfileTab">
      <div
        class="tab-pane fade show active"
        id="reviews"
        role="tabpanel"
        aria-labelledby="reviews-tab"
      >
        <UserReviewsComponent
          :reviews="reviews"
          :pagination="reviewsPagination"
          :filter="reviewFilter"
          @get="GetUserReviews"
          @delete="DeleteReview"
          @update="UpdateReview"
          :key="reviews"
        />
      </div>
      <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
        <div class="row mb-3">
          <div class="col-md mb-3 mb-md-0 col-xxl-3">
            <div class="input-group left">
              <span class="input-group-text">
                <img src="../../assets/images/icons/search-normal-20x20.svg" />
              </span>
              <input
                id="search"
                v-model="ordersFilter.SearchQuery"
                @keyup.enter="GetUserOrders"
                type="text"
                class="search form-control"
                placeholder="Search in..."
              />
            </div>
          </div>
          <div class="col col-xxl-3">
            <VueDatePicker
              v-model="ordersFilter.Date"
              placeholder="Select date"
              :enableTimePicker="false"
              autoApply
              class="custom-datepicker"
              name="Date"
              format="dd-MM-yyyy"
              id="client-profile-date"
              @update:modelValue="GetUserOrders()"
            ></VueDatePicker>
          </div>
        </div>
        <OrdersClientListComponent
          :orderByProp="ordersFilter.OrderBy"
          :ordersProp="orders"
          :pageNumber="ordersFilter.PageNumber"
          :totalPageCount="ordersPagination.TotalPageCount"
          @delete-order="DeleteOrder"
          @collapse-products="ColapseOrderProducts"
          @order-by="OrderBy"
          @select-page="SelectPageOrderProducts"
          :is-client-profile="true"
        />
      </div>
    </div>
  </div>

  <EditUserLocationModal
    ref="editLocationModalRef"
    :location="selectedLocation"
    @edit="EditUserLocation"
  />
  <EditUserModal :user="selectedUser" @edit="UpdateUser" />
</template>

<script>
import authHeader from '../../services/auth-header';
import EditUserLocationModal from '../../components/Users/Modals/EditUserLocationModal.vue';
import EditUserModal from '../../components/Clients/ClientModals/EditClientStandardModal.vue';
import UserReviewsComponent from '../../components/Users/UserReviewsComponent.vue';
import OrdersClientListComponent from '../../components/Orders/OrdersClientListComponent.vue';

export default {
  name: 'ClientProfile',
  props: {
    clientIdParam: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: {},
      reviews: [],
      orders: [],
      selectedTab: 'review',
      selectedLocation: {},
      selectedUser: {},
      ordersPagination: {},
      reviewsPagination: {},
      isAdmin: false,
      reviewFilter: {
        SearchQuery: '',
        Date: '',
        OrderBy: 'date_desc',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      ordersFilter: {
        SearchQuery: '',
        Date: '',
        OrderBy: 'date_desc',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
    };
  },
  components: {
    EditUserLocationModal,
    EditUserModal,
    UserReviewsComponent,
    OrdersClientListComponent,
  },
  methods: {
    OrderBy(params) {
      if (!this[params.filter].OrderBy.includes('_desc')) {
        this[params.filter].OrderBy = `${params.orderBy}_desc`;
      } else {
        this[params.filter].OrderBy = params.orderBy;
      }
      this[params.filter].PageNumber = 1;
      this.GetUserOrders();
    },
    SelectPageOrderProducts(nr) {
      this.ordersFilter.PageNumber = nr;
      this.GetUserOrders();
    },
    GetEditUserDetails() {
      this.$axios
        .get(`/api/User/getUser/${this.clientIdParam}`)
        .then((response) => {
          this.selectedUser = response.data;
          // eslint-disable-next-line no-undef
          $('#editUserModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    UpdateUser(user) {
      this.$axios
        .put('/api/User/updateUser', user)
        .then(() => {
          this.$utils.toastNotify('success', 'User updated succesfully.');
          this.GetUserDetails();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
      // eslint-disable-next-line no-undef
      $('#editUserModal').modal('hide');
    },
    EditUserLocation(location) {
      this.$axios
        .put('/api/User/updateUserLocation', location)
        .then(() => {
          this.$utils.toastNotify('success', 'Location updated succesfuly.');
          this.GetUserDetails();
          // eslint-disable-next-line no-undef
          $('#editUserLocationModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
          // eslint-disable-next-line no-undef
          $('#editUserLocationModal').modal('hide');
        });
    },
    GetLocation(id) {
      this.$refs.editLocationModalRef.ClearModal();
      this.$axios
        .get(`/api/User/getLocation/${id}`)
        .then((response) => {
          this.selectedLocation = response.data;
          // eslint-disable-next-line no-undef
          $('#editUserLocationModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    OpenTabById(id, functionName) {
      // eslint-disable-next-line no-unused-expressions
      this[functionName]();
      // eslint-disable-next-line no-undef
      $(`#${id}`).tab('show');
    },
    GetOrderProducts(order) {
      const searchParams = new URLSearchParams(order.Filter);
      this.$axios
        .get(`/api/Orders/getOrderProducts/${order.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          order.Products = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          order.ProductsPagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    ColapseOrderProducts(order) {
      const searchParams = new URLSearchParams(order.Filter);
      this.$axios
        .get(`/api/Orders/getOrderProducts/${order.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          order.Products = response.data.Items;
          // eslint-disable-next-line no-undef
          $(`#${order.Id}`).collapse('toggle');
          // eslint-disable-next-line no-param-reassign
          order.Collapse = order.Collapse == null ? true : !order.Collapse;
          // eslint-disable-next-line no-param-reassign
          order.ProductsPagination = response.data.PageDetails;
        });
    },

    GetUserOrders() {
      this.ordersFilter.Date = this.ordersFilter.Date
        ? new Date(this.ordersFilter.Date).toLocaleDateString('en-US')
        : '';
      const searchParams = new URLSearchParams(this.ordersFilter);
      this.$axios
        .get(`/api/Orders/getUserOrders/${this.clientIdParam}?${searchParams}`)
        .then((response) => {
          this.ordersPagination = response.data.PageDetails;
          this.orders = response.data.Items;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    GetUserReviews() {
      this.reviewFilter.Date = this.reviewFilter.Date
        ? new Date(this.reviewFilter.Date).toLocaleDateString('en-US')
        : '';
      const searchParams = new URLSearchParams(this.reviewFilter);
      this.$axios
        .get(`/api/Reviews/getUserReviews/${this.clientIdParam}?${searchParams}`)
        .then((response) => {
          this.reviewsPagination = response.data.PageDetails;
          this.reviews = response.data.Items;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    GetUserDetails() {
      this.$axios
        .get(`/api/User/getUserDetails/${this.clientIdParam}`)
        .then((response) => {
          this.user = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    ChangePassword() {
      this.$axios
        .post('/api/Auth/forgotPassword', this.user, { headers: authHeader() })
        .then(() => {
          this.$utils.toastNotify('success', 'Please check your email for password reset instructions');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteReview(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Reviews/delete/${id}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Review deleted succesesfully.');
                this.GetUserReviews();
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },

    DeleteOrder(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Orders/deleteOrder/${id}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Review deleted succesesfully.');
                this.GetUserDetails();
                this.GetUserOrders();
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },

    UpdateReview(review) {
      const self = this;
      this.$swal
        .fire({
          title: 'Warning',
          text: 'Are you sure you want to change this property?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.value) {
            self.$axios
              .put(`/api/Reviews/update/${review.Id}`, review)
              .then((response) => {
                self.GetUserReviews();
                self.$swal.fire('Success!', `${response.data}`, 'success');
              })
              .catch(() => {
                self.$swal.fire('Error', 'Something went wrong.', 'error');
              });
          }
        });
    },

    UpdateClientAccess(id) {
      const self = this;
      this.$swal
        .fire({
          title: 'Warning',
          text: 'Are you sure you want to change this property?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.value) {
            self.$axios
              .put(`/api/User/changeUserAccess/${id}`, self.$store.state.auth.user)
              .then((response) => {
                self.GetUserDetails();
                self.$swal.fire('Success!', `${response.data}`, 'success');
              })
              .catch(() => {
                self.$swal.fire('Error', 'Something went wrong.', 'error');
              });
          }
        });
    },
    GetUserRole() {
      this.$axios
        .get('/api/Role/isUserAuthorized')
        .then((response) => {
          this.isAdmin = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
  created() {
    this.GetUserDetails();
    this.GetUserReviews();
    this.GetUserRole();
  },
};
</script>
<style scoped>
.box {
  border-radius: 8px;
  padding: 19px 14px;
  border: 1px solid var(--blacktint-2);
}
</style>
