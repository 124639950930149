<template>
  <div class="row m-0" style="height: 100vh">
    <div class="col-md-6 bg-img left">
      <!--  margin-top: 264px;-->
      <div class="login-content-position login-form-mt">
        <div class="display-01 text-neutral-07" style="margin-bottom: 64px">Welcome!</div>
        <div class="heading-04 fw-normal text-neutral-07" style="margin-bottom: 26px">
          Please sign in below
        </div>
        <div style="max-width: 350px" class="w-100">
          <Form @submit="Submit" :validation-schema="schema" v-slot="{ errors }">
            <div class="mb-2 pb-1">
              <label for="Email" class="label-02 fw-semibold text-neutral-07 mb-2">Email</label>
              <Field
                v-model="user.Email"
                name="Email"
                type="text"
                class="form-control"
                placeholder="Email"
                :class="{ 'border-danger': errors.Email }"
              />
              <ErrorMessage name="Email" class="error-message" />
            </div>
            <div class="mb-4 pb-2">
              <label for="Password" class="label-02 fw-semibold text-neutral-07 mb-2">
                Password
              </label>
              <div
                class="input-group right"
                :class="{ 'border-danger-input-group': errors.Password }"
              >
                <Field
                  :type="user.ToggleViewPassword ? 'text' : 'password'"
                  name="Password"
                  class="form-control"
                  placeholder="Password"
                  v-model="user.Password"
                />
                <span
                  class="input-group-text"
                  :class="[user.Password ? 'pointer' : 'cursor-default']"
                  :title="!user.ToggleViewPassword ? 'Show password' : 'Hide password'"
                  v-on:click="
                    user.Password ? (user.ToggleViewPassword = !user.ToggleViewPassword) : ''
                  "
                >
                  <!--eye-->
                  <span v-if="user.Password">
                    <img src="../../assets/images/icons/eye.png" v-if="user.ToggleViewPassword" />
                    <img src="../../assets/images/icons/eye-slash.png" v-else />
                  </span>
                </span>
              </div>
              <ErrorMessage name="Password" class="error-message" />
              <div class="error-message mt-3 mb-0 fw-semibold" v-if="message">{{ message }}</div>
            </div>
            <div class="row mb-4 pb-1">
              <div class="col-6"></div>
              <div class="col-6 text-end">
                <router-link to="/account/forgot-password" class="forgot-pass-text">
                  Forgot password?
                </router-link>
              </div>
            </div>
            <button type="submit" class="button btn-large btn-success-solid w-100">Login</button>
          </Form>
        </div>
      </div>
    </div>
    <div class="col-6 bg-img right position-relative d-none d-md-block">
      <!-- style="margin-top: 289px" -->
      <div class="login-content-position text-center" style="margin-top: 31vh">
        <div class="display-01 text-white mb-4 pb-2" style="margin-bottom: 64px">
          Easy store management
        </div>
        <div class="efortless-text">
          Effortlessly streamline inventory, sales, and customer relations with <br />
          our intuitive and user-friendly store management solution
        </div>
      </div>
      <img
        src="../../assets/images/login-right-screenshots-cropped.png"
        class="img-right-screenshots"
      />
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: '',
      user: {},
    };
  },
  watch: {
    user: {
      handler() {
        this.message = '';
      },
      deep: true,
    },
  },
  computed: {
    schema() {
      return yup.object({
        Email: yup.string().required('Username is required!').email('Please provide a valid email'),
        Password: yup.string().required('Password is required!'),
      });
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    Submit() {
      this.handleLogin(this.user);
    },
    handleLogin(user) {
      this.$store.state.loader = true;
      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/');
          this.$store.state.loader = false;
        },
        () => {
          this.message = 'Something went wrong, please try again';
          this.$store.state.loader = false;
        },
      );
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1850px) {
  .img-right-screenshots {
    width: 100%;
  }
}
.login-form-mt {
  margin-top: 20vh;
}
@media only screen and (min-width: 768px) {
  .login-form-mt {
    margin-top: 28.5vh;
  }
}

.img-right-screenshots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.efortless-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
}
.forgot-pass-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--neutral-5);
  transition: 0.2;
}

.forgot-pass-text:hover {
  color: var(--neutral-8);
}

.form-control {
  border-radius: 8px;
  padding: 13.5px;
  border-color: var(--neutral-3);
}
.form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--neutral-5);
}
.login-content-position {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bg-img {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.bg-img.left {
  background-image: url(../../assets/images/bg-login-left.png);
}
.bg-img.right {
  background-image: url(../../assets/images/bg-login-right.png);
}
</style>
