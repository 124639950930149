<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.93359 12.8467L16.0002 16.94L23.0136 12.8733"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 24.1934V16.9267"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.347 8.88668L10.0803 11.26C9.12032 11.7933 8.32031 13.14 8.32031 14.2467V18.7667C8.32031 19.8733 9.10699 21.22 10.0803 21.7533L14.347 24.1267C15.2537 24.6333 16.747 24.6333 17.667 24.1267L21.9337 21.7533C22.8937 21.22 23.6936 19.8733 23.6936 18.7667V14.2333C23.6936 13.1267 22.907 11.78 21.9337 11.2467L17.667 8.87334C16.747 8.36668 15.2537 8.36668 14.347 8.88668Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.3333 20.5C29.3333 25.66 25.16 29.8333 20 29.8333L21.4 27.5"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66699 12.5C2.66699 7.34002 6.84033 3.16669 12.0003 3.16669L10.6003 5.50002"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold  d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="OpenAddStatus()"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Add <span class="d-none d-sm-inline-block">status</span>
        </button>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="row mb-3 filters-zone">
      <div class="col col-lg-7 col-xxl-3">
        <label for="prod-statuses-search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            v-model="filter.SearchQuery"
            @keyup.enter="GetStatuses()"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            id="prod-statuses-search"
          />
        </div>
      </div>
    </div>

    <div class="table-scroll">
      <div class="row-table m-0">
        <div class="row header m-0">
          <div class="col-4 ps-0" :class="{ active: filter.OrderBy.includes('name') }">
            <span @click="OrderBy('name')" class="pointer d-inline-flex">
              <span class="text">Name</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="name" />
            </span>
          </div>
          <div class="col-3">
            <span class="text">Availability</span>
          </div>
          <div class="col">Color</div>
          <div class="col"></div>
          <div class="col-auto"></div>
        </div>
        <div
          class="row content align-items-center m-0"
          v-for="(status, index) in statuses"
          :key="index"
        >
          <div class="col-4 ps-0">
            <div class="title">{{ status.Name }}</div>
            <div class="description" :title="status.Description">
              {{ this.$utils.Ellipsify(status.Description, 80) }}
            </div>
          </div>
          <div class="col-3">
            <span class="text-button-02 text-success-06" v-if="status.IsAvailable">
              Available
            </span>
            <span class="text-button-02 text-danger-05" v-else>Not Available</span>
          </div>
          <div class="col d-flex align-items-center">
            <span class="colored-box me-2" :style="{ 'background-color': status.Color }"></span>
            <span class="label-02 fw-semibold text-neutral-08 text-uppercase">
              {{ status.Color }}
            </span>
          </div>
          <div class="col"></div>
          <div class="col-auto pe-0">
            <div class="d-flex align-items-center">
              <button
                type="button"
                class="button table-btn me-2"
                v-on:click="GetStatusDetails(status.Id)"
              >
                <img src="../../assets/images/icons/table/edit-linear.svg" />
              </button>
              <button type="button" class="button table-btn" v-on:click="DeleteStatus(status.Id)">
                <img src="../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="statuses.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>
  </div>

  <AddStatusModal ref="addStatusModal" @save="AddStatus" />
  <EditStatusModal :status="status" @edit="EditStatus" />

  <pagination-component
    :numberOfPages="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    :functionName="SelectPage"
  ></pagination-component>
</template>

<script>
import AddStatusModal from '../../components/Status/Modals/AddStatusModal.vue';
import EditStatusModal from '../../components/Status/Modals/EditStatusModal.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';

export default {
  name: 'StatusesView',
  data() {
    return {
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        // PageSize: this.$store.state.globalPageSize,
        PageSize: 10,
        OrderBy: 'name',
      },
      pagination: {},
      statuses: [],
      status: {},
    };
  },
  components: {
    AddStatusModal,
    EditStatusModal,
    PaginationComponent,
    OrderByIconComponent,
  },
  methods: {
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetStatuses();
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetStatuses();
    },
    OpenAddStatus() {
      this.$refs.addStatusModal.ClearModal();
      // eslint-disable-next-line no-undef
      $('#addStatusModal').modal('show');
    },
    GetStatusDetails(id) {
      this.$axios
        .get(`api/ProductStatus/get/${id}`)
        .then((result) => {
          this.status = result.data;
          // eslint-disable-next-line no-undef
          $('#editStatusModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetStatuses() {
      const searchParams = new URLSearchParams(this.filter);
      this.$axios
        .get(`api/ProductStatus/getAll?${searchParams}`)
        .then((result) => {
          this.statuses = result.data.Items;
          this.pagination = result.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    AddStatus(status) {
      this.$axios
        .post('/api/ProductStatus/create', status)
        .then(() => {
          this.$utils.toastNotify('success', 'Status added successfully.');
          this.GetStatuses();
          // eslint-disable-next-line no-undef
          $('#addStatusModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    EditStatus(status) {
      this.$axios
        .put(`/api/ProductStatus/edit/${status.Id}`, status)
        .then(() => {
          this.$utils.toastNotify('success', 'Status edited successfully.');
          this.GetStatuses();
          // eslint-disable-next-line no-undef
          $('#editStatusModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteStatus(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/ProductStatus/delete/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetStatuses();
                this.$utils.toastNotify('success', 'Status deleted successfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
  },
  created() {
    this.GetStatuses();
    this.$store.commit('ChangePage', 'Status');
  },
};
</script>
<style scoped>
@media (max-width: 1200px) {
  .table-scroll {
      overflow-x: auto;
      position: relative;
  }
 .row-table {
    width: 1200px;
  }
}

.colored-box {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
</style>
