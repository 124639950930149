<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="modal fade"
    id="changeProductModal"
    tabindex="-1"
    aria-labelledby="changeProductLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img src="../../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
          <h5 class="modal-title" id="changeProductLabel">Choose product</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center" style="padding: 15px 24px">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66602 12.0003V9.33366C2.66602 5.33366 5.33268 2.66699 9.33268 2.66699H22.666C26.666 2.66699 29.3327 5.33366 29.3327 9.33366V12.0003"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66602 20V22.6667C2.66602 26.6667 5.33268 29.3333 9.33268 29.3333H22.666C26.666 29.3333 29.3327 26.6667 29.3327 22.6667V20"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.93359 12.3467L16.0003 16.44L23.0136 12.3734"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 23.6934V16.4268"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.347 8.38707L10.0803 10.7604C9.1203 11.2938 8.32031 12.6404 8.32031 13.7471V18.2671C8.32031 19.3738 9.10697 20.7204 10.0803 21.2538L14.347 23.6271C15.2536 24.1337 16.747 24.1337 17.667 23.6271L21.9336 21.2538C22.8936 20.7204 23.6936 19.3738 23.6936 18.2671V13.7471C23.6936 12.6404 22.907 11.2938 21.9336 10.7604L17.667 8.38707C16.747 7.86707 15.2536 7.86707 14.347 8.38707Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="ms-2 label-02 fw-semibold text-neutral-08">
            {{ product.Title }}
          </span>
        </div>
        <div class="modal-body border-top border-bottom" style="padding: 15px 24px">
          <div class="row text-neutral-07 align-items-center">
            <div class="col label-02 fw-semibold">
              {{ product.SpecificationName }}
            </div>
            <div class="col paragraph-02">
              {{ product.Value }}
            </div>
          </div>
        </div>
        <div class="modal-body">
          <label for="search">Search product</label>
          <div class="input-group left mb-4">
            <span class="input-group-text">
              <img src="../../../../assets/images/icons/search-normal-20x20.svg" />
            </span>
            <input
              id="search"
              v-model="filter.SearchQuery"
              @keyup.enter="$emit('search')"
              type="text"
              class="search form-control"
              placeholder="Search in..."
            />
          </div>
          <div class="paragraph-03 fw-normal text-neutral-09">Products</div>
          <div
            class="custom-scrollbar mt-2"
            style="height: 250px; overflow-y: auto; overflow-x: hidden"
          >
            <div
              class="row align-items-center pt-3 pb-3 row-border-bottom"
              v-for="(prod, index) in products"
              :key="index"
            >
              <div class="col d-flex align-items-center">
                <img :src="$utils.GetImageFormat(prod.ImgBase64)" class="cover-img-48px" />
                <div class="ms-3">
                  <div class="label-02 fw-semibold text-neutral-08 mb-3px">{{ prod.Title }}</div>
                  <div
                    v-if="prod.Description"
                    class="paragraph-01 text-neutral-06"
                    :title="prod.Description"
                  >
                    {{ this.$utils.Ellipsify(prod.Description, 41) }}
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input
                    v-model="prod.Selected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`product-radio-${index}`"
                  />
                  <label class="form-check-label" :for="`product-radio-${index}`"> </label>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center ps-2 pe-2 mt-3">
            <button
              v-if="filter.PageNumber < pagination.TotalPageCount"
              @click="$emit('get')"
              class="button btn-small btn-success-outlined"
            >
              Show more
            </button>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            class="button btn-success-solid btn-medium w-100"
            @click="AddProducts"
            data-bs-dismiss="modal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeProductModal',
  props: {
    product: Object,
    specificationName: String,
  },
  data() {
    return {
      filter: {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
      },
      products: [],
      pagination: {},
    };
  },
  methods: {
    AddProducts() {
      const selectedValues = [];
      this.values.forEach((element) => {
        if (element.Selected === true) {
          selectedValues.push({
            ProductId: this.productId,
            ValueId: element.Id,
          });
        }
      });
      if (selectedValues.length > 0) {
        this.$emit('add-values', selectedValues);
      }
    },
    GetProducts() {
      const searchParams = new URLSearchParams(this.filter);
      this.$axios
        .get(`/api/SpecificationCategory/getAll?${searchParams}`)
        .then((response) => {
          this.products.push(
            {
              Title: 'Tip sistem',
              Description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sapien arcu, tempus ut nisl in, interdum mollis dui. Praesent mollis diam et orci vestibulum cursus. Vivamus lectus arcu,',
            },
            {
              Title: 'Sistem de operare',
              Description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sapien arcu, tempus ut nisl in, interdum mollis dui. Praesent mollis diam et orci vestibulum cursus. Vivamus lectus arcu,',
            },
            {
              Title: 'Producator procesor',
              Description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sapien arcu, tempus ut nisl in, interdum mollis dui. Praesent mollis diam et orci vestibulum cursus. Vivamus lectus arcu,',
            },
          );
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    ClearModal() {
      this.filter = {
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
      };
    },
  },
};
</script>

<style>
.border-bottom {
  border-bottom: 1px solid black;
}
</style>
