<template>
  <div class="d-flex justify-content-center mt-5 mb-5 bg-primary p-5">
    <div style="overflow: hidden; width: 200px; height: 200px; border-radius: 50%">
      <img
        src="https://i.postimg.cc/WpfHz3W5/image.png"
        style="transform: scale(1.1); object-fit: cover; width: 100%; height: 100%"
      />
    </div>
  </div>

  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.3333 15.0332V5.9665C29.3333 3.9665 28.48 3.1665 26.36 3.1665H20.9733C18.8533 3.1665 18 3.9665 18 5.9665V15.0332C18 17.0332 18.8533 17.8332 20.9733 17.8332H26.36C28.48 17.8332 29.3333 17.0332 29.3333 15.0332Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.3333 27.0335V24.6335C29.3333 22.6335 28.48 21.8335 26.36 21.8335H20.9733C18.8533 21.8335 18 22.6335 18 24.6335V27.0335C18 29.0335 18.8533 29.8335 20.9733 29.8335H26.36C28.48 29.8335 29.3333 29.0335 29.3333 27.0335Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 17.9665V27.0332C14 29.0332 13.1467 29.8332 11.0267 29.8332H5.64002C3.52002 29.8332 2.66669 29.0332 2.66669 27.0332V17.9665C2.66669 15.9665 3.52002 15.1665 5.64002 15.1665H11.0267C13.1467 15.1665 14 15.9665 14 17.9665Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 5.9665V8.3665C14 10.3665 13.1467 11.1665 11.0267 11.1665H5.64002C3.52002 11.1665 2.66669 10.3665 2.66669 8.3665V5.9665C2.66669 3.9665 3.52002 3.1665 5.64002 3.1665H11.0267C13.1467 3.1665 14 3.9665 14 5.9665Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold">Categories</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Add Anything :)
        </button>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="row-table" style="margin-top: 77px">
      <div class="row header">
        <div class="col-4 active d-flex">
          <span class="text">Name</span>
          <order-by-icon-component orderBy="name_desc" orderByName="name" />
        </div>
        <div class="col-4 d-flex">
          <span class="text">Subcategories</span>
          <order-by-icon-component orderBy="bla" orderByName="name_desc" />
        </div>
        <div class="col"></div>
        <div class="col-auto"></div>
      </div>
      <div class="row content expandable align-items-center">
        <div class="col-4 d-flex align-items-center ps-5">
          <img class="content-img" src="../assets/images/no-content-found.png" />
          <div class="ms-3">
            <div class="title">Nintendu</div>
            <div class="description">Lorem ipsum dolor sit amet, consectetur a...</div>
          </div>
        </div>
        <div class="col-4">
          <div class="title">10 subcategories</div>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <div class="d-flex align-items-center">
            <button type="button" class="button table-btn me-2" style="padding: 10px">
              <img src="../assets/images/icons/table/add-linear.svg" />
            </button>
            <button type="button" class="button table-btn me-2">
              <img src="../assets/images/icons/table/edit-linear.svg" />
            </button>
            <button type="button" class="button table-btn">
              <img src="../assets/images/icons/table/trash-linear.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin-top: 200px" />
    <div class="container">
      <div class="row mb-5 pb-5 align-items-center">
        <div class="col-auto">
          <button type="button" class="button table-btn">
            <img src="../assets/images/icons/table/add-linear.svg" />
          </button>
        </div>
        <div class="col-auto">
          <button type="button" class="button table-btn">
            <img src="../assets/images/icons/table/edit-linear.svg" />
          </button>
        </div>
        <div class="col-auto">
          <button type="button" class="button table-btn">
            <img src="../assets/images/icons/table/trash-linear.svg" />
          </button>
        </div>
      </div>
      <div class="row mb-5 pb-5 align-items-center">
        <div class="col-auto">
          <button type="button" class="button btn-small btn-success-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-success-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-success-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-small btn-success-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-success-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-success-outlined">Button</button>
        </div>
      </div>
      <div class="row mb-5 pb-5 align-items-center">
        <div class="col-auto">
          <button type="button" class="button btn-small btn-neutral-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-neutral-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-neutral-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-small btn-neutral-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-neutral-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-neutral-outlined">Button</button>
        </div>
      </div>
      <div class="row mb-5 pb-5 align-items-center">
        <div class="col-auto">
          <button type="button" class="button btn-small btn-danger-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-danger-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-danger-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-small btn-danger-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-danger-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-danger-outlined">Button</button>
        </div>
      </div>
      <div class="row mb-5 pb-5 align-items-center">
        <div class="col-auto">
          <button type="button" class="button btn-small btn-secondary-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-secondary-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-secondary-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-small btn-secondary-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-secondary-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-secondary-outlined">Button</button>
        </div>
      </div>
      <div class="row mb-5 pb-5 align-items-center">
        <div class="col-auto">
          <button type="button" class="button btn-small btn-disabled-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-disabled-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-disabled-solid">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-small btn-disabled-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-medium btn-disabled-outlined">Button</button>
        </div>
        <div class="col-auto">
          <button type="button" class="button btn-large btn-disabled-outlined">Button</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderByIconComponent from '../components/General/OrderByIconComponent.vue';

export default {
  name: 'trash-view',
  components: { OrderByIconComponent },
};
</script>
