<template>
  <div class="row align-items-center mb-3">
    <div class="col">
      <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">Recommended products</div>
    </div>
    <div class="col-auto">
      <button
        type="button"
        class="button btn-small btn-success-solid text-center d-flex align-items-center"
        @click="OpenRecomandationModal"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 10.5H15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 15.5V5.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Add product
      </button>
    </div>
  </div>

  <!--table-->
  <div
    class="row label-02 fw-semibold text-neutral-07 row-border-bottom ms-0 me-0"
    style="padding-bottom: 12px"
  >
    <div class="col ps-0">Name & description</div>
  </div>
  <div
    class="row row-border-bottom pt-3 pb-3 ms-0 me-0 align-items-center"
    v-for="(recomandation, index) in recomandations"
    :key="index"
  >
    <div class="col ps-0 d-flex align-items-center">
      <img :src="$utils.GetImageFormat(recomandation.ImgBase64)" class="recommandation-img" />

      <div>
        <div class="label-02 fw-semibold text-neutral-08 mb-1">
          {{ recomandation.Title }}
        </div>
        <div
          v-if="recomandation.ShortDescription"
          class="paragraph-01 text-neutral-06"
          :title="recomandation.ShortDescription"
        >
          {{ this.$utils.Ellipsify(recomandation.ShortDescription, 100) }}
        </div>
      </div>
    </div>
    <div class="col-1 pe-0">
      <button
        type="button"
        class="button table-btn float-end"
        @click="RemoveRecomandation(recomandation.Id)"
      >
        <img src="../../../assets/images/icons/table/trash-linear.svg" />
      </button>
    </div>
  </div>

  <div v-if="!recomandations || recomandations.length == 0" class="mt-5 pt-5 text-center">
    <img src="../../../assets/images/not-found-img.png" />
  </div>
  <AddRecomandationModal
    v-if="selectedTab === 'recomandation'"
    ref="addRecomandationModalRef"
    :filter="filter"
    :productId="productId"
    :products="products"
    :pagination="pagination"
    :recomandations="recomandations"
    @add="AddRecomandation"
    @get="GetProducts"
    @search="GetSearch"
  />
</template>
<script>
import AddRecomandationModal from './Modals/AddRecomandationModal.vue';

export default {
  name: 'ProductRecomandation',
  props: {
    recomandations: Array,
    productId: Number,
    selectedTab: String,
  },
  data() {
    return {
      products: [],
      pagination: {},
      filter: {
        PageSize: 5,
        PageNumber: 1,
        SearchQuery: '',
      },
    };
  },
  components: {
    AddRecomandationModal,
  },
  methods: {
    RemoveRecomandation(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.recomandations.splice(index, 1);
    },
    OpenRecomandationModal() {
      this.filter.PageNumber = 1;
      const searchParams = {
        ProductId: this.productId,
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
      };
      this.$axios
        .get(`/api/ProductRecomandation/getProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.products.forEach((element) => {
            this.AlreadyExist(element);
          });
          this.pagination = response.data.PageDetails;
          this.$refs.addRecomandationModalRef.ClearModal();
          // eslint-disable-next-line no-undef
          $('#addRecomandation').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    AlreadyExist(product) {
      this.recomandations.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        if (element.ProductId === product.Id) {
          // eslint-disable-next-line no-param-reassign
          product.Selected = true;
        }
      });
    },
    GetProducts() {
      this.filter.PageNumber += 1;
      const searchParams = {
        ProductId: this.productId,
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/ProductRecomandation/getProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.AlreadyExist(element);
            this.products.push(element);
          });
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetSearch() {
      this.filter.PageNumber = 1;
      const searchParams = {
        ProductId: this.productId,
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/ProductRecomandation/getProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.pagination = response.data.PageDetails;
          // eslint-disable-next-line no-undef
          $('#addRecomandation').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    AddRecomandation(products) {
      // eslint-disable-next-line vue/no-mutating-props
      this.recomandations.splice(0, this.recomandations.length);
      products.forEach((element) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.recomandations.push(element);
      });
    },
  },
};
</script>

<style scoped>
.recommandation-img {
  width: 48px;
  height: 48px;
  object-fit: contain;
  margin-right: 12px;
}
</style>
