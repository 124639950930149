<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div :class="{ 'dropdown-value-selected': filter.Month || filter.DateInterval }">
    <div class="dropdown period-filter">
      <button
        class="btn-dropdown-outlined"
        :class="{ 'dropdown-toggle': !filter.Month && !filter.DateInterval }"
        type="button"
        :id="`${filterName}DropdownFilter`"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="!filter.Month && !filter.DateInterval"> Select period </span>
        <span v-else>
          {{ filter.Month ? filter.Month.Name : '' }}
          <span v-if="filter.DateInterval && filter.DateInterval.length > 0">
            {{ this.$utils.FormatDayMonthYear(filter.DateInterval[0]) }} -
            {{ this.$utils.FormatDayMonthYear(filter.DateInterval[1]) }}
          </span>
        </span>
      </button>
      <div class="dropdown-menu p-3" :aria-labelledby="`${filterName}DropdownFilter`">
        <div class="row">
          <div
            class="col-6 mb-1 pb-2"
            v-for="(month, index) in optionsList"
            :key="index"
            :class="{ 'pe-0': index % 2 === 0 }"
          >
            <div
              class="filter-item-box"
              @click="
                filter.Month = month;
                filter.DateInterval = null;
                $emit('apply-filter');
              "
              :class="{ active: filter.Month ? filter.Month.Name == month.Name : '' }"
            >
              {{ month.Name }}
            </div>
          </div>
        </div>
        <div>
          <hr class="mt-1 mb-2" />
          <div class="paragraph-02 text-neutral-05 mb-2">Custom</div>
          <VueDatePicker
            v-model="filter.DateInterval"
            placeholder="Select interval"
            :enableTimePicker="false"
            autoApply
            multi-calendars
            range
            class="custom-datepicker"
            :auto-position="false"
            name="Start date"
            format="dd-MM-yyyy"
            id="orders-date"
            @update:modelValue="
              filter.Month = null;
              $emit('apply-filter');
            "
          ></VueDatePicker>
        </div>
      </div>
    </div>
    <span
      class="btn-remove-multiselect"
      v-on:click="
        filter.Month = null;
        filter.DateInterval = null;
        $emit('apply-filter');
      "
    >
    </span>
  </div>
</template>
<script>
export default {
  name: 'dropdown-filter-component',
  emits: ['apply-filter'],
  props: {
    filterName: {
      type: String,
      default: null,
    },
    optionsList: {
      type: Array,
      default: null,
    },
    filter: {
      type: Object,
      default: null,
    },
  },
  methods: {},
  data() {
    return {};
  },
};
</script>
<style scoped></style>
