<!-- eslint-disable vue/no-textarea-mustache -->
<!-- eslint-disable vue/no-mutating-props -->
eslint-disable import/no-extraneous-dependencies
<template>
  <div
    class="modal fade"
    id="editSubcategoryModal"
    tabindex="-1"
    aria-labelledby="editSubcategoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
          <div class="modal-header">
            <img src="../../../../assets/images/icons/edit-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="editSubcategoryModalLabel">
              Edit subcategory {{ subcategory.id }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="recipient-name">Subcategory name</label>
              <Field
                name="name"
                type="text"
                class="form-control"
                id="recipient-name"
                v-model="subcategory.Name"
                placeholder="Name"
                :class="{ 'border-danger': errors.name }"
              ></Field>
              <ErrorMessage class="error-message" name="name" />
            </div>
            <label for="message-text">Subcategory description</label>
            <Field
              rows="2"
              as="textarea"
              name="description"
              type="text"
              class="form-control"
              id="message-text"
              v-model="subcategory.Description"
              placeholder="Description"
              :class="{ 'border-danger': errors.description }"
            ></Field>
            <ErrorMessage class="error-message" name="description" />
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddSubcategoryModal',
  props: {
    subcategory: { type: Object },
    category: { type: Object },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    onSubmit() {
      this.SaveSubcategory();
    },
    SaveSubcategory() {
      this.$axios
        .put(`/api/subcategories/update/${this.subcategory.Id}`, this.subcategory)
        .then(() => {
          this.$emit('update', this.category);
          // eslint-disable-next-line no-undef
          $('#editSubcategoryModal').modal('hide');
          this.$utils.toastNotify('success', 'Subcategory edited successfully.');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
  computed: {
    schema() {
      return yup.object({
        name: yup.string().required('Name field is required.').min(3, 'Subcategory title must have at least 3 characters').max(50, 'Subcategory title must be under 50 characters')
          .trim(),
        description: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,200})$/, 'Between 5-200 characters')
          .trim(),
      });
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
}

.testImage {
  width: 500em;
  height: 50em;
  display: flex;
}

.errorMessage {
  color: red;
}
</style>
