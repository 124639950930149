<template>
  <nav class="white-navbar shadow-sm d-md-none" id="navbar-mobile">
    <div class="align-items-center pt-4 row justify-content-around ms-0 me-0">
      <div class="col-auto pe-0 pe-sm-3">
        <div class="hamburger" :class="{ 'is-active': hamburgerOpen }" v-on:click="hamburgerOpen = !hamburgerOpen">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
      <div class="col-auto pe-0 ps-0 pe-sm-3 ps-sm-3">
        <router-link :to="{ name: 'Dashboard' }" v-on:click="this.$route.name == 'Dashboard' ? this.$router.go() : ''">
          <img class="logo" :src="require('@/assets/SidebarAssets/logo2.png')" />
        </router-link>
      </div>
      <div class="col-auto d-flex ps-0 ps-sm-3">
        <div class="user-img pointer" id="dropdownLogout" data-bs-toggle="dropdown" aria-expanded="false">
          <img :src="require('@/assets/user-img.png')" alt="User profile icon" />
        </div>

        <ul class="dropdown-menu" aria-labelledby="dropdownLogout">
          <li v-if="currentUser">
            <span class="dropdown-item d-flex align-items-center">Salut, {{ currentUser.FirstName }} {{
              currentUser.LastName }}</span>
            <hr class="dropdown-divider me-3 ms-3 border-color-blacktint-1" />
          </li>
          <li>
            <button class="dropdown-item ps-3" type="button" v-on:click="LogOut()">
              Deconectează-te
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="hamburger-menu d-md-none" :class="{ 'hamburger-menu-after': hamburgerOpen }">
    <div class="container pt-5" style="padding-bottom: 90px">
      <div v-for="(page, index) in pages" :key="index">
        <div class="heading-01 mb-2">{{ page.Category }}</div>
        <div class="row mb-3">
          <div class="col-auto mb-3" v-for="(subPage, indexSubPage) in page.Pages" :key="indexSubPage">
            <router-link :to="{ name: subPage.Name }">
              <div class="page-card">
                <div class="align-items-center d-flex flex-column justify-content-center h-100">
                  <img :src="require(`@/assets/images/pages-icons/${subPage.Icon}`)" />
                  <span class="mt-1" v-html="subPage.Label || subPage.Name"></span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="heading-01 mb-2">Informations</div>
      <div class="row mb-3">
        <div class="col-auto mb-3">
          <a href="./documentation.pdf">
            <div class="page-card">
              <div class="align-items-center d-flex flex-column justify-content-center h-100">
                <img src="@/assets/images/pages-icons/document-text.svg" />
                <span class="mt-1">Documentation</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagesList from '../../utils/pages-list';

export default {
  name: 'navbar-compponent-mobile',
  components: {},
  data() {
    return {
      hamburgerOpen: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    pages() {
      return pagesList;
    },
  },
  methods: {
    LogOut() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to log out?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Log out!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('auth/logout').then(() => {
              this.$router.push('/login');
            });
          }
        });
    },
    ExpandNavbar() {
      const navbar = document.getElementById('navbar-mobile');
      // eslint-disable-next-line eqeqeq
      if (navbar.style.height == '155px') {
        navbar.style.height = '80px';
      } else {
        navbar.style.height = '155px';
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.hamburgerOpen = false;
      },
      deep: true,
      immediate: true,
    },

    hamburgerOpen: {
      handler(value) {
        // eslint-disable-next-line no-unused-expressions
        value
          ? ((document.body.style.overflowY = 'hidden'), (document.body.style.position = 'fixed'))
          : ((document.body.style.overflowY = 'visible'),
          (document.body.style.position = 'relative'));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
.container .router-link-exact-active img {
  filter: invert(50%) sepia(92%) saturate(480%) hue-rotate(60deg) brightness(90%) contrast(90%);
}

.container img {
  filter: invert(57%) sepia(13%) saturate(557%) hue-rotate(149deg) brightness(82%) contrast(89%);
}

button {
  padding: 0px;
  border: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
}

.page-card {
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  color: var(--neutral-4);
  text-align: center;
  line-height: 1;
  height: 100%;
  width: 110px;
}

.router-link-exact-active .page-card {
  background-color: var(--neutral-2);
  color: var(--neutral-5);
}

.hamburger-menu {
  margin-top: -20px;
  height: 0px;
  background-color: white;
  transition: height 0.2s ease;
  overflow: hidden;
  overflow-y: scroll;
}

.hamburger-menu-after {
  height: calc(100vh - 50px);
  transition: height 0.2s ease;
}

.user-img {
  display: flex;
  align-items: center;
}

.user-img span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #343a40;
  margin-right: 20px;
}

.user-img img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.white-navbar {
  position: sticky;
  top: 0;
  height: 80px;
  background: rgb(255 255 255 / 100%);
  z-index: 55;
  transition: 0.2s;
}

.hamburger-menu h4 {
  margin-left: 5%;
  margin-top: 50px;
}

.hamburger-menu .menu-pages {
  margin-left: 12%;
}

.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: var(--neutral-4);
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(5px) rotate(45deg);
  -ms-transform: translateY(5px) rotate(45deg);
  -o-transform: translateY(5px) rotate(45deg);
  transform: translateY(5px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}

.logo {
  width: 180px;
}
</style>
