const pages = [
  {
    Category: 'General',
    Pages: [
      {
        Name: 'Dashboard',
        Label: '',
        Icon: 'general-dashboard.svg',
      },
      {
        Name: 'Users',
        Label: '',
        Icon: 'general-users.svg',
      },
      {
        Name: 'Clients',
        Label: '',
        Icon: 'general-clients.svg',
      },
      {
        Name: 'Coupons',
        Label: '',
        Icon: 'general-coupons.svg',
      },
      {
        Name: 'Reviews',
        Label: '',
        Icon: 'general-reviews.svg',
      },
      {
        Name: 'Settings',
        Label: '',
        Icon: 'general-settings.svg',
      },
    ],
  },
  {
    Category: 'Orders',
    Pages: [
      {
        Name: 'Orders',
        Label: '',
        Icon: 'order-orders.svg',
      },
      {
        Name: 'Summary',
        Label: '',
        Icon: 'order-summary.svg',
      },
      {
        Name: 'OrderStripeErrors',
        Label: 'Stripe errors',
        Icon: 'orders-stripe-errors.svg',
      },
    ],
  },
  {
    Category: 'Blog',
    Pages: [
      {
        Name: 'Blog',
        Label: '',
        Icon: 'blog-blogs.svg',
      },
      {
        Name: 'BlogCategories',
        Label: 'Categories',
        Icon: 'blog-categories.svg',
      },
    ],
  },
  {
    Category: 'Products',
    Pages: [
      {
        Name: 'Categories',
        Label: '',
        Icon: 'prod-categories.svg',
      },
      {
        Name: 'Brands',
        Label: '',
        Icon: 'prod-brands.svg',
      },
      {
        Name: 'Products',
        Label: '',
        Icon: 'prod-products.svg',
      },
      {
        Name: 'Locations',
        Label: '',
        Icon: 'prod-locations.svg',
      },
      {
        Name: 'Specifications',
        Label: '',
        Icon: 'prod-specifications.svg',
      },
      {
        Name: 'Product Status',
        Label: '',
        Icon: 'prod-productstatus.svg',
      },
    ],
  },
];

export default pages;
