<template>
  <div
    class="modal fade"
    id="addSectionModal"
    tabindex="-1"
    aria-labelledby="addSectionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addSectionFormRef"
          @submit="Submit"
          :validation-schema="Schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addSectionModalLabel">Add section</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <label for="name">Section name</label>
            <Field
              name="name"
              v-model="section.Name"
              type="text"
              class="form-control"
              placeholder="Name"
              :class="{ 'border-danger': errors.name }"
            />
            <ErrorMessage class="error-message" name="name" />
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddSectionModal',
  data() {
    return {
      section: {
        Name: '',
        Specifications: [],
      },
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      this.$emit('add-section', this.section);
      // eslint-disable-next-line no-undef
      $('#addSectionModal').modal('hide');
      this.section = {
        Name: '',
        Specifications: [],
      };
      this.$refs.addSectionFormRef.resetForm();
    },
    ClearSectionModal() {
      this.$refs.addSectionFormRef.resetForm();
    },
  },
  computed: {
    Schema() {
      return yup.object({
        name: yup.string().required('Name field is required.').min(3).trim(),
      });
    },
  },
};
</script>
