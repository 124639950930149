<template>
  <div
    class="modal fade"
    id="addBrandModal"
    tabindex="-1"
    aria-labelledby="addBrandModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addBrandFromRef"
          @submit="Submit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addBrandModalLabel">Add brand</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ResetModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="name">Brand name</label>
              <Field
                name="name"
                type="text"
                class="form-control"
                v-model="brand.Name"
                placeholder="Name"
                :class="{ 'border-danger': errors.name }"
              ></Field>
              <ErrorMessage class="error-message" name="name" />
            </div>
            <div class="mb-4">
              <label for="description">Brand description</label>
              <Field
                rows="3"
                name="description"
                type="text"
                class="form-control"
                as="textarea"
                v-model="brand.Description"
                placeholder="Description"
              ></Field>
              <ErrorMessage class="error-message" name="description" />
            </div>
            <div class="change-image-box">
              <div class="row align-items-center h-100 justify-content-around">
                <div class="col-auto">
                  <div class="label-02 fw-semibold text-neutral-07 mb-2">Choose image</div>
                  <label
                    for="upload-image-add-brand"
                    class="button btn-small btn-success-outlined text-success-06 text-button-01 w-100 text-center"
                    >Upload image</label
                  >
                  <input
                    id="upload-image-add-brand"
                    accept="image/*"
                    type="file"
                    class="d-none"
                    @input="$utils.InputImage(brand)"
                  />
                </div>
                <div class="col-auto">
                  <div class="position-relative">
                    <img :src="$utils.GetImageFormat(brand.ImgBase64)" />
                    <img
                      v-if="brand.ImgBase64"
                      src="../../../assets/images/icons/red-trash-16x16.svg"
                      class="delete-icon"
                      @click="brand.ImgBase64 = ''"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddBrandModal',
  data() {
    return {
      brand: {
        Name: '',
        Description: '',
        ImgBase64: '',
      },
    };
  },
  methods: {
    ClearModalValues() {
      this.$refs.addBrandFromRef.resetForm();
      this.brand.ImgBase64 = '';
    },
    Submit() {
      this.SaveBrand();
    },
    SaveBrand() {
      this.$axios
        .post('/api/Brand/create', this.brand)
        .then(() => {
          this.$utils.toastNotify('success', 'Brand added successfully.', 2500);
          this.$emit('save');
          // eslint-disable-next-line no-undef
          $('#addBrandModal').modal('hide');
          this.ResetModal();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.', 2500);
        });
    },
    ChangeImage() {
      this.$utils.InputImage(this.brand);
    },
    ResetModal() {
      this.brand = {};
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  computed: {
    schema() {
      return yup.object({
        name: yup.string().required('Name field is required.').min(3, 'Brand title must have at least 3 characters').max(50, 'Brand title must be under 50 characters')
          .trim(),
        description: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,200})$/, 'Between 5-200 characters')
          .trim(),
      });
    },
  },
};
</script>
<style scoped>
.change-image-box {
  height: 136px;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
}

.change-image-box .delete-icon {
  cursor: pointer;
  background-color: white;
  border-radius: 2px;
  padding: 5px;
  position: absolute;
  top: 2px;
  right: 2px;
  transition: 0.2s;
}

.change-image-box .delete-icon:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.change-image-box img:first-child {
  border: 1px solid var(--neutral-3);
  height: 88px;
  width: 88px;
  object-fit: contain;
  border-radius: 8px;
}
</style>
