<template>
  <Form :validation-schema="schema" @submit="Submit" v-slot="{ errors }">
    <div class="page-header page-header-height">
      <div class="row align-items-center">
        <div class="col-lg mb-3 mb-lg-0">
          <div class="d-flex align-items-center">
            <svg
              class="page-header-img"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28 9.33341V22.6667C28 26.6667 26 29.3334 21.3333 29.3334H10.6667C6 29.3334 4 26.6667 4 22.6667V9.33341C4 5.33341 6 2.66675 10.6667 2.66675H21.3333C26 2.66675 28 5.33341 28 9.33341Z"
                stroke="#1F2629"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.3335 6V8.66667C19.3335 10.1333 20.5335 11.3333 22.0002 11.3333H24.6668"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6665 17.3333H15.9998"
                stroke="#1F2629"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6665 22.6667H21.3332"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="heading-04 fw-semibold d-none d-sm-block">Blog</div>
            <div class="heading-02 fw-semibold  d-sm-none">Blog</div>
          </div>
          <div class="label-01 fw-semibold mt-2 d-flex align-items-center">
            <router-link class="text-neutral-05" target="_blank" :to="{ name: 'Blog' }">
              Blogs
            </router-link>
            <img
              src="../../assets/images/icons/arrow-right-12x12.svg"
              class="ms-2 ps-1 me-2 pe-1"
            />
            <span class="text-success-05"> Edit article</span>
          </div>
        </div>
        <div class="col-sm-auto pe-0 mb-3 mb-sm-0">
          <button
            type="submit"
            class="button btn-small btn-neutral-solid"
            style="width: 125px"
            @click="draft = true"
          >
            Save as draft
          </button>
        </div>
        <!-- <div class="col-sm-auto pe-0 mb-3 mb-sm-0">
          <button type="button" class="button btn-small btn-secondary-solid" style="width: 125px">
            Preview
          </button>
        </div> -->
        <div class="col-auto">
          <button
            type="submit"
            class="button btn-small btn-success-solid"
            style="width: 125px"
            @click="draft = false"
          >
            Publish
          </button>
        </div>
      </div>
    </div>
    <div class="page-content">
      <h1
        class="heading-01 text-neutral-08 fw-semibold"
        style="margin-top: 40px; margin-bottom: 36px"
      >
        General information
      </h1>
      <div class="row">
        <div class="col-lg-7 col-xl me-xxl-5 mb-3 mb-lg-0">
          <div class="pb-3 mb-1">
            <label for="title" class="label-02 text-neutral-07 fw-semibold mb-2">Title</label>
            <Field
              v-model="blog.Title"
              type="text"
              name="title"
              id="title"
              class="form-control form-control-lg"
              placeholder="Name"
              :class="{ 'border-danger': errors.title }"
            />
            <ErrorMessage class="error-message" name="title" />
          </div>
          <div class="pb-3 mb-1">
            <label for="description" class="label-02 text-neutral-07 fw-semibold mb-2"
              >Short description</label
            >
            <textarea
              v-model="blog.ShortDescription"
              type="textarea"
              rows="3"
              cols="50"
              name="description"
              id="description"
              class="form-control form-control-lg"
              placeholder="Short Description.."
              :class="{ 'border-danger': errors.description }"
            ></textarea>
            <ErrorMessage class="error-message" name="description" />
          </div>
          <div class="pb-3 mb-1">
            <label for="category" class="label-02 text-neutral-07 fw-semibold mb-2">Category</label>
            <Field v-model="blog.Category" name="category" class="d-none"></Field>
              <VueMultiselect
                class="custom-multiselect lg"
                v-model="blog.Category"
                :options="categories"
                label="Name"
                track-by="Id"
                :close-on-select="true"
                @remove="blog.Category = ''"
                placeholder="All"
                id="category"
                :class="{ 'border-danger-multiselect': errors.category }"
              />
            <ErrorMessage class="error-message" name="category" />
          </div>
          <label for="tags" class="label-02 text-neutral-07 fw-semibold mb-2">Tags</label>
          <VueMultiselect
            class="custom-multiselect lg"
            v-model="tags"
            tag-placeholder="Add this as new tag"
            placeholder="Search or add a tag"
            id="tags"
            label="Name"
            track-by="Code"
            :options="tagsList"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
          >
          </VueMultiselect>
        </div>
        <div class="col-lg-5 col-xl">
          <label class="label-02 text-neutral-07 fw-semibold mb-2">Thumbnail</label>
          <div class="thumbnail card">
            <div class="card-body">
              <img :src="$utils.GetImageFormat(blog.ImgBase64)" />
            </div>
            <div class="card-footer">
              <div class="row align-items-center">
                <div class="col-xxl paragraph-02 text-neutral-08 mb-2 mb-xxl-0">
                  Select thumbnail image <br />
                  for the article
                </div>
                <div class="col-auto d-flex justify-content-between">
                  <label
                    for="change-thumbnail"
                    style="width: 126px"
                    class="button btn-small btn-success-solid me-3 text-center"
                    >Upload image</label
                  >
                  <input
                    accept="image/*"
                    type="file"
                    id="change-thumbnail"
                    class="d-none"
                    @input="$utils.InputImage(blog)"
                  />
                  <button
                    type="button"
                    class="button btn-small btn-neutral-outlined fw-semibold"
                    style="width: 126px"
                    @click="blog.ImgBase64 = ''"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Editor v-model="blog.Content" />
    </div>
  </Form>
  {{ blog.Category }}
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';
import Editor from '../../components/Blog/Editor.vue';

export default {
  name: 'AddBlogArticle',
  props: {
    articleIdParam: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      blog: {},
      categories: [],
      category: '',
      tagsList: [],
      tags: [],
      draft: true,
    };
  },
  components: {
    Field,
    ErrorMessage,
    Form,
    Editor,
  },
  methods: {
    GetCategories() {
      this.$axios
        .get('/api/Blog/getCategories')
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    addTag(newTag) {
      const newTagF = {
        Name: newTag,
        Code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.tagsList.push(newTagF);
      this.tags.push(newTagF);
    },
    GetTags() {
      this.tags = [];
      this.$axios
        .get('/api/Blog/getTags')
        .then((response) => {
          response.data.forEach((element) => {
            const code = element.substring(0, 2) + Math.floor(Math.random() * 10000000);
            if (this.blog.Tags.includes(element)) {
              this.tags.push({
                Name: element,
                Code: code,
              });
            }
            this.tagsList.push({
              Name: element,
              Code: code,
            });
          });
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    Submit() {
      const article = {
        Title: this.blog.Title,
        ShortDescription: this.blog.ShortDescription,
        ImgBase64: this.blog.ImgBase64,
        Content: this.$utils.sanitizeInput(this.blog.Content),
        // Content: this.blog.Content,
        Draft: this.draft,
        Tags: this.tags.map((tag) => tag.Name).join(','),
        UpdatedBy: this.currentUser.UserId,
        CategoryId: !this.blog.Category ? null : this.blog.Category.Id,
      };
      this.$axios
        .put(`/api/Blog/update/${this.blog.Id}`, article)
        .then(() => {
          this.$utils.toastNotify('success', 'Article updated succesfully.');
          this.$router.push('/Blogs');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetAricleDetails() {
      this.$axios
        .get(`/api/Blog/getBlog/${this.articleIdParam}`)
        .then((response) => {
          this.blog = response.data;
          this.GetTags();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
   
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    schema() {
      return yup.object({
        title: yup.string().required('Article tilte is required'),
        category: yup.object().required('Category is required.'),
      });
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.GetAricleDetails();
    this.GetCategories();
  },
};
</script>
<style scoped>
.page-header-height {
  height: 100%;
}
@media only screen and (min-width: 992px) {
  .page-header-height {
    height: 102px;
  }
}
.thumbnail.card {
  border-radius: 16px;
  border-color: var(--blacktint-2);
}
.thumbnail.card .card-body img {
  max-width: 415px;
  width: 100%;
  height: 264px;
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid var(--blacktint-2);
  background-color: white;
}
.thumbnail.card .card-body {
  background-color: #f5f6f9;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 309px;
}
.thumbnail.card .card-footer {
  padding-top: 13px;
  padding-bottom: 13px;
  border-color: var(--blacktint-2);
  background-color: transparent;
}
</style>
