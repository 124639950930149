<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 25.6468H22.9867C21.92 25.6468 20.9067 26.0601 20.16 26.8068L17.88 29.0601C16.84 30.0868 15.1467 30.0868 14.1067 29.0601L11.8267 26.8068C11.08 26.0601 10.0533 25.6468 9 25.6468H8C5.78667 25.6468 4 23.8735 4 21.6868V7.14014C4 4.95347 5.78667 3.18018 8 3.18018H24C26.2133 3.18018 28 4.95347 28 7.14014V21.6868C28 23.8602 26.2133 25.6468 24 25.6468Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 13.8334C17.7158 13.8334 19.1067 12.4425 19.1067 10.7267C19.1067 9.01096 17.7158 7.62012 16 7.62012C14.2842 7.62012 12.8933 9.01096 12.8933 10.7267C12.8933 12.4425 14.2842 13.8334 16 13.8334Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3334 21.3798C21.3334 18.9798 18.9467 17.0332 16.0001 17.0332C13.0534 17.0332 10.6667 18.9798 10.6667 21.3798"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">Clients</div>
          <div class="heading-02 fw-semibold d-sm-none">Clients</div>
        </div>
      </div>
      <div class="col-auto d-none">
        <button
          :disabled="!isAdmin"
          @click="OpenAddClient"
          type="button"
          :class="{
            'button btn-small btn-success-solid d-flex align-items-center pe-3': isAdmin,
            'button btn-small btn-disabled-solid d-flex align-items-center pe-3': !isAdmin,
          }"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Add <span class="d-none d-sm-inline-block">client</span>
        </button>
      </div>
    </div>
  </div>

  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center mb-3">
      <div class="col col-lg-7 col-xxl-3 filters-zone">
        <label for="clients-search">Search</label>
        <div class="input-group left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="clients-search"
            v-model="filter.SearchQuery"
            @keyup.enter="SearchByQuery"
            type="text"
            class="search form-control"
            placeholder="Search in..."
          />
        </div>
      </div>
      <div class="col d-none d-lg-block"></div>
      <div class="col-auto d-flex align-items-center">
        <input
          id="switch-active-users"
          class="switch-small switch-small--shadow"
          type="checkbox"
          :checked="filter.ShowActiveUsers"
          v-on:click="
            filter.ShowActiveUsers = !filter.ShowActiveUsers;
            GetClients();
          "
        />
        <label for="switch-active-users"></label>
        <label
          class="label-02 fw-semibold text-neutral-07 ms-2 mb-0 pointer"
          for="switch-active-users"
        >
          Active clients
        </label>
      </div>
    </div>
    <!--table-->
    <div class="table-scroll">
      <table class="custom table">
        <thead>
          <tr valign="middle">
            <th :class="{ active: filter.OrderBy.includes('name') }" width="16%">
              <span class="pointer" @click="OrderBy('name')">
                Full name
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="name"
                  class="ms-1"
                />
              </span>
            </th>
            <th width="20%">Email</th>
            <th :class="{ active: filter.OrderBy.includes('phone_number') }">
              <span @click="OrderBy('phone_number')" class="pointer">
                Phone number
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="phone_number"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('reviews') }">
              <span class="pointer" @click="OrderBy('reviews')">
                Reviews
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="reviews"
                  class="ms-1"
                />
              </span>
            </th>
            <!-- <th @click="OrderBy('comments')" :class="{ active: filter.OrderBy.includes('comments') }">
            Comments
            <order-by-icon-component
              :orderBy="filter.OrderBy"
              orderByName="comments"
              class="ms-1"
            />
          </th> -->
            <th :class="{ active: filter.OrderBy.includes('fidelity_points') }">
              <span class="pointer" @click="OrderBy('fidelity_points')">
                Fidelity points
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="fidelity_points"
                  class="ms-1"
                />
              </span>
            </th>
            <th>Active</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(client, index) in clients" :key="index" valign="middle">
            <td>{{ client.FirstName }} {{ client.LastName }}</td>
            <td>
              {{ client.Email }}
            </td>
            <td>
              {{ client.PhoneNumber }}
            </td>
            <td>
              {{ client.Reviews }}
            </td>
            <!-- <td>comments</td> -->
            <td>
              {{ client.FidelityPoints }}
            </td>
            <td>
              <input
                :disabled="!isAdmin"
                :id="'switch-shadow-' + index"
                :class="{
                  'switch-small switch-small--shadow': isAdmin,
                  'switch-small switch-disabled': !isAdmin,
                }"
                type="checkbox"
                :checked="client.IsActive"
                v-on:click="UpdateClientAccess(client.Id)"
                @click.prevent
              />
              <label :for="'switch-shadow-' + index"></label>
            </td>
            <td>
              <div class="d-flex align-items-center float-end">
                <router-link
                  class="button table-btn me-2"
                  :to="{ name: 'Client profile', params: { clientIdParam: client.Id } }"
                >
                  <img src="../../assets/images/icons/table/user-icon-20x20.svg" />
                </router-link>

                <button
                  type="button"
                  :disabled="!isAdmin"
                  :class="{
                    'button table-btn me-2': isAdmin,
                    'button table-btn btn-disabled-solid me-2': !isAdmin,
                  }"
                  @click="GetClientDetails(client.Id)"
                >
                  <img src="../../assets/images/icons/table/edit-linear.svg" />
                </button>

                <button
                  type="button"
                  :disabled="!isAdmin"
                  :class="{
                    'button table-btn me-2': isAdmin,
                    'button table-btn btn-disabled-solid me-2': !isAdmin,
                  }"
                  v-on:click="DeleteClient(client.Id)"
                >
                  <img src="../../assets/images/icons/table/trash-linear.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="clients.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>

  <AddClientModal ref="addClientModalRef" @create="CreateClient" />
  <EditClientModal
    ref="editClientModalRef"
    :client="selectedClient"
    :shipping="selectedShipping"
    :billing="selectedBilling"
    @edit="EditClient"
  />
</template>

<script>
import AddClientModal from '../../components/Clients/ClientModals/AddClientModal.vue';
import EditClientModal from '../../components/Clients/ClientModals/EditClientModal.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';

export default {
  name: 'ClientsView',
  data() {
    return {
      clients: [],
      selectedClient: {},
      selectedShipping: {},
      selectedBilling: {},
      pagination: {},
      isAdmin: false,
      filter: {
        ShowActiveUsers: true,
        SearchQuery: '',
        OrderBy: 'name',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
    };
  },
  components: {
    AddClientModal,
    EditClientModal,
    PaginationComponent,
    OrderByIconComponent,
  },
  methods: {
    OpenAddClient() {
      this.$refs.addClientModalRef.ClearModal();
      // eslint-disable-next-line no-undef
      $('#addClientModal').modal('show');
    },
    CreateClient(client) {
      this.$axios
        .post('/api/User/createClient', client)
        .then(() => {
          this.$utils.toastNotify('success', 'Client created succesfully');
          // eslint-disable-next-line no-undef
          $('#addClientModal').modal('hide');
          this.GetClients();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    SearchByQuery() {
      this.filter.PageNumber = 1;
      this.GetClients();
    },
    EditClient(client) {
      this.$axios
        .put(`/api/User/updateClient/${client.Id}`, client)
        .then(() => {
          this.$utils.toastNotify('success', 'Client modified succesfully');
          this.GetClients();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetClients() {
      const searchParams = new URLSearchParams(this.filter);
      this.$axios
        .get(`/api/User/getClients?${searchParams}`)
        .then((response) => {
          this.clients = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetClients();
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetClients();
    },
    DeleteClient(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/User/deleteUser/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetClients();
                this.$utils.toastNotify('success', 'Client deleted succesfully');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    GetClientDetails(id) {
      this.selectedBilling = {};
      this.selectedClient = {};
      this.selectedShipping = {};
      this.$axios
        .get(`/api/User/getUserDetails/${id}`)
        .then((response) => {
          this.selectedClient = response.data;
          // eslint-disable-next-line prefer-destructuring
          this.selectedShipping = response.data.Locations[0];
          // eslint-disable-next-line prefer-destructuring
          this.selectedBilling = response.data.Locations[1];
          this.$refs.editClientModalRef.ClearModal();
          // eslint-disable-next-line no-undef
          $('#editClientModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    UpdateClientAccess(id) {
      const self = this;
      this.$swal
        .fire({
          title: 'Warning',
          text: 'Are you sure you want to change this property?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.value) {
            self.$axios
              .put(`/api/User/changeUserAccess/${id}`, self.currentUser)
              .then((response) => {
                self.GetClients();
                self.$swal.fire('Success', `${response.data}`, 'success');
              })
              .catch(() => {
                self.$swal.fire('Error', 'Something went wrong.', 'error');
              });
          }
        });
    },
    GetUserRole() {
      this.$axios
        .get('/api/Role/isUserAuthorized')
        .then((response) => {
          this.isAdmin = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/login');
    } else {
      this.GetClients();
      this.GetUserRole();
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1700px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .table {
    width: 1400px;
  }
}
</style>
