<template>
  <div class="row m-0" style="height: 100vh">
    <div class="col-md-6 bg-img left">
      <!--  margin-top: 264px;-->
      <div class="login-content-position login-form-mt">
        <div class="display-01 text-neutral-07" style="margin-bottom: 64px">Change password</div>
        <div class="heading-04 fw-normal text-neutral-07" style="margin-bottom: 26px">
          Please sign in below
        </div>
        <div style="max-width: 350px" class="w-100">
          <Form
            @submit="ChangePassword"
            :validation-schema="schema"
            v-slot="{ errors }"
            ref="changePasswordFormRef"
          >
            <div class="mb-2 pb-1">
              <label for="password" class="label-02 fw-semibold text-neutral-07 mb-2"
                >Password</label
              >
              <Field
                v-model="user.Password"
                class="form-control"
                type="password"
                name="password"
                id="password"
                placeholder="New password..."
                :class="{ 'border-danger': errors.password }"
              />
              <ErrorMessage name="password" class="error-message" />
            </div>
            <div class="mb-4 pb-2">
              <label for="confirmedPassword" class="label-02 fw-semibold text-neutral-07 mb-2">
                Confirm password
              </label>
              <Field
                v-model="user.ConfirmPassword"
                class="form-control"
                type="password"
                name="confirmedPassword"
                id="confirmedPassword"
                placeholder="Confirm password..."
                :class="{ 'border-danger': errors.confirmedPassword }"
              />
              <ErrorMessage name="confirmedPassword" class="error-message" />
              <div class="error-message mt-3 mb-0 fw-semibold" v-if="message">{{ message }}</div>
            </div>
            <div class="row mb-4 pb-1">
              <div class="col-6"></div>
              <div class="col-6 text-end">
                <router-link to="/login" class="forgot-pass-text"> Login </router-link>
              </div>
            </div>
            <button type="submit" class="button btn-large btn-success-solid w-100">
              Change password
            </button>
          </Form>
        </div>
      </div>
    </div>
    <div class="col-6 bg-img right position-relative d-none d-md-block">
      <!-- style="margin-top: 289px" -->
      <div class="login-content-position text-center" style="margin-top: 31vh">
        <div class="display-01 text-white mb-4 pb-2" style="margin-bottom: 64px">
          Easy store management
        </div>
        <div class="efortless-text">
          Effortlessly streamline inventory, sales, and customer relations with <br />
          our intuitive and user-friendly store management solution
        </div>
      </div>
      <img
        src="../../assets/images/login-right-screenshots-cropped.png"
        class="img-right-screenshots"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import authHeader from '../../services/auth-header';

export default {
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: '',
      user: {},
    };
  },
  methods: {
    ClearInputs() {
      this.$refs.changePasswordFormRef.resetForm();
    },
    ChangePassword() {
      this.$axios
        .post('/api/Auth/resetPassword', this.user, { headers: authHeader() })
        .then(() => {
          this.$utils.toastNotify('success', 'Password changed successfully.');
          this.$router.push('/login');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
       
    },
    VerifyToken() {
      if (this.$route.query.token && this.$route.query.email) {
        const verification = {
          Email: this.$route.query.email,
          Code: this.$route.query.token.toString().replace(/\s/g, '+'),
        };
        this.$axios
          .post('/api/Auth/verifyToken', verification)
          .then(() => {})
          .catch(() => {
            this.$utils.toastNotify('error', 'Something went wrong.');
            setTimeout(() => {
              this.$route.push('/login');
            }, "2500");
          });
      } else {
        this.$route.push('/login');
      }
    },
  },
  created() {
    if (!(this.$route.query.token && this.$route.query.email)) {
      this.$route.push('/login');
    }
  },
  mounted() {
    this.VerifyToken();
    this.ClearInputs();
    this.user.Code = this.$route.query.token.toString().replace(/\s/g, '+');
    this.user.Email = this.$route.query.email;
  },
  computed: {
    schema() {
      return yup.object({
        password: yup
          .string()
          .required('New password is required')
          .min(6, 'Password must be at least 6 characters long')
          .test('containt-special-symbols', 'Password must contain special symbols', (password) =>
            /[^a-zA-Z0-9]/.test(password),
          ),
        confirmedPassword: yup
          .string()
          .required('Confirmed new password is required')
          .test('verf-passwords', 'Confimed password is not the same', (password) => {
            if (password === this.user.Password) return true;
            return false;
          }),
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1850px) {
  .img-right-screenshots {
    width: 100%;
  }
}
.login-form-mt {
  margin-top: 20vh;
}
@media only screen and (min-width: 768px) {
  .login-form-mt {
    margin-top: 28.5vh;
  }
}

.img-right-screenshots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.efortless-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
}
.forgot-pass-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--neutral-5);
  transition: 0.2;
}

.forgot-pass-text:hover {
  color: var(--neutral-8);
}

.form-control {
  border-radius: 8px;
  padding: 13.5px;
  border-color: var(--neutral-3);
}
.form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--neutral-5);
}
.login-content-position {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bg-img {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.bg-img.left {
  background-image: url(../../assets/images/bg-login-left.png);
}
.bg-img.right {
  background-image: url(../../assets/images/bg-login-right.png);
}
</style>
