<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center mt-3">
      <li :class="{ 'page-item': true, disabled: pageNumber === 1 }">
        <a @click="ChangePage(-1)" class="page-link" href="#" tabindex="-1" aria-disabled="true">
          <img src="../../assets/images/icons/pagination/chevron-left.svg" />
        </a>
      </li>
      <li class="page-item" v-for="(item, index) in totalPageCount" :key="index">
        <div v-if="item <= 2">
          <a
            :class="{ active: item === pageNumber }"
            @click="SelectPage(item)"
            class="page-link"
            href="#"
            >{{ item }}</a
          >
        </div>
        <div v-else>
          <div v-if="item - 1 === pageNumber || item === pageNumber || item + 1 === pageNumber">
            <a
              :class="{ active: item === pageNumber }"
              @click="SelectPage(item)"
              class="page-link"
              href="#"
              >{{ item }}</a
            >
          </div>
          <div v-else>
            <div v-if="item - 2 <= totalPageCount">
              <div v-if="item > totalPageCount - 2">
                <a
                  :class="{ active: item === pageNumber }"
                  @click="SelectPage(item)"
                  class="page-link"
                  href="#"
                  >{{ item }}</a
                >
              </div>
              <div v-else>
                <div v-if="item - 2 === pageNumber || item + 2 === pageNumber">
                  <div class="row ms-1 me-1">
                    <a class="col-auto page-link" href="#">.</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li :class="{ 'page-item': true, disabled: pageNumber === totalPageCount }">
        <a @click="ChangePage(1)" class="page-link test me-2" href="#">
          <img src="../../assets/images/icons/pagination/chevron-right.svg" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    totalItemCount: {},
    totalPageCount: {},
    pageSize: {},
    pageNumber: {},
    element: {},
  },
  name: 'ColapsePagination',
  methods: {
    SelectPage(nr) {
      this.$emit('select-page', nr, this.element);
    },
    ChangePage(nr) {
      this.$emit('select-page', this.pageNumber + nr, this.element);
    },
  },
};
</script>

<style scoped>
.page-item {
  margin-left: 6px;
  margin-right: 6px;
}
.page-link {
  background-color: transparent !important;
  border: 0px;
  color: var(--neutral-5);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
  cursor: pointer;

  font-size: 14px !important;
  font-weight: 400 !important;
}

.active > .page-link,
.page-link.active {
  background-color: var(--neutral-7) !important;
  color: white;
}

.page-link:hover {
  background-color: var(--neutral-2) !important;
  color: var(--neutral-5);
}
</style>
