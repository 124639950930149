<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="table-scroll">
    <div class="row-table m-0">
      <div class="row header m-0 align-items-center">
        <div class="col-auto ps-0" :class="{ active: orderByProp.includes('id') }" style="width: 5%">
          <span @click="OrderBy('id')" class="pointer d-inline-flex">
            <span class="text">ID</span>
            <order-by-icon-component :orderBy="orderByProp" orderByName="id" />
          </span>
        </div>
        <div class="col-2">
          <span class="text">Products</span>
        </div>
        <div class="col-auto" style="width: 10%" :class="{ active: orderByProp.includes('quantity') }">
          <span @click="OrderBy('quantity')" class="pointer d-inline-flex">
            <span class="text">Quantity</span>
            <order-by-icon-component :orderBy="orderByProp" orderByName="quantity" />
          </span>
        </div>
        <div class="col-2" v-if="!isClientProfile">
          <span class="text">User</span>
        </div>
        <div class="col" :class="{ active: orderByProp.includes('date') }">
          <span @click="OrderBy('date')" class="pointer d-inline-flex">
            <span class="text">Date</span>
            <order-by-icon-component :orderBy="orderByProp" orderByName="date" />
          </span>
        </div>
        <div class="col">
          <span class="text">Status</span>
        </div>
        <div class="col">
          <span class="text">Payment</span>
        </div>
        <div class="col" :class="{ active: orderByProp.includes('price') }">
          <span @click="OrderBy('price')" class="pointer d-inline-flex">
            <span class="text">Total price</span>
            <order-by-icon-component :orderBy="orderByProp" orderByName="price" />
          </span>
        </div>
        <div class="col" :class="{ active: orderByProp.includes('fidelity_points') }">
          <div @click="OrderBy('fidelity_points')" class="pointer d-inline-flex align-items-center">
            <div class="text">Fidelity points</div>
            <order-by-icon-component :orderBy="orderByProp" orderByName="fidelity_points" />
          </div>
        </div>
        <div class="col-auto" style="width: 58px"></div>
      </div>
      <div :class="{ expandable: order.NumberOfProducts > 1 }" v-for="(order, index) in ordersProp" :key="index" v-on:click="order.NumberOfProducts > 1 ? ColapseProducts(order) : ''">
        <div class="row content align-items-center m-0">
          <div class="col-auto ps-0" style="width: 5%">
            <span class="paragraph-01" :style="[order.NumberOfProducts > 1 ? 'padding-left: 37px' : '']">
              <strong>
                <router-link class="no-decoration text-neutral-06" :to="{ name: 'OrderDetails', params: { orderIdParam: order.Id } }">
                  #{{ order.Id }}
                </router-link>
              </strong>
            </span>
          </div>
          <div v-if="order.NumberOfProducts === 1" class="col-2 d-flex align-items-center">
            <div class="row align-items-center">
              <div class="col-auto">
                <img class="content-img" :src="$utils.GetImageFormat(order.ImgBase64)" />
              </div>
              <div class="col">
                <div class="label-02 fw-semibold ms-3">{{ order.ProductName }}</div>
                <div v-if="order.OrderConfigurations" class="label-02 ms-3 mt-2">
                  {{ order.OrderConfigurations }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-2 d-flex align-items-center">
            <img class="content-img" src="../../assets/images/icons/multiple-products-order-24x24.svg" />
            <div class="title ms-3">Multiple product orders</div>
          </div>
          <div class="col-auto" style="width: 10%">{{ order.Quantity }}</div>

          <div class="col">
            {{ new Date(order.PurchaseDate).toLocaleDateString('ro-RO') }}
          </div>
          <div class="col">
            <div class="table-dropdown dropdown" v-on:click.stop>
              <button class="btn-dropdown-outlined dropdown-toggle" type="button" :id="`status-dropdown-${index}`" @click="ShowDropdown(index)" aria-expanded="false">
                {{ order.Status }}
              </button>
              <ul class="dropdown-menu" :aria-labelledby="`status-dropdown-${index}`">
                <li v-for="(status, index) in statuses" :key="index" @click="ChangeOrderStatus(order.Id, status)">
                  <div class="dropdown-item" :class="{ active: status === order.Status }">
                    {{ status }}
                  </div>
                  <hr v-if="index < statuses.length - 1" class="dropdown-divider me-3 ms-3 border-color-blacktint-1" />
                </li>
              </ul>
            </div>
          </div>
          <div class="col">{{ order.Payment }}</div>
          <div class="col d-flex align-items-start">
            <img src="../../assets/images/icons/table/order-table-bag.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="order.CouponCode" />
            <span class="ms-1">{{  $utils.FormatNumber(order.TotalPrice) }} RON</span>
          </div>
          <div class="col">{{ order.TotalFidelityPoints }}</div>
          <div class="col-auto pe-0">
            <div class="d-flex align-items-center float-end" v-on:click.stop>
              <button type="button" class="button table-btn" v-on:click="DeleteOrder(order.Id)">
                <img src="../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
          </div>
          <div class="col-12 collapse expanded-subcategory pe-0" :id="order.Id" style="padding-left: 5.7%">
            <div v-for="product in order.Products" :key="product.Id" class="row subcategories align-items-center m-0">
              <div class="col-auto d-flex align-items-center ps-0 ps-0" style="width: 17.5%">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <img class="content-img" :src="$utils.GetImageFormat(product.ImgBase64)" />
                  </div>
                  <div class="col">
                    <div class="label-02 fw-semibold ms-3">{{ product.Name }}</div>
                    <div v-if="product.OrderConfigurations" class="label-02 ms-3 mt-2">
                      {{ product.OrderConfigurations }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto p-0" :style="[isClientProfile ? 'width: 52.5%' : 'width: 59.5%']">
                {{ product.Quantity }}
              </div>
              <div class="col ps-0">{{ product.Price }} RON</div>
              <div class="col">{{ product.FidelityPoints }}</div>
              <div class="col-auto" style="width: 60px"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-1"></div>
            <div class="col ">
              <div class="row align-items-center jusitfy-content-center mt-3 m-0 border-top-test">
                <div class="col text-neutral-07" v-if="!isClientProfile">
                  <div class="paragraph-02 fw-semibold">{{ order.UserName }}</div>
                  <div class="row align-items-center">
                    <div class="col-auto text-button-01 fw-normal">{{ order.UserPhoneNumber }}</div>
                    <div class="col-auto text-button-01 fw-normal">{{ order.UserEmail }}</div>
                  </div>
                </div>
                <div v-if="order.IsJuridicalPerson" class="col text-neutral-07">
                  <div class="paragraph-02 fw-bold">{{ order.ClientType }}</div>
                  <div class="row align-items-center">
                    <div class="col-auto text-button-01 fw-normal">{{ order.CompanyCIF }}</div>
                    <div class="col-auto text-button-01 fw-normal">{{ order.CompanyName }}</div>
                    <div class="col-auto text-button-01 fw-normal">{{ order.CompanyTradeNumber }}</div>
                  </div>
                </div>
                <div v-else class="col text-neutral-07">
                  <div class="paragraph-02 fw-bold">{{ order.ClientType }}</div>
                </div>
                <div class="col text-neutral-07">
                  <div class="paragraph-02 fw-bold">Pick up date</div>
                  <div class="text-button-01 fw-normal">{{ order.PickUpDate }}</div>
                </div>
                <div v-if="order.Clarifications" class="col text-neutral-07">
                  <div class="paragraph-02 fw-bold">Mentions</div>
                  <div :title="order.Clarifications" class="text-button-01 fw-normal"> {{ $utils.Ellipsify(order.Clarifications, 30) }}</div>
                </div>
                <div v-else class="col text-neutral-07">
                  <div class="paragraph-02 fw-bold">Mentions</div>
                  <div class="text-button-01 fw-normal">No details</div>
                </div>
              </div>
            </div>
            <div class="col-1"></div>
          </div>

          <div v-if="order.ProductsPagination && order.ProductsPagination.TotalPageCount > 1">
            <ColapsePagination :pageSize="order.ProductsPagination.PageSize" :totalItemCount="order.ProductsPagination.TotalItemCount" :totalPageCount="order.ProductsPagination.TotalPageCount" :pageNumber="order.Filter.PageNumber" :element="order" @select-page="SelectPageOrderProducts" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="ordersProp.length == 0" class="mt-5 pt-5 text-center">
      <img src="../../assets/images/not-found-img.png" />
    </div>
  </div>

  <pagination-component :numberOfPages="totalPageCount" :pageNumber="pageNumber" :functionName="SelectPage"></pagination-component>
</template>

<script>
import ColapsePagination from '../General/ColapsePagination.vue';
import OrderByIconComponent from '../General/OrderByIconComponent.vue';
import PaginationComponent from '../General/PaginationComponent.vue';

export default {
  name: 'orders-list-component',
  props: {
    pageNumber: Number,
    totalPageCount: Number,
    orderByProp: String,
    ordersProp: Array,
    isClientProfile: {
      default: false,
    },
  },
  emits: [
    'delete-order',
    'collapse-products',
    'order-by',
    'select-page',
    'select-page-order-products',
  ],
  components: { OrderByIconComponent, ColapsePagination, PaginationComponent },
  data() {
    return {
      statuses: ['New', 'In progress', 'Ready', 'Finished', 'Not Picket UP'],
    };
  },
  methods: {
    ShowDropdown(index) {
      // eslint-disable-next-line no-undef
      $('.dropdown-menu').removeClass('show');
      // eslint-disable-next-line no-undef
      $(`#status-dropdown-${index}`).dropdown('toggle');
    },
    DeleteOrder(id) {
      this.$emit('delete-order', id);
    },
    ColapseProducts(item) {
      this.$emit('collapse-products', item);
    },
    OrderBy(orderBy) {
      // eslint-disable-next-line no-undef
      $('.dropdown-menu').removeClass('show');
      this.$emit('order-by', { orderBy, filter: 'ordersFilter' });
    },
    SelectPage(value) {
      // eslint-disable-next-line no-undef
      $('.dropdown-menu').removeClass('show');
      this.$emit('select-page', value);
    },
    SelectPageOrderProducts(value) {
      this.$emit('select-page-order-products', value);
    },
    ChangeOrderStatus(orderId, newStatus) {
      this.$axios
        .put(`/api/Orders/updateOrderStatus/${orderId}?status=${newStatus}`)
        .then(() => {
          // eslint-disable-next-line no-undef
          $('.dropdown-menu').removeClass('show');
          this.SelectPage(this.pageNumber);
          this.$utils.toastNotify('success', 'Order status updated succesfully');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 1600px) {
  .table-scroll {
      overflow-x: auto;
      position: relative;
  }
 .row-table {
    width: 1500px;
  }
}

.border-top-test {
  border-top: 1px solid var(--tints-blacktint-1, rgba(0, 0, 0, 0.1));
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}
</style>
