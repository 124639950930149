<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66675 12.5V9.83335C2.66675 5.83335 5.33341 3.16669 9.33341 3.16669H22.6667C26.6667 3.16669 29.3334 5.83335 29.3334 9.83335V12.5"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66675 20.5V23.1667C2.66675 27.1667 5.33341 29.8333 9.33341 29.8333H22.6667C26.6667 29.8333 29.3334 27.1667 29.3334 23.1667V20.5"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.93335 12.8467L16 16.94L23.0133 12.8734"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 24.1934V16.9267"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.3467 8.88664L10.0801 11.26C9.12006 11.7933 8.32007 13.14 8.32007 14.2467V18.7667C8.32007 19.8734 9.10672 21.22 10.0801 21.7533L14.3467 24.1266C15.2534 24.6333 16.7467 24.6333 17.6667 24.1266L21.9334 21.7533C22.8934 21.22 23.6934 19.8734 23.6934 18.7667V14.2467C23.6934 13.14 22.9067 11.7933 21.9334 11.26L17.6667 8.88664C16.7467 8.36664 15.2534 8.36664 14.3467 8.88664Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <router-link
          :to="{ name: 'AddProduct' }"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Add <span class="d-none d-sm-inline-block">product</span>
        </router-link>
      </div>
    </div>
  </div>
  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center filters-zone mb-3">
      <!--search-->
      <div class="col-md-6 col-lg-4 col-xxl-3 mb-3">
        <label for="search">Search</label>

        <div class="input-group left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="search"
            v-model="filter.SearchQuery"
            @keyup.enter="GetProducts"
            type="text"
            class="search form-control"
            placeholder="Search..."
          />
        </div>
      </div>
      <!--brand-->
      <div class="col-md-6 col-lg-4 col-xxl-3 mb-3">
        <label for="brand"> Brand</label>
        <div :class="{ 'multiselect-value-selected': filter.Brand }">
          <VueMultiselect
            id="brand"
            v-model="filter.Brand"
            :options="listOfBrands"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="GetProducts"
            @remove="GetProducts"
            class="custom-multiselect"
            select-label=""
            placeholder="Brand"
            @search-change="GetListOfBrands"
            @open="GetListOfBrands()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Brand = '';
              GetProducts();
            "
          >
          </span>
        </div>
      </div>
      <!--category-->
      <div class="col-md-6 col-lg-4 col-xxl-3 mb-3">
        <label for="category"> Categories</label>
        <div :class="{ 'multiselect-value-selected': filter.Category }">
          <VueMultiselect
            id="category"
            v-model="filter.Category"
            :options="listOfCategories"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="OnChangeCategory(Category)"
            @remove="OnChangeCategory(Category)"
            class="custom-multiselect"
            select-label=""
            placeholder="Category"
            @search-change="GetListOfCategories"
            @open="GetListOfCategories()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Category = '';
              GetProducts();
            "
          >
          </span>
        </div>
      </div>
      <!--SUBcategory-->
      <div class="col-md-6 col-lg-4 col-xxl-3 mb-3">
        <label for="subcategory"> Subcategories</label>
        <div :class="{ 'multiselect-value-selected': filter.Subcategory }">
          <VueMultiselect
            id="subcategory"
            v-model="filter.Subcategory"
            :options="listOfSubcategories"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="GetProducts"
            @remove="GetProducts"
            class="custom-multiselect"
            placeholder="Subcategory"
            select-label=""
            @open="OpenSubcategoryMultiselect"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Subcategory = '';
              GetProducts();
            "
          >
          </span>
        </div>
      </div>
      <!--locations-->
      <!-- <div :class="{'col': !filter.Location, 'col-auto': !!filter.Location || isOpen.Location}  [filter.Location || isOpen.Location?'':'']" class="mb-2 mb-xxl-0"> -->
      <div
        :class="[filter.Location ? 'col-auto' : 'col-md-6 col-lg-4 col-xxl-3']"
        class="mb-3 mb-xxl-0"
      >
        <label for="locations"> Locations</label>
        <div :class="{ 'multiselect-value-selected': filter.Location }">
          <VueMultiselect
            id="locations"
            v-model="filter.Location"
            :options="listOfLocations"
            label="LocationName"
            :close-on-select="true"
            track-by="LocationId"
            @select="GetProducts"
            @remove="GetProducts"
            class="custom-multiselect"
            select-label=""
            @search-change="GetListOfLocations"
            placeholder="Location"
            @open="GetListOfLocations()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Location = '';
              GetProducts();
            "
          >
          </span>
        </div>
      </div>
      <!--published-->
      <div class="col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
        <label for="published"> Published</label>
        <div :class="{ 'multiselect-value-selected': filter.Published }">
          <VueMultiselect
            id="published"
            v-model="filter.Published"
            :options="[
              { Id: false, Name: 'Published' },
              { Id: true, Name: 'Draft' },
            ]"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="GetProducts"
            @remove="GetProducts"
            class="custom-multiselect"
            select-label=""
            placeholder="Publish status"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Published = '';
              GetProducts();
            "
          >
          </span>
        </div>
      </div>
      <!--fidelity-->
      <!-- <div class="col-md-6 col-xl-auto mb-2 mb-xl-0">
        <label for="fidelity"> Fidelity</label>
        <div :class="{ 'multiselect-value-selected': filter.Fidelity }">
          <VueMultiselect id="fidelity" v-model="filter.Fidelity" :options="[
              { Id: true, Name: 'Available on fidelity program ' },
              { Id: false, Name: 'Unavailable on fidelity program' },
            ]" label="Name" :close-on-select="true" track-by="Id" @select="GetProducts" @remove="GetProducts" class="custom-multiselect" select-label="" placeholder="Fidelity" />
          <span class="btn-remove-multiselect" v-on:click="
              filter.Fidelity = '';
              GetProducts();
            ">
          </span>
        </div>
      </div> -->
      <!--status-->
      <div class="col-md-6 col-lg-4 col-xxl-3">
        <label for="status"> Status</label>
        <div :class="{ 'multiselect-value-selected': filter.Status }">
          <VueMultiselect
            id="status"
            v-model="filter.Status"
            :options="listOfStatuses"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            @select="GetProducts"
            @remove="GetProducts"
            class="custom-multiselect"
            select-label=""
            @search-change="GetListOfStatuses"
            @open="GetListOfStatuses()"
            placeholder="Status"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Status = '';
              GetProducts();
            "
          >
          </span>
        </div>
      </div>
    </div>
    <!--table-->
    <div class="table-scroll">
      <table class="custom table">
        <thead>
          <tr>
            <th :class="{ active: filter.OrderBy.includes('name') }" width="18%">
              <span @click="OrderBy('name')" class="pointer">
                Name
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="name"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('sku') }" width="8%">
              <span @click="OrderBy('sku')" class="pointer">
                SKU
                <order-by-icon-component :orderBy="filter.OrderBy" orderByName="sku" class="ms-1" />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('price') }" width="8%">
              <span @click="OrderBy('price')" class="pointer">
                Price
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="price"
                  class="ms-1"
                />
              </span>
            </th>
            <th :class="{ active: filter.OrderBy.includes('stock') }" width="7%">
              <span @click="OrderBy('stock')" class="pointer">
                Stock
                <order-by-icon-component
                  :orderBy="filter.OrderBy"
                  orderByName="stock"
                  class="ms-1"
                />
              </span>
            </th>
            <th width="10%">Category</th>
            <th width="7%">Stock</th>
            <th width="8%">Available</th>
            <th width="16%">Locations</th>
            <th width="11%">Published</th>
            <th width="11%">Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="tbody-hover">
          <tr v-for="(product, index) in products" :key="index" valign="middle">
            <td>
              <div class="d-flex align-items-center">
                <img class="content-img" :src="$utils.GetImageFormat(product.ImgBase64)" />
                <div>
                  <div class="title">{{ product.Title }}</div>
                  <div
                    class="description"
                    v-if="product.ShortDescription"
                    :title="product.ShortDescription"
                  >
                    {{ this.$utils.Ellipsify(product.ShortDescription, 20) }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div v-if="product.Sku">
                <p style="max-width: 150px; overflow-wrap: break-word">{{ product.Sku }}</p>
              </div>
              <div v-else>No SKU</div>
            </td>
            <td>
              <div v-if="product.RegularPrice">
                <div>
                  {{ product.RegularPrice }} RON
                  <div v-if="product.SalePrice" style="font-style: normal">
                    <strong class="original-price" style="color: green"
                      >{{ product.SalePrice }} RON</strong
                    >
                  </div>
                  <div v-if="product.Tva" style="font-style: normal">
                    <small style="color: var(--success-5)"
                      ><strong>{{ product.Tva }}% TVA</strong></small
                    >
                  </div>
                </div>
              </div>
              <div v-else>No Price</div>
            </td>
            <td>
              <div v-if="product.Stock">
                {{ product.Stock }}
              </div>
              <div v-else>No Stock</div>
            </td>
            <td>
              <div class="d-flex align-items-center" v-if="product.Category">
                <img
                  v-if="product.Category.ImgBase64"
                  class="content-img-small"
                  :src="$utils.DynamicPathAttachment(product.Category.ImgBase64)"
                />
                <div>
                  <div class="label-02 fw-semibold text-neutral-07">
                    {{ product.Category.Name }}
                  </div>
                  <div class="label-01 fw-normal mt-3px" v-if="product.Subcategory">
                    {{ product.Subcategory.Name }}
                  </div>
                </div>
              </div>
              <div v-else>No category associated</div>
            </td>
            <!-- TO ADD -->
            <td class="fw-semibold">
              <div v-if="product.InStock" style="color: var(--success-5)">Yes</div>
              <div v-else style="color: var(--danger-5)">No</div>
            </td>
            <td class="fw-semibold">
              <div v-if="product.IsAvailable" style="color: var(--success-5)">Yes</div>
              <div v-else style="color: var(--danger-5)">No</div>
            </td>
            <td>
              <div v-if="product.Locations.length > 0">
                <div v-if="product.Locations.length === 1">
                  <div
                    class="label-02 fw-semibold mb-3px text-neutral-07"
                    :title="product.Locations[0].Name"
                  >
                    {{ this.$utils.Ellipsify(product.Locations[0].Name, 18) }}
                  </div>
                  <div
                    class="label-01 fw-normal text-neutral-05"
                    :title="product.Locations[0].Description"
                  >
                    {{ this.$utils.Ellipsify(product.Locations[0].Description, 36) }}
                  </div>
                </div>
                <div v-else class="dropdown table-dropdown">
                  <button
                    class="btn-dropdown-outlined dropdown-toggle"
                    type="button"
                    id="locationsDropdownMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    See locations
                  </button>

                  <ul class="dropdown-menu" aria-labelledby="locationsDropdownMenu">
                    <li v-for="(location, index) in product.Locations" :key="index">
                      <div class="dropdown-item">
                        <div class="row align-items-center">
                          <div class="col">
                            <div
                              class="label-02 fw-semibold mb-3px text-neutral-07"
                              :title="location.Name"
                            >
                              {{ this.$utils.Ellipsify(location.Name, 10) }}
                            </div>
                            <div
                              class="label-01 fw-normal text-neutral-05"
                              :title="location.Description"
                            >
                              {{ this.$utils.Ellipsify(location.Description, 48) }}
                            </div>
                          </div>
                          <div class="col-auto">
                            <span class="paragraph-01"
                              >{{ $utils.FormatNumber(location.RegularPrice || 0) }} RON</span
                            >
                            <!-- <div>In stock: {{ location.Stock || 0 }}</div> -->
                          </div>
                        </div>
                      </div>
                      <hr
                        v-if="index < product.Locations.length - 1"
                        class="dropdown-divider me-3 ms-3 border-color-blacktint-1"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else>Online shop</div>
            </td>
            <td>
              <div v-if="product.Draft" class="d-flex align-items-center">
                <img src="../../assets/images/icons/draft-gray-20x20.svg" />
                <span class="label-02 fw-semibold text-neutral-07 ms-2">Draft</span>
              </div>
              <div v-else class="d-flex align-items-center">
                <img src="../../assets/images/icons/published-success-15x18.svg" />
                <span class="label-02 fw-semibold text-success-06 ms-2">Published</span>
              </div>
            </td>
            <td class="fw-semibold">
              <span v-if="product.Status" :style="{ color: product.Status.Color }">
                {{ product.Status.Name }}
              </span>
              <span v-else> No Status </span>
            </td>
            <td>
              <div class="d-flex align-items-center float-end">
                <router-link
                  class="button table-btn me-2"
                  :to="{ name: 'EditProduct', params: { productIdParam: product.Id } }"
                >
                  <img src="../../assets/images/icons/table/edit-linear.svg" />
                </router-link>

                <button
                  type="button"
                  class="button table-btn"
                  v-on:click="DeleteProduct(product.Id)"
                >
                  <img src="../../assets/images/icons/table/trash-linear.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="products.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';

export default {
  name: 'ProductView',
  components: {
    OrderByIconComponent,
    PaginationComponent,
    VueMultiselect,
  },
  data() {
    return {
      products: {},
      listOfCategories: [],
      listOfBrands: [],
      listOfSubcategories: [],
      listOfLocations: [],
      listOfStatuses: [],
      isOpen: {
        Fidelity: false,
        Category: false,
        Brand: false,
        Published: false,
        Subcategory: false,
        Location: false,
        Status: false,
      },
      filter: {
        Fidelity: '',
        Category: '',
        Brand: '',
        Published: '',
        Subcategory: '',
        Location: '',
        Status: '',
        OrderBy: 'name',
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      pagination: {},
    };
  },

  methods: {
    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetProducts();
    },

    GetProducts() {
      const searchParams = {
        ...(this.filter.Fidelity ? { Fidelity: this.filter.Fidelity.Id } : ''),
        ...(this.filter.Category ? { CategoryId: this.filter.Category.Id } : ''),
        ...(this.filter.Published ? { Published: this.filter.Published.Name } : ''),
        ...(this.filter.Brand ? { BrandId: this.filter.Brand.Id } : ''),
        ...(this.filter.Subcategory ? { SubcategoryId: this.filter.Subcategory.Id } : ''),
        ...(this.filter.Location ? { LocationId: this.filter.Location.LocationId } : ''),
        ...(this.filter.Status ? { StatusId: this.filter.Status.Id } : ''),
        OrderBy: this.filter.OrderBy,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
      };
      this.$axios
        .get(`/api/Product/getAll?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfStatuses(search) {
      this.$axios
        .get(`/api/ProductStatus/getList?searchQuery=${search !== undefined ? search : ''}`)
        .then((response) => {
          this.listOfStatuses = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfBrands(search) {
      this.$axios
        .get(`/api/Brand/getListOfBrands?searchQuery=${search !== undefined ? search : ''}`)
        .then((response) => {
          this.listOfBrands = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfCategories(search) {
      this.$axios
        .get(
          `/api/categories/getListOfCategories?searchQuery=${search !== undefined ? search : ''}`,
        )
        .then((response) => {
          this.listOfCategories = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfSubcategories() {
      this.$axios
        .get('/api/subcategories/getList')
        .then((response) => {
          this.listOfSubcategories = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfLocations(search) {
      this.$axios
        .get(`/api/Location/getListOfLocations?searchQuery=${search !== undefined ? search : ''}`)
        .then((response) => {
          this.listOfLocations = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteProduct(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Product/delete/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetProducts();
                this.$utils.toastNotify('success', 'Product has been deleted.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetProducts();
    },
    ClearBrandId() {
      this.filter.Brand = '';
      this.GetProducts();
    },
    ClearCategoryId() {
      this.filter.Category = '';
      this.GetProducts();
    },
    GetCategorySubcategories(id) {
      this.$axios
        .get(`/api/categories/subcategories/${id}`)
        .then((response) => {
          this.listOfSubcategories = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    OnChangeCategory() {
      if (this.filter.Category) {
        this.GetCategorySubcategories(this.filter.Category.Id);
      } else {
        this.GetListOfSubcategories();
      }
      this.filter.Subcategory = '';
      this.GetProducts();
    },
    OpenSubcategoryMultiselect() {
      if (this.filter.Category) {
        this.GetCategorySubcategories(this.filter.Category.Id);
      } else {
        this.GetListOfSubcategories();
      }
      this.GetProducts();
    },
  },
  created() {
    this.GetProducts();
    this.$store.commit('ChangePage', 'Products');
  },
};
</script>

<style scoped>
@media (max-width: 1800px) {
  .table-scroll {
    overflow-x: auto;
    position: relative;
  }
  .table {
    width: 1700px;
  }
}
.original-price {
  color: var(--danger-5) !important;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
}
</style>
