<template>
  <div class="row align-items-center mb-3">
    <div class="col">
      <div class="heading-01 fw-semibold text-neutral-08 mb-3 pb-1">Package</div>
    </div>
    <div class="col-auto">
      <button
        type="button"
        class="button btn-small btn-success-solid text-center d-flex align-items-center"
        @click="OpenPackageModal"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 10.5H15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 15.5V5.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Add product
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <!--table-->
    <div
      class="row label-02 fw-semibold text-neutral-07 row-border-bottom ms-0 me-0"
      style="padding-bottom: 12px"
    >
      <div class="col ps-0">Name & description</div>
      <div class="col-2">Discount product</div>
      <div class="col-2">Discount associated product</div>
      <div class="col-1 pe-0"></div>
    </div>
    <Form :validation-schema="schema" ref="productPackageFormRef" v-slot="{ errors }">
      <FieldArray name="pack">
        <div
          class="row row-border-bottom pt-3 pb-3 ms-0 me-0 align-items-center"
          v-for="(pack, index) in packages"
          :key="index"
        >
          <div class="col ps-0 d-flex align-items-center">
            <img :src="$utils.GetImageFormat(pack.ImgBase64)" class="package-img" />

            <div>
              <div class="label-02 fw-semibold text-neutral-08 mb-1">
                {{ pack.Title }}
              </div>
              <div class="paragraph-01 text-neutral-06" :title="pack.Description">
                {{ this.$utils.Ellipsify(pack.Description, 100) }}
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="input-group right">
            <Field
              :name="`pack[${index}].discountProduct`"
              v-model="pack.DiscountProduct"
              type="number"
              class="form-control form-control-lg"
              placeholder="Enter discount product"
              :class="{ 'border-danger': errors[`pack[${index}].discountProduct`] }"
            />
            <span class="input-group-text right">%</span>
          </div>
            <ErrorMessage class="error-message" :name="`pack[${index}].discountProduct`" />
          </div>
          <div class="col-2">
            <div class="input-group right">
              <Field
              :name="`pack[${index}].discountAssociatedProduct`"
              v-model="pack.DiscountAssociatedProduct"
              type="number"
              class="form-control form-control-lg"
              placeholder="Enter discount associated"
              :class="{ 'border-danger': errors[`pack[${index}].discountAssociatedProduct`] }"
            />
            <span class="input-group-text right">%</span>
          </div>
          <ErrorMessage
              class="error-message"
              :name="`pack[${index}].discountAssociatedProduct`"
            />
          </div>
          <div class="col-1 pe-0">
            <button type="button" class="button table-btn float-end" @click="RemovePackage(index)">
              <img src="../../../assets/images/icons/table/trash-linear.svg" />
            </button>
          </div>
        </div>
      </FieldArray>
    </Form>

    <div v-if="!packages || packages.length == 0" class="mt-5 pt-5 text-center">
      <img src="../../../assets/images/not-found-img.png" />
    </div>
  </div>
  <AddPackageModal
    v-if="selectedTab === 'package'"
    ref="addPackageModalRef"
    :filter="filter"
    :productId="productId"
    :products="products"
    :pagination="pagination"
    @add="AddPackage"
    @get="GetProducts"
    @search="GetSearch"
  />
</template>

<script>
/*eslint-disable*/
import { Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import AddPackageModal from './Modals/AddPackageModal.vue';

// import AddPackageModal from '../ProductPackage/Modals/AddPackageModal.vue';
import * as yup from 'yup';

export default {
  name: 'ProductPackage',
  emits: ['get', 'add', 'delete', 'edit'],
  props: {
    packages: Array,
    productId: Number,
    selectedTab: String,
  },
  data() {
    return {
      products: [],
      pagination: {},
      filter: {
        PageSize: this.$store.state.globalPageSize,
        PageNumber: 1,
        SearchQuery: '',
      },
    };
  },
  components: {
    AddPackageModal,
    Field,
    ErrorMessage,
    FieldArray,
    Form,
  },
  methods: {
    RemovePackage(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.packages.splice(index, 1);
    },
    Submit() {
      return this.$refs.productPackageFormRef.validate().then((result) => result.valid);
    },
    OpenPackageModal() {
      this.filter.PageNumber = 1;
      const searchParams = {
        ProductId: this.productId,
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
      };
      this.$axios
        .get(`/api/ProductPackage/getProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.pagination = response.data.PageDetails;
          // eslint-disable-next-line vue/no-mutating-props
          this.products.splice(0, this.products.length);
          response.data.Items.forEach((element) => {
            this.products.push(this.AlreadyExist(element));
          });
          // eslint-disable-next-line no-undef
          this.$refs.addPackageModalRef.ClearModal();
          $('#addPackage').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProducts() {
      this.filter.PageNumber += 1;
      const searchParams = {
        ProductId: this.productId,
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/ProductPackage/getProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.products.splice(0, this.products.length);
          response.data.Items.forEach((element) => {
            this.products.push(this.AlreadyExist(element));
          });
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetSearch() {
      this.filter.PageNumber = 1;
      const searchParams = {
        ProductId: this.productId,
        PageSize: this.filter.PageSize,
        PageNumber: this.filter.PageNumber,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
      };
      this.$axios
        .get(`/api/ProductPackage/getProducts?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          response.data.Items.forEach((element) => {
            this.products.push(this.AlreadyExist(element));
          });
          this.pagination = response.data.PageDetails;
          this.$refs.addRecomandationModalRef.ClearModal();
          // eslint-disable-next-line no-undef
          $('#addPackage').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    AlreadyExist(product) {
      let pack = this.packages.find((x) => x.AssociatedProductId === product.AssociatedProductId);
      if (pack) {
        pack.Selected = true;
        return pack;
      } else {
        product.Selected = false;
        return product;
      }
    },
    AddPackage(products) {
      // eslint-disable-next-line vue/no-mutating-props
      this.packages.splice(0, this.packages.length);
      products.forEach((element) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.packages.push(element);
      });
    },
  },
  computed: {
    schema() {
      return yup.object({
        pack: yup.array().of(
          yup.object().shape({
            discountProduct: yup
              .number()
              .min(0, 'Discount product cannot be negative')
              .test('is-deciaml', 'The number should not be a deciaml', (number) => {
                if (!number) {
                  return true;
                }
                return /^\d+(\.\d{0})?$/.test(number);
              })
              .notRequired()
              .transform((_, val) => (val === Number(val) ? val : null)),
            discountAssociatedProduct: yup
              .number()
              .min(0, 'Discount associated product cannot be negative')
              .test('is-deciaml', 'The number should not be a deciaml', (number) => {
                if (!number) {
                  return true;
                }
                return /^\d+(\.\d{0})?$/.test(number);
              })
              .notRequired()
              .transform((_, val) => (val === Number(val) ? val : null)),
          }),
        ),
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1200px) {
  .table-responsive .row {
    width: 992px;
  }
}

.package-img {
  width: 48px;
  height: 48px;
  object-fit: contain;
  margin-right: 12px;
}

.input-group-text {
  border-color: var(--bs-border-color) !important;
}
</style>
