<template>
  <div class="row align-items-center">
    <div class="col">
      <div class="heading-01 fw-semibold text-neutral-08">Specifications</div>
    </div>
    <div class="col-auto d-flex align-items-center">
      <button
        type="button"
        class="button btn-small btn-success-solid text-center d-flex align-items-cemter"
        @click="OpenSectionModal"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 10.5H15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 15.5V5.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Add section
      </button>
    </div>
  </div>
  <!-- :data-bs-target="`#section-collapse-${sectionIndex}`" aria-expanded="false"
  :aria-controls="`#section-collapse-${sectionIndex}`" -->
  <div class="table-responsive">
    <div class="row-table m-0">
      <Form :validation-schema="schema" ref="productSpecificationsFormRef" v-slot="{ errors }">
        <FieldArray name="section">
          <div
            class="row content expandable align-items-center ms-0 me-0 mt-3 border-top-hightlighted border-bottom-0"
            v-for="(section, sectionIndex) in sections"
            :key="sectionIndex"
            @click="ExpandSpecification(`#section-collapse-${sectionIndex}`)"
          >
            <div class="col-5">
              <div class="ms-4" v-on:click.stop>
                <label class="title mb-2">Section name</label>
                <Field
                  :name="`section[${sectionIndex}].name`"
                  v-model="section.Name"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Name"
                  :class="{ 'border-danger': errors[`section[${sectionIndex}].name`] }"
                />
                <ErrorMessage class="error-message" :name="`section[${sectionIndex}].name`" />
              </div>
            </div>
            <div class="col"></div>
            <div class="col-auto pe-0" v-on:click.stop>
              <button type="button" class="button table-btn" @click="RemoveSection(sectionIndex)">
                <img src="../../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
            <div
              class="row expanded-subcategory p-0 m-0 collapse"
              v-on:click.stop
              :id="`section-collapse-${sectionIndex}`"
            >
              <div class="col-auto cursor-default" style="width: 50px"></div>
              <div class="col p-0">
                <div class="row row-border-bottom pb-2 label-02 fw-semibold text-neutral-07 mt-3">
                  <div class="col-3 col-xxl-4 ps-0">Name</div>
                  <div class="col"></div>
                  <div class="col">Value</div>
                  <div class="col-1 pe-0"></div>
                </div>

                <FieldArray :name="`section[${sectionIndex}].specifications`">
                  <div
                    v-for="(specification, index) in section.Specifications"
                    :key="index"
                    class="row subcategories align-items-center"
                  >
                    <div class="col-3 col-xxl-4 paragraph-02 fw-normal text-neutral-08 ps-0">
                      {{ specification.Name }}
                    </div>
                    <div class="col"></div>
                    <div class="col">
                      <VueMultiselect
                        class="custom-multiselect lg"
                        v-show="specification.Values.length > 0"
                        v-model="specification.ValueDetails"
                        :options="specification.Values"
                        :close-on-select="true"
                        :allow-empty="false"
                        label="Value"
                        track-by="Id"
                        placeholder="Select one"
                        deselectLabel="Selected option"
                        select-label=""
                        :class="{
                          'border-danger-multiselect':
                            errors[`section[${sectionIndex}].specifications[${index}].value`],
                        }"
                      />
                      <Field
                        v-if="specification.ValueDetails"
                        v-model="specification.ValueDetails.Value"
                        :name="`section[${sectionIndex}].specifications[${index}].value`"
                        class="d-none"
                      ></Field>

                      <Field
                        v-show="specification.Values.length == 0"
                        type="text"
                        v-model="specification.Value"
                        :name="`section[${sectionIndex}].specifications[${index}].value`"
                        class="form-control form-control-lg"
                        placeholder="Enter value"
                        :class="{
                          'border-danger':
                            errors[`section[${sectionIndex}].specifications[${index}].value`],
                        }"
                      ></Field>
                      <ErrorMessage
                        :name="`section[${sectionIndex}].specifications[${index}].value`"
                        class="error-message"
                      />
                    </div>
                    <div class="col-1 pe-0">
                      <button
                        type="button"
                        class="button table-btn float-end"
                        @click="RemoveSpecification(section.Specifications, index)"
                      >
                        <img src="../../../assets/images/icons/table/trash-linear.svg" />
                      </button>
                    </div>
                  </div>
                </FieldArray>
                <button
                  type="button"
                  @click="OpenSpecifcationModal(section)"
                  class="button btn-add-specification mt-3"
                >
                  <div class="text-center d-flex align-items-center">
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 10.5H15.5"
                        stroke="#53666D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 15.5V5.5"
                        stroke="#53666D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div class="text-button-02 fw-semibold text-neutral-06">Add specifications</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </FieldArray>
      </Form>
    </div>
  </div>

  <div v-if="sections.length == 0" class="mt-5 pt-5 text-center">
    <img src="../../../assets/images/not-found-img.png" />
  </div>

  <AddSpecifications
    ref="addSpecificationModalRef"
    @add-specifications="AddSpecification"
    v-if="selectedTab === 'specifications'"
  />
  <AddSection ref="addSectionModalRef" :sections="sections" @add-section="AddSection" />
</template>

<script>
/* eslint-disable */
import VueMultiselect from 'vue-multiselect';
import { Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';

import AddSpecifications from './Modals/AddSpecificationsModal.vue';
import AddSection from './Modals/AddSectionModal.vue';

export default {
  name: 'ProductSpecificationsComponent',
  emits: ['save'],
  components: {
    VueMultiselect,
    AddSpecifications,
    AddSection,
    Field,
    ErrorMessage,
    FieldArray,
    Form,
  },
  props: {
    sections: Array,
    selectedTab: String,
  },
  data() {
    return {
      selectedSection: {},
    };
  },
  methods: {
    ExpandSpecification(id) {
      $(`${id}`).collapse('toggle');
    },

    async Submit() {
      return this.$refs.productSpecificationsFormRef.validate().then((result) => result.valid);
    },

    OpenSectionModal() {
      this.$refs.addSectionModalRef.ClearSectionModal();
      // eslint-disable-next-line no-undef
      $('#addSectionModal').modal('show');
    },
    OpenSpecifcationModal(section) {
      this.selectedSection = section;
      this.$refs.addSpecificationModalRef.ClearSpecificationModal();
      this.$refs.addSpecificationModalRef.GetSpecifcationCategories();
      // eslint-disable-next-line no-undef
      $('#addSpecificationModal').modal('show');
    },

    AddSection(section) {
      // eslint-disable-next-line vue/no-mutating-props
      this.sections.push(section);
    },
    AddSpecification(specifications) {
      specifications.forEach((specification) => {
        this.selectedSection.Specifications.push({
          SpecificationId: specification.Id,
          Name: specification.Name,
          Values: specification.Values,
        });
      });
    },

    RemoveSpecification(specifications, index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (index !== -1) {
              specifications.splice(index, 1);
              this.$refs.productSpecificationsFormRef.handleReset();
              this.$utils.toastNotify('success', 'Specification has been deleted.');
            }
          }
        }).catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    RemoveSection(index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (index !== -1) {
              // eslint-disable-next-line vue/no-mutating-props
              this.sections.splice(index, 1);
            }
            this.$refs.productSpecificationsFormRef.handleReset();
            this.$utils.toastNotify('success', 'Section has been deleted.');
          }
        }).catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
  },
  computed: {
    schema() {
      return yup.object({
        section: yup.array().of(
          yup.object().shape({
            name: yup
              .string()
              .required('Section name is required')
              .min(3, 'Section name must have at least 3 caracters')
              .trim(),
            specifications: yup.array().of(
              yup.object().shape({
                value: yup
                  .string()
                  .test('is-valid', 'Specification name is required', (value) => {
                    if (!value) {
                      return false;
                    }
                    return true;
                  })
                  .trim(),
              }),
            ),
          }),
        ),
      });
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1000px) {
  .table-responsive .row-table {
    width: 700px;
  }
}

.row-table .content.expandable:before {
  top: 50px;
}
.border-top-hightlighted {
  border-top: 1px solid var(--blacktint-4);
}

.btn-add-specification {
  object-fit: cover;
  width: 100%;
  height: 53px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.btn-add-specification:hover {
  background-color: var(--neutral-2);
}
</style>
