<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7466 3.1665L6.91992 8.0065"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.2529 3.1665L25.0796 8.0065"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66699 10.9665C2.66699 8.4998 3.98699 8.2998 5.62699 8.2998H26.3737C28.0137 8.2998 29.3337 8.4998 29.3337 10.9665C29.3337 13.8331 28.0137 13.6331 26.3737 13.6331H5.62699C3.98699 13.6331 2.66699 13.8331 2.66699 10.9665Z"
              stroke="#1F2629"
              stroke-width="1.5"
            />
            <path
              d="M13.0137 19.1665V23.8998"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M19.1465 19.1665V23.8998"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M4.66699 13.8335L6.54699 25.3535C6.97366 27.9402 8.00033 29.8335 11.8137 29.8335H19.8537C24.0003 29.8335 24.6137 28.0202 25.0937 25.5135L27.3337 13.8335"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <div class="d-none d-sm-flex align-items-center">
          <div class="text-center">
            <div style="font-size: x-small; line-height: 1" class="text-uppercase fw-medium">
              total
            </div>
            <div class="heading-04 fw-semibold">{{ pagination.TotalItemCount || 0 }}</div>
          </div>
        </div>
        <div class="d-flex align-items-center d-sm-none">
          <div class="text-center">
            <div style="font-size: 8px; line-height: 1" class="text-uppercase fw-medium">total</div>
            <div class="heading-02 fw-semibold">{{ pagination.TotalItemCount || 0 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content" style="margin-top: 12px">
    <div class="row align-items-center filters-zone mb-3">
      <div class="col-sm-7 col-xl-4 col-xxl">
        <label for="search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="search"
            v-model="filter.SearchQuery"
            @keyup.enter="SearchByQuery"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            aria-label="Text input with dropdown button"
          />
        </div>
      </div>
      <div class="col-sm-5 mt-3 mt-sm-0 col-xl-4 col-xxl">
        <!-- <label for="orders-date">Date</label>
        <VueDatePicker
          v-model="filter.Date"
          placeholder="Select date"
          :enableTimePicker="false"
          autoApply
          class="custom-datepicker"
          name="Start date"
          format="dd-MM-yyyy"
          id="orders-date"
          @update:modelValue="FilterByDate()"
        ></VueDatePicker> -->
        <label for="orders-date">Date</label>
        <VueDatePicker
          v-model="orderInterval"
          placeholder="Select interval"
          :enableTimePicker="false"
          autoApply
          multi-calendars
          range
          class="custom-datepicker"
          name="Start date"
          format="dd-MM-yyyy"
          id="orders-date"
          @update:modelValue="FilterByDate()"
        ></VueDatePicker>
      </div>
      <div class="col-sm-6 col-lg col-xl-4 col-xxl mt-3 mt-xl-0">
        <label for="orders-date">Status</label>
        <div :class="{ 'multiselect-value-selected': filter.Status }">
          <VueMultiselect
            id="status"
            v-model="filter.Status"
            :options="statusesForFilter"
            :close-on-select="true"
            @select="GetOrders"
            @remove="GetOrders"
            class="custom-multiselect"
            select-label=""
            placeholder="Select status"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Status = '';
              GetOrders();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-sm-6 col-lg mt-3 mt-xxl-0">
        <label for="orders-date">Client type</label>
        <div :class="{ 'multiselect-value-selected': filter.ClientType }">
          <VueMultiselect
            id="status"
            v-model="filter.ClientType"
            :options="[
              { Name: 'Individual', Value: false },
              { Name: 'Legal person', Value: true },
            ]"
            :close-on-select="true"
            @select="GetOrders"
            @remove="GetOrders"
            class="custom-multiselect"
            select-label=""
            placeholder="Select type"
            label="Name"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.ClientType = '';
              GetOrders();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-6 col-lg mt-3 mt-xxl-0">
        <label>Pick up date</label>
        <VueDatePicker
          v-model="filter.PickUpDate"
          placeholder="Select date"
          :enableTimePicker="false"
          autoApply
          class="custom-datepicker"
          format="dd-MM-yyyy"
          id="orders-date"
          @update:modelValue="GetOrders()"
        ></VueDatePicker>
      </div>
       <div class="col-6 col-lg mt-3 mt-xxl-0">
        <label for="orders-date">Payment type</label>
        <div :class="{ 'multiselect-value-selected': filter.CashPayment }">
          <VueMultiselect
            id="status"
            v-model="filter.CashPayment"
            :options="[
              { Name: 'Cash', Value: true },
              { Name: 'Card', Value: false },
            ]"
            :close-on-select="true"
            @select="GetOrders"
            @remove="GetOrders"
            class="custom-multiselect"
            select-label=""
            placeholder="Select type"
            label="Name"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.CashPayment = '';
              GetOrders();
            "
          >
          </span>
        </div>
      </div>
    </div>
    <orders-list-component
      :orderByProp="filter.OrderBy"
      :ordersProp="orders"
      :pageNumber="filter.PageNumber"
      :totalPageCount="pagination.TotalPageCount"
      @delete-order="DeleteOrder"
      @collapse-products="ColapseProducts"
      @order-by="OrderBy"
      @select-page="SelectPage"
      @select-page-order-products="SelectPageOrderProducts"
    />
  </div>
</template>

<script>
import OrdersListComponent from '../../components/Orders/OrdersListComponent.vue';

export default {
  name: 'UserOrdersComponent',
  data() {
    return {
      orders: [],
      pagination: {},
      filter: {
        SearchQuery: '',
        StartDate: '',
        EndDate: '',
        OrderBy: 'date_desc',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        Status: '',
        ClientType: '',
        PickUpDate: '',
      },
      statusesForFilter: ['New', 'In progress', 'Ready', 'Finished', 'Not Picket UP', 'Canceled'],
      orderInterval: '',
    };
  },
  components: {
    OrdersListComponent,
  },
  methods: {
    FilterByDate() {
      if (this.orderInterval) {
        this.filter.StartDate = this.orderInterval
          ? new Date(this.orderInterval[0]).toISOString()
          : '';
        this.filter.EndDate = this.orderInterval
          ? new Date(this.orderInterval[1]).toISOString()
          : '';
      }
      this.GetOrders();
    },
    SearchByQuery() {
      this.filter.PageNumber = 1;
      this.GetOrders();
    },
    SelectPage(nr) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.PageNumber = nr || 1;
      this.GetOrders();
    },
    SelectPageOrderProducts(nr, order) {
      // eslint-disable-next-line no-param-reassign
      order.Filter.PageNumber = nr;
      this.GetOrderProducts(order);
    },
    ColapseProducts(order) {
      if (!order.Filter) {
        // eslint-disable-next-line no-param-reassign
        order.Filter = {
          PageNumber: 1,
          PageSize: this.$store.state.globalPageSize,
        };
      }
      this.ColapseOrderProducts(order);
    },
    OrderBy(params) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${params.orderBy}_desc`;
      } else {
        this.filter.OrderBy = params.orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetOrders();
    },
    GetOrders() {
      const searchParams = new URLSearchParams({
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
        ...(this.filter.OrderBy ? { OrderBy: this.filter.OrderBy } : ''),
        ...(this.filter.Status ? { Status: this.filter.Status } : ''),
        // ...(this.filter.ClientType ? { ClientType: this.filter.ClientType } : ''),
        ...(this.filter.StartDate ? { StartDate: this.filter.StartDate } : ''),
        ...(this.filter.EndDate ? { EndDate: this.filter.EndDate } : ''),
        ...(this.filter.PickUpDate ? { PickUpDate: this.filter.PickUpDate.toISOString() } : ''),
        ...(this.filter.ClientType ? { IsJuridicalPerson: this.filter.ClientType.Value } : ''),
        ...(this.filter.CashPayment ? { CashPayment: this.filter.CashPayment.Value } : ''),

      });
      // filterParam.Date = this.filter.Date ? moment(this.filter.Date).format('YYYY-MM-DD') : '';
      if (this.orders.length > 0) {
        // eslint-disable-next-line no-undef
        $('div.expanded-subcategory').removeClass('show');
      }
      this.$axios
        .get(`/api/Orders/getOrders?${searchParams}`)
        .then((response) => {
          this.pagination = response.data.PageDetails;
          this.orders = response.data.Items;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    DeleteOrder(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Orders/deleteOrder/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetOrders();
                this.$utils.toastNotify('success', 'Order deleted succesesfully');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },

    ColapseOrderProducts(order) {
      // eslint-disable-next-line no-param-reassign
      order.Filter.PageSize = 5;
      // eslint-disable-next-line no-param-reassign
      const searchParams = new URLSearchParams(order.Filter);
      this.$axios
        .get(`/api/Orders/getOrderProducts/${order.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          order.Products = response.data.Items;
          // eslint-disable-next-line no-undef
          $(`#${order.Id}`).collapse('toggle');
          // eslint-disable-next-line no-param-reassign
          order.Collapse = order.Collapse == null ? true : !order.Collapse;
          // eslint-disable-next-line no-param-reassign
          order.ProductsPagination = response.data.PageDetails;
        });
    },

    GetOrderProducts(order) {
      const searchParams = new URLSearchParams(order.Filter);
      this.$axios
        .get(`/api/Orders/getOrderProducts/${order.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          order.Products = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          order.ProductsPagination = response.data.PageDetails;
        });
    },
  },
  mounted() {
    this.GetOrders();
  },
};
</script>
