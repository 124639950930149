<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/valid-v-bind -->
<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            class="page-header-img"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.3333 11.86V5.80669C29.3333 3.92669 28.48 3.16669 26.36 3.16669H20.9733C18.8533 3.16669 18 3.92669 18 5.80669V11.8467C18 13.74 18.8533 14.4867 20.9733 14.4867H26.36C28.48 14.5 29.3333 13.74 29.3333 11.86Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.3333 26.86V21.4733C29.3333 19.3533 28.48 18.5 26.36 18.5H20.9733C18.8533 18.5 18 19.3533 18 21.4733V26.86C18 28.98 18.8533 29.8333 20.9733 29.8333H26.36C28.48 29.8333 29.3333 28.98 29.3333 26.86Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 11.86V5.80669C14 3.92669 13.1467 3.16669 11.0267 3.16669H5.64002C3.52002 3.16669 2.66669 3.92669 2.66669 5.80669V11.8467C2.66669 13.74 3.52002 14.4867 5.64002 14.4867H11.0267C13.1467 14.5 14 13.74 14 11.86Z"
              stroke="#3AAB14"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 26.86V21.4733C14 19.3533 13.1467 18.5 11.0267 18.5H5.64002C3.52002 18.5 2.66669 19.3533 2.66669 21.4733V26.86C2.66669 28.98 3.52002 29.8333 5.64002 29.8333H11.0267C13.1467 29.8333 14 28.98 14 26.86Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold  d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="OpenAddCategoryModal()"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10.5H15"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 15.5V5.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Add <span class="d-none d-sm-inline-block">category</span>
        </button>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="row mb-3 filters-zone">
      <div class="col-lg-8 mb-3 mb-md-0 col-xxl-4">
        <label for="categories-search">Search</label>
        <div class="input-group w-100 left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-normal-20x20.svg" />
          </span>
          <input
            id="categories-search"
            v-model="filter.SearchQuery"
            @keyup.enter="GetCategories()"
            :placeholder="`Search in...`"
            type="text"
            class="form-control search"
            aria-label="Text input with dropdown button"
          />

          <button
            type="button"
            class="button btn-neutral-outlined dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ filter.SearchBy }}
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              @click="ChangeSearchBy"
              :class="{ active: filter.SearchBy == 'Categories' }"
            >
              Categories
            </li>
            <li>
              <hr class="dropdown-divider me-3 ms-3 border-color-blacktint-1" />
            </li>
            <li
              class="dropdown-item"
              @click="ChangeSearchBy"
              :class="{ active: filter.SearchBy == 'Subcategories' }"
            >
              Subcategories
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table-scroll">
      <div class="row-table m-0">
        <div class="row header m-0">
          <div class="col-7 ps-0" :class="{ active: filter.OrderBy.includes('name') }">
            <span @click="OrderBy('name')" class="pointer d-flex">
              <span class="text">Name</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="name" />
            </span>
          </div>
          <div class="col-4" :class="{ active: filter.OrderBy.includes('subcategories') }">
            <span @click="OrderBy('subcategories')" class="pointer d-flex">
              <span class="text">Subcategories</span>
              <order-by-icon-component :orderBy="filter.OrderBy" orderByName="subcategories" />
            </span>
          </div>
          <div class="col-auto"></div>
        </div>
        <div
          class="row content align-items-center m-0"
          :class="{ expandable: category.NumberOfSubcategories > 0 }"
          v-on:click="
            category.NumberOfSubcategories > 0 ? ColapseCategorySubcategories(category) : ''
          "
          v-for="(category, index) in categoriesList"
          :key="index"
        >
          <div class="col-7 d-flex align-items-center" style="padding-left: 37px">
            <img class="content-img" :src="$utils.DynamicPathAttachment(category.ImgBase64)" />
            <div class="ms-3">
              <div class="title">{{ category.Name }}</div>
              <div
                v-if="category.Description"
                class="description"
                :title="category.Description"
                style="max-width: 90%"
              >
                {{ category.Description }}
              </div>
            </div>
          </div>
          <div class="col">
            <div class="title">{{ category.NumberOfSubcategories }} subcategories</div>
          </div>
          <div class="col-auto pe-0" v-on:click.stop>
            <div class="d-flex align-items-center">
              <button
                type="button"
                class="button table-btn me-2"
                style="padding: 11px"
                v-on:click="OpenAddSubcategoryModal(category)"
              >
                <img src="../../assets/images/icons/table/add-linear.svg" />
              </button>
              <button
                type="button"
                class="button table-btn me-2"
                v-on:click="GetCategoryDetails(category.Id)"
              >
                <img src="../../assets/images/icons/table/edit-linear.svg" />
              </button>
              <button
                type="button"
                class="button table-btn"
                v-on:click="DeleteCategory(category.Id)"
              >
                <img src="../../assets/images/icons/table/trash-linear.svg" />
              </button>
            </div>
          </div>
          <div class="row collapse expanded-subcategory p-0 m-0" v-on:click.stop :id="category.Id">
            <div class="col-auto cursor-default" style="width: 100px"></div>
            <div class="col p-0">
              <div class="subcategory">Subcategories</div>
              <div
                v-for="(subcategory, index) in category.SubcategoriesList"
                :key="index"
                class="row subcategories align-items-center"
              >
                <div class="col-auto sub-title pe-0" style="width: 56.33333333%; flex: 0 0 auto">
                  {{ subcategory.Name }}
                </div>
                <div class="col sub-description">{{ subcategory.Description }}</div>
                <div class="col-auto d-flex align-items-center">
                  <img
                    src="../../assets/images/icons/table/expanded/edit.svg"
                    class="me-3 pointer"
                    @click="GetSubcategoryDetails(subcategory.Id, category)"
                  />
                  <img
                    class="pointer"
                    src="../../assets/images/icons/table/expanded/trash.svg"
                    @click="DeleteSubcategory(subcategory.Id, category)"
                  />
                </div>
              </div>
              <div
                v-if="
                  category.SubcategoryPagination &&
                  category.SubcategoryPagination.TotalPageCount > 1
                "
              >
                <ColapsePagination
                  :key="componentKey"
                  :pageSize="category.SubcategoryPagination.PageSize"
                  :totalItemCount="category.SubcategoryPagination.TotalItemCount"
                  :totalPageCount="category.SubcategoryPagination.TotalPageCount"
                  :pageNumber="category.Filter.PageNumber"
                  :element="category"
                  @select-page="SelectPageSubcategory"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="categoriesList.length == 0" class="mt-5 pt-5 text-center">
        <img src="../../assets/images/not-found-img.png" />
      </div>
    </div>

    <EditSubcategoryModal
      :subcategory="editSubcategoryData"
      :category="selectedCategory"
      @update="GetCategorySubcategories"
    />
    <AddSubcategoryModal
      ref="addSubcategoryModalRef"
      :category="selectedCategory"
      @get="GetCategorySubcategories"
    />

    <AddCategoryModal ref="addCategoryModalRef" @save="GetCategories" />
    <EditCategoryModal :category="editCategoryData" @update="GetCategories" />

    <pagination-component
      :numberOfPages="pagination.TotalPageCount"
      :pageNumber="filter.PageNumber"
      :functionName="SelectPage"
    ></pagination-component>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
/* eslint-disable import/no-extraneous-dependencies */

import AddCategoryModal from '../../components/Category/Modals/CategoryModal/AddCategoryModal.vue';
import EditCategoryModal from '../../components/Category/Modals/CategoryModal/EditCategoryModal.vue';

import AddSubcategoryModal from '../../components/Category/Modals/SubcategoryModal/AddSubcategoryModal.vue';
import EditSubcategoryModal from '../../components/Category/Modals/SubcategoryModal/EditSubcategoryModal.vue';

import OrderByIconComponent from '../../components/General/OrderByIconComponent.vue';
import ColapsePagination from '../../components/General/ColapsePagination.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';

export default {
  name: 'CategoryView',
  components: {
    AddCategoryModal,
    AddSubcategoryModal,
    EditSubcategoryModal,
    EditCategoryModal,
    OrderByIconComponent,
    ColapsePagination,
    PaginationComponent,
  },
  data() {
    return {
      categoriesList: [],
      editCategoryData: {},
      editSubcategoryData: {},
      subcategoriesList: [],
      filter: {
        SearchBy: 'Categories',
        SearchQuery: '',
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
        OrderBy: 'name',
        SubcategoryId: '',
      },
      subcategoryFilter: {
        PageNumber: 1,
        PageSize: this.$store.state.globalPageSize,
      },
      pagination: {},
      selectedCategory: {},
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    ChangeSearchBy() {
      if (this.filter.SearchBy === 'Categories') {
        this.filter.SearchBy = 'Subcategories';
      } else {
        this.filter.SearchBy = 'Categories';
      }
      this.GetCategories();
    },
    FilterBySubcategory(id) {
      this.filter.SubcategoryId = id;
      this.GetCategories();
      this.filter.SubcategoryId = '';
    },

    OrderBy(orderBy) {
      if (!this.filter.OrderBy.includes('_desc')) {
        this.filter.OrderBy = `${orderBy}_desc`;
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.filter.PageNumber = 1;
      this.GetCategories();
    },
    OpenAddCategoryModal() {
      this.$refs.addCategoryModalRef.ClearModalValues();
      // eslint-disable-next-line no-undef
      $('#addCategoryModal').modal('show');
    },
    OpenAddSubcategoryModal(category) {
      this.selectedCategory = category;
      this.$refs.addSubcategoryModalRef.ClearModalValues();
      // eslint-disable-next-line no-undef
      $('#addSubcategoryModal').modal('show');
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr || 1;
      this.GetCategories();
    },
    SelectPageSubcategory(nr, category) {
      // eslint-disable-next-line no-param-reassign
      category.Filter.PageNumber = nr;
      this.GetCategorySubcategories(category);
    },
    GetCategories() {
      const searchParams = new URLSearchParams(this.filter);
      if (this.categoriesList.length > 0) {
        // eslint-disable-next-line no-undef
        $('div.expanded-subcategory').removeClass('show');
      }
      this.$axios
        .get(`/api/categories/getAll?${searchParams}`)
        .then((response) => {
          this.categoriesList = response.data.Items;
          this.pagination = response.data.PageDetails;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },

    GetCategorySubcategories(category) {
      if (!category.Filter) {
        // eslint-disable-next-line no-param-reassign
        category.Filter = {
          PageNumber: 1,
          PageSize: 5,
        };
      }
      const searchParams = new URLSearchParams(
        {
          PageNumber: category.Filter.PageNumber,
          PageSize: category.Filter.PageSize,
          ...(this.filter.SearchBy === 'Categories' ? '' : { SearchQuery: this.filter.SearchQuery }),
        },
      );
      this.$axios
        .get(`/api/categories/getSubcategories/${category.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          category.SubcategoriesList = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          category.SubcategoryPagination = response.data.PageDetails;
          this.$forceUpdate();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    ColapseCategorySubcategories(category) {
      if (!category.Filter) {
        // eslint-disable-next-line no-param-reassign
        category.Filter = {
          PageNumber: 1,
          PageSize: 5,
        };
      }
      const searchParams = new URLSearchParams(
        {
          PageNumber: category.Filter.PageNumber,
          PageSize: category.Filter.PageSize,
          ...(this.filter.SearchBy === 'Categories' ? '' : { SearchQuery: this.filter.SearchQuery }),
        },
      );
      this.$axios
        .get(`/api/categories/getSubcategories/${category.Id}?${searchParams}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          category.SubcategoriesList = response.data.Items;
          // eslint-disable-next-line no-param-reassign
          category.SubcategoryPagination = response.data.PageDetails;
          // eslint-disable-next-line no-undef
          $(`#${category.Id}`).collapse('toggle');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    // Edits functions
    GetSubcategoryDetails(id, category) {
      this.selectedCategory = category;

      this.$axios
        .get(`/api/subcategories/get/${id}`)
        .then((response) => {
          this.editSubcategoryData = response.data;
          // eslint-disable-next-line no-undef
          $('#editSubcategoryModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetCategoryDetails(id) {
      this.$axios
        .get(`/api/categories/get/${id}`)
        .then((response) => {
          this.editCategoryData = response.data;
          // eslint-disable-next-line no-undef
          $('#editCategoryModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteCategory(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/categories/delete/virtual/${id}`)
              .then(() => {
                this.SelectPage();
                this.GetCategories();
                this.$utils.toastNotify('success', 'Category has been deleted.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    DeleteSubcategory(subcategoryId, category) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/subcategories/delete/virtual/${subcategoryId}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Subcategory has been deleted.');
                // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
                category.NumberOfSubcategories -= 1;
                if (category.NumberOfSubcategories === 0) {
                  // eslint-disable-next-line no-undef
                  $(`#${category.Id}`).collapse('toggle');
                }
                this.GetCategorySubcategories(category);
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
  },
  created() {
    this.GetCategories();
    this.$store.commit('ChangePage', 'Categories');
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1200px) {
  .table-scroll {
      overflow-x: auto;
      position: relative;
  }
  .table-scroll .row-table {
    width: 1000px;
  }
}
</style>
