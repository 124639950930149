eslint-disable import/no-extraneous-dependencies
<template>
  <div
    class="modal fade"
    id="addSubcategoryModal"
    tabindex="-1"
    aria-labelledby="addSubcategoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addSubcategoryFormRef"
          @submit="Submit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../../assets/images/icons/edit-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="editSubcategoryModalLabel">
              Add subcategory for: {{ category.Name }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="recipient-name">Subcategory name</label>
              <Field
                name="name"
                type="text"
                class="form-control"
                id="recipient-name"
                v-model="subcategory.Name"
                placeholder="Name"
                :class="{ 'border-danger': errors.name }"
              ></Field>
              <ErrorMessage class="error-message" name="name" />
            </div>
            <label for="message-text">Subcategory description</label>
            <Field
              name="description"
              as="textarea"
              type="text"
              class="form-control"
              id="message-text"
              v-model="subcategory.Description"
              placeholder="Description"
              :class="{ 'border-danger': errors.description }"
            ></Field>
            <ErrorMessage class="error-message" name="description" />
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'AddSubcategoryModal',
  emits: ['get'],
  props: {
    category: {},
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      subcategory: {
        Name: '',
        Description: '',
        CategoryId: '',
      },
    };
  },
  methods: {
    ClearModalValues() {
      this.$refs.addSubcategoryFormRef.resetForm();
    },
    Submit() {
      this.SaveSubcategory();
    },
    SaveSubcategory() {
      this.subcategory.CategoryId = this.category.Id;
      this.$axios
        .post('/api/subcategories/create', this.subcategory)
        .then(() => {
          this.$utils.toastNotify('success', 'Subcategory added successfully.');
          // eslint-disable-next-line vue/no-mutating-props
          this.category.NumberOfSubcategories += 1;
          this.$emit('get', this.category);
          // eslint-disable-next-line no-undef
          $('#addSubcategoryModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    ResetModal() {
      this.subcategory = {};
    },
  },
  computed: {
    schema() {
      return yup.object({
        name: yup.string().required('Name field is required.').min(3, 'Subcategory title must have at least 3 characters').max(50, 'Subcategory title must be under 50 characters')
          .trim(),
        description: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,200})$/, 'Description must be between 5-200 characters'),
      });
    },
  },
};
</script>
