<template>
  <!-- not logged in -->

  <router-view />
</template>

<script>
export default {
  name: 'not-logged-in-layout',
  data() {
    return {};
  },
};
</script>

<style scoped></style>
